import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import ApiContext from "../Context/context";
import { useSearch } from "../Context/SearchContext";
import LoadingSpinner from "../../../../FemilerCom/loading/spinner";

function MyEnrollments() {
  
  const {
    enrollments,
    setShouldFetchEnrollments,
    loading,
  } = useContext(ApiContext);
  

  const { searchQuery } = useSearch();

  const navigate = useNavigate();

  useEffect(() => {
    if(setShouldFetchEnrollments){
      setShouldFetchEnrollments(true)
    }
  }, [])
  
  const filteredcompanies = enrollments?.filter((product) =>
    product.code?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  return (
    <Box>
      {loading && <LoadingSpinner />}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Exam Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredcompanies?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.code}</TableCell>
                <TableCell>
                  {row.finished === 1 ? <Typography color="success">Finished</Typography> : <Typography color="error">Not Finished</Typography>}
                </TableCell>
                <TableCell>{row.exam_id === 1 ? "Twindix Executive" : "Job Bar"}</TableCell>
                <TableCell>
                  <Button
                  sx={{margin :'4px'}}
                    variant="contained"
                    style={{
                          background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                          color: "white",
                        }}
                    onClick={() =>
                      row.finished === 1
                        ? 
                        window.open(`/reportPage?enro_id=${row.id}&exam_id=${row.exam_id}`)
                        : 
                        navigate(`/Qustion/${row.exam_id}/${row.id}`)
                    }
                  >
                    {row.finished === 1 ? "Download Report" : "Take Assessment"}
                  </Button>
                  {row?.is_debriefing === 1 && row.finished === 1 (
                    <Button
                    sx={{color: "white",margin :'4px', background : row?.is_debriefing ? 'gray' :"linear-gradient(45deg, #2c6264, #5ac4cb)" }}
                    variant="contained"
                    disabled={row?.is_debriefing}>
                        Send Debriefing
                  </Button>)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default MyEnrollments;
