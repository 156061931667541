import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import axios from "axios";
import AccesCompanyComponent from "./Component/AccessJopBar/AccesCompany";
import AccesPositionComponent from "./Component/AccessJopBar/AccesPosition";
import JopBarEmployee from "./Component/AccessJopBar/JopBarEmployee";
import ApiContext from "../Context/context";

function AccessJopBar({
  setShowBackBtn,
}) {
  const {handelPermission } = useContext(ApiContext);
    const isHasPermission = handelPermission('company-show')

  const [showSection, setShowSection] = useState('company');
  const [company, setCompany] = useState();
  const [positionId, setPositionId] = useState();
  const [getSpecCompany, setGetSpecCompany] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    setShowBackBtn(true);
  }, [showSection, setShowBackBtn]);

  const handleBackButtonClick = () => {
    if (showSection === 'assessment') {
      setShowSection('position'); // Go back to the position section
    } else if (showSection === 'position') {
      setShowSection('company'); // Go back to the company section
    }
  };

  useEffect(() => {
    if(company?.id !== undefined && isHasPermission){      
      setLoading(true)
    const fetchEmployeeDetails = async () => {
      try {
        const res = await axios.get(`https://api.twindix.com/companies/${company?.id}`, {
          headers: { Authorization: `Bearer ${localStorage.token}` },
        });
        setLoading(false)
        setGetSpecCompany(res.data.payload);
      } catch (err) {
        setLoading(false)
        console.log(err)
      }
    };

    fetchEmployeeDetails();
  }
  }, [company?.id, isHasPermission]);

  return (
    <Box>
      {showSection === "company" ?
        <AccesCompanyComponent
          setShowSection={setShowSection}
          setCompany={setCompany}
        />
        : showSection === "position" ?
        <AccesPositionComponent
        setShowSection={setShowSection}
        handleBackButtonClick={handleBackButtonClick}
        setPositionId={setPositionId}
        company={company}
        getSpecCompany={getSpecCompany}
        loading={loading}
        />
        : showSection === "assessment" &&
        <JopBarEmployee
          getSpecCompany={getSpecCompany}
          handleBackButtonClick={handleBackButtonClick}
          positionId={positionId}
        />
      }
    </Box>
  );
}

export default AccessJopBar;
