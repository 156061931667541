import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import axios from "axios";
import AddPackageForm from "../Forms/AddPackageForm";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDropzone } from "react-dropzone";
import DeleteDialog from "../../../../FemilerCom/DeleteDialog/DeleteDiaolg";

function Packages() {
  const [Packages, setPackages] = useState([]);
  const [SnackbarMsg, setSnackbarMsg] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [Refrech, setRefrech] = useState(0);
  const [imagePreview, setImagePreview] = useState(null);

  // Individual state for each field
  const [editId, setEditId] = useState(null);
  const [editName, setEditName] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editExamCount, setEditExamCount] = useState("");
  const [editExamId, setEditExamId] = useState("");
  const [editPrice, setEditPrice] = useState("");
  const [editCoverImage, setEditCoverImage] = useState(null);

  useEffect(() => {
    fetchPackages();
  }, [Refrech]);

  const fetchPackages = async () => {
    try {
      const res = await axios.get("https://api.twindix.com/dashboard/packages", {
        headers: { Authorization: "Bearer " + localStorage.token },
      });
      setPackages(res.data.payload.data);
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  const handleDeletePackage = async (id) => {
    try {
      const res = await axios.delete(
        `https://api.twindix.com/dashboard/packages/${id}`,
        { headers: { Authorization: "Bearer " + localStorage.token } }
      );
      setSnackbarMsg(res.status === 200 ? "Package Deleted" : "Package not Deleted");
      setOpenSnackbar(true);
      fetchPackages();
    } catch (error) {
      console.error("Error deleting package:", error);
      setSnackbarMsg("Error deleting package");
      setOpenSnackbar(true);
    }
  };

  const handleEditPackage = (pkg) => {
    setEditId(pkg.id);
    setEditName(pkg.name);
    setEditDescription(pkg.description);
    setEditExamCount(pkg.exams_count);
    setEditExamId(pkg.exam_id);
    setEditPrice(pkg.price);
    setEditCoverImage(pkg.cover_image || null);
    setImagePreview(pkg.cover_image || null);
    setOpenEditDialog(true);
  };

  const handleSaveEdit = async () => {
    try {
      const formData = new FormData();
      formData.append("name", editName);
      formData.append("description", editDescription);
      formData.append("exams_count", editExamCount);
      formData.append("exam_id", editExamId);
      formData.append("price", editPrice);

      if (editCoverImage instanceof File) {
        formData.append("cover_image", editCoverImage);
      }

      await axios.put(
        `https://api.twindix.com/dashboard/packages/${editId}`,
        formData,
        { headers: { Authorization: "Bearer " + localStorage.token, "Content-Type": "multipart/form-data" } }
      );

      setSnackbarMsg("Package updated successfully");
      setOpenSnackbar(true);
      fetchPackages();
      setOpenEditDialog(false);
    } catch (error) {
      console.error("Error updating package:", error);
      setSnackbarMsg("Error updating package");
      setOpenSnackbar(true);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setEditCoverImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });
    const [PackageId, setPackageId] = useState()
    const [openDelete, setOpenDelete] = useState(false)
    const handleCloseDelete = () => {
      setOpenDelete(false)
    }
    const DeleteFun = () => {
      handleDeletePackage(PackageId)
      handleCloseDelete()
    }

  return (
    <Box>
      <Box width="100%">
        <Button
          variant="contained"
          sx={{ margin: "20px", background: "linear-gradient(45deg, #2c6264, #5ac4cb)" }}
          onClick={() => setOpenAddDialog(true)}
        >
          Add Package
        </Button>
        <AddPackageForm setRefrech={setRefrech} open={openAddDialog} setOpen={setOpenAddDialog} />
      </Box>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="left">Exam Count</TableCell>
              <TableCell align="left">Exam Id</TableCell>
              <TableCell align="left">Price</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Packages.length ? (
              Packages.map((pkg) => (
                <TableRow key={pkg.id}>
                  <TableCell>{pkg.id}</TableCell>
                  <TableCell>{pkg.name}</TableCell>
                  <TableCell>{pkg.description}</TableCell>
                  <TableCell>{pkg.exams_count}</TableCell>
                  <TableCell>{pkg.exam_id}</TableCell>
                  <TableCell>{pkg.price}</TableCell>
                  <TableCell>
                    <Button sx={{ background: "red", color: "white" }} onClick={() => {
                      setPackageId(pkg.id)
                      setOpenDelete(true)
                      }}>
                      <DeleteIcon />
                    </Button>
                    <Button sx={{ background: "linear-gradient(45deg, #2c6264, #5ac4cb)", color: "white" }} onClick={() => handleEditPackage(pkg)}>
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7}>There are no Packages</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} fullWidth maxWidth="sm">
        <DialogTitle>Edit Package</DialogTitle>
        <DialogContent>
          <TextField fullWidth margin="normal" label="Name" value={editName} onChange={(e) => setEditName(e.target.value)} />
          <TextField fullWidth margin="normal" label="Description" value={editDescription} onChange={(e) => setEditDescription(e.target.value)} />
          <TextField fullWidth margin="normal" label="Exam Count" value={editExamCount} onChange={(e) => setEditExamCount(e.target.value)} />
          <TextField fullWidth margin="normal" label="Exam ID" value={editExamId} onChange={(e) => setEditExamId(e.target.value)} />
          <TextField fullWidth margin="normal" label="Price" value={editPrice} onChange={(e) => setEditPrice(e.target.value)} />
          
          <Box {...getRootProps()} sx={{ border: '2px dashed #aaa', padding: '20px', textAlign: 'center', cursor: 'pointer' }}>
            <input {...getInputProps()} />
            {imagePreview ? <img src={imagePreview} alt="Cover" width={100} /> : "Drag & drop an image or click to select"}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleSaveEdit}>Save</Button>
        </DialogActions>
      </Dialog>
      <DeleteDialog open={openDelete} handleClose={handleCloseDelete} DialogTitle="Package" DialogDeleteContent='This Package' DeleteFun={DeleteFun}  />
    </Box>
  );
}

export default Packages;
