import {Button, Stack, useMediaQuery } from "@mui/material"
import {useState } from "react"
import Flex from "./Component/Subscriptions/Flex"
import Package from "./Component/Subscriptions/Packages";
import Statistics from "../ShareHr/statistics";


function Subscriptions ({setSubscriptions}) {
  const isMobile = useMediaQuery("(max-width:600px)");

    const [ToggleSubscriptions ,setToggleSubscriptions] = useState(true)
    const toggleHandler = () => {
        setToggleSubscriptions((prev) => !prev);
    }
    
    return (
        <>
          <Statistics  setSubscriptions={setSubscriptions} />
          <Stack width='100%'>
              <Stack  justifyContent='center' p={1} m='auto' spacing={3} direction='row' sx={{width: isMobile ? '100%': '450px', border: '3px solid #2c6768', borderRadius: '28px'}}>
                  <Button onClick={toggleHandler} sx={{ border : '0', fontWeight: 'bold', fontSize: '13px', color : !ToggleSubscriptions ? '#2c6768' : 'white', borderRadius :'30px', background: !ToggleSubscriptions ?'white' :  '#2c6768'}} variant="contained">Flexible Purchases</Button>
                  <Button onClick={toggleHandler} sx={{ border : '0', fontWeight: 'bold', fontSize: '13px', color : !ToggleSubscriptions ?'white' : '#2c6768', borderRadius :'30px', background:  !ToggleSubscriptions ?'#2c6768' : 'white'}} variant="contained">Annual Subscriptions</Button>
              </Stack>
              {ToggleSubscriptions ? <Flex /> : <Package />}
          </Stack>
        </>
        
    )
}

export default Subscriptions
