import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box, Grid, TextField, Typography, Stack, DialogActions, Snackbar, useMediaQuery, Checkbox, FormControlLabel, CircularProgress } from '@mui/material';
import ApiContext from '../../../../Context/context';

export default function AddRole({ setFetchRolesTrigger, open, setOpen }) {
  const { allPermissions, setShouldFetchPermissions } = React.useContext(ApiContext);
  
  const [formData, setFormData] = React.useState({
    name: '',
    permissions: [],
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [error, setError] = React.useState('');
  const isMobile = useMediaQuery('(max-width:600px)');

   React.useEffect(() => {
      if(setShouldFetchPermissions){
        setShouldFetchPermissions(true)
      }
    }, [])

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle checkbox change
  const handleCheckboxChange = (permissionId) => {
    setFormData((prev) => ({
      ...prev,
      permissions: prev.permissions.includes(permissionId)
        ? prev.permissions.filter((id) => id !== permissionId) // Remove if unchecked
        : [...prev.permissions, permissionId], // Add if checked
    }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!formData.name || formData.permissions.length === 0) {
      setError('Please fill all required fields.');
      return;
    }

    const payload = {
      name: formData.name,
      permission: formData.permissions,
    };

    setIsLoading(true);
    setError('');

    try {
      const response = await fetch('https://api.twindix.com/roles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Response:', data);
      setOpenSnackbar(true); // Show success snackbar
      setFormData({ name: '', permissions: [] }); // Reset form
      setFetchRolesTrigger((prev) => prev + 1); // Trigger refetch
      setOpen(false); // Close the dialog
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to create role. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <React.Fragment>
      <Dialog
        PaperProps={{
          sx: {
            position: isMobile ? 'fixed' : 'relative',
            left: isMobile ? 0 : 'auto',
            margin: 0,
            height: isMobile ? '100%' : 'auto',
            borderRadius: isMobile ? 0 : '8px',
            width: isMobile ? 'auto' : '450px',
          },
        }}
        maxWidth={isMobile ? 'sm' : 'md'}
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ bgcolor: '#929292', height: isMobile ? '100%' : 'auto' }}>
          <DialogContent
            sx={{
              maxHeight: '500px',
              overflowY: 'auto',
              '&::-webkit-scrollbar': { width: '6px' },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
              },
              '&::-webkit-scrollbar-track': { background: '#929292', borderRadius: '10px' },
            }}
          >
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>
                  Name:
                </Typography>
                <TextField
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Insert role name"
                  fullWidth
                  sx={{ backgroundColor: 'white', borderRadius: 1 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>
                  Permissions:
                </Typography>
                <Stack>
                  <Grid container spacing={2}>
                    {allPermissions.map((permission) => (
                      <Grid item xs={6} key={permission.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formData.permissions.includes(permission.id)}
                              onChange={() => handleCheckboxChange(permission.id)}
                              sx={{ color: 'white', '&.Mui-checked': { color: '#5ac4cb' } }}
                            />
                          }
                          label={permission.name}
                          sx={{ color: 'white' }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
                borderRadius: '10px',
                margin: 'auto',
              }}
              onClick={handleSubmit}
              variant="contained"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Create Role'}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Role Added"
      />
    </React.Fragment>
  );
}