import { Button, Stack } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import BorderHorizontalIcon from '@mui/icons-material/BorderHorizontal';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SendIcon from '@mui/icons-material/Send';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useContext } from "react";
import ApiContext from "../../../Context/context";

export default function Buttons ({
    isMobile,
    setOpen,
    setOpenFile,
    handleDownload,
    handleSelectAll,
    handleOpenSelectedAllInvite,
    handleOpenSelectedInvite,
    setOpenSend,
    handleOpenSelectedAllReminder,
    jopBar,
    handleSelectAllReminder
}) {
  const {
    handelPermission2
  } = useContext(ApiContext);
  handelPermission2(['employee-create'])

    return (
        <Stack spacing={2.5}>
        {/* First two buttons in one line */}
        <Stack spacing={2} direction={isMobile ? 'column': "row"}>
        <Stack direction="row" spacing={3} justifyContent="flex-start">
          <Button
          id="employee-create"
            onClick={() => {
                setOpen(true);
            }}
            size="large"
            variant="contained"
            sx={{ fontSize: isMobile ? '11px' : "17px", borderRadius: "15px", background: "#3f8e8f" }}
          >
            <AddIcon />
            Add Participant
          </Button>
          <Button
          id="employee-create"
            onClick={() => {
              setOpenFile(true)}}
            size="large"
            variant="contained"
            sx={{ fontSize:  isMobile ? '11px' :"17px", borderRadius: "15px", background: "#3f8e8f" }}
          >
            <BorderHorizontalIcon />
            Upload Bulk Participant
          </Button>
        </Stack>

        {/* Third button alone in a new line */}
        <Stack direction="row" justifyContent="flex-start">
          <Button
            onClick={handleDownload}
            size="large"
            variant="contained"
            sx={{ margin: isMobile && '10px auto 10px auto' ,fontSize: isMobile ? '11px' : "17px", borderRadius: "15px", background: "#3f8e8f" }}
          >
            <InsertDriveFileIcon />
            Download Form
          </Button>
        </Stack>
        </Stack>

        <Stack spacing={2} direction={isMobile ? 'column': "row"}>
        {/* Fourth and Fifth buttons in one line */}
        <Stack direction="row" spacing={3} justifyContent="flex-start">
          <Button
            onClick={() => {
              if(jopBar) {
                setOpenSend(true)
              }else{
              handleSelectAll();
              handleOpenSelectedAllInvite();
              }
            }}
            size="small"
            variant="contained"
            sx={{ fontSize: isMobile ? '11px' : "14px", background: "#3f8e8f", borderRadius: "30px" }}
          >
            <SendIcon />
            Send Assessment to All Participant
          </Button>
          <Button
            onClick={() =>{ 
              if(jopBar) {
                setOpenSend(true)
              }else{
            handleOpenSelectedInvite()
              }}
            }
            size="small"
            variant="contained"
            sx={{ fontSize: isMobile ? '11px' : "14px", background: "#3f8e8f", borderRadius: "30px" }}
          >
            <SendIcon />
            Send Assessment to Selected Participant
          </Button>
        </Stack>
          
        {/* Sixth button alone in a new line */}
        <Stack direction="row" justifyContent="flex-start">
          <Button
            onClick={() => {
              handleSelectAllReminder();
              handleOpenSelectedAllReminder();
            }}
            size="small"
            variant="contained"
            sx={{margin: isMobile && '10px auto 10px auto' , fontSize:  isMobile ? '11px' :"14px", background: "#3f8e8f", borderRadius: "30px" }}
          >
            <NotificationsIcon />
            Send Reminder to All Participant
          </Button>
        </Stack>
        </Stack>
      </Stack>
    )
}