import { Chip, Stack, useMediaQuery } from "@mui/material";
import DownloadForOfflineIcon from '@mui/icons-material/ArrowDownward';

function ActionsChips({
  benchmark,
  ind,
  downloadAllBenchmarkReport,
  downloadBenchmarkSelectedReport,
  downloadAllIngReport,
  downloadIngSelectedReport,
}) {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Stack mt={isMobile ? 1 : 4} mb={4} direction="row" justifyContent="left" alignItems="center" spacing={2}>
      {benchmark && (
        <>
          <div
            style={{ml : isMobile && '0', textAlign : isMobile && 'center', width: isMobile ? '50%' : 'auto', cursor: 'pointer' }}
            onClick={downloadAllBenchmarkReport}
          >
            <Chip
              sx={{
                width :'100%' ,
                fontSize: isMobile ? '10px' : 'inherit',
                mr: isMobile ? 0 : 2,
                color: 'white',
                backgroundColor: "#3f8d90",
                padding: isMobile ? '0' : '20px',
                '& .MuiChip-icon': { color: 'white', fontSize: '14px' },
              }}
              variant="filled"
              label="Download All Reports"
              icon={<DownloadForOfflineIcon />}
            />
          </div>
          <div
            style={{ml : isMobile && '0', textAlign : isMobile && 'center', width: isMobile ? '50%' : 'auto', cursor: 'pointer' }}
            onClick={downloadBenchmarkSelectedReport}
          >
            <Chip
              sx={{
                width :'100%',
                fontSize: isMobile ? '10px' : 'inherit',
                mr: isMobile ? 0 :2,
                color: 'white',
                backgroundColor: "#3f8d90",
                padding:  isMobile ? '0' :'20px',
                '& .MuiChip-icon': { color: 'white', fontSize: '14px' },
              }}
              variant="filled"
              label="Download Selected Reports"
              icon={<DownloadForOfflineIcon />}
            />
          </div>
        </>
      )}
      {ind && (
        <>
          <div
            style={{ textAlign : isMobile && 'center', width: isMobile ? '50%' : 'auto', cursor: 'pointer' }}
            onClick={downloadAllIngReport}
          >
            <Chip
              sx={{
                width :'100%',
                fontSize: isMobile ? '10px' : 'inherit',
                mr: isMobile ? 0 : 2,
                color: 'white',
                backgroundColor: "#3f8d90",
                padding: isMobile ? '0' : '20px',
                '& .MuiChip-icon': { color: 'white', fontSize: '14px' },
              }}
              variant="filled"
              label="Download All Reports"
              icon={<DownloadForOfflineIcon />}
            />
          </div>
          <div
            style={{ml : isMobile && '0', textAlign : isMobile && 'center', width: isMobile ? '50%' : 'auto', cursor: 'pointer' }}
            onClick={downloadIngSelectedReport}
          >
            <Chip
              sx={{
                width :'100%',
                fontSize: isMobile ? '10px' : 'inherit',
                mr: isMobile ? 0 :2,
                color: 'white',
                backgroundColor: "#3f8d90",
                padding:  isMobile ? '0' :'20px',
                '& .MuiChip-icon': { color: 'white', fontSize: '14px' },
              }}
              variant="filled"
              label="Download Selected Reports"
              icon={<DownloadForOfflineIcon />}
            />
          </div>
        </>
      )}
    </Stack>
  );
}

export default ActionsChips;
