import {
    Button,
    Checkbox,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
} from "@mui/material";
import BigChips from "../../../ShareHr/BigChips/Index";
import ActionsChips from "../../../ShareHr/ActionsChips";
import { useContext, useEffect, useState } from "react";
import DownloadForOfflineIcon from "@mui/icons-material/ArrowDownward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import Regenerate from "../../../../Individual/Component/Form/Regenerate";
import ApiContext from "../../../Context/context";

export default function TwindexEmployee({
    setShowUser,
    setShowBackBtn,
    company,
}) {
  const {handelPermission } = useContext(ApiContext);
    const isHasPermission = handelPermission('employee-show')
  
    const isMobile = useMediaQuery("(max-width:600px)");

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [openDownload, setopenDownload] = useState(false);
    const [ids, setIds] = useState([]);
    const [getSpecCompany, setGetSpecCompany] = useState();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [SnackbarText, setSnackbarText] = useState()

    const finishedAssessments = getSpecCompany?.exam_enrollments.filter((ele) => ele.finished === 1).length
    const pendingAssessments = getSpecCompany?.exam_enrollments.filter((ele) => ele.finished === 0).length


    const handleRowSelect = (rowId) => {
        setSelectedRows((prev) =>
          prev.includes(rowId) ? prev.filter((id) => id !== rowId) : [...prev, rowId]
        );
      };

    const handleSelectAll = () => {
      if (selectAll) {
        setSelectedRows([]);
      } else {
        setSelectedRows(getSpecCompany?.exam_enrollments.map((row) => row.finished === 1 && row.exam_enrollment_id));
      }
      setSelectAll(!selectAll);
    };

    const handleClickOpen = () => {
        setopenDownload(true);
    };
      
    const handleSelectId = (rowId) => {
        setIds((prevIds) =>
          prevIds.includes(rowId) ? prevIds.filter((id) => id !== rowId) : [...prevIds, rowId]
        );
    };

    const handleDownloadAll = () => {
        const ids = getSpecCompany?.exam_enrollments
          .filter((row) => row.finished === 1)
          .map((row) => row.exam_enrollment_id);
      
          setIds(ids);
    };
  
    useEffect(() => {
      if(company.id !== undefined && isHasPermission){      
      const fetchEmployeeDetails = async () => {
        try {
          const res = await axios.get(`https://api.twindix.com/companies/${company.id}`, {
            headers: { Authorization: `Bearer ${localStorage.token}` },
          });
          setGetSpecCompany(res.data.payload);
        } catch (err) {
          console.log(err)
        } 
      };
      
      fetchEmployeeDetails();
    }
    }, [company.id, isHasPermission]);
    // Handle back button click
    const handleBackButton = () => {
        setShowUser(false);
        setShowBackBtn(false);
    };
    console.log(getSpecCompany)
    return (
    <>
        <Button
            onClick={handleBackButton}
            sx={{
            position: "fixed",
            top: "97px",
            left: "0",
            fontSize: "18px",
        }}
        >
            <ArrowBackIcon /> Back
        </Button>
        <BigChips
            finishedAssessments={`${finishedAssessments}`}
            pendingAssessments={`${pendingAssessments}`}
            userName={company.name}
        />
        <ActionsChips
            ind={true}
            downloadAllIngReport={() => {
          handleDownloadAll()
          handleClickOpen()
           }}
            downloadIngSelectedReport={() => {
          setIds(selectedRows)
          handleClickOpen()
          }}
        />
        {getSpecCompany?.exam_enrollments.length > 0 ? (
        <TableContainer>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell width="74px">
                    <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                        aria-label="Select all rows"
                    />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {getSpecCompany?.exam_enrollments.map((enrol) => {
                return (
                    <TableRow key={enrol.exam_enrollment_id}>
                        <TableCell width="74px">
                        <Checkbox
                            checked={selectedRows.includes(enrol.exam_enrollment_id)}
                            onChange={() => handleRowSelect(enrol.exam_enrollment_id)}
                        />
                        </TableCell>
                        <TableCell sx={{ color: "#5e5e5e", textDecoration: "underline" }}>
                        {enrol.user_name}
                        </TableCell>
                        <TableCell sx={{ color: "#5e5e5e", textDecoration: "underline" }}>
                        {enrol.user_email}
                        </TableCell>
                        <TableCell sx={{ color: "#5e5e5e", textDecoration: "underline" }}>
                        {enrol.created_at.slice(0, 10)}
                        </TableCell>
                        <TableCell>
                        <Stack direction="row" spacing={2}>
                            {enrol.finished === 1 ? (
                            <Button
                                variant="contained"
                                sx={{ background: "#2b6566" }}
                                onClick={() => {
                                    handleSelectId(enrol.exam_enrollment_id)
                                    handleClickOpen(enrol)}}
                            >
                                <DownloadForOfflineIcon />
                                Download Report
                            </Button>
                            ) : (
                            <Button variant="contained" sx={{ background: "#2b6566" }}>
                                No Report Yet
                            </Button>
                            )}
                        </Stack>
                        </TableCell>
                    </TableRow>
                    );
                })}
            </TableBody>
            </Table>
            </TableContainer>
        ) : (
            <Typography sx={{ marginLeft: "350px" }}>No Reports Yet</Typography>
        )}
              <Regenerate
                openDownload={openDownload}
                isMobile={isMobile}
                handleCloseDownload={() => setopenDownload(false)}
                ids={ids}
                setSnackbarOpen={setSnackbarOpen}
                setSnackbarText={setSnackbarText}
              />
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={SnackbarText}
              />
        </>
    );
}