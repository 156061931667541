import { Chip, Stack, useMediaQuery } from "@mui/material";

function BigChips({ FinishedAssessment, PendingAssessment, UserName }) {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Stack mt={4} mb={isMobile ? 1 : 4} direction={isMobile ? 'column' : 'row'} justifyContent='left' alignItems='center'>
      <Stack sx={{ width: isMobile ? '100%' : '33%' }}>
        <Chip
          sx={{
            m: 1,
            padding: '30px 70px',
            borderRadius: "140px",
            border: '3px solid #3e7e72',
            color: '#3e7e72',
            fontSize: "18px",
            fontWeight: '700'
          }}
          label={UserName}
          variant="outlined"
        />
      </Stack>
      <Stack direction={'row'} sx={{ width: isMobile ? '100%' : '75%' }}>
        <Chip
          sx={{
            width: isMobile ? 'calc(50% - 8px)' : '49%',
            padding: isMobile ? '10px' : '30px 70px',
            m: 1,
            background: "linear-gradient(45deg, #77c853, #347461)",
            color: 'white',
            fontSize: isMobile ? '10px' : "18px",
            fontWeight: isMobile ? '500' : '700',
            borderRadius: "140px"
          }}
          label={`${FinishedAssessment} Finished Assessments`}
        />
        <Chip
          sx={{
            width: isMobile ? 'calc(50% - 8px)' : '49%',
            padding: isMobile ? '10px' : '30px 70px',
            m: 1,
            background: "linear-gradient(45deg, #ad301a, #d87631)",
            color: 'white',
            fontSize: isMobile ? '10px' : "18px",
            fontWeight: isMobile ? '500' : '700',
            borderRadius: "140px"
          }}
          label={`${PendingAssessment} Pending Assessments`}
        />
      </Stack>
    </Stack>
  );
}

export default BigChips;