import { Box, Button, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { appContext } from "../../../../../App";
import _ from "lodash"; 

export default function ResendAnswers({
    openSendExist,
    handleSendExistClose,
    downloadDetails,
    filteredEnroll
}) {
    const navigate = useNavigate();
    const isLoggedIn = useContext(appContext);
    const userName = isLoggedIn?.userName?.replaceAll(" ", "-");

    const [newestTwinixExam, setNewestTwinixExam] = useState(null);
    const [newestJobBarExam, setNewestJobBarExam] = useState(null);

    const assessmentName =  downloadDetails.exam_id === 1 ? "TwinixExecutives" 
                            :
                            downloadDetails.exam_id === 65 ? "JobBar" : "Another";

    useEffect(() => {
        const finishedTwinixExams = filteredEnroll?.filter(enrollment => enrollment.finished === 1 && enrollment.exam_id === 1);
        const finishedJobBarExams = filteredEnroll?.filter(enrollment => enrollment.finished === 1 && enrollment.exam_id === 65);
        
        if (finishedTwinixExams?.length > 0) {
            const newestFinishedTwinix = finishedTwinixExams?.reduce((latest, current) =>
                new Date(current.created_at) > new Date(latest.created_at) ? current : latest
            );
            setNewestTwinixExam(newestFinishedTwinix);
        }

        if (finishedJobBarExams?.length > 0) {
            const newestFinishedJobBar = finishedJobBarExams?.reduce((latest, current) =>
                new Date(current.created_at) > new Date(latest.created_at) ? current : latest
            );
            setNewestJobBarExam(newestFinishedJobBar);
        }
    }, [filteredEnroll]);

    const submitQuestions = _.throttle(async (e) => {
        console.log('first')
        e.preventDefault();
        try {
            const answers = downloadDetails.exam_id === 1 ? newestTwinixExam.answers : newestJobBarExam.answers;
            await axios.put(`https://api.twindix.com/enrollments/${downloadDetails.id}`, { answers }, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            });
            await generateReport(`https://assessment.twindix.com/reportPage?lang=ar&enro_id=${downloadDetails.id}&exam_id=${downloadDetails.exam_id}&name=${userName}`);
            navigate("/");
        } catch (err) {
            console.error("Error submitting questions:", err);
        }
    }, 60000);

    const generateReport = async (url) => {
        try {
            const enrollmentId = Number(downloadDetails.id);
            const response = await axios.post(
                "https://vmi2353814.contaboserver.net/api/report-generate",
                {
                    url,
                    isLogged: true,
                    regenerate: false,
                    token: isLoggedIn.localStorage.token,
                    userType: "2",
                    enrollment_id: enrollmentId,
                    userId: isLoggedIn?.userData?.id,
                    userName: isLoggedIn?.userName,
                    AssessmentName: assessmentName,
                }
            );
            console.log("Report Response:", response);
        } catch (error) {
            console.error("Error generating report:", error);
        }
    };

    return (
        <Dialog
            maxWidth="lg"
            open={openSendExist}
            onClose={handleSendExistClose}
        >
            <Box
                sx={{
                    bgcolor: '#929292',
                    width: '600px',
                }}
            >
                <DialogTitle sx={{ padding: '0' }}>
                    <Typography sx={{ width: '80%', margin: '15px auto', color: 'white', textDecoration: 'underline', textAlign: "center" }}>
                        You have resolved this assessment before. Would you like to use your last answers for this assessment?
                    </Typography>
                </DialogTitle>
                <DialogActions>
                    <Button
                        variant="contained"
                        style={{
                            padding: '6px',
                            background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                            color: "white",
                            margin: 'auto'
                        }}
                        onClick={submitQuestions}
                    >
                        Yes, please!
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            padding: '6px',
                            background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                            color: "white",
                            margin: 'auto'
                        }}
                        onClick={() => navigate(`/Qustion/${downloadDetails.exam_id}/${downloadDetails.id}`)}
                    >
                        No, I want to update my answers, please!
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
}