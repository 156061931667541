import React, { useContext, useState } from "react";
  // MUI
import {
  Box,
  Button,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
  // LIB
import * as XLSX from "xlsx";
import JSZip from "jszip";
import { saveAs } from "file-saver";
  //COMPONENT
import BigChips from "../../../ShareHr/BigChips/Index";
import AddPartisipantFile from "../EmployeeManagement/Diaolgs/AddPartisipantFile";
import Buttons from "../MangeTwindixAssessments/Buttons";
import InviteJopBar from "./Dialog/inviteJopBar";
import EditEmployee from "./Dialog/EditEmployee";
import AddParticipant from "./Dialog/AddParticipant";
import EmployeeTable from "./EmpolyeeTable";
  //HOOKS
import { appContext } from "../../../../../../App";
import ApiContext from "../../../Context/context";
import Reminder from "../MangeTwindixAssessments/Diaolgs/sendReminder";
function MangeJopBar({
  PositionId,
  company,
  handleBackButtonClick,
  getSpecCompany,
  setReFetchSpecificCompany
}) {
  const {
    statistics,
  } = useContext(ApiContext);

  const companyName = getSpecCompany.name
  getSpecCompany = getSpecCompany.positions?.filter((ele) => ele.id === PositionId)?.[0]
  const isMobile = useMediaQuery("(max-width:600px)");
  
  const [userId, setUserId] = useState();
  const [open, setOpen] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [openBench, setOpenBench] = useState(false)
  const [EmployeeId, setEmployeeId] = useState('');
  const [openEditForm, setOpenEditForm] = useState(false);
  const [getSpecEmp, setGetSpecEmp] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const [reminderOpen, setReminderOpen] = useState(false);
  const [dialogContentReminder, setDialogContentReminder] = useState();
  const [snackbarTextReminder, setSnackbarTextReminder] = useState(false);
  const [openSnackbarReminder, setOpenSnackbarReminder] = useState(false);

  let isLoggedIn = useContext(appContext);

  const handleOpenSelectedAllReminder = () => {
    setDialogContentReminder(`Are you sure you want to send Reminder to all participants?`)
    setReminderOpen(true)
  }
  const handleOpenReminder = () => {
    setReminderOpen(true)
  }
  const handleSelectAllReminder = () => { 
    if (allSelected) {
      setSelectedEmployees([]);
    } else {
      const ids = getSpecCompany?.employees
        ?.filter((user) => user.has_enrollment === true)
        ?.map((user) => user.user_id) || []; // Ensures a valid array
  
      setSelectedEmployees(ids);
    }
    setAllSelected(!allSelected);
  };
  
  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedEmployees([]);
    } else {
      const ids = getSpecCompany.enrollments_employees
      ?.map((user) => user.user_id);
      setSelectedEmployees(ids);
    }
    setAllSelected(!allSelected);
  };

  const handleClose = () => {
    setOpenBench(false);
  };

  const handleCloseEditForm = () => {
    setOpenEditForm(false)
  }

  const handleDownload = async () => {
      // Define your data
        const data = [
          { name: "name", email: "name@domain.com",phone: '1012303188'
            ,password: "password", company_id: company.id, position_id: PositionId },
        ];
      
        // Convert data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);
      
        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      
        // Generate the Excel file as a Blob
        const excelBlob = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      
        // Create a text file as a Blob
        const textContent = `
        ==============================
        Welcome to Twindix Assessments
        ==============================
        
        To successfully upload your participants, please follow these steps:
        
        1- Don't change the file name 'users_import.xlsx'.
        2- The Participant data must be like the sample row provided in the file.
        3- You may type any password you suggest consisting of 8-12 characters with at least one capital letter and 1 digit. You can set the same password for all participants.
        4- Don't change the company_id and position_id fields, as they refer to the company and position from which you downloaded the file.
        5- Fill the entire company_id and position_id fields for all participants with the same values as in the sample row.
        6- Don't change any column headers.
        7- Don't leave any data empty.
        8- Delete the sample row before uploading your file.
        
        ==============================
        مرحباُ بك في اختبارات تويندكس
        ==============================
        
        رجاء اتباع التعليمات التالية بدقة حتى يتم رفع ملف الدعوات بشكل صحيح:
        1- لا تغير اسم ملف الاكسل
        2- يجب ملء بيانات المدعوين بنفس طريقة صف النموذج في الملف
        3- يمكنك اختيار كلمة مرور مناسبة بالحروف اللاتينية تتكون من 8-12 حرفًا، وحرف واحد على الأقل كبير ورقم واحد على الأقل
        4- لا تغير الأرقام في حقول
            company_id 
            position_id
            حيث إنها تحتوي على تعريف الشركة والوظيفة التي قمت بتحميل الملف منها
        5- انسخ كود الشركة والوظيفة في صف النموذج لكافة صفوف المدعوين
            company_id
            position_id
        6- لا تغير عناوين الأعمدة
        7- لا تترك أي حقل خالي من البيانات
        8- احذف صف النموذج قبل رفع الملف
        `;        
        const textBlob = new Blob([textContent], { type: "text/plain" });
      
        // Create a new zip file
        const zip = new JSZip();
      
        // Add the Excel file to the zip
        zip.file("users_import.xlsx", excelBlob);
      
        // Add the text file to the zip
        zip.file("Important guide.txt", textBlob);
      
        // Generate the zip file
        const zipBlob = await zip.generateAsync({ type: "blob" });
      
        // Download the zip file
        saveAs(zipBlob, "files.zip");
  };
  const handleClickSnackbarReminder = () => {
    setOpenSnackbarReminder(true);
  };
  const handleCloseSnackbarReminder = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbarReminder(false);
  };

    return (
      <>
        <Button onClick={handleBackButtonClick} sx={{ 
          position : 'fixed',
          top : '97px',
          left :'0',
          fontSize :'18px'
          }}>
            <ArrowBackIcon />Back
        </Button>
        <Box spacing={2}>
          <BigChips  userName={companyName} finishedAssessments={`${statistics?.statistics?.[65].remaining}`} />
          <Buttons
            isMobile={isMobile}
            isLoggedIn={isLoggedIn}
            setOpen={setOpen}
            setOpenFile={setOpenFile}
            handleDownload={handleDownload}
            handleSelectAll={handleSelectAll}
            handleOpenSelectedAllReminder={handleOpenSelectedAllReminder}
            setOpenSend={() => setOpenBench(true)}
            jopBar={true}
            handleSelectAllReminder={handleSelectAllReminder}
          />
          <EmployeeTable  
            setOpenEditForm={setOpenEditForm}
            setGetSpecEmp={setGetSpecEmp}
            setUserId={setUserId}
            setEmployeeId={setEmployeeId}
            setOpenBench={() => setOpenBench(true)}
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
            allSelected={allSelected}
            handleSelectAll={handleSelectAll}
            employees={getSpecCompany?.enrollments_employees}
            handleOpenReminder={handleOpenReminder}
            setDialogContentReminder={setDialogContentReminder}
          />
          <InviteJopBar 
            openBench={openBench}
            handleClose={handleClose}
            selectedEmployees={selectedEmployees}
            userId={userId}
            company_id={company?.id}
            position_id={PositionId}
            setReFetchSpecificCompany={setReFetchSpecificCompany}
          />
          <EditEmployee 
            openEditForm={openEditForm}
            handleCloseEditForm={handleCloseEditForm}
            getSpecEmp={getSpecEmp}
            EmployeeId={EmployeeId}
            setReFetchSpecificCompany={setReFetchSpecificCompany}
            PositionId={PositionId}
            companyId={company.id}
            selectedEmployees={selectedEmployees}
          />
          <AddParticipant 
          setReFetchSpecificCompany={setReFetchSpecificCompany}  
          company_id={company?.id} 
          position_id={PositionId} 
          open={open} 
          setopen={setOpen} />

          <AddPartisipantFile 
          setRefreshSpecCompany={setReFetchSpecificCompany} 
          open={openFile} 
          setopen={setOpenFile} 
          company_id={company?.id} 
          position_id={PositionId} />

          <Reminder
            isMobile={isMobile}
            reminderOpen={reminderOpen}
            handleCloseReminder={() => setReminderOpen(false)}
            dialogContentReminder={dialogContentReminder}
            userId={userId}
            handleClickSnackbarReminder={handleClickSnackbarReminder}
            setSnackbarTextReminder={setSnackbarTextReminder}
            handleSelectAll={handleSelectAll}
            selectedEmployees={selectedEmployees}
          />
          <Snackbar
            open={openSnackbarReminder}
            autoHideDuration={3000}
            onClose={handleCloseSnackbarReminder}
            message={snackbarTextReminder}
          />
      </Box>
      </>
    );
}

export default MangeJopBar;
