import { Box, Button, Dialog, DialogActions, DialogContent, InputLabel, MenuItem, Select, Typography } from "@mui/material";


export default function AddEmployee ({
    isMobile,
    open1,
    setOpen1,
    formData,
    setFormData,
    companies,
    setSelectedCompany,
    positions,
    selectedCompany,
    handleSubmit,
}) {

    return (
        <Dialog
        PaperProps={isMobile ? {
    sx: {
      position: "fixed",
      left: 80, // Align to the left border
      margin: 0,
      height: "auto",
      borderRadius: isMobile ? 0 : "8px", // No border-radius in mobile
    },
  }: {}}
  maxWidth={isMobile ? "sm" : 'md'} 
  open={open1} onClose={() => setOpen1(false)}>
    <Box
      sx={{
        bgcolor: "#929292",
        width: isMobile ? 'auto' : "450px",
        height: isMobile && '100%'
      }}
    >  
      <DialogContent
        sx={{
          maxHeight: '500px',
          overflowY: 'auto',
          "&::-webkit-scrollbar": {
            width: '6px',
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: '10px',
            background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
          },
          "&::-webkit-scrollbar-track": {
            background: '#929292',
            borderRadius: '10px',
          },
        }}
      >
        <Typography
          sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}
        >
          Participant:
        </Typography>
        <input
          placeholder="Enter name"
          value={formData.name}
          onChange={(e) =>
        setFormData({ ...formData, name: e.target.value })
      }
          style={{
            padding: '10px',
            fontSize: '10px',
            border: '0',
            borderRadius: '4px',
            width: '100%',
            height: '40px',
          }}
        />

        <Typography
          sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px', mt: '20px' }}
        >
          Email:
        </Typography>
        <input
          placeholder="Enter email"
          value={formData.email}
          onChange={(e) =>
        setFormData({ ...formData, email: e.target.value })
      }
          style={{
            padding: '10px',
            fontSize: '10px',
            border: '0',
            borderRadius: '4px',
            width: '100%',
            height: '40px',
          }}
        />
    {/* Company Dropdown */}
    <InputLabel sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px', mt: '20px' }}>Company</InputLabel>
    <Select
                  sx={{
            padding: '10px',
            fontSize: '10px',
            border: '0',
            borderRadius: '4px',
            width: '100%',
            height: '40px',
            bgcolor: 'white'
          }}
      fullWidth
      value={formData.company_id}
      onChange={(e) =>
        setFormData({ ...formData, company_id: e.target.value, position_id: "" })
      } // Reset position when company changes
    >
      <MenuItem value="">Select Company</MenuItem>
      {companies.map((comp) => (
        <MenuItem key={comp.id} value={comp.id} onClick={() => setSelectedCompany(comp)}>
          {comp.name}
        </MenuItem>
      ))}
    </Select>
    {/* Position Dropdown */}
    <InputLabel sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px', mt: '20px' }}>Position</InputLabel>
    <Select
                  sx={{
            padding: '10px',
            fontSize: '10px',
            border: '0',
            borderRadius: '4px',
            width: '100%',
            height: '40px',
            bgcolor: 'white'
          }}
      fullWidth
      value={formData.position_id}
      onChange={(e) =>
        setFormData({ ...formData, position_id: e.target.value })
      }
      disabled={!formData.company_id} // Disable if no company is selected
    >
      <MenuItem value="">Select Position</MenuItem>
      {positions?.filter((ele) => ele.company_id === selectedCompany.id)
      ?.map((pos) => (
        <MenuItem key={pos.id} value={pos.id}>
          {pos.title}
        </MenuItem>
      ))}
    </Select>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
            borderRadius: '10px',
            margin: 'auto',
          }}
          onClick={handleSubmit}
          variant="contained"
        >
          Add Participant
        </Button>
      </DialogActions>
    </Box>
  </Dialog>
    )
}