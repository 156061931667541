
import { useContext, useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useParams } from "react-router-dom";
import { appContext } from "../../App";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faCircleExclamation, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _ from "lodash";  // Import lodash for shuffle and throttle
import { Box, Menu, MenuItem, Typography,  Button as MuiButton  } from "@mui/material";
import "./Question.css";

function Question() {
      const isLoggedIn = useContext(appContext);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [questions, setQuestions] = useState(null);
    const [choosedBg, setChoosedBg] = useState(null);
    const [finalQuestion, setFinalQuestion] = useState(false);
    const [choosedAnswer, setChoosedAnswer] = useState(null);
    const [choosedAnswers, setChoosedAnswers] = useState({});
    const [isFlipped, setIsFlipped] = useState(false);
    const [loading, setLoading] = useState(true);  // Loading state
    const [error, setError] = useState(null);      // Error state
    const navigate = useNavigate();
    const { examNumber } = useContext(appContext);
    const { examid, enroid } = useParams();
    const [language, setLanguage] = useState('ar');
    var AssessmentName;
    function shuffleQuestions(questionsArray) {
        return _.shuffle(questionsArray);
    }

    function randomizeOptions() {
        setIsFlipped(Math.random() >= 0.5);
    }
    const toggleLanguage = () => {
        setLanguage(prevLang => (prevLang === 'ar' ? 'en' : 'ar'));
    };
    function incrementQuestion(e) {
        e.preventDefault();
        if (choosedAnswers) {
            setChoosedAnswer(null);
            setChoosedBg(null);
        }
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
            randomizeOptions();
            if (currentQuestion === questions.length - 2) setFinalQuestion(true);
        }
    }

    function chooseQuestion(value, code, qnum) {
        setChoosedBg(qnum);
        setChoosedAnswer(true);
        setChoosedAnswers({ ...choosedAnswers, [code]: value });
    }
  const name = isLoggedIn?.userName?.replaceAll(" ", "-")
    const submitQuestions = _.throttle((e) => {
        e.preventDefault();
        axios.put(`https://api.twindix.com/enrollments/${enroid}`, { answers: choosedAnswers }, {
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        }).then(res => {
            spacReport(`https://assessment.twindix.com/reportPage?enro_id=${enroid}&exam_id=${examid}&name=${name}`)
            // console.log(res);
            navigate("/");
        }).catch(err => {
            console.error(err);
            setError("Failed to submit answers. Please try again.");
        });
    }, 60000); 

    if (examid == 1) {
        AssessmentName = "TwinixExecutives";
    } else if (examid == 65) {
        AssessmentName = "JobBar";
    } else {
        AssessmentName = "TwinixExecutives";
    }
    const spacReport = async (url) => {
        try {
            const enrollmentId = Number(enroid);
          const response = await axios.post(
            "https://vmi2353814.contaboserver.net/api/report-generate",
            {
                "url": url,
                "isLogged": true,
                "regenerate": false,
                "token": isLoggedIn.localStorage.token,
                "userType": "2",
                "enrollment_id": enrollmentId,
                "userId": isLoggedIn?.userData?.id,
                "userName": isLoggedIn?.userName,
                "AssessmentName": AssessmentName,
            }
          );
          console.log("Report Response:", response);
          
        } catch (error) {
          console.error("Error generating report:", error);
        }
      };

    useEffect(() => {
        setLoading(true);  // Set loading state before fetching
        axios.get(`https://api.twindix.com/exams/${examid}`, {
            headers: {
                "Accept-Language" : language,
                Authorization: `Bearer ${localStorage.token}`
            }
        }).then(res => {
            if (res.data.payload.questions) {
                // const shuffledQuestions = shuffleQuestions(res.data.payload.questions);
                setQuestions(res.data.payload.questions);
                randomizeOptions();
                // console.log(shuffledQuestions);
            } else {
                throw new Error('No Data Was Found');
            }
        }).catch(err => {
            console.error(err);
            setError("Failed to load questions. Please try again.");
        }).finally(() => setLoading(false));
    }, [examNumber?.exam, language, examid]);

    const progressPercentage = questions ? (currentQuestion / questions.length) * 100 : 0;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
        <Box mb="40px">
            <Box className="language-toggle">
            <MuiButton
                sx={{
                background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
                borderRadius: '10px',
                color : 'white',
                margin : '10px'
                }}
                onClick={handleClick}
                >
                <ArrowDropDownIcon />
                    language
                </MuiButton>
                    <Menu      
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}>
                        <MenuItem onClick={() => {
                            handleClose()
                            toggleLanguage()
                        }}>
                            {language === 'ar' ? 
                            <Typography>English</Typography> 
                            : 
                            <Typography>العربية</Typography>}
                        </MenuItem>
                    </Menu>
            </Box>
            <div className="logo-holder" style={{margin: '10px',}}>
                <img src='https://assessment.twindix.com/Logo-sm.png'  alt="Website Logo" />
            </div>
            <div className="progress" style={{ height: "14px", marginTop: "20px", width: "70%", margin: "auto" }}>
                <div className="progress-bar" role="progressbar" style={{ width: `${progressPercentage}%`, backgroundColor: "#224e92", transition: "width 0.5s ease" }} aria-valuenow={progressPercentage} aria-valuemin="0" aria-valuemax="100">
                </div>
            </div>
            <div className="QusetionContainer Question-Con">
            {examid === 65 ? 
                <div className="SentenceContaner">
                                <div className="SentAndIcon">
                                    <div style={{ fontSize: "24px" }}><FontAwesomeIcon icon={faCircleExclamation} /></div>
                                    <div className="question-text text-center Sentence">
                                    {language === 'ar' ? 
                                ' يستغرق إجراء التقييم حوالي 30 إلى 35 دقيقة. يُرجى تخصيص الوقت اللازم لقراءة الإجابات بعناية وفهم معانيها بشكل كامل. '                                   
                                    : 
                                    'The assessment requires approximately 30 to 35 minutes to complete. Please take the necessary time to carefully review and fully understand the meaning of each response option.'
                                    }
                                </div>
                                </div>
                                <div className="SentAndIcon">
                                    <div style={{ fontSize: "24px" }}><FontAwesomeIcon icon={faCircleUser} /></div>
                                    <div className="question-text text-center Sentence">
                                    {language === 'ar' ? 
                                ' اختر الإجابات التي تعبّر بصدق عن أسلوب عملك وواقع حياتك المهنية اليومية.'
                                    :
                                    'Select the answers that genuinely reflect your work style and the realities of your daily professional life.'
                                    }
                                    </div>
                                </div>
                                <div className="SentAndIcon">
                                    <div style={{ fontSize: "24px" }}><FontAwesomeIcon icon={faArrowsRotate} /></div>
                                    <div className="question-text text-center Sentence">
                                    {language === 'ar' ? 
                                ' تجنب تقديم إجابات تبدو أكثر إيجابية مما هي عليه في الواقع. الهدف من هذا التقييم هو مساعدتك على اكتشاف نقاط قوتك الفريدة وتحديد التوافق الأمثل مع بيئة عملك.'
                                    :
                                    'Avoid providing responses that may appear more favorable than they truly are. The purpose of this assessment is to help identify your unique strengths and determine the optimal alignment with your work environment.'
                                    }
                            </div>
                                </div>
                            </div>
                            :
                <div className="SentenceContaner">
                    <div className="SentAndIcon">
                        <div style={{ fontSize: "24px" }}><FontAwesomeIcon icon={faCircleExclamation} /></div>
                        <div className="question-text text-center Sentence">
                        {language === 'ar' ?
                            ' يرجى عدم محاولة تجميل الإجابات، حيث أن الهدف من هذا الاختبار هو اكتشاف جوانب التطوير الخاصة بك وبمؤسستك.'
                        :
                        'The assessment takes about 15 to 25 minutes to complete. Make sure to take enough time to read the answers carefully and really understand what they mean.'
                        }
                        </div>
                    </div>
                    <div className="SentAndIcon">
                        <div style={{ fontSize: "24px" }}><FontAwesomeIcon icon={faCircleUser} /></div>
                        <div className="question-text text-center Sentence">
                        {language === 'ar'
                        ?
'                            الرجاء اختيار الإجابة التي تتوافق مع واقع ممارساتك الإدارية وما يحدث فعلياً داخل مؤسستك.'
                        :
                        'Choose the answer that truly reflects how you manage things and what is really happening in your organization.'
                        }
                        </div>
                    </div>
                    <div className="SentAndIcon">
                        <div style={{ fontSize: "24px" }}><FontAwesomeIcon icon={faArrowsRotate} /></div>
                        <div className="question-text text-center Sentence">
                            {language === 'ar'
                            ?
                           ' إتمام الاختبار يستغرق ما بين ١٥ إلى ٢٥ دقيقة، يُنصح بأخذ الوقت الكافي في قراءة الإجابات والتمهل لفهم المعنى المقصود منها.'
                            :
                            'Do not try to make your answers sound better than they are. The goal of this assessment is to help you and your organization figure out what you need to work on.'
                            }
                        </div>
                    </div>
                </div>
            }

                <form onSubmit={submitQuestions} className="QustionForm" style={{ display: "flex" }}>
                    {loading ? (
                        <div className="text-center" style={{ width: "100%" }}>
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p>Loading Assessment...</p>
                        </div>
                    ) : error ? (
                        <div className="text-center" style={{ width: "100%", color: "red" }}>
                            <p>{error}</p>
                        </div>
                    ) : (
                        questions && (
                            <div className="d-flex flex-column text-center cardContaner" style={{ width: "100%" }}>
                                {/* <h4>السؤال رقم {currentQuestion + 1}</h4> */}
                                <div className="questionsCon d-flex justify-content-center text-center">
                                    {/* {isFlipped ? (
                                        <>
                                            <div style={{direction : language === 'en' ? 'ltr' : 'rtl', marginRight: "12.5px"}} onClick={() => chooseQuestion(questions[currentQuestion].second_statement_weight, questions[currentQuestion].code, 1)} className={choosedBg === 1 ? "Quscard option-card BackgrounQustionsCard text-white card-Click" : "Quscard option-card card-Click"}>
                                                {questions[currentQuestion].second_statement}
                                            </div>
                                            <div style={{direction : language === 'en' ? 'ltr' : 'rtl', marginLeft: "12.5px"}} onClick={() => chooseQuestion(questions[currentQuestion].first_statement_weight, questions[currentQuestion].code, 2)} className={choosedBg === 2 ? "Quscard option-card BackgrounQustionsCard text-white card-Click" : "Quscard option-card card-Click"}>
                                                {questions[currentQuestion].first_statement}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div style={{direction : language === 'en' ? 'ltr' : 'rtl',  marginRight: "12.5px" }} onClick={() => chooseQuestion(questions[currentQuestion].first_statement_weight, questions[currentQuestion].code, 1)} className={choosedBg === 1 ? "Quscard option-card BackgrounQustionsCard text-white card-Click" : "Quscard option-card card-Click"}>
                                                {questions[currentQuestion].first_statement}
                                            </div>
                                            <div style={{direction : language === 'en' ? 'ltr' : 'rtl',  marginLeft: "12.5px" }} onClick={() => chooseQuestion(questions[currentQuestion].second_statement_weight, questions[currentQuestion].code, 2)} className={choosedBg === 2 ? "Quscard option-card BackgrounQustionsCard text-white card-Click" : "Quscard option-card card-Click"}>
                                                {questions[currentQuestion].second_statement}
                                            </div>
                                        </>
                                    )} */}
                                    <div onClick={() => chooseQuestion(questions[currentQuestion].first_statement_weight, questions[currentQuestion].code, 1)} className={choosedBg === 1 ? "Quscard option-card BackgrounQustionsCard text-white card-Click" : "Quscard option-card card-Click"} style={{ marginRight: "12.5px" }}>
                                                {questions[currentQuestion].first_statement}
                                            </div>
                                            <div onClick={() => chooseQuestion(questions[currentQuestion].second_statement_weight, questions[currentQuestion].code, 2)} className={choosedBg === 2 ? "Quscard option-card BackgrounQustionsCard text-white card-Click" : "Quscard option-card card-Click"} style={{ marginLeft: "12.5px" }}>
                                                {questions[currentQuestion].second_statement}
                                            </div>
                                </div>
                            </div>
                        )
                    )}

                    {finalQuestion ? (
                            <button className="btn btn-primary button" type="submit">{language === 'ar' ? 'ارسال' : 'Submit'}</button>
                    ) : (
                        <button disabled={!choosedAnswer} onClick={incrementQuestion} className="Back-Btn btn btn-primary button">{language === 'ar' ? 'التالي' : 'Next'}</button>
                    )}
                </form>
            </div>
        </Box>
    );
}

export default Question;
