import { createContext, useEffect, useState } from 'react';
import {Route,Routes, Navigate} from 'react-router-dom';
import axios from 'axios';

import CodeField from './Component/CodeField/CodeField';
import Payment from './Component/Dashboard-V2/Individual/Component/Payment.js';
import BenchMarkQustions from './Component/Dashboard-V2/HR/Pages/Component/BenchmarksShelve/BenchMarkQustions.js';
import Report from './Component/Report/ReportComponent';
import Question from './Component/Question/Question.js';
import SendEmail from "./Component/ResetPassword/SendEmail.js";
import PasswordReset from "./Component/ResetPassword/passwordReset.js";
import AdminSendEmail from "./Component/AdminResetPassword/AdminSendEmail.js";
import ResetPasswordForAdmin from "./Component/AdminResetPassword/AdminPasswordReset.js";

import LogIn from './Pages/LogIn/LogIn';
import Register from './Pages/Register/Register';
import LogInForAdmin from './Pages/LogInForAdmin/LogInForAdmin';
import Dashboard_v2 from './Pages/Dashboard-V2/index.js';

import './App.css';

export let appContext = createContext(null);

function App() {
    let [isLoggedin, setLoggedin] = useState(true);
    let [isAdmin, setIsAdmin] = useState(false);
    let [userType, setUserType] = useState(null);
    let [userName, setUserName] = useState(null);
    let [userEmail, setUserEmail] = useState(null);
    let [userData, setUserData] = useState(null);
    let [examNumber, setExamNumber] = useState(null);
    let [homePage, setHomePage] = useState(null);

      useEffect(() => {
        if(localStorage.token && !localStorage.isAdmin) {

        setLoggedin(true);
            axios.get("https://api.twindix.com/auth/me", {headers: {
              Authorization: 'Bearer ' + localStorage.token
          }
      }).then(res => {
        if(res.data.code == 200) {
                  setUserType(res.data.payload.type);
                  setUserName(res.data.payload.name);
                  setUserEmail(res.data.payload.email);
                  setUserData(res.data.payload)
                localStorage.setItem("userType", res.data.payload.type);
                  if(localStorage.userType == 2) {
                    setHomePage("/individual")
                  } else if(localStorage.userType == 1) {
                    setHomePage("/Business")
                  } else if(localStorage.userType == 3) {
                    setHomePage("/admin")
                  }
                  }
                  }).catch((res) => {
                    
                    if(res.response.data.code == 401) {
                      localStorage.removeItem("isAdmin")
                      localStorage.removeItem("token")
                      localStorage.removeItem("userType")
                      localStorage.removeItem("isLogged")
            }
          });

        } else if(localStorage.token && localStorage.isAdmin) {
          axios.get("https://api.twindix.com/dashboard/auth/me", {headers: {
            Authorization: 'Bearer ' + localStorage.token
        }

    }).then(res => {
            if(res.data.code == 200) {
              setHomePage("/admin")
                setUserName(res.data.payload.name);
                setUserEmail(res.data.payload.email);
                setUserType(null)
                localStorage.setItem("isAdmin", true);
                localStorage.isLogged = true;
            }
        }).catch(() => {});
        } else {
          setLoggedin(false);
          localStorage.removeItem("isLogged");
          localStorage.removeItem( "token" );
          localStorage.removeItem( "userType" );
          localStorage.removeItem( "isLoggedIn" );
        }
      }, [isLoggedin])

  return (
          <appContext.Provider value={{isLoggedin,examNumber,localStorage,  setExamNumber, setLoggedin, setUserType,userData, userName,setUserName,userEmail, userType, isAdmin, setIsAdmin}}>
          <div className="App">
            <Routes>
                <Route path='/' element={!localStorage.token && !localStorage.isLogged ? <LogIn /> : <Navigate to={homePage} /> } />
                <Route path='/Register' element={!localStorage.token && !localStorage.isLogged ? <Register /> : <Navigate to={homePage} /> } /> 
                <Route path='/Payment' element={isLoggedin && userType == 2 ? <Payment /> : <Navigate to="/" replace={true} />} />
                <Route path='/LogInForAdmin' element={<LogInForAdmin /> } />
                <Route path='/Qustion/:examid/:enroid' element={1 ? <Question/> : <Navigate to="/individual" replace={true} /> } />
                <Route path='/Business' element={localStorage.token && localStorage.userType == 1 ? <Dashboard_v2/> : <Navigate to="/" replace={true} /> } />
                <Route path='/Individual' element={localStorage.token && localStorage.userType == 2 ? <Dashboard_v2/> : <Navigate to="/" replace={true} /> } />
                <Route path='/Admin' element={<Dashboard_v2 /> } />
                <Route path='/verify-payment' element={localStorage.token && localStorage.userType ? <CodeField /> : <Navigate to="/" replace={true} />} />
                <Route className="PDF" id='PDF' path='/ReportPage' element={<Report /> } />
                <Route path='/ResetPassword' element={<SendEmail  /> } /> 
                <Route path='/password-reset' element={<PasswordReset  /> } /> 
                <Route path='/LogInForAdmin/ResetPasswordForAdmin' element={<AdminSendEmail  /> } />
                <Route path='/dashboard/password-reset' element={<ResetPasswordForAdmin  /> } /> 
                <Route path='/BenchMarkQustion/:id' element={<BenchMarkQustions />} />
            </Routes>
          </div>
      </appContext.Provider>
  );
}

export default App;


