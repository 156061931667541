 //MUI
import { Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Snackbar, useMediaQuery } from "@mui/material"; 
// HOOKS
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { appContext } from "../../../../../../App";
// Component
import AddPartisipantFile from "../EmployeeManagement/Diaolgs/AddPartisipantFile";
import BigChips from "../../../ShareHr/BigChips/Index";
import ApiContext from "../../../Context/context";
import Invite from "../../Component/MangeTwindixAssessments/Diaolgs/invite";
import Buttons from "./Buttons";
// LIB
import * as XLSX from "xlsx";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import SendIcon from '@mui/icons-material/Send';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EditEmployee from "./Diaolgs/EditEmployee";
import AddParticipant from "./Diaolgs/AddParticipant";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Reminder from "./Diaolgs/sendReminder";

function MangeInd({ company ,setShowUser, enrollmentsCount }) {
  const {
    statistics,
    setFetchStatisticsTrigger,
    handelPermission2
  } = useContext(ApiContext);
  handelPermission2(["employee-show", 'employee-create', 'employee-update', 'exam-invite'])

  const isMobile = useMediaQuery("(max-width:600px)");

    const [open, setOpen] = useState(false);
    const [openFile, setOpenFile] = useState(false);
    const [userId, setUserId] = useState(null);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openSnackbarReminder, setOpenSnackbarReminder] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [currentEmployee, setCurrentEmployee] = useState();
    const [getSpecCompany, setGetSpecCompany] = useState();
    const [refreshSpecCompany, setRefreshSpecCompany] = useState(0);
    const [formOpen, setFormOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState(false);
    const [snackbarText, setSnackbarText] = useState(false);
    
    const [reminderOpen, setReminderOpen] = useState(false);
    const [dialogContentReminder, setDialogContentReminder] = useState(false);
    const [snackbarTextReminder, setSnackbarTextReminder] = useState(false);

    let isLoggedIn = useContext(appContext);

    const handleOpenSelectedInvite = () => {
      setDialogContent(`Are you sure you want to send invite to selected participants?`)
      setFormOpen(true)
    }
    const handleOpenSelectedAllInvite = () => {
      setDialogContent(`Are you sure you want to send invite to all participants?`)
      setFormOpen(true)
    }
    const handleOpenInvite = () => {
      setFormOpen(true)
    }
    const handleOpenSelectedAllReminder = () => {
      setDialogContentReminder(`Are you sure you want to send Reminder to all participants?`)
      setReminderOpen(true)
      console.log(selectedEmployees)
    }
    const handleOpenReminder = () => {
      setReminderOpen(true)
    }

        useEffect(() => {
          if(company.id !== undefined){      
          const fetchEmployeeDetails = async () => {
            try {
              const res = await axios.get(`https://api.twindix.com/companies/${company.id}`, {
                headers: { Authorization: `Bearer ${localStorage.token}` },
              });
              setGetSpecCompany(res.data.payload);
            } catch (err) {
              console.log(err)
            } 
          };
          
          fetchEmployeeDetails();
        }
        }, [company.id, refreshSpecCompany]);

      const handleSelectAll = () => {
        if (allSelected) {
          setSelectedEmployees([]);
        } else {
          const ids = getSpecCompany?.employees.map((user) => user.user_id);
          setSelectedEmployees(ids);
        }
        setAllSelected(!allSelected);
      };
      const handleSelectAllReminder = () => { 
        if (allSelected) {
          setSelectedEmployees([]);
        } else {
          const ids = getSpecCompany?.employees
            ?.filter((user) => user.has_enrollment === true)
            ?.map((user) => user.user_id) || []; // Ensures a valid array
      
          setSelectedEmployees(ids);
        }
        setAllSelected(!allSelected);
      };

      const handleSelectRow = (id) => {

        if (selectedEmployees.includes(id)) {
          setSelectedEmployees(selectedEmployees.filter((empId) => empId !== id));
        } else {
          setSelectedEmployees([...selectedEmployees, id]);
        }
      };

      const handleClose = () => {
        setFormOpen(false);
      };

      const handleClickSnackbar = () => {
        setOpenSnackbar(true);
      };
      const handleClickSnackbarReminder = () => {
        setOpenSnackbarReminder(true);
      };
      
      const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
      };
      const handleCloseSnackbarReminder = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbarReminder(false);
      };

      const handleDownload = async () => {
        // Define your data
        const data = [
          { name: "name", email: "name@domain.com",
            phone: '1012303188',
            password: "password", company_id: company.id },
        ];
    
      // Convert data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(data);
    
      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
      // Generate the Excel file as a Blob
      const excelBlob = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    
      // Create a text file as a Blob
      const textContent = `
      ==============================
      Welcome to Twindix Assessments
      ==============================
      
      To successfully upload your participants, please follow these steps:
      
      1- Don't change the file name 'users_import.xlsx'.
      2- The Participant data must be like the sample row provided in the file.
      3- You may type any password you suggest consisting of 8-12 characters with at least one capital letter and 1 digit. You can set the same password for all participants.
      4- Don't change the company_id  fields, as they refer to the company from which you downloaded the file.
      5- Fill the entire company_id  fields for all participants with the same values as in the sample row.
      6- Don't change any column headers.
      7- Don't leave any data empty.
      8- Delete the sample row before uploading your file.
      
      ==============================
      مرحباُ بك في اختبارات تويندكس
      ==============================
      
      رجاء اتباع التعليمات التالية بدقة حتى يتم رفع ملف الدعوات بشكل صحيح:
      1- لا تغير اسم ملف الاكسل
      2- يجب ملء بيانات المدعوين بنفس طريقة صف النموذج في الملف
      3- يمكنك اختيار كلمة مرور مناسبة بالحروف اللاتينية تتكون من 8-12 حرفًا، وحرف واحد على الأقل كبير ورقم واحد على الأقل
      4- لا تغير الأرقام في حقول
          company_id 
          حيث إنها تحتوي على تعريف الشركة والوظيفة التي قمت بتحميل الملف منها
      5- انسخ كود الشركة والوظيفة في صف النموذج لكافة صفوف المدعوين
          company_id
      6- لا تغير عناوين الأعمدة
      7- لا تترك أي حقل خالي من البيانات
      8- احذف صف النموذج قبل رفع الملف
      `;        
      const textBlob = new Blob([textContent], { type: "text/plain" });
    
      // Create a new zip file
      const zip = new JSZip();
    
      // Add the Excel file to the zip
      zip.file("users_import.xlsx", excelBlob);
    
      // Add the text file to the zip
      zip.file("Important guide.txt", textBlob);
    
      // Generate the zip file
      const zipBlob = await zip.generateAsync({ type: "blob" });
    
      // Download the zip file
      saveAs(zipBlob, "files.zip");
      };

      const handleBackButton = () => {
        setShowUser(false); 
      };
      
          return (
            <>
              <Button onClick={handleBackButton} 
                sx={{ 
                  position : 'fixed',
                  top : '97px',
                  left :'0',
                  fontSize :'18px'
                }}>
                <ArrowBackIcon />Back
              </Button>
              <Stack spacing={3}>
              <BigChips  userName={getSpecCompany?.name} finishedAssessments={`${statistics?.statistics?.[1].remaining}`} />
              <Buttons
                isMobile={isMobile}
                isLoggedIn={isLoggedIn}
                setOpen={setOpen}
                setOpenFile={setOpenFile}
                handleDownload={handleDownload}
                handleSelectAll={handleSelectAll}
                handleOpenSelectedAllInvite={handleOpenSelectedAllInvite}
                handleOpenSelectedInvite={handleOpenSelectedInvite}
                handleOpenSelectedAllReminder={handleOpenSelectedAllReminder}
                handleSelectAllReminder={handleSelectAllReminder} 
              />
              <Box>
                <TableContainer>
                  <Table aria-label="Employees Table">
                    <TableHead>
                      <TableRow>
                          <TableCell>
                              <Checkbox
                                  checked={selectedEmployees.length ===  getSpecCompany?.employees?.length}
                                  onChange={handleSelectAll}
                              />
                          </TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>phone</TableCell>
                          <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getSpecCompany?.employees
                        .map((user, index) => {
                            return (
                              <TableRow key={`${user.user_id}-${index}`}>
                                <TableCell>
                                  <Checkbox
                                    checked={selectedEmployees.includes(user.user_id)}
                                    onChange={() => handleSelectRow(user.user_id)}
                                  />
                                </TableCell>
                                <TableCell>{user.name}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.phone ?? 'There is no phone'}</TableCell>
                                <TableCell>
                                  <Stack direction="row" spacing={2}>
                                    <Button
                                    id="employee-update"
                                      variant="contained"
                                      sx={{ width: '100px', padding : '10px 0', borderRadius: "14px", background: "#2b6566" }}
                                      onClick={() => {
                                          setOpenEditForm(true)
                                          setCurrentEmployee(user.user_id)
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        {user?.has_enrollment === false ?
                                          <Button
                                        id="invite"
                                          variant="contained"
                                          sx={{ width: '200px', padding : '10px 0', borderRadius: '14px', background: "#2b6566" }}
                                          onClick={() => {
                                              setUserId(user.user_id);
                                              handleOpenInvite(true);
                                              setDialogContent(`Are you sure you want to send an invite to ${user.name}?`)
                                            }}
                                          >
                                            <SendIcon />
                                            Send Assessment
                                          </Button>
                                        :
                                        <Button
                                            variant="contained"
                                            sx={{ width: '200px', padding : '10px 0', borderRadius: '14px', background: "#2b6566" }}
                                            onClick={() => {
                                              setDialogContentReminder(`Are you sure you want to send an Reminder to ${user.name}?`)
                                              setUserId(user.user_id);
                                              handleOpenReminder()
                                            }}
                                          >
                                          <NotificationsIcon />
                                          Send Reminder
                                        </Button>
                                        }
                                      </Stack>
                                    </TableCell>
                                  </TableRow>
                                  )}
                                )}
                    </TableBody>
                  </Table>
                </TableContainer>
                </Box>
                <AddParticipant setFetchCompaniesTrigger={setRefreshSpecCompany}  company_id={company?.id} open={open} setopen={setOpen} />
                <AddPartisipantFile company_id={company?.id} setRefreshSpecCompany={setRefreshSpecCompany} open={openFile} setopen={setOpenFile} />
                <EditEmployee
                  isMobile={isMobile}
                  openEditForm={openEditForm}
                  setOpenEditForm={setOpenEditForm}
                  currentEmployee={currentEmployee}
                  companyId={company.id}
                  setRefreshSpecCompany={setRefreshSpecCompany}
                />
                <Invite 
                  isMobile={isMobile}
                  formOpen={formOpen}
                  handleClose={handleClose}
                  userId={userId}
                  selectedEmployees={selectedEmployees}
                  handleClickSnackbar={handleClickSnackbar}
                  setFetchStatisticsTrigger={setFetchStatisticsTrigger}
                  company_id={company?.id}
                  setRefreshSpecCompany={setRefreshSpecCompany}
                  dialogContent={dialogContent}
                  setSnackbarText={setSnackbarText}
                />
                <Reminder 
                  isMobile={isMobile}
                  reminderOpen={reminderOpen}
                  handleCloseReminder={() => setReminderOpen(false)}
                  dialogContentReminder={dialogContentReminder}
                  userId={userId}
                  handleClickSnackbarReminder={handleClickSnackbarReminder}
                  setSnackbarTextReminder={setSnackbarTextReminder}
                  selectedEmployees={selectedEmployees}
                />
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={3000}
                  onClose={handleCloseSnackbar}
                  message={snackbarText}
                />
                <Snackbar
                  open={openSnackbarReminder}
                  autoHideDuration={3000}
                  onClose={handleCloseSnackbarReminder}
                  message={snackbarTextReminder}
                />
              </Stack>
              </>
          );
}

export default MangeInd;
