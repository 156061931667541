import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Box,
  Typography,
  Stack,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from "react-router";
import EditIcon from '@mui/icons-material/Edit';
import { appContext } from "../../../../App";
import ApiContext from "../Context/context";
import DeleteDialog from "../../../../FemilerCom/DeleteDialog/DeleteDiaolg";
import AddBench from "./Component/BenchmarksShelve/Diaolgs/AddBench";
import EditBench from "./Component/BenchmarksShelve/Diaolgs/EditBench";
import SendBench from "./Component/BenchmarksShelve/Diaolgs/SendBench";
import LoadingSpinner from "../../../../FemilerCom/loading/spinner";

export default function AddBenchmarkForm() {
  const {
    positions,
    fetchPositions,
    companies,
    getBenchMarkData,
    setShouldFetchPositions,
    setShouldFetchCompanies,
    setShouldFetchBenchmark,
    setFetchBenchmarkTrigger,
    loading,
    handelPermission2
  } = useContext(ApiContext);
        handelPermission2(["benchmark-show", 'benchmark-create', 'benchmark-update', 'benchmark-delete'])


  const isMobile = useMediaQuery("(max-width:600px)");

  const [openAddBenchMark, setOpenAddBenchMark] = useState(false);
  const [openSendBenchMark, setOpenSendBenchMark] = useState(false);
  const [openEditBenchMark, setOpenEditBenchMark] = useState(false);
  const [benchmarkId, setBenchmarkId] = useState()
  const [error, setError] = useState(null);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openDelete, setOpenDelete] = useState(false)


  const navigate = useNavigate()
  const isLoggedIn = useContext(appContext);

  const handleCloseDelete = () => setOpenDelete(false)

  useEffect(() => {
    if(setShouldFetchCompanies){
      setShouldFetchCompanies(true)
    }
    if(setShouldFetchBenchmark){
      setShouldFetchBenchmark(true)
    }
    if(setShouldFetchPositions){
      fetchPositions(true)
    }
  }, [])

  const handleCopy = (index, code) => {
    // Copy the code to the clipboard
    navigator.clipboard.writeText(code)
      .then(() => {
        // Show the tooltip for the specific item for 2 seconds
        setOpenTooltip(index);
        setTimeout(() => {
          setOpenTooltip(null); // Hide tooltip after 2 seconds
        }, 2000);
      })
      .catch((error) => {
        alert('Failed to copy code: ', error);
      });
  };

  const handleDeleteBenchMark = async () => {
    try {
      const response = await axios.delete(
        `https://api.twindix.com/benchmark-questions/1/benchmarks/${benchmarkId}`,
        {
          headers: { Authorization: "Bearer " + localStorage.token }
        }
      );
      setFetchBenchmarkTrigger((prev) => prev + 1)
      setOpenDelete(false)
    } catch (error) {
      console.error("Error deleting benchmark:", error);
      setError("Failed to delete benchmark. Please try again.");
    }
  };
      return (
        <div>
      {loading && <LoadingSpinner />}

          <Stack width={isMobile ? '100%' : '400px' } direction={isMobile ? 'column' : 'row'}>
            <Button
            id="benchmark-create"
              variant="contained"
              onClick={() => {
                  setOpenAddBenchMark(true)
                }}
              sx={{
                fontSize :'11px !important',
                margin: '12px',
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                borderRadius: "10px",
              }}
            >
            <AddIcon />
              Add Benchmark
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenSendBenchMark(true)}
              
              sx={{
                fontSize :'11px !important',
                margin: '12px',
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                borderRadius: "10px",
              }}
            >
            <SendIcon />
              Send Benchmark
            </Button>
          </Stack>

          <Stack spacing={3} direction={isMobile ? 'column' : 'row' } 
            sx={{ flexWrap: "wrap", justifyContent:isMobile ? 'center' : "left", alignItems : isMobile && 'center' }}>
            {getBenchMarkData.length  > 0 ?  getBenchMarkData?.map((bench, index) => {
              return (
                <Stack
                key={bench.id}
                borderRadius={6}
                spacing={3}
                p={2}
                justifyContent='center'
                alignItems='left'
                sx={{
                  marginLeft : '0 !important',
                  margin : '10px !important',
                  width:isMobile ? '90%' : 'calc((100% / 3) - 30px)',
                  background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                }}
                >
                  <Box>
                    <Stack direction='row' justifyContent='space-between' sx={{color: "white" }} alignItems='center'>
                      <Typography sx={{ fontWeight: "bold", textDecoration: "underline"}}>
                        Shelve Benchmark Details
                      </Typography>
                      <Button id="benchmark-update" sx={{color : 'white'}} onClick={() => {
                        setOpenEditBenchMark(true)
                        setBenchmarkId(bench.id)
                      }}>
                        <EditIcon/>
                      </Button>
                    </Stack>
                    <Box
                      sx={{
                        background: "#e2e2e2",
                        width: "90%",
                        borderRadius: "12px",
                        padding: "8px",
                        margin: "8px 0",
                        color: '#35797b',
                        fontWeight: 'bold'
                      }}
                    >
                      {bench.title}
                    </Box>
                    <Typography sx={{ fontWeight: "bold", textDecoration: "underline", color: "white" }}>
                      Company
                    </Typography>
                    <Box
                      sx={{
                        background: "#e2e2e2",
                        width: "90%",
                        borderRadius: "12px",
                        padding: "8px",
                        margin: "8px 0",
                        color: '#35797b',
                        fontWeight: 'bold'
                      }}
                    >
                      {companies.length > 0 ? companies.map((com) =>  bench.company_id === com.id  ? com.name : ''): 'there is no companies available'}
                    </Box>
                    <Typography sx={{ fontWeight: "bold", textDecoration: "underline", color: "white" }}>
                      Position
                    </Typography>
                    <Box
                      sx={{
                        background: "#e2e2e2",
                        width: "90%",
                        borderRadius: "12px",
                        padding: "8px",
                        margin: "8px 0",
                        color: '#35797b',
                        fontWeight: 'bold'
                      }}
                    >
                      {positions.length > 0 ? positions.map((pos) =>  bench.position_id === pos.id  ? pos.title : '') : 'there is no positions available'}
                    </Box>
                  </Box>
                  <Typography sx={{ fontWeight: "bold", textDecoration: "underline", color: "white" }}>
                    Benchmark Code
                  </Typography>
                  <Stack sx={{marginTop : '0px !important'}} justifyContent='space-around' alignItems='center' spacing={3} direction="row">
                    <Box sx={{color : 'white'}}>{bench.code}</Box>
                    <Tooltip
                        title="Code copied!"
                        open={openTooltip === index}
                          placement="top"
                          disableInteractive
                        >
                      <Button onClick={() => handleCopy(index, bench.code)} variant="text" sx={{color : 'white'}}>
                        <ContentCopyIcon />
                        Copy Code
                      </Button>
                    </Tooltip>
                  </Stack>
                  <Stack sx={{marginTop: '0 !important'}} justifyContent='space-around' alignItems='center' spacing={3} direction="row">
                    {bench.send_to_id === null || bench.send_to_id === isLoggedIn.userData.id ?  
                      <Button
                      id="benchmark-delete"
                        sx={{
                          background: "linear-gradient(45deg, #9a9a9a, #ededed)",
                          color: "#387c7e",
                          borderRadius: "12px",
                          padding: 1,
                          fontWeight: 'bold'
                        }}
                        onClick={() => {
                            navigate(`/BenchMarkQustion/${bench.id}?benchMarkCode=${bench.code}`)  
                          }}
                      >
                        {(bench.benchmark.length > 1 ?  
                          'Re-Take the Assessment'  
                          : 'Take the Assessment')  }
                      </Button>
                      : 
                      <Button
                        sx={{
                          background: "linear-gradient(45deg, #9a9a9a, #ededed)",
                          color: "#387c7e",
                          borderRadius: "12px",
                          padding: 1,
                          fontWeight: 'bold'
                        }}
                      >
                        {(bench.benchmark.length > 1 ?  
                          'Solved by client'  
                          : 'Waiting for client')  }
                      </Button>}
                      <Button 
                        id="benchmark-delete"
                        onClick={() => {
                            setBenchmarkId(bench.id)
                            setOpenDelete(true)
                        }} 
                        sx={{display: 'flex', flexDirection: 'column', fontSize: '12px', color : 'white'}}>
                        <DeleteIcon />
                        Delete
                        </Button>
                    </Stack>
                  </Stack>
                  )
                }) : <Typography sx={{marginLeft: '350px'}}>There Is No Benchmarks Yet</Typography>}
              </Stack>
              <DeleteDialog open={openDelete} handleClose={handleCloseDelete} DialogTitle="Benchmarks" DialogDeleteContent='This Benchmark' DeleteFun={handleDeleteBenchMark} />
              <AddBench 
              isMobile={isMobile}
              open={openAddBenchMark}
              handleClose={() => setOpenAddBenchMark(false)}
              companies={companies}
              positions={positions}
              />
              <EditBench 
              companies={companies}
              positions={positions}
              error={error}
              isMobile={isMobile}
              open={openEditBenchMark}
              handleClose={() => setOpenEditBenchMark(false)}
              benchmarkId={benchmarkId}
              setFetchBenchmarkTrigger={setFetchBenchmarkTrigger}
              />
              <SendBench 
              open={openSendBenchMark}
              handleClose={() => setOpenSendBenchMark(false)}
              companies={companies}
              positions={positions}
              error={error}
              isMobile={isMobile}
              />
    </div>
  );
}

