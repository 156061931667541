import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadForOfflineIcon from '@mui/icons-material/ArrowDownward';
import BigChips from "../../../ShareHr/BigChips/Index";
import ActionsChips from "../../../ShareHr/ActionsChips";
import Regenerate from "../../../../Individual/Component/Form/Regenerate";

export default function JobBarEmployee({
  handleBackButtonClick,
  getSpecCompany,
  positionId
}) {
  const companyName = getSpecCompany.name
  getSpecCompany = getSpecCompany.positions?.filter((ele) => ele.id === positionId)?.[0]
  const isMobile = useMediaQuery("(max-width:600px)");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [ids, setIds] = useState([]);
  const [DownloadDetails, setDownloadDetails] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [SnackbarText, setSnackbarText] = useState()
  const [openDownload, setOpenDownload] = useState(false);

  const handleRowSelect = (rowId) => {
    setSelectedRows((prev) =>
      prev.includes(rowId) ? prev.filter((id) => id !== rowId) : [...prev, rowId]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const ids = getSpecCompany?.enrollments_employees
        ?.filter((ele) => ele.has_enrollment === true)
        .flatMap((ele) => ele.enrollments?.map((enrollment) => enrollment.id) || []) || [];
      setSelectedRows(ids);
    }
    setSelectAll(!selectAll);
  };

  const handleDownloadAll = () => {
    if (!getSpecCompany?.enrollments_employees) return;
    const ids = getSpecCompany.enrollments_employees
      .filter((ele) => ele.has_enrollment === true)
      .flatMap((ele) => ele.enrollments?.map((enrollment) => enrollment.id) || []);
    setIds(ids);
  };

  const finishedAssessments = getSpecCompany?.enrollments_employees
    ?.flatMap((ele) => ele.enrollments?.filter((enrollment) => enrollment.finished === 1) || [])
    .length || 0;

  const pendingAssessments = getSpecCompany?.enrollments_employees
    ?.flatMap((ele) => ele.enrollments?.filter((enrollment) => enrollment.finished === 0) || [])
    .length || 0;
    console.log(DownloadDetails)
  return (
    <>
      <Button onClick={handleBackButtonClick} sx={{ position: 'fixed', top: '96px', left: '0', fontSize: '18px' }}>
        <ArrowBackIcon />Back
      </Button>
      <BigChips finishedAssessments={`${finishedAssessments}`} pendingAssessments={`${pendingAssessments}`} userName={companyName} />
      <ActionsChips
        benchmark={true}
        downloadAllBenchmarkReport={() => {
          handleDownloadAll();
          setOpenDownload(true);
        }}
        downloadBenchmarkSelectedReport={() => {
          setIds(selectedRows);
          setOpenDownload(true);
        }}
      />
      {getSpecCompany?.enrollments_employees?.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={20}>
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAll}
                    aria-label="Select all rows"
                  />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {getSpecCompany?.enrollments_employees
  ?.filter((employee) => employee.has_enrolment === true)
  ?.flatMap((employee) => 
    employee.enrollments?.map((enrollment) => (
      <TableRow key={enrollment.id}>
        <TableCell width={20}>
          <Checkbox
            checked={selectedRows.includes(enrollment.id)}
            onChange={() => handleRowSelect(enrollment.id)}
          />
        </TableCell>
        <TableCell sx={{ width: '180px', color: "#5e5e5e", textDecoration: "underline" }}>
          {employee.user_name || enrollment.user_name}
        </TableCell>
        <TableCell sx={{ width: '180px', color: "#5e5e5e", textDecoration: "underline" }}>
          {employee.user_email || enrollment.user_email}
        </TableCell>
        <TableCell sx={{ width: '180px', color: "#5e5e5e", textDecoration: "underline" }}>
          {enrollment.code}
        </TableCell>
        <TableCell sx={{ width: '180px', color: "#5e5e5e", textDecoration: "underline" }}>
          {enrollment.created_at?.slice(0, 10)}
        </TableCell>
        <TableCell>
          <Stack direction="row" spacing={2}>
            {enrollment.finished === 1 ? (
              <Button
                variant="contained"
                sx={{ background: "#2b6566" }}
                onClick={() => {
                  setDownloadDetails(enrollment);
                  setOpenDownload(true);
                  setIds((prev) => [...prev, enrollment.id]);
                }}
              >
                <DownloadForOfflineIcon />
                Download Report
              </Button>
            ) : (
              <Button variant="contained" sx={{ background: "#2b6566" }}>
                No Report Yet
              </Button>
            )}
          </Stack>
        </TableCell>
      </TableRow>
    ))
  )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography sx={{ marginLeft: '350px' }}>No Reports Yet</Typography>
      )}
      <Regenerate
        openDownload={openDownload}
        isMobile={isMobile}
        handleCloseDownload={() => setOpenDownload(false)}
        ids={ids}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarText={setSnackbarText}
        // enroid={DownloadDetails.id}
        // examid={DownloadDetails.exam_id}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={SnackbarText}
      />
    </>
  );
}