import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";

const apiBase = "https://api.twindix.com/dashboard/benchmark-questions";

const Benchmark = () => {
  const [open, setOpen] = useState(false);
  const [jsonPayload, setJsonPayload] = useState("");
  const [benchmarks, setBenchmarks] = useState([]);
  const [editId, setEditId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch benchmarks from the API
  const fetchBenchmarks = async () => {
    try {
      setLoading(true);
      const response = await axios.get(apiBase, {
        headers: { Authorization: "Bearer " + localStorage.token },
      });
      setBenchmarks(response.data.payload);
    } catch (err) {
      console.error("Failed to fetch benchmarks:", err);
      console.log(localStorage.token)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBenchmarks();
  }, []);

  // Add or edit benchmark
// Add or edit benchmark
const handleSubmit = async () => {
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.token,
    };

    // Parse and validate JSON payload
    const parsedPayload = JSON.parse(jsonPayload);
    const payload = {
      benchmark_questions: parsedPayload,
    };

    if (editId) {
      // Edit benchmark
      await axios.put(`${apiBase}/${editId}`, payload, { headers });
    } else {
      // Add new benchmark
      await axios.post(apiBase, payload, { headers });
    }

    setJsonPayload("");
    setEditId(null);
    setError(null);
    setOpen(false);
    fetchBenchmarks(); // Refresh the list after add or edit
  } catch (err) {
    setError("Invalid JSON or error while submitting.");
    console.error("Error while submitting:", err);
  }
};


  // Delete benchmark
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiBase}/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      });
      fetchBenchmarks(); // Refresh the list after deletion
    } catch (err) {
      console.error("Error while deleting:", err);
    }
  };

  // Open dialog for editing
  const handleEdit = (id, currentPayload) => {
    setEditId(id);
    setJsonPayload(JSON.stringify(currentPayload, null, 2)); // Pre-fill JSON editor
    setOpen(true);
  };

  return (
    <Box sx={{ padding: "16px" }}>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        sx={{
          marginBottom: "16px",
          background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
          borderRadius: "10px",
        }}
      >
        <AddIcon />
        {editId ? "Edit Benchmark" : "Add Benchmark"}
      </Button>

      {/* Dialog for Add/Edit */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{editId ? "Edit Benchmark" : "Add Benchmark"}</DialogTitle>
        <DialogContent>
          <TextField
            value={jsonPayload}
            onChange={(e) => setJsonPayload(e.target.value)}
            fullWidth
            multiline
            rows={6}
            label="Benchmark Questions"
            sx={{ marginTop: "16px" }}
          />
          {error && <Typography color="red">{error}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              borderRadius: "10px",
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Benchmark Table */}
      <TableContainer Component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Benchmark Data</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  Loading...
                </TableCell>
              </TableRow>
            ) : benchmarks.length === 0 ? (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  No benchmarks found.
                </TableCell>
              </TableRow>
            ) : (
              benchmarks
              .map((benchmark) => (
                <TableRow key={benchmark.id}>
                  <TableCell>
                    <pre>{JSON.stringify(benchmark, null, 2)}</pre>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleEdit(benchmark.id, benchmark)}
                      sx={{ marginRight: "8px" }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleDelete(benchmark.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {/* .filter((benchmark) => benchmark.id === 1) */}
      </TableContainer>
    </Box>
  );
};
export default Benchmark;
