import { Stack, Typography, useMediaQuery } from "@mui/material"
import SearchInput from "./SearchInput"


function Header ({headerTilte, isSearch}) {
  const isMobile = useMediaQuery("(max-width:600px)");
    
    return (
        <Stack spacing={isMobile ? 2 : null} mt={isMobile ? '124px' : 4} mb={4} direction={isMobile ? 'column' : 'row'} justifyContent='space-between' alignItems='center'>
            <Typography sx={{textAlign : isMobile &&  'center', color: '#448084', textDecoration : 'underline', fontSize: '30px', fontWeight: '600'}}>
                {headerTilte}
            </Typography>
            {isSearch !== false && 
            <SearchInput />
             }
        </Stack>
    )
}

export default Header