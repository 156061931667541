import { Badge, Box, IconButton, ListItemText, Menu, MenuItem, Stack, Typography } from "@mui/material"
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useContext, useEffect, useState } from "react";
import { appContext } from "../../../../../App";
import { useNavigate } from "react-router";
import logo from "../../../../../Media/Logo/NavBarLogo.png"
import axios from "axios";
    function NavBar ({toggleDrawer, toggleCart}) {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleOpen = (event) => {
          setAnchorEl(event.currentTarget); // Set the anchor element to the button
        };
      
        const handleClose = () => {
          setAnchorEl(null); // Close the menu
        };
        const isLoggedIn = useContext(appContext);
        console.log(isLoggedIn)

        let navigate = useNavigate();
        function logout(e) {
            e.preventDefault();
            
            axios.delete("https://api.twindix.com/dashboard/auth/logout", {headers:{
                Authorization:"Bearer " + localStorage.token
            }}).then(res => {
                console.log("logged out successfully");
                localStorage.removeItem("token");
                localStorage.removeItem("isAdmin");
                localStorage.removeItem("isLogged");
                localStorage.removeItem("userType");
                isLoggedIn.setLoggedin(null);
                isLoggedIn.setUserType(null);
                isLoggedIn.setIsAdmin(null);
                navigate('/loginForAdmin')
                window.location.reload()
            }).catch(err => console.log(err))
        
        }


        const [anchorNotf, setAnchorNotf] = useState(null);  // For menu anchor
        const [data, setData] = useState([]);  // To store fetched data
        const [loading, setLoading] = useState(false);
      
        // Open the menu when the button is clicked
        const handleClickNotf = (event) => {
          setAnchorNotf(event.currentTarget);
        };
      
        // Close the menu
        const handleCloseNotf = () => {
          setAnchorNotf(null);
        };
      
        // Fetch data from the endpoint
        const fetchData = async () => {
          setLoading(true);
          try {
            const response = await axios.get('https://api.twindix.com/dashboard/benchmark-questions/3', {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                "Content-Type": "application/json",
              },
            });
            setData(response?.data?.payload?.benchmark_questions?.debriefing_Req);
            console.log(response?.data?.payload?.benchmark_questions?.debriefing_Req)
          } catch (error) {
            console.error('Error fetching data', error);
          } finally {
            setLoading(false);
          }
        };
      
        // Fetch data when the menu is opened
        useEffect(() => {
          if (anchorNotf) {
            fetchData();
          }
        }, [anchorNotf]);

        console.log(data)

        return (
            <Stack          
            sx={{
                width: "100%",
                background: "linear-gradient(45deg, rgb(0, 102, 102), rgb(0, 200, 202))",
                padding: '12px'
            }}
            direction='row' spacing={4} alignItems='center' justifyContent='space-between'>
            <Stack direction='row' spacing={4}>
                <IconButton sx={{color : 'white'}} onClick={toggleDrawer}>
                    <DensityMediumIcon />
                </IconButton>
                <Box>
                            <img src='https://assessment.twindix.com/Logo-sm-menu.png' alt="dd" />
                        </Box>
            </Stack>
            {/* <Stack bgcolor='red'><Typography fontSize={25} fontWeight='bold' sx={{color: 'white'}}>نسخه تجريبيه</Typography></Stack> */}
            <Stack   direction='row' alignItems='center' justifyContent='space-around'>
                <Stack direction='row' justifyContent='center' alignItems='center' Component='span' variant="span">    
                    <Typography>
                    English (US) 
                    </Typography>
                    <IconButton >
                        <ArrowDropDownIcon/>
                    </IconButton>
                </Stack>
    <div>
      {/* Notifications Icon Button */}
      <IconButton onClick={handleClickNotf}>
        <NotificationsIcon />
      </IconButton>

      {/* Menu with user_id and enroll_id */}
      <Menu
        anchorEl={anchorNotf}
        open={Boolean(anchorNotf)}
        onClose={handleCloseNotf}
      >
          {data?.length > 0 ? data.map((item, index) => (
            <MenuItem key={index}>
            User {item.user_id} request debriefing for Ass. {item.enroll_id}
            </MenuItem>
          )) : <Typography m={1}>There are no notifications at the moment.</Typography>
        }
      </Menu>
    </div>
                    <Box>
                    <IconButton onClick={handleOpen}>
                            <PersonIcon />
                          </IconButton>

                          <Menu
                          sx={{margin: '0'}}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                          >
                            <MenuItem onClick={handleClose} sx={{textDecoration : 'underline', marginLeft: '-6px'}}>{isLoggedIn.userType === '1' ? "HR" :isLoggedIn.userType === '2' ?  'Indivdual' : 'Admin'} </MenuItem>
                            <MenuItem onClick={handleClose}>{isLoggedIn.userName}</MenuItem>
                            {/* <MenuItem onClick={handleClose}>{isLoggedIn.userData.name}</MenuItem> */}
                            <MenuItem onClick={handleClose}>{isLoggedIn.userEmail}</MenuItem>
                            {/* <MenuItem onClick={handleClose}>{isLoggedIn.userData.phone}</MenuItem> */}
                            {/* <MenuItem onClick={handleClose}>{isLoggedIn.userData.job_title}</MenuItem> */}
                            {/* <MenuItem onClick={handleClose}>My Account</MenuItem> */}
                            <MenuItem onClick={logout}>Logout</MenuItem>
                          </Menu>
                    </Box>
            </Stack>
        </Stack>
        )
    }

    export default NavBar
