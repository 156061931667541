import NavBar from "./ShareHr/NavBar";
import Header from './ShareHr/Header/Index';
import Dashboard from "./Pages/Dashboard";
import AccessTwindixIndivdual from "./Pages/TwindixIndivdual";
import TeamAccess from "./Pages/TeamAccess";
import MangeTwindixAssessments from "./Pages/MangeTwindixAssessments";
import MangeJopBarAssessments from "./Pages/MangeJopBarAssessments";
import Subscriptions from './Pages/Subscriptions';
import BenchmarksShelve from "./Pages/BenchmarksShelve";
import MyOrder from "./Pages/MyOrders";
import { Container } from "@mui/material";
import RolesManagement from "./Pages/Roles";
import CompanyManagement from "./Pages/Company";
import EmployeeManagement from "./Pages/Employee";
import Enrollments from "./Pages/MyEnrollments";
import GetInTouch from "./Pages/GetInToch";
import { useEffect } from "react";
import AccessJopBar from "./Pages/AccessJopBar";
const HrComponent = ({
  toggleDrawer,
  isDashboardHR,
  isTwindixIndivdual,
  isJopBar,
  isBenchmarksShelve,
  isTeamAccess,
  isMangeTwindixAssessments,
  isMangeJopBarAssessments,
  isSubscriptions,
  isHetInTouch,
  toggleCart,
  isMyPackages,
  Roles,
  Company,
  Employee,
  BackToLastPage,
  setshowBackBtn,
  MyEnrollments,
  setSubscriptions
}) => {
  // Mapping for header titles
  const headerTitles = {
    isTwindixIndivdual: "Access ‘‘Twindix Executives’’ Reports",
    isJopBar: "Access ‘‘Job bar’’ Reports",
    isBenchmarksShelve: "Shelve Benchmarks",
    isTeamAccess: "Team Access",
    isMangeTwindixAssessments: "Manage Twindix Assessments",
    isMangeJopBarAssessments: "Manage Job Bar  Assessments",
    isSubscriptions: "Subscriptions",
    isMyPackages: "My Orders",
    Roles: "Roles",
    Company : 'Companies ',
    Employee : 'Participant',
    MyEnrollments :'My Assessments',
    isHetInTouch: "Get In Touch",
  };

  // Map props to Components
  const pages = {
    isDashboardHR: <Dashboard setSubscriptions={setSubscriptions} />,
    isTwindixIndivdual: <AccessTwindixIndivdual setshowBackBtn={setshowBackBtn} />,
    isJopBar: <AccessJopBar  setShowBackBtn={setshowBackBtn} />,
    isBenchmarksShelve: <BenchmarksShelve />,
    isTeamAccess: <TeamAccess />,
    isMangeTwindixAssessments: <MangeTwindixAssessments setshowBackBtn={setshowBackBtn} />,
    isMangeJopBarAssessments: <MangeJopBarAssessments setShowBackBtn={setshowBackBtn} />,
    isSubscriptions: <Subscriptions setSubscriptions={setSubscriptions} />,
    isMyPackages: <MyOrder />,
    Roles: <RolesManagement />,
    Employee: <EmployeeManagement />,
    MyEnrollments: <Enrollments />,
    Company: <CompanyManagement />,
    isHetInTouch : <GetInTouch />
  };


  const activeTitle = Object.keys(headerTitles).find((key) => !!eval(key));
  const activePage = Object.keys(pages).find((key) => !!eval(key));

useEffect(() => {
  setshowBackBtn(false)
}, [])
  return (
    <>
      <NavBar toggleDrawer={toggleDrawer} toggleCart={toggleCart} />
      {!isDashboardHR && BackToLastPage()}
    
      <Container maxWidth="lg" sx={{marginTop :'120px'}}>
        <Header
          isSearch={!activePage || activePage === "isDashboardHR" ? false : true}
          headerTilte={headerTitles[activeTitle] || ""}
        />
        {activePage ? pages[activePage] : <Dashboard setSubscriptions={setSubscriptions}/>}
      </Container>
    </>
  );
};

export default HrComponent;