import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
  TextField,
  MenuItem,
} from "@mui/material";
import axios from "axios";

export default function EditCompany({
  setFetchCompaniesTrigger,
  openEditDialog,
  handleCloseEditDialog,
  selectedCompany,
}) {
  const isMobile = useMediaQuery("(max-width:600px)");

  // Initialize all form fields with valid values (empty strings)
  const [editCompanyForm, setEditCompanyForm] = useState({
    companyName: "",
    contactPerson: "",
    phoneNumber: "",
    mail: "",
    contact_person_job: "",
    contact_person_name: "",
    examId: " ", // Initialize with a valid value
  });

  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  // Destructure form state for cleaner code
  const {
    companyName,
    contactPerson,
    phoneNumber,
    mail,
    contact_person_job,
    contact_person_name,
    examId,
  } = editCompanyForm;

  // Handle input changes
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setEditCompanyForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  // Pre-fill the form when selectedCompany changes
  useEffect(() => {
    if (selectedCompany) {
      setEditCompanyForm({
        companyName: selectedCompany.name || "",
        contactPerson: selectedCompany.contactPerson || "",
        phoneNumber: selectedCompany.phoneNumber || "",
        mail: selectedCompany.mail || "",
        contact_person_job: selectedCompany.contact_person_job || "",
        contact_person_name: selectedCompany.contact_person_name || "",
        examId: selectedCompany.exam_id || " ", // Ensure examId is never null
      });
    } else {
      // Reset the form if no company is selected
      setEditCompanyForm({
        companyName: "",
        contactPerson: "",
        phoneNumber: "",
        mail: "",
        contact_person_job: "",
        contact_person_name: "",
        examId: " ", // Ensure examId is never null
      });
    }
  }, [selectedCompany]);

  // Handle save changes
  const handleSaveEdit = useCallback(async () => {
    if (!selectedCompany) return;

    // Basic validation
    if (!companyName || !contactPerson || !phoneNumber || !mail) {
      setError("Please fill in all required fields.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const updatedCompany = {
        name: companyName,
        contact_person: contactPerson,
        phone: phoneNumber,
        mail: mail,
        contact_person_job: contact_person_job,
        contact_person_name: contact_person_name,
        exam_id: examId,
      };

      const response = await axios.put(
        `https://api.twindix.com/companies/${selectedCompany.id}`,
        updatedCompany,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        handleCloseEditDialog();
        setFetchCompaniesTrigger((prev) => prev + 1);
      }
    } catch (error) {
      setError("Failed to update company. Please try again.");
      console.error("Error updating company:", error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedCompany, editCompanyForm]);

  return (
    <Dialog
      PaperProps={
        isMobile
          ? {
              sx: {
                position: "fixed",
                left: 0,
                margin: 0,
                height: "auto",
                borderRadius: isMobile ? 0 : "8px",
              },
            }
          : {}
      }
      maxWidth={isMobile ? "sm" : "md"}
      open={openEditDialog}
      onClose={handleCloseEditDialog}
    >
      <Box
        sx={{
          bgcolor: "#929292",
          width: isMobile ? "auto" : "450px",
          height: isMobile && "100%",
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: "24px", width: "200px", margin: "auto", color: "white" }}>
            Edit Company
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={3}>
            {/* Company Name */}
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Company Name:</Typography>
              <TextField
                fullWidth
                name="companyName"
                value={companyName}
                onChange={handleChange}
                placeholder="Enter company name"
                variant="outlined"
                sx={{ backgroundColor: "white", borderRadius: "4px" }}
              />
            </Grid>

            {/* Contact Person */}
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Contact Person:</Typography>
              <TextField
                fullWidth
                name="contactPerson"
                value={contactPerson}
                onChange={handleChange}
                placeholder="Enter contact person"
                variant="outlined"
                sx={{ backgroundColor: "white", borderRadius: "4px" }}
              />
            </Grid>

            {/* Phone Number */}
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Phone Number:</Typography>
              <TextField
                fullWidth
                name="phoneNumber"
                value={phoneNumber}
                onChange={handleChange}
                placeholder="Enter phone number"
                variant="outlined"
                sx={{ backgroundColor: "white", borderRadius: "4px" }}
              />
            </Grid>

            {/* Mail */}
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Mail:</Typography>
              <TextField
                fullWidth
                name="mail"
                value={mail}
                onChange={handleChange}
                placeholder="Enter mail"
                variant="outlined"
                sx={{ backgroundColor: "white", borderRadius: "4px" }}
              />
            </Grid>

            {/* Contact Person Job */}
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Contact Person Job:</Typography>
              <TextField
                fullWidth
                name="contact_person_job"
                value={contact_person_job}
                onChange={handleChange}
                placeholder="Enter contact person job"
                variant="outlined"
                sx={{ backgroundColor: "white", borderRadius: "4px" }}
              />
            </Grid>

            {/* Contact Person Name */}
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Contact Person Name:</Typography>
              <TextField
                fullWidth
                name="contact_person_name"
                value={contact_person_name}
                onChange={handleChange}
                placeholder="Enter contact person name"
                variant="outlined"
                sx={{ backgroundColor: "white", borderRadius: "4px" }}
              />
            </Grid>

            {/* Exam */}
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Exam:</Typography>
              <TextField
                fullWidth
                select
                name="examId"
                value={examId}
                onChange={handleChange}
                variant="outlined"
                sx={{ backgroundColor: "white", borderRadius: "4px" }}
              >
                <MenuItem value=" ">Select an option</MenuItem>
                <MenuItem value="1">Twindix</MenuItem>
                <MenuItem value="65">Man Code</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {error && (
            <Typography color="error" sx={{ textAlign: "center", mt: 2 }}>
              {error}
            </Typography>
          )}
          <Button
            sx={{
              background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              borderRadius: "10px",
              margin: "auto",
            }}
            onClick={handleSaveEdit}
            variant="contained"
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save Changes"}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}