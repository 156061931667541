import { createContext, useEffect, useState, useCallback, useContext } from "react";
import axios from "axios";
import { appContext } from "../../../../App";
import Swal from "sweetalert2";

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const [exams, setExams] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [sentEnrollments, setSentEnrollments] = useState(null);
  const [enrollments, setEnrollments] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orders, setOrders] = useState([]);
  const [packages, setPackages] = useState([]);
  const [editors, setEditors] = useState([]);
  const [roles, setRoles] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [getBenchMarkData, setGetBenchMarkData] = useState([]);
  const [getEmployeesForAssignedCompanies, setGetEmployeesForAssignedCompanies] = useState([]);
  const [statistics, setStatistics ] = useState([])

  // Separate triggers and conditions for each API call
  const [fetchStatisticsTrigger, setFetchStatisticsTrigger] = useState(0);
  const [fetchExamsTrigger, setFetchExamsTrigger] = useState(0);
  const [fetchEnrollmentsTrigger, setFetchEnrollmentsTrigger] = useState(0);
  const [fetchSentEnrollmentsTrigger, setFetchSentEnrollmentsTrigger] = useState(0);
  const [fetchCompaniesTrigger, setFetchCompaniesTrigger] = useState(0);
  const [fetchBenchmarkTrigger, setFetchBenchmarkTrigger] = useState(0);
  const [fetchOrdersTrigger, setFetchOrdersTrigger] = useState(0);
  const [fetchPackagesTrigger, setFetchPackagesTrigger] = useState(0);
  const [fetchPermissionsTrigger, setFetchPermissionsTrigger] = useState(0);
  const [fetchRolesTrigger, setFetchRolesTrigger] = useState(0);
  const [fetchEditorsTrigger, setFetchEditorsTrigger] = useState(0);
  const [fetchEmployeesTrigger, setFetchEmployeesTrigger] = useState(0);
  const [fetchPositionsTrigger, setFetchPositionsTrigger] = useState(0);
  const [fetchEmployeesForAssignedCompaniesTrigger, setFetchEmployeesForAssignedCompaniesTrigger] = useState(0);

  // Conditions to control when each API call is made
  const [shouldFetchStatistics, setShouldFetchStatistics] = useState(false);
  const [shouldFetchExams, setShouldFetchExams] = useState(false);
  const [shouldFetchEnrollments, setShouldFetchEnrollments] = useState(false);
  const [shouldFetchSentEnrollments, setShouldFetchSentEnrollments] = useState(false);
  const [shouldFetchCompanies, setShouldFetchCompanies] = useState(false);
  const [shouldFetchBenchmark, setShouldFetchBenchmark] = useState(false);
  const [shouldFetchOrders, setShouldFetchOrders] = useState(false);
  const [shouldFetchPackages, setShouldFetchPackages] = useState(false);
  const [shouldFetchPermissions, setShouldFetchPermissions] = useState(false);
  const [shouldFetchRoles, setShouldFetchRoles] = useState(false);
  const [shouldFetchEditors, setShouldFetchEditors] = useState(false);
  const [shouldFetchEmployees, setShouldFetchEmployees] = useState(false);
  const [shouldFetchPositions, setShouldFetchPositions] = useState(false);
  const [shouldFetchEmployeesForAssignedCompanies, setShouldFetchEmployeesForAssignedCompanies] = useState(false);

      //** HANDEL PERMISSION FOR HR */

      const isLoggedIn = useContext(appContext);

      const handelPermission = (Permission) => { 
        if (isLoggedIn?.userData?.managed_by !== null) {
          const rolesForPerson = isLoggedIn?.userData?.roles?.[0]?.permissions;
      
          const isHasPermission = rolesForPerson?.some((ele) => ele === Permission);
  
          if (!isHasPermission) {
            Swal.fire({
              icon: 'error',
              title: 'Access Denied',
              text: 'You do not have permission to access this page.',
              timer: 2000,
              showConfirmButton: true, 
              willClose: () => {
                window.location.reload();
              }
            })
          }
            return isHasPermission
        }
        return true
      };

      const handelPermission2 = (permissions) => {
        if (isLoggedIn?.userData?.managed_by !== null) {
            const rolesForPerson = isLoggedIn?.userData?.roles?.[0]?.permissions;
    
            // Loop through each permission in the array
            permissions.forEach((permission) => {
                // Extract the action (e.g., 'create', 'update', 'delete') from the permission string
                const action = permission.split('-')[1]; // e.g., 'company-create' -> 'create'
                const buttonId = permission; // Use the permission as the button ID
    
                // Filter permissions that match the current permission
                const isHasPermission = rolesForPerson
                    ?.filter((role) => role.name === permission)
                    .map((role) => role.name);
    
                // Check if the user has the required permission
                const hasPermission = isHasPermission.length > 0;
    
                // Check if any permission includes 'show'
                const hasShowPermission = isHasPermission.some((ele) => ele.includes('show'));
    
                // Get the button by ID
                const button = document.getElementById(buttonId);
    
                if (button && !button.hasAttribute('data-listener-attached')) {
                    // Mark the button to indicate the listener has been attached
                    button.setAttribute('data-listener-attached', 'true');
    
                    // Attach a click event listener to the button
                    button.addEventListener('click', (event) => {
                        if (!hasPermission) {
                            // Prevent the default button action (e.g., form submission, navigation, etc.)
                            event.preventDefault();
                            event.stopPropagation(); // Stop the event from bubbling up
    
                            // Show the popup if the user does not have the permission
                            Swal.fire({
                                icon: 'error',
                                title: 'Access Denied',
                                text: `You do not have permission to perform this action (${action}).`,
                                timer: 2000,
                                showConfirmButton: true,
                            });
    
                            // Stop further execution
                            return;
                        }
    
                        // Additional check for 'show' permission
                        if (!hasShowPermission) {
                            // Prevent the default button action
                            event.preventDefault();
                            event.stopPropagation();
    
                            // Show the popup if the user does not have the 'show' permission
                            Swal.fire({
                                icon: 'error',
                                title: 'Access Denied',
                                text: 'You do not have permission to access this page.',
                                timer: 2000,
                                showConfirmButton: true,
                                willClose: () => {
                                    window.location.reload();
                                }
                            });
    
                            // Stop further execution
                            return;
                        }
    
                        // If the user has permission, proceed with the action
                        console.log(`User has ${permission} permission. Proceeding with the action...`);
                        // Add your action logic here
                    });
                }
            });
    
            return true; // Return true if the user is not managed by anyone (or handle as needed)
        }
    
        return true; // Return true if the user is not managed by anyone (or handle as needed)
    };
    
    
  //** FETCHING ALL API FOR HR ACCOUNT */

  // Fetch Exams
  useEffect(() => {
    if (shouldFetchExams) {
      const fetchExams = async () => {
        try {
          setLoading(true);
          const token = localStorage.getItem("token");
          const examsRes = await axios.get("https://api.twindix.com/exams", { headers: { Authorization: `Bearer ${token}` } });
          setExams(examsRes.data.payload.data);
          setLoading(false);
        } catch (err) {
          setError(err);
          setLoading(false);
        }
      };
      fetchExams();
    }
  }, [fetchExamsTrigger, shouldFetchExams]);

  // Fetch Enrollments
  useEffect(() => {
    if (shouldFetchEnrollments) {
      const fetchneEnrollments = async () => {
        try {
          setLoading(true);
          const token = localStorage.getItem("token");
          let allEnrollments = [];
          let currentPage = 1;
          let hasMoreData = true;
          while (hasMoreData) {
            const enrollmentsRes = await axios.get(`https://api.twindix.com/enrollments?page=${currentPage}`, {
              headers: { Authorization: `Bearer ${token}` },
            });
            const newEnrollments = enrollmentsRes.data.payload.data;
            allEnrollments = [...allEnrollments, ...newEnrollments];
            hasMoreData = newEnrollments.length === 15;
            currentPage++;
          }
          setEnrollments(allEnrollments);
          setLoading(false);
        } catch (err) {
          setError(err);
          setLoading(false);
        }
      };
      fetchneEnrollments();
    }
  }, [fetchEnrollmentsTrigger, shouldFetchEnrollments]);

  // Fetch Sent Enrollments
  useEffect(() => {
    if (shouldFetchSentEnrollments) {
      const fetchSentEnrollments = async () => {
        try {
          setLoading(true);
          const token = localStorage.getItem("token");
          const sentEnrollmentsRes = await axios.get("https://api.twindix.com/sent-enrollments", { headers: { Authorization: `Bearer ${token}` } });
          setSentEnrollments(sentEnrollmentsRes.data.payload);
          setLoading(false);
        } catch (err) {
          setError(err);
          setLoading(false);
        }
      };
      fetchSentEnrollments();
    }
  }, [fetchSentEnrollmentsTrigger, shouldFetchSentEnrollments]);

  // Fetch Companies
  useEffect(() => {
    if (shouldFetchCompanies) {
      const fetchCompanies = async () => {
        try {
          setLoading(true);
          const token = localStorage.getItem("token");
          const companiesRes = await axios.get("https://api.twindix.com/companies", { headers: { Authorization: `Bearer ${token}` } });
          setCompanies(companiesRes.data.payload);
          setLoading(false);
        } catch (err) {
          setError(err);
          setLoading(false);
        }
      };
      fetchCompanies();
    }
  }, [fetchCompaniesTrigger, shouldFetchCompanies]);

  // Fetch Benchmark Data
  useEffect(() => {
    if(shouldFetchBenchmark) {
      const fetchBenchmark = async () => {
        try {
          setLoading(true);
          const token = localStorage.getItem("token");
          const benchmarkRes = await axios.get("https://api.twindix.com/benchmark-questions/1/benchmarks", { headers: { Authorization: `Bearer ${token}` } });
          setGetBenchMarkData(benchmarkRes.data.payload);
          setLoading(false);
        } catch (err) {
          setError(err);
          setLoading(false);
        }
      };
      fetchBenchmark();
    }
  }, [fetchBenchmarkTrigger, shouldFetchBenchmark]);

  // Fetch Orders
  useEffect(() => {
    if (shouldFetchOrders) {
      const fetchOrders = async () => {
        try {
          setLoading(true);
          const token = localStorage.getItem("token");
          const ordersRes = await axios.get("https://api.twindix.com/orders", { headers: { Authorization: `Bearer ${token}` } });
          setOrders(ordersRes.data.payload.data);
          setLoading(false);
        } catch (err) {
          setError(err);
          setLoading(false);
        }
      };
      fetchOrders();
    }
  }, [fetchOrdersTrigger, shouldFetchOrders]);

  // Fetch Packages
  useEffect(() => {
    if (shouldFetchPackages) {
      const fetchPackages = async () => {
        try {
          setLoading(true);
          const packagesRes = await axios.get("https://api.twindix.com/packages");
          const packagesVar = packagesRes.data.payload.data;
          setPackages(packagesVar);
          setLoading(false);
        } catch (err) {
          setError(err);
          setLoading(false);
        }
      };
      fetchPackages();
    }
  }, [fetchPackagesTrigger, shouldFetchPackages]);

  // Fetch Permissions
  useEffect(() => {
    if (shouldFetchPermissions) {
      const fetchPermissions = async () => {
        try {
          setLoading(true);
          const token = localStorage.getItem("token");
          const permissionsRes = await axios.get("https://api.twindix.com/permissions", { headers: { Authorization: `Bearer ${token}` } });
          setAllPermissions(permissionsRes.data.payload);
          setLoading(false);
        } catch (err) {
          setError(err);
          setLoading(false);
        }
      };
      fetchPermissions();
    }
  }, [fetchPermissionsTrigger, shouldFetchPermissions]);

  // Fetch Roles
  useEffect(() => {
    if(shouldFetchRoles){
      const fetchRoles = async () => {
        try {
          setLoading(true);
          const token = localStorage.getItem("token");
          const rolesRes = await axios.get("https://api.twindix.com/roles", { headers: { Authorization: `Bearer ${token}` } });
          setRoles(rolesRes.data.payload);
          setLoading(false);
        } catch (err) {
          setError(err);
          setLoading(false);
        }
      };
      fetchRoles();
    }
  }, [fetchRolesTrigger, shouldFetchRoles]);

  // Fetch Editors
  useEffect(() => {
    if (shouldFetchEditors) {
      const fetchEditors = async () => {
        try {
          setLoading(true);
          const token = localStorage.getItem("token");
          const editorsRes = await axios.get("https://api.twindix.com/editors", { headers: { Authorization: `Bearer ${token}` } });
          setEditors(editorsRes.data.payload);
          setLoading(false);
        } catch (err) {
          setError(err);
          setLoading(false);
        }
      };
      fetchEditors();
    }
  }, [fetchEditorsTrigger, shouldFetchEditors]);


// Fetch Employees
useEffect(() => {
  if (shouldFetchEmployees) {
    const fetchEmployees = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        const employeesRes = await axios.get(`https://api.twindix.com/employees`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setEmployees(employeesRes.data.payload);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
    fetchEmployees();
  }
}, [fetchEmployeesTrigger, shouldFetchEmployees]);

  // Fetch Positions
      const fetchPositions = async (selectedCompanyId) => {
        try {
          setLoading(true);
          const token = localStorage.getItem("token");
          const positionsRes = await axios.get(`https://api.twindix.com/companies/${selectedCompanyId}/positions`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setPositions(positionsRes.data.payload);
          setLoading(false);
        } catch (err) {
          setError(err);
          setLoading(false);
        }
      };

      useEffect(() => {
        fetchPositions()
      }, [fetchPositionsTrigger, shouldFetchPositions])

  // Fetch Employees for Assigned Companies
  const fetchEmployeesForAssignedCompanies = async (company_id, positions_id) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const url = positions_id
        ? `https://api.twindix.com/employees-for-assigned-companies?company_id=${company_id}&position_id=${positions_id}`
        : `https://api.twindix.com/employees-for-assigned-companies?company_id=${company_id}`;
      const employeesRes = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGetEmployeesForAssignedCompanies(employeesRes.data.payload);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  // Expose a function to trigger the fetch
  const refetchEmployeesForAssignedCompanies = (company_id, positions_id) => {
    setFetchEmployeesForAssignedCompaniesTrigger((prev) => prev + 1); // Increment trigger
    setShouldFetchEmployeesForAssignedCompanies(true); // Enable fetching
    fetchEmployeesForAssignedCompanies(company_id, positions_id); // Fetch data
  };

  // Fetch employees when the trigger or condition changes
  useEffect(() => {
    if (shouldFetchEmployeesForAssignedCompanies) {
      fetchEmployeesForAssignedCompanies();
    }
  }, [fetchEmployeesForAssignedCompaniesTrigger, shouldFetchEmployeesForAssignedCompanies]);


  useEffect(() => {
    if(shouldFetchStatistics){
      axios
        .get(`https://api.twindix.com/statistics `, {
          headers: { Authorization: `Bearer ${localStorage.token}` },
        })
        .then((res) => {
          setStatistics(res.data.payload)})
        .catch((error) => console.error("Error fetching positions:", error));
    }
  }, [shouldFetchStatistics, fetchStatisticsTrigger]);


  return (
    <ApiContext.Provider
      value={{
        exams,
        employees,
        enrollments,
        sentEnrollments,
        companies,
        selectedCompany,
        setSelectedCompany,
        positions,
        loading,
        error,
        orders,
        packages,
        editors,
        setEditors,
        roles,
        allPermissions,
        getBenchMarkData,
        getEmployeesForAssignedCompanies,
        statistics,
        fetchPositions,
        fetchEmployeesForAssignedCompanies,
        refetchEmployeesForAssignedCompanies,

        setShouldFetchStatistics,
        setShouldFetchExams,
        setShouldFetchEnrollments,
        setShouldFetchSentEnrollments,
        setShouldFetchCompanies,
        setShouldFetchBenchmark,
        setShouldFetchOrders,
        setShouldFetchPackages,
        setShouldFetchPermissions,
        setShouldFetchRoles,
        setShouldFetchEditors,
        setShouldFetchEmployees,
        setShouldFetchPositions,
        setShouldFetchEmployeesForAssignedCompanies,

        setFetchStatisticsTrigger,
        setFetchExamsTrigger,
        setFetchEnrollmentsTrigger,
        setFetchSentEnrollmentsTrigger,
        setFetchCompaniesTrigger,
        setFetchBenchmarkTrigger,
        setFetchOrdersTrigger,
        setFetchPackagesTrigger,
        setFetchPermissionsTrigger,
        setFetchRolesTrigger,
        setFetchEditorsTrigger,
        setFetchEmployeesTrigger,
        setFetchPositionsTrigger,
        setFetchEmployeesForAssignedCompaniesTrigger,

        handelPermission,
        handelPermission2
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export default ApiContext;