import LogoBlu from "../../../FemilerCom/Logo/SLogoBlu"
import Logowhit from "../../../FemilerCom/Logo/BLogowhit"
import twentyone from "../../../Media/Twindexreport/twenty-one.webp"
import twentytwo from "../../../Media/Twindexreport/twenty-two.webp"
import twentythree from "../../../Media/Twindexreport/twenty-three.webp"
import twentyfour from "../../../Media/Twindexreport/twenty-four.webp"
import twentyfive from "../../../Media/Twindexreport/twenty-five.webp"
import twentysix from "../../../Media/Twindexreport/twenty-six.webp"
import twentyseven from "../../../Media/Twindexreport/twenty-seven.webp"
import twentyeight from "../../../Media/Twindexreport/twenty-eight.webp"
import twentynine from "../../../Media/Twindexreport/twenty-nine.webp"
import thirty from "../../../Media/Twindexreport/thirty.webp"
import thirtyone from "../../../Media/Twindexreport/thirty-one.webp"
import { useContext } from "react"
import { repoCon } from "../ReportComponent"
import ButtonBlueRight from "../../../Media/Twindexreport/ButtonBlueRight.png"
import ButtonBlueLeft from "../../../Media/Twindexreport/ButtonBlueLeft.png"
import ButtonBlackLeft from "../../../Media/Twindexreport/ButtonBlackLeft.png"
import ButtonBlackRight from "../../../Media/Twindexreport/ButtonBlackRight.png"
import ButtonGreenLeft from "../../../Media/Twindexreport/ButtonGreenLeft.png"
import ButtonGreenRight from "../../../Media/Twindexreport/ButtonGreenRight.png"
import ButtonRedLeft from "../../../Media/Twindexreport/ButtonRedLeft.png"
import ButtonRedRight from "../../../Media/Twindexreport/ButtonRedRight.png"
import en21 from "../../../Media/Twindexreport/21-en.jpg"
import en23 from "../../../Media/Twindexreport/23-en.jpg"
import en25 from "../../../Media/Twindexreport/25-en.jpg"
function Report3 ({lang})  {
    let repoConn = useContext(repoCon)

    function Charts (upchart, downchart)  {
        let s = 200 - (upchart + downchart);
        upchart = Math.ceil((upchart + Math.ceil(s*upchart / 100)) / 1.97);
        downchart = Math.ceil((downchart + Math.ceil(s*downchart / 100)) / 1.97);
        return { upchart, downchart };
    }

    let R164 = repoConn.references["R164"];
    let R163 = repoConn.references["R163"];
    let R162 = repoConn.references["R162"];
    let R161 = repoConn.references["R161"];

    let R172 = repoConn.references["R172"];
    let R171 = repoConn.references["R171"];
    let R170 = repoConn.references["R170"];
    let R169 = repoConn.references["R169"];
const result17 = Charts(R164, R172);
const result18 = Charts(R163, R171);
const result19 = Charts(R162, R170);
const result20 = Charts(R161, R169);

const SR164 = {
    height: `${result17.upchart}%`,
};
const SR163 = {
    height: `${result18.upchart}%`,
};
const SR162 = {
    height: `${result19.upchart}%`,
};
const SR161 = {
    height: `${result20.upchart}%`,
};
const SR172 = {
    height: `${result17.downchart}%`,
};
const SR171 = {
    height: `${result18.downchart}%`,
};
const SR170 = {
    height: `${result19.downchart}%`,
};
const SR169 = {
    height: `${result20.downchart}%`,
};
const IsEnglish = lang === 'en'

    return (
        <>
            <div id="Third">
            <div className='ManyBox-2Report-3 pdf-container-5'>
                <img className='ImgReport' src={lang === 'en' ? en21 : twentyone} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                <div className='IntroBatrn'>
                    <div className="StrepCountaner">
                    <div>{repoConn.referencess && repoConn.referencess[316][lang === 'en' ? 'en content' : 'ar content']}</div>
                    </div>
                </div>
                </div>
                <div className="BoxsContaner">
                <div className="SmallBox">
                        <div>
                            <span>{repoConn.referencess && repoConn.referencess[317][lang === 'en' ? 'en content' : 'ar content']}</span>
                        </div>
                        <div>
                            <span>{repoConn.referencess && repoConn.referencess[327][lang === 'en' ? 'en content' : 'ar content']}</span>
                        </div>
                    </div>
                    <div className="BigBox">
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["221"] == 0 ? "ImportBox ManyBoxGray" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["221"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[328][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[318][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className={repoConn.answers["221"] == 1 ? "ImportBox ManyBoxGray" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["221"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                        </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["222"] == 0 ? "ImportBox ManyBoxGray" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["222"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[329][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[319][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className={repoConn.answers["222"] == 1 ? "ImportBox ManyBoxGray" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["222"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                                                    </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["223"] == 0 ? "ImportBox ManyBoxGray" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["223"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                            
                        {/* <span className="ImportBox">الإعتماد علي خبرات المدراء في تشغيل المؤسسة</span> */}
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[330][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[320][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className={repoConn.answers["223"] == 1 ? "ImportBox ManyBoxGray" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["223"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                        </div>
                        <div className="DetilsBoxs">
                                                    <span className={repoConn.answers["224"] == 0 ? "ImportBox ManyBoxGray" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["224"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[331][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[321][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className={repoConn.answers["224"] == 1 ? "ImportBox ManyBoxGray" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["224"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                                                    </div>
                        <div className="DetilsBoxs">
                                                    <span className={repoConn.answers["225"] == 0 ? "ImportBox ManyBoxGray" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["225"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[332][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[322][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className={repoConn.answers["225"] == 1 ? "ImportBox ManyBoxGray" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["225"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                                                    </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["226"] == 0 ? "ImportBox ManyBoxGray" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["226"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[333][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[323][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className={repoConn.answers["226"] == 1 ? "ImportBox ManyBoxGray" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["226"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                        </div>
                        <div className="DetilsBoxs">
                                                    <span className={repoConn.answers["227"] == 0 ? "ImportBox ManyBoxGray" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["227"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[334][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[324][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className={repoConn.answers["227"] == 1 ? "ImportBox ManyBoxGray" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["227"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                        </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["228"] == 0 ? "ImportBox ManyBoxGray" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["228"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[335][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[325][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className={repoConn.answers["228"] == 1 ? "ImportBox ManyBoxGray" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["228"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                        </div>
                        <div className="DetilsBoxs">
                                                    <span className={repoConn.answers["229"] == 0 ? "ImportBox ManyBoxGray" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["229"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[336][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[326][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className={repoConn.answers["229"] == 1 ? "ImportBox ManyBoxGray" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["229"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                        </div>
                    </div>
                </div>
            </div>
            <div className='Empaty-8Report3 pdf-container-5'>
                <img className='ImgReport' src={twentytwo} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                <div className='IntroBatrn'>
                    <div className="StrepCountaner">
                    <div>{repoConn.referencess && repoConn.referencess[337][lang === 'en' ? 'en content' : 'ar content']}</div>
                    </div>
                </div>
                </div>
                <h1 className='H1Text' style={{width : IsEnglish && '90%'}}>
                {repoConn.referencess && repoConn.referencess[1310][lang === 'en' ? 'en content' : 'ar content']}
                </h1>
                <div className='BigText' style={{lineHeight : IsEnglish ? '33px' : '40px'}}>
                {repoConn.answers["231"] == 1 ? repoConn.referencess && repoConn.referencess[338][lang === 'en' ? 'en content' : 'ar content'] 
                    : repoConn.referencess && repoConn.referencess[339][lang === 'en' ? 'en content' : 'ar content']}
                    {/* <br /> */}
                    {repoConn.answers["232"] == 1 ? repoConn.referencess && repoConn.referencess[340][lang === 'en' ? 'en content' : 'ar content'] 
                    : repoConn.referencess && repoConn.referencess[341][lang === 'en' ? 'en content' : 'ar content']}
                    {/* <br /> */}
                    {repoConn.answers["233"] == 1 ? repoConn.referencess && repoConn.referencess[342][lang === 'en' ? 'en content' : 'ar content'] 
                    : repoConn.referencess && repoConn.referencess[343][lang === 'en' ? 'en content' : 'ar content']}
                    {/* <br /> */}
                    {repoConn.answers["234"] == 1 ? repoConn.referencess && repoConn.referencess[344][lang === 'en' ? 'en content' : 'ar content'] 
                    : repoConn.referencess && repoConn.referencess[345][lang === 'en' ? 'en content' : 'ar content']}
                    {/* <br /> */}
                    {repoConn.answers["235"] == 1 ? repoConn.referencess && repoConn.referencess[346][lang === 'en' ? 'en content' : 'ar content'] 
                    : repoConn.referencess && repoConn.referencess[347][lang === 'en' ? 'en content' : 'ar content']}
                    {/* <br /> */}
                    {repoConn.answers["236"] == 1 ? repoConn.referencess && repoConn.referencess[348][lang === 'en' ? 'en content' : 'ar content'] 
                    : repoConn.referencess && repoConn.referencess[349][lang === 'en' ? 'en content' : 'ar content']}
                    {/* <br /> */}
                    {repoConn.answers["237"] == 1 ? repoConn.referencess && repoConn.referencess[350][lang === 'en' ? 'en content' : 'ar content'] 
                    : repoConn.referencess && repoConn.referencess[351][lang === 'en' ? 'en content' : 'ar content']}
                    {/* <br /> */}
                    {repoConn.answers["238"] == 1 ? repoConn.referencess && repoConn.referencess[352][lang === 'en' ? 'en content' : 'ar content'] 
                    : repoConn.referencess && repoConn.referencess[353][lang === 'en' ? 'en content' : 'ar content']}
                    {/* <br /> */}
                    {repoConn.answers["239"] == 1 ? repoConn.referencess && repoConn.referencess[354][lang === 'en' ? 'en content' : 'ar content'] 
                    : repoConn.referencess && repoConn.referencess[355][lang === 'en' ? 'en content' : 'ar content']}
                    {/* <br /> */}
                </div>
            </div>
            <div className='ManyBox-3Report-3 pdf-container-5'>
                <img className='ImgReport' src={lang === 'en' ? en23 : twentythree} alt="ReportImge" />
                <div>s</div>
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                <div className='IntroBatrn'>
                    <div className="StrepCountaner">
                    <div>{repoConn.referencess && repoConn.referencess[356][lang === 'en' ? 'en content' : 'ar content']}</div>
                    </div>
                </div>
                </div>
                <div className="BoxsContaner">
                <div className="SmallBox">
                        <div>
                            <span>{repoConn.referencess && repoConn.referencess[357][lang === 'en' ? 'en content' : 'ar content']}</span>
                        </div>
                        <div>
                            <span>{repoConn.referencess && repoConn.referencess[367][lang === 'en' ? 'en content' : 'ar content']}</span>
                        </div>
                    </div>
                    <div className="BigBox">
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["231"] == 0 ? "ImportBox ManyBoxRed" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["231"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[368][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[358][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className={repoConn.answers["231"] == 1 ? "ImportBox ManyBoxRed" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["231"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                        
                            </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["232"] == 0 ? "ImportBox ManyBoxRed" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["232"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                            
                        <span className="ImportBox">{repoConn.referencess && repoConn.referencess[369][lang === 'en' ? 'en content' : 'ar content']}</span>
                            
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[359][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className={repoConn.answers["232"] == 1 ? "ImportBox ManyBoxRed" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["232"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                       
                             </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["233"] == 0 ? "ImportBox ManyBoxRed" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["233"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                            
                        <span className="ImportBox">{repoConn.referencess && repoConn.referencess[370][lang === 'en' ? 'en content' : 'ar content']}</span>
                            
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[360][lang === 'en' ? 'en content' : 'ar content']}</span>

                            <span className={repoConn.answers["233"] == 1 ? "ImportBox ManyBoxRed" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["233"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                        
                            </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["234"] == 0 ? "ImportBox ManyBoxRed" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["234"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                            
                        <span className="ImportBox">{repoConn.referencess && repoConn.referencess[371][lang === 'en' ? 'en content' : 'ar content']}</span>
                            
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[361][lang === 'en' ? 'en content' : 'ar content']}</span>

                            <span className={repoConn.answers["234"] == 1 ? "ImportBox ManyBoxRed" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["234"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                        
                            </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["235"] == 0 ? "ImportBox ManyBoxRed" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["235"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                            
                        <span className="ImportBox">{repoConn.referencess && repoConn.referencess[372][lang === 'en' ? 'en content' : 'ar content']}</span>
                            
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[362][lang === 'en' ? 'en content' : 'ar content']}</span>

                            <span className={repoConn.answers["235"] == 1 ? "ImportBox ManyBoxRed" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["235"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                        
                            </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["236"] == 0 ? "ImportBox ManyBoxRed" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["236"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                            
                        <span className="ImportBox">{repoConn.referencess && repoConn.referencess[373][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[363][lang === 'en' ? 'en content' : 'ar content']}</span>

                            <span className={repoConn.answers["236"] == 1 ? "ImportBox ManyBoxRed" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["236"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                        
                            </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["237"] == 0 ? "ImportBox ManyBoxRed" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["237"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                            
                        <span className="ImportBox">{repoConn.referencess && repoConn.referencess[374][lang === 'en' ? 'en content' : 'ar content']}</span>
                            
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[364][lang === 'en' ? 'en content' : 'ar content']}</span>

                            <span className={repoConn.answers["237"] == 1 ? "ImportBox ManyBoxRed" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["237"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                        
                            </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["238"] == 0 ? "ImportBox ManyBoxRed" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["238"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                            
                        <span className="ImportBox">{repoConn.referencess && repoConn.referencess[375][lang === 'en' ? 'en content' : 'ar content']}</span>
                            
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[365][lang === 'en' ? 'en content' : 'ar content']}</span>

                            <span className={repoConn.answers["238"] == 1 ? "ImportBox ManyBoxRed" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["238"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                        
                            </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["239"] == 0 ? "ImportBox ManyBoxRed" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["239"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                            
                        <span className="ImportBox">{repoConn.referencess && repoConn.referencess[376][lang === 'en' ? 'en content' : 'ar content']}</span>
                            
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[366][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className={repoConn.answers["239"] == 1 ? "ImportBox ManyBoxRed" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["239"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                        
                            </div>
                    </div>
                </div>
            </div>
            <div className='Empaty-9Report3 pdf-container-6'>
                <img className='ImgReport' src={twentyfour} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                <div className='IntroBatrn'>
                    <div className="StrepCountaner">
                    <div>{repoConn.referencess && repoConn.referencess[377][lang === 'en' ? 'en content' : 'ar content']}</div>
                    </div>
                </div>
                </div>
                <h1 className='H1Text' style={{fontSize : IsEnglish && '34px', width : IsEnglish && '90%'}}>
                {repoConn.referencess && repoConn.referencess[1311][lang === 'en' ? 'en content' : 'ar content']}
                </h1>
                <div className='BigText' style={{lineHeight : IsEnglish ? '33px' : '40px'}}>
                {repoConn.answers["241"] == 1 ? repoConn.referencess && repoConn.referencess[378][lang === 'en' ? 'en content' : 'ar content'] 
                    : repoConn.referencess && repoConn.referencess[379][lang === 'en' ? 'en content' : 'ar content']}
                    {/* <br /> */}
                    {repoConn.answers["242"] == 1 ? repoConn.referencess && repoConn.referencess[380][lang === 'en' ? 'en content' : 'ar content'] 
                    : repoConn.referencess && repoConn.referencess[381][lang === 'en' ? 'en content' : 'ar content']}
                    {/* <br /> */}
                    {repoConn.answers["243"] == 1 ? repoConn.referencess && repoConn.referencess[382][lang === 'en' ? 'en content' : 'ar content'] 
                    : repoConn.referencess && repoConn.referencess[383][lang === 'en' ? 'en content' : 'ar content']}
                    {/* <br /> */}
                    {repoConn.answers["244"] == 1 ? repoConn.referencess && repoConn.referencess[384][lang === 'en' ? 'en content' : 'ar content'] 
                    : repoConn.referencess && repoConn.referencess[385][lang === 'en' ? 'en content' : 'ar content']}
                    {/* <br /> */}
                    {repoConn.answers["245"] == 1 ? repoConn.referencess && repoConn.referencess[386][lang === 'en' ? 'en content' : 'ar content'] 
                    : repoConn.referencess && repoConn.referencess[387][lang === 'en' ? 'en content' : 'ar content']}
                    {/* <br /> */}
                    {repoConn.answers["246"] == 1 ? repoConn.referencess && repoConn.referencess[388][lang === 'en' ? 'en content' : 'ar content'] 
                    : repoConn.referencess && repoConn.referencess[389][lang === 'en' ? 'en content' : 'ar content']}
                    {/* <br /> */}
                    {repoConn.answers["247"] == 1 ? repoConn.referencess && repoConn.referencess[390][lang === 'en' ? 'en content' : 'ar content'] 
                    : repoConn.referencess && repoConn.referencess[391][lang === 'en' ? 'en content' : 'ar content']}
                    {/* <br /> */}
                    {repoConn.answers["248"] == 1 ? repoConn.referencess && repoConn.referencess[392][lang === 'en' ? 'en content' : 'ar content'] 
                    : repoConn.referencess && repoConn.referencess[393][lang === 'en' ? 'en content' : 'ar content']}
                    {/* <br /> */}
                    {repoConn.answers["249"] == 1 ? repoConn.referencess && repoConn.referencess[394][lang === 'en' ? 'en content' : 'ar content'] 
                    : repoConn.referencess && repoConn.referencess[395][lang === 'en' ? 'en content' : 'ar content']}
                    {/* <br /> */}
                </div>
            </div>
            <div className='ManyBox-4Report-3 pdf-container-6'>
                <img className='ImgReport' src={lang === 'en' ? en25 : twentyfive} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                <div className='IntroBatrn'>
                    <div className="StrepCountaner">
                    <div>{repoConn.referencess && repoConn.referencess[396][lang === 'en' ? 'en content' : 'ar content']}</div>
                    </div>
                </div>
                </div>
                <div className="BoxsContaner">
                <div className="SmallBox">
                        <div>
                            <span> {repoConn.referencess && repoConn.referencess[397][lang === 'en' ? 'en content' : 'ar content']}</span>
                        </div>
                        <div>
                            <span>{repoConn.referencess && repoConn.referencess[407][lang === 'en' ? 'en content' : 'ar content']}</span>
                        </div>
                    </div>
                    <div className="BigBox">
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["241"] == 0 ? "ImportBox ManyBoxGreen" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["241"] == 0? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>  
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[408][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[398][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className={repoConn.answers["241"] == 1 ? "ImportBox ManyBoxGreen" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["241"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                            </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["242"] == 0 ? "ImportBox ManyBoxGreen" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["242"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                            
                        <span className="ImportBox">{repoConn.referencess && repoConn.referencess[409][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[399][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className={repoConn.answers["242"] == 1 ? "ImportBox ManyBoxGreen" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["242"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                        </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["243"] == 0 ? "ImportBox ManyBoxGreen" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["243"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                              
                        <span className="ImportBox">{repoConn.referencess && repoConn.referencess[410][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[400][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className={repoConn.answers["243"] == 1 ? "ImportBox ManyBoxGreen" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["243"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                        </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["244"] == 0 ? "ImportBox ManyBoxGreen" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["244"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                              
                        <span className="ImportBox">{repoConn.referencess && repoConn.referencess[411][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[401][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <span className={repoConn.answers["244"] == 1 ? "ImportBox ManyBoxGreen" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["244"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                        </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["245"] == 0 ? "ImportBox ManyBoxGreen" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["245"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                              
                        <span className="ImportBox">{repoConn.referencess && repoConn.referencess[412][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[402][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <span className={repoConn.answers["245"] == 1 ? "ImportBox ManyBoxGreen" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["245"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                        </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["246"] == 0 ? "ImportBox ManyBoxGreen" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["246"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                              
                        <span className="ImportBox">{repoConn.referencess && repoConn.referencess[413][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[403][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className={repoConn.answers["246"] == 1 ? "ImportBox ManyBoxGreen" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["246"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                        </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["247"] == 0 ? "ImportBox ManyBoxGreen" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["247"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                              
                        <span className="ImportBox">{repoConn.referencess && repoConn.referencess[414][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[404][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className={repoConn.answers["247"] == 1 ? "ImportBox ManyBoxGreen" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["247"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                        </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["248"] == 0 ? "ImportBox ManyBoxGreen" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["248"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                              
                        <span className="ImportBox">{repoConn.referencess && repoConn.referencess[415][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[405][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className={repoConn.answers["248"] == 1 ? "ImportBox ManyBoxGreen" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["248"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                        </div>
                        <div className="DetilsBoxs">
                        <span className={repoConn.answers["249"] == 0 ? "ImportBox ManyBoxGreen" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["249"] == 0 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>                              
                        <span className="ImportBox">{repoConn.referencess && repoConn.referencess[416][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[406][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <span className={repoConn.answers["249"] == 1 ? "ImportBox ManyBoxGreen" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["249"] == 1 ? "" : "DisplayNON"}> {lang === 'en' ? 'Important ' : 'أهم'} </span></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ScoCover-3 pdf-container-6'>
                <img className='ImgReport' src={twentysix} alt="ReportImge" />
                <div className='AsidLogo'>
                    <Logowhit />
                </div>
                <div className="Text">{repoConn.referencess && repoConn.referencess[417][lang === 'en' ? 'en content' : 'ar content']}</div>
            </div>
            <div className='BigOneGraph-3 pdf-container-6'>
                <img className='ImgReport' src={twentyseven} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                <div className='IntroBatrn'>
                    <div className="StrepCountaner">
                    <div>{repoConn.referencess && repoConn.referencess[418][lang === 'en' ? 'en content' : 'ar content']}</div>
                    </div>
                </div>
                </div>
                <div className="Graph-1 ">
                    <div className="TopBox">
                        <div></div>
                        <div>
                            <span style={{fontSize : IsEnglish ? '22px' : '26px'}}>{repoConn.referencess && repoConn.referencess[420][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span style={{fontSize : IsEnglish ? '22px' : '26px'}}>{repoConn.referencess && repoConn.referencess[421][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span style={{fontSize : IsEnglish ? '22px' : '26px'}}>{repoConn.referencess && repoConn.referencess[422][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span style={{fontSize : IsEnglish ? '22px' : '26px'}}>{repoConn.referencess && repoConn.referencess[423][lang === 'en' ? 'en content' : 'ar content']}</span>
                        </div>
                        <div>
                            <span>{result17.upchart}%</span>
                            <span>{result18.upchart}%</span>
                            <span>{result19.upchart}%</span>
                            <span>{result20.upchart}%</span>
                        </div>
                    </div>
                    <div className="Graph-Top-1">
                        <div className="BigCanvas" style={SR164}></div>
                        <div className="BigCanvas" style={SR163}></div>
                        <div className="BigCanvas" style={SR162}></div>
                        <div className="BigCanvas" style={SR161}></div>
                    </div>
                    <div className="Graph-Bottom-1">
                        <div className="BigCanvas" style={SR172}></div>
                        <div className="BigCanvas" style={SR171}></div>
                        <div className="BigCanvas" style={SR170}></div>
                        <div className="BigCanvas" style={SR169}></div>
                    </div>
                    <div className="BottomBox">
                        <div>
                            <span>{result17.downchart}%</span>
                            <span>{result18.downchart}%</span>
                            <span>{result19.downchart}%</span>
                            <span>{result20.downchart}%</span>
                        </div>
                        <div>
                            <span style={{fontSize : IsEnglish ? '22px' : '26px'}}>{repoConn.referencess && repoConn.referencess[424][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span style={{fontSize : IsEnglish ? '22px' : '26px'}}>{repoConn.referencess && repoConn.referencess[425][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span style={{fontSize : IsEnglish ? '22px' : '26px'}}>{repoConn.referencess && repoConn.referencess[426][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span style={{fontSize : IsEnglish ? '22px' : '26px'}}>{repoConn.referencess && repoConn.referencess[427][lang === 'en' ? 'en content' : 'ar content']}</span>
                        </div>
                    </div>
                </div>
                    <div className="BottomTitleGraphCon"><div className="BottomTitleGraph" style={{width : IsEnglish ? '300px' : '260px'}}>{repoConn.referencess && repoConn.referencess[419][lang === 'en' ? 'en content' : 'ar content']}</div></div>
            </div>
            {/* 429 */}
            <div className='Btn-1 pdf-container-7'>
                <img className='ImgReport' src={twentyeight} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                <div className='IntroBatrn'>
                    <div className="StrepCountaner">
                    <div>{repoConn.referencess && repoConn.referencess[428][lang === 'en' ? 'en content' : 'ar content']}</div>
                    </div>
                </div>
                </div>
                    <div className="BtnsContaner">
                        <div className="BtnCon postionLeft">
                            <div className="ButtonsText">
                                <div>{lang === 'en' ? 'Active ' : 'فعال'} </div>
                                <div className={repoConn.answers["311"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[429][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["311"] == 0  ? ButtonBlueLeft : ButtonBlueRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight">
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active ' : 'فعال'}</div>
                                <div className={repoConn.answers["312"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[430][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["312"] == 0 ? ButtonBlueLeft : ButtonBlueRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionLeft">
                            <div className="ButtonsText">
                                <div>{lang === 'en' ? 'Active ' : 'فعال'}</div>
                                <div className={repoConn.answers["313"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[431][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["313"] == 0 ? ButtonBlueLeft : ButtonBlueRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight">
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active ' : 'فعال'}</div>
                                <div className={repoConn.answers["314"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[432][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["314"] == 0 ? ButtonBlueLeft : ButtonBlueRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionLeft">
                            <div className="ButtonsText">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["315"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[433][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["315"] == 0 ? ButtonBlueLeft : ButtonBlueRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight">
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["316"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[434][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["316"] == 0 ? ButtonBlueLeft : ButtonBlueRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionLeft">
                            <div className="ButtonsText">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["317"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[435][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["317"] == 0 ? ButtonBlueLeft : ButtonBlueRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight">
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["318"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[436][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["318"] == 0 ? ButtonBlueLeft : ButtonBlueRight } alt="ss" />
                        </div>
                        <div className="BtnCon" style={{position: "relative", left: "255px"}}>
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["319"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[437][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["319"] == 0 ? ButtonBlueLeft : ButtonBlueRight } alt="ss" />
                        </div>
                    </div>
            </div>
            <div className='Btn-2 pdf-container-7'> 
                <img className='ImgReport' src={twentynine} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                <div className='IntroBatrn'>
                    <div className="StrepCountaner">
                    <div>{repoConn.referencess && repoConn.referencess[438][lang === 'en' ? 'en content' : 'ar content']}</div>
                    </div>
                </div>
                </div>
                    <div className="BtnsContaner">
                        <div className="BtnCon postionLeft">
                            <div className="ButtonsText">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["321"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[439][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["321"] == 0 ? ButtonBlackLeft : ButtonBlackRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight">
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["322"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[440][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["322"] == 0 ? ButtonBlackLeft : ButtonBlackRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionLeft">
                            <div className="ButtonsText">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["323"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[441][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["323"] == 0 ? ButtonBlackLeft : ButtonBlackRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight">
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["324"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[442][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["324"] == 0 ? ButtonBlackLeft : ButtonBlackRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionLeft">
                            <div className="ButtonsText">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["325"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[443][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["325"] == 0 ? ButtonBlackLeft : ButtonBlackRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight">
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["326"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[444][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["326"] == 0 ? ButtonBlackLeft : ButtonBlackRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionLeft">
                            <div className="ButtonsText">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["327"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[445][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["327"] == 0 ? ButtonBlackLeft : ButtonBlackRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight">
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["328"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[446][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["328"] == 0 ? ButtonBlackLeft : ButtonBlackRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight" style={{position: "relative", left: "255px"}}>
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["329"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[447][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["329"] == 0 ? ButtonBlackLeft : ButtonBlackRight } alt="ss" />
                        </div>
                </div>
            </div>
            <div className='Btn-3 pdf-container-7'>
                <img className='ImgReport' src={thirty} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                <div className='IntroBatrn'>
                    <div className="StrepCountaner">
                    <div>{repoConn.referencess && repoConn.referencess[448][lang === 'en' ? 'en content' : 'ar content']}</div>
                    </div>
                </div>
                </div>
                    <div className="BtnsContaner">
                        <div className="BtnCon postionLeft">
                            <div className="ButtonsText">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["331"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[449][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["331"] == 0 ? ButtonRedLeft : ButtonRedRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight">
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["332"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[450][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["332"] == 0 ? ButtonRedLeft : ButtonRedRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionLeft">
                            <div className="ButtonsText">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["333"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[451][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["333"] == 0 ? ButtonRedLeft : ButtonRedRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight">
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["334"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[452][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["334"] == 0 ? ButtonRedLeft : ButtonRedRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionLeft">
                            <div className="ButtonsText">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["335"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[453][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["335"] == 0 ? ButtonRedLeft : ButtonRedRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight">
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["336"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[454][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["336"] == 0 ? ButtonRedLeft : ButtonRedRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionLeft">
                            <div className="ButtonsText">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["337"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[455][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["337"] == 0 ? ButtonRedLeft : ButtonRedRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight">
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["338"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[456][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["338"] == 0 ? ButtonRedLeft : ButtonRedRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight" style={{position: "relative", left: "255px"}}>
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["339"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[457][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["339"] == 0 ? ButtonRedLeft : ButtonRedRight } alt="ss" />
                        </div>
                    </div>
            </div>
            <div className='Btn-4 pdf-container-7'>
                <img className='ImgReport' src={thirtyone} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                <div className='IntroBatrn'>
                    <div className="StrepCountaner">
                    <div>{repoConn.referencess && repoConn.referencess[458][lang === 'en' ? 'en content' : 'ar content']}</div>
                    </div>
                </div>
                </div>
                    <div className="BtnsContaner">
                        <div className="BtnCon postionLeft">
                            <div className="ButtonsText">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["341"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[459][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["341"] == 0 ? ButtonGreenLeft : ButtonGreenRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight">
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["342"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[460][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["342"] == 0 ? ButtonGreenLeft : ButtonGreenRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionLeft">
                            <div className="ButtonsText">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["343"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[461][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["343"] == 0 ? ButtonGreenLeft : ButtonGreenRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight">
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["344"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[462][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["344"] == 0 ? ButtonGreenLeft : ButtonGreenRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionLeft">
                            <div className="ButtonsText">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["345"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[463][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["345"] == 0 ? ButtonGreenLeft : ButtonGreenRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight">
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["346"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[464][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["346"] == 0 ? ButtonGreenLeft : ButtonGreenRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionLeft">
                            <div className="ButtonsText">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["347"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[465][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["347"] == 0 ? ButtonGreenLeft : ButtonGreenRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight">
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["348"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[466][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["348"] == 0 ? ButtonGreenLeft : ButtonGreenRight } alt="ss" />
                        </div>
                        <div className="BtnCon postionRight" style={{position: "relative", left: "255px"}}>
                            <div className="ButtonsText ">
                                <div>{lang === 'en' ? 'Active' : 'فعال'}</div>
                                <div className={repoConn.answers["349"] == 0 ? "left" : "Right" }>{repoConn.referencess && repoConn.referencess[467][lang === 'en' ? 'en content' : 'ar content']}</div>
                                <div>{lang === 'en' ? 'In-Active' : 'غير فعال'}</div>
                            </div>
                            <img className="ButtonImage"  src={repoConn.answers["349"] == 0 ? ButtonGreenLeft : ButtonGreenRight } alt="ss" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Report3