import { Chip, Stack, useMediaQuery } from "@mui/material";

function ActionsChips({ downloadAllReport, downloadSelectedReport }) {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <>
      {isMobile ? (
        <Stack
          mt={isMobile ? 1 : 4}
          mb={4}
          direction="row"
          justifyContent="left"
          alignItems="center"
        >
          <Chip
            sx={{
              margin: isMobile ? "10px" : "",
              width: isMobile ? "calc(50% - 8px)" : "",
              padding: isMobile ? "10px" : "30px 70px",
              fontSize: isMobile ? "10px" : "18px",
              fontWeight: isMobile ? "500" : "700",
              cursor: "pointer",
              m: isMobile ? 1 : 2,
              color: "white",
              backgroundColor: "#3f8d90",
            }}
            variant="filled"
            label="Download All Reports"
            onClick={downloadAllReport} // Add onClick handler directly to Chip
          />
          <Chip
            sx={{
              margin: isMobile ? "10px" : "",
              width: isMobile ? "calc(50% - 8px)" : "",
              padding: isMobile ? "10px" : "30px 70px",
              fontSize: isMobile ? "10px" : "18px",
              fontWeight: isMobile ? "500" : "700",
              cursor: "pointer",
              m: isMobile ? 1 : 2,
              color: "white",
              backgroundColor: "#3f8d90",
            }}
            variant="filled"
            label="Download Selected Reports"
            onClick={downloadSelectedReport} // Add onClick handler directly to Chip
          />
        </Stack>
      ) : (
        <Stack
          mt={4}
          mb={4}
          direction={isMobile ? "column" : "row"}
          justifyContent="left"
          alignItems="center"
        >
          <Chip
            sx={{
              m: 2,
              color: "white",
              backgroundColor: "#3f8d90",
              padding: "20px",
              cursor: "pointer", // Move cursor style here
            }}
            variant="filled"
            label="Download All Reports"
            onClick={downloadAllReport} // Add onClick handler directly to Chip
          />
          <Chip
            sx={{
              m: 2,
              color: "white",
              backgroundColor: "#3f8d90",
              padding: "20px",
              cursor: "pointer", // Move cursor style here
            }}
            variant="filled"
            label="Download Selected Reports"
            onClick={downloadSelectedReport} // Add onClick handler directly to Chip
          />
        </Stack>
      )}
    </>
  );
}

export default ActionsChips;