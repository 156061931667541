import { 
  Box, Button, Dialog, DialogActions, DialogContent, 
  IconButton, MenuItem, Select, Stack, Typography 
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import axios from "axios";
import LoadingSpinner from "../../../../../FemilerCom/loading/spinner";

export default function Regenerate({
  openDownload,
  isMobile,
  handleCloseDownload,
  ids,
  setSnackbarOpen,
  setSnackbarText,
  // enroid,
  // examid,
}) {
  const [selectedValue, setSelectedValue] = useState("ar");
  const [loader, setLoader] = useState(false);

  const downloadReports = async () => {
    setSnackbarText("Reports Downloading Will Start As Soon As Possible.");
    setSnackbarOpen(true);
    setLoader(true);
    try {
      const response = await axios.post(
        "https://vmi2353814.contaboserver.net/api/reports-download",
        {
          enrollment_ids: ids,
          lang: selectedValue,
        }
      );
      const zipFileUrl = response.data.payload.reports_zip_file;

      const link = document.createElement("a");
      link.href = zipFileUrl;
      link.setAttribute("download", `reports_${selectedValue}.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      setSnackbarText("Reports Downloading Started.");
      setSnackbarOpen(true);
      handleCloseDownload();
    } catch (error) {
      setSnackbarText(error?.response?.data?.message || "Failed to download the report.");
      setSnackbarOpen(true);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      {loader && <LoadingSpinner />}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={openDownload}
        onClose={handleCloseDownload}
        sx={{
          "& .MuiDialog-paper": { 
            width: "100%", 
            maxWidth: isMobile ? "95%" : "600px",
            borderRadius: 2
          }
        }}
      >
        <Box sx={{ bgcolor: "#929292", p: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{ color: "white", fontSize: { xs: "14px", sm: "16px" } }}>
              It may take about 1-2 minutes to generate the report.
            </Typography>
            <IconButton onClick={handleCloseDownload} sx={{ color: "white" }}>
              <CloseIcon />
            </IconButton>
          </Stack>

          <DialogContent>
            <Typography sx={{ textDecoration: "underline", color: "white", mb: 2 }}>
              Language:
            </Typography>
            <Select
              sx={{
                width: "100%",
                height: "40px",
                backgroundColor: "white",
                borderRadius: "4px",
              }}
              value={selectedValue}
              onChange={(e) => setSelectedValue(e.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>Select Language</MenuItem>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="ar">Arabic</MenuItem>
              <MenuItem value="all">All</MenuItem>
            </Select>

            <Stack alignItems="center" mt={3}>
              <Button
                variant="contained"
                sx={{
                  fontSize: { xs: "12px", sm: "14px" },
                  px: 3,
                  py: 1,
                  background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                  color: "white",
                  "&:hover": { background: "linear-gradient(45deg, #1e4b4d, #42999e)" }
                }}
                onClick={downloadReports}
                disabled={loader}
              >
                Download Report in {selectedValue === "ar" ? "Arabic" : "English"}
              </Button>
            </Stack>
          </DialogContent>
          <DialogActions>
                            {/* <Button onClick={() => window.open(`/reportPage?enro_id=${enroid}&exam_id=${examid}&lang=${selectedValue}`)}>open</Button> */}
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
