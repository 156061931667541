import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  Card,
  CardContent,
  List,
  ListItem,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ApiContext from "../../../Context/context";
import ConfirmPay from "./Dialog/ConfirmPay";
import LoadingSpinner from "../../../../../../FemilerCom/loading/spinner";


export default function Flex() {
  const { packages, setShouldFetchPackages, loading, handelPermission } = useContext(ApiContext);
  const isHasPermission = handelPermission('package-payment')

  const isMobile = useMediaQuery("(max-width:600px)");

  const [opeConfirmPayDialog, setOpeConfirmPayDialog] = useState(false);
  const [packageId, setPackageId] = useState(null);

  useEffect(() => {
    if (setShouldFetchPackages) {
      setShouldFetchPackages(true);
    }
  }, [setShouldFetchPackages]);

  const handleBuyNow = ( examId) => {
    if (isHasPermission) {
      setOpeConfirmPayDialog(true);
      setPackageId(examId);
    }
  };

  return (
    <>
      {loading && <LoadingSpinner />}

      <Stack justifyContent='space-around' gap={2} p={3} flexWrap='wrap' direction='row' spacing={3} m={4}>
        <Typography
          variant="h4"
          sx={{ textAlign: "center", margin: "20px 0", fontWeight: "bold", color: "#006d6d !important" }}
        >
          Packages
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "20px",
          }}
        >
{packages?.length > 0 ? 
  packages
    .filter((ele) => ele.name !== 'Twindix Executives' && ele.name !== 'Job Bar') // Filter out unwanted packages
    .sort((a, b) => b.type - a.type) // Sort by type (1 first, 0 last)
    .map((pack, index, filteredPackages) => {
      // Check if the current package is the first one with type === 0
      const isFirstTypeZero = pack.type === 0 && (index === 0 || filteredPackages[index - 1].type === 1);

      return (
        <React.Fragment key={pack.id}>
          {/* Render the "Addons" heading before the first type === 0 package */}
          {isFirstTypeZero && (
            <Typography
              variant="h4"
              sx={{ textAlign: "center", margin: "20px 0", fontWeight: "bold", color: "#006d6d !important", width: "100%" }}
            >
              Addons
            </Typography>
          )}

          {/* Render the package card */}
          <Card
            sx={{
              width: "245px",
              height: '286px',
              position: "relative",
              borderRadius: "15%",
              background: "linear-gradient(-60deg, #00c3c3 ,#006d6d )",
              overflow: "hidden",
              padding: "10px",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: "#fff",
                borderRadius: "15%",
                margin: "4px",
                zIndex: 1,
              },
            }}
          >
            <Box
              sx={{
                margin: "auto",
                width: "95%",
                height: '65px',
                borderRadius: "40px",
                background: "linear-gradient(45deg, #006d6d, #00c3c3)",
                zIndex: 1,
                textAlign: "center",
                position: "relative",
              }}
            >
              <Typography fontSize={13} sx={{ padding: '6px 2px', height: '100%', alignContent: 'center', color: "white", fontWeight: "bold" }}>
                {pack.name}
              </Typography>
            </Box>

            <CardContent sx={{ padding: '0 16px', paddingBottom: '0 !important', zIndex: 2, position: "relative" }}>
              <List>
                <ListItem sx={{ padding: "0" }}>
                  <Typography sx={{ margin: "auto", fontSize: "18px", fontWeight: "bold", color: "#2c6264" }}>
                    ${pack.price}/ Year
                  </Typography>
                </ListItem>
                <ListItem sx={{ padding: "4px 0" }}>
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: "#2c6264" }}>
                    • Annual Access to {pack.exam_id === 1 ? "Twindix Executive" : 'job bar'} Assessment
                  </Typography>
                </ListItem>
                <ListItem sx={{ padding: "4px 0" }}>
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: "#2c6264" }}>
                    • Total: {pack.exams_count} Assessments
                  </Typography>
                </ListItem>
                <ListItem sx={{ padding: "0" }}>
                  <Typography sx={{ margin: "auto", fontSize: "18px", fontWeight: "bold", color: "#2c6264" }}>
                    ${(pack.price / pack.exams_count).toFixed(2)}/ Assessment
                  </Typography>
                </ListItem>
                <Box
                  sx={{
                    margin: 'auto',
                    width: '108%',
                    height: '45px',
                    borderRadius: '40px',
                    background: ' #00c3c3',
                    zIndex: '1',
                    textAlign: 'center',
                    position: 'relative',
                    bottom: '-11px',
                    left: '-5px',
                  }}
                >
                  <Button
                    sx={{
                      padding: '6px 2px',
                      height: '100%',
                      alignContent: 'center',
                      color: "white",
                      fontWeight: "bold"
                    }}
                    onClick={() => {
                      if (isHasPermission) {
                        handleBuyNow(pack.id);
                      }
                    }}
                  >
                    Subscribe Now
                  </Button>
                </Box>
              </List>
            </CardContent>
          </Card>
        </React.Fragment>
      );
    })
  : <Typography>There Is No Packages Yet</Typography>
}
        </Box>

        <ConfirmPay
          isMobile={isMobile}
          opeConfirmPayDialog={opeConfirmPayDialog}
          setOpeConfirmPayDialog={setOpeConfirmPayDialog}
          packageId={packageId}
          isPackage={true}
        />
      </Stack>
    </>
  );
}