import { 
  Box, Button, Dialog, DialogActions, DialogContent, Snackbar, Typography, Select, MenuItem 
} from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import ApiContext from "../../../../Context/context";

export default function InviteJopBar({
  openBench,
  handleClose,
  selectedEmployees,
  userId,
  company_id,
  position_id,
  setReFetchSpecificCompany
}) {

  const {
    getBenchMarkData,
    setShouldFetchBenchmark,
    setFetchStatisticsTrigger
  } = useContext(ApiContext);
  const [benchmark, setBenchMark] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);

    useEffect(() => {
      if(setShouldFetchBenchmark){
        setShouldFetchBenchmark(true)
      }
    }, [])
    
  const handleCloseSnackbar = (_, reason) => {
    if (reason === "clickaway") return;
    setOpenSnackbar(false);
  };

  const inviteUser = async (e) => {
    if (!benchmark) {
      alert("Please select a benchmark.");
      return;
    }
    setLoading(true)
    try {
      if (selectedEmployees.length > 1) {
        const requests = selectedEmployees.map((employee) =>
          axios.post("https://api.twindix.com/invite", {
            user_id: employee,
            benchmark_code: benchmark,
            company_id: company_id,
            position_id: position_id,
          }, {
            headers: { Authorization: "Bearer " + localStorage.token },
          })
        );
        await Promise.all(requests);
      } else {
        e.preventDefault();
        await axios.post("https://api.twindix.com/invite", {
          user_id: userId,
          benchmark_code: benchmark,
          company_id: company_id,
          position_id: String(position_id),
        }, {
          headers: { Authorization: "Bearer " + localStorage.token },
        });
      }
  
      setFetchStatisticsTrigger((prev) => prev + 1);
      setReFetchSpecificCompany((prev) => prev + 1);

    setLoading(false)
      handleClose();
      setOpenSnackbar(true);
    } catch (error) {
      handleClose();
    setLoading(false)
      console.error("Error sending invite:", error);
    }
  };


  return (
    <>
      <Dialog maxWidth="md" open={openBench} onClose={handleClose}>
        <Box sx={{ bgcolor: "#929292", width: "450px" }}>
          <DialogContent
            sx={{
              maxHeight: "500px",
              overflowY: "auto",
              "&::-webkit-scrollbar": { width: "6px" },
              "&::-webkit-scrollbar-thumb": { borderRadius: "10px", background: "linear-gradient(45deg, #2c6264, #5ac4cb)" },
              "&::-webkit-scrollbar-track": { background: "#929292", borderRadius: "10px" },
            }}
          >
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "22px", textDecoration: "underline", color: "white", mb: "20px" }}>
                  Shelve Benchmark Code:
                </Typography>
                <Select
                  value={benchmark}
                  onChange={(e) => setBenchMark(e.target.value)}
                  displayEmpty
                  sx={{ width: "100%", height: "40px", backgroundColor: "white", borderRadius: "4px", fontSize: "14px" }}
                >
                  <MenuItem value="" disabled>Select Benchmark Code</MenuItem>
                  {getBenchMarkData?.map((item) => (
                    <MenuItem key={item.code} value={item.code}>{item.title}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
            disabled={loading}
              onClick={inviteUser}
              sx={{ background: "linear-gradient(45deg, #2c6264, #5ac4cb)", borderRadius: "10px", margin: "auto" }}
              variant="contained"
            >
              {loading ?  'Sending' : 'Send Assessment' }
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Assessment sent to selected participants successfully."
      />
    </>
  );
}
