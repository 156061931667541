import { useContext, useEffect, useState } from "react";
import "../../../../../Question/Question.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Box, Menu, MenuItem, Typography, Button as MuiButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faCircleExclamation, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { appContext } from "../../../../../../App";
function BenchMarkQustions() {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [questions, setQuestions] = useState(null);
    const [choosedBg, setChoosedBg] = useState(null);
    const [finalQuestion, setFinalQuestion] = useState(false);
    const [choosedAnswer, setChoosedAnswer] = useState(null);
    const [choosedAnswers, setChoosedAnswers] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [enrollments, setEnrollments] = useState(null);
    const [matchingEnrollmentIds, setMatchingEnrollmentIds] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [language, setLanguage] = useState('ar'); // Language state to handle language toggle

    const navigate = useNavigate();
    const { id } = useParams();

const [searchParams] = useSearchParams();
const benchmarkCode = searchParams.get("benchMarkCode");

    const progressPercentage = questions ? (currentQuestion / questions.length) * 100 : 0;

    useEffect(() => {
        setLoading(true);
        axios.get(`https://api.twindix.com/benchmark-questions/1`, {
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        }).then(res => {
            if (res) {
                // console.log(res.data.payload.benchmark_questions);
                setQuestions(res.data.payload.benchmark_questions.benchmark_questions);
            } else {
                throw new Error('No Data Was Found');
            }
        }).catch(err => {
            console.error(err);
            setError("Failed to load questions. Please try again.");
        }).finally(() => setLoading(false));
    }, [id]);


    useEffect(() => {
        // Fetch enrollments if not already loaded
        if (!enrollments) {
            axios
            .get("https://api.twindix.com/sent-enrollments", {
                headers: { Authorization: `Bearer ${localStorage.token}` },
              })
              .then((res) => {
                  const fetchedEnrollments = res.data.payload;
              setEnrollments(fetchedEnrollments);
    
              // Compare with benchmarkCode from localStorage
    
              if (benchmarkCode) {
                const matchedIds = fetchedEnrollments
                  .filter(
                    (enrollment) => enrollment.benchmark_code === benchmarkCode
                  )
                  .map((enrollment) => enrollment.id);
                setMatchingEnrollmentIds(matchedIds);
              }
            })
            .catch((error) => {
              console.error("Error fetching enrollments:", error);
            });
        }
      }, [enrollments]);
      
    // Function to toggle language between Arabic and English
    const toggleLanguage = () => {
        setLanguage(prevLang => (prevLang === 'ar' ? 'en' : 'ar'));
    };

    function incrementQuestion(e) {
        e.preventDefault();
        if (choosedAnswers) {
            setChoosedAnswer(null);
            setChoosedBg(null);
        }
    
        const nextQuestion = currentQuestion + 1;
    
        if (nextQuestion < questions.length) {
            setCurrentQuestion(nextQuestion);
        }
            if (nextQuestion === questions.length ) {
            setFinalQuestion(true);
        }
    }

    function chooseQuestion(value, code, qnum) {
        setChoosedBg(qnum);
        setChoosedAnswer(true);
        setChoosedAnswers(prevAnswers => ({
            ...prevAnswers,
            [currentQuestion]: value === questions[currentQuestion].leftPhrase_ar ? 1 : 0  // Left is true (1), right is false (0)
        }));
        console.log([currentQuestion],  value === questions[currentQuestion].leftPhrase_ar ? 1 : 0 )
    }

    const isLoggedIn = useContext(appContext);

    const reGenerate = (enrollmentId) => {
        axios
          .post(
            `https://vmi2353814.contaboserver.net/api/report-generate`,
            {
                url: `https://assessment.twindix.com/reportPage?lang=ar&enro_id=${enrollmentId}&exam_id=65&name=${isLoggedIn?.userName}`,
                isLogged: true,
                regenerate: true,
                token: isLoggedIn.localStorage.token,
                userType: "2",
                enrollment_id: enrollmentId,
                userId: isLoggedIn?.userData?.id,
                userName: isLoggedIn?.userName,
                AssessmentName: 'JopBAr',
              },              
            {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            }
          )
          .then((res) => {
            console.log(`Re-generated report for enrollment_id: ${enrollmentId}`);
            navigate("/Business");
          })
          .catch((err) => {
            console.error(err);
            setError("Failed to submit answers. Please try again.");
          });
    };
    const handleReGenerateAll = () => {
        matchingEnrollmentIds.forEach((id) => {
          reGenerate(id);
        });
    };

    const submitQuestions = (e) => {
        e.preventDefault();
        axios.put(`https://api.twindix.com/benchmark-questions/1/benchmarks/${id}`, { benchmark: choosedAnswers }, {
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        }).then(res => {
            console.log(res);
            handleReGenerateAll()
            navigate("/Business");
        }).catch(err => {
            console.error(err);
            setError("Failed to submit answers. Please try again.");
        });
    };

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
        <>
            <Box className="language-toggle">
            <MuiButton
                sx={{
                background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
                borderRadius: '10px',
                color : 'white',
                margin : '10px'
                }}
                onClick={handleClick}
                >
                <ArrowDropDownIcon />
                    language
                </MuiButton>
                    <Menu      
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}>
                        <MenuItem onClick={() => {
                            handleClose()
                            toggleLanguage()
                        }}>
                            {language === 'ar' ? 
                            <Typography>English</Typography> 
                            : 
                            <Typography>العربية</Typography>}
                        </MenuItem>
                    </Menu>
            </Box>
            <div className="logo-holder">
                <img src='https://assessment.twindix.com/Logo-sm.png'  width="300" alt="Website Logo" />
            </div>
            <div className="progress" style={{ height: "14px", marginTop: "20px", width: "70%", margin: "auto" }}>
                <div className="progress-bar" role="progressbar" style={{ width: `${progressPercentage}%`, backgroundColor: "#224e92", transition: "width 0.5s ease" }} aria-valuenow={progressPercentage} aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <div className="QusetionContainer Question-Con">
            {language === 'ar' ?
                            <div className="SentenceContaner">
                                <div className="SentAndIcon">
                                    <div style={{ fontSize: "24px" }}><FontAwesomeIcon icon={faCircleExclamation} /></div>
                                    <div className="question-text text-center Sentence">
                                    يستغرق إجراء هذا التقييم حوالي ١٠ إلى ١٢ دقيقة. يُرجى تخصيص الوقت اللازم لقراءة الإجابات بعناية.</div>
                                </div>
                                <div className="SentAndIcon">
                                    <div style={{ fontSize: "24px" }}><FontAwesomeIcon icon={faCircleUser} /></div>
                                    <div className="question-text text-center Sentence">
                                    اختر الإجابات التي تعبّر بشكل حاسم عن أبرز المهارات الضرورية فقط للوظيفة المعنية بهذا الاختبار.
                                    </div>
                                </div>
                                <div className="SentAndIcon">
                                    <div style={{ fontSize: "24px" }}><FontAwesomeIcon icon={faArrowsRotate} /></div>
                                    <div className="question-text text-center Sentence">
                                    تجنب اختيار مهارات ليست ضرورية للوظيفة حتي لا تضع معيار وظيفي مرتفع غير مؤثر.</div>
                                </div>
                            </div>
            :
            <div className="SentenceContaner">
                                <div className="SentAndIcon">
                                    <div style={{ fontSize: "24px" }}><FontAwesomeIcon icon={faCircleExclamation} /></div>
                                    <div className="question-text text-center Sentence">
                                    This assessment takes approximately 10 to 12 minutes to complete. Please allocate sufficient time to carefully review the answers.                                    </div>
                                </div>
                                <div className="SentAndIcon">
                                    <div style={{ fontSize: "24px" }}><FontAwesomeIcon icon={faCircleUser} /></div>
                                    <div className="question-text text-center Sentence">
                                    Choose answers that precisely represent the key skills essential for the specific role being assessed.
                                    </div>
                                </div>
                                <div className="SentAndIcon">
                                    <div style={{ fontSize: "24px" }}><FontAwesomeIcon icon={faArrowsRotate} /></div>
                                    <div className="question-text text-center Sentence">
                                    Avoid selecting non-essential skills to prevent setting an unnecessarily & ineffective high job standard.
                                    </div>
                                </div>
                            </div>
            }
                <form onSubmit={submitQuestions} className="QustionForm" style={{ display: "flex" }}>
                    {loading ? (
                        <div className="text-center" style={{ width: "100%" }}>
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p>جاري تحميل الأسئلة...</p>
                        </div>
                    ) : error ? (
                        <div className="text-center" style={{ width: "100%", color: "red" }}>
                            <p>{error}</p>
                        </div>
                    ) : (
                        questions && (
                            <div className="d-flex flex-column text-center cardContaner" style={{ width: "100%" }}>
                                <h4>{language === 'ar' ? `السؤال رقم ${currentQuestion + 1}` : `Question ${currentQuestion + 1}`}</h4>
                                <div className="questionsCon d-flex justify-content-center text-center">
                                    <div 
                                        onClick={() => chooseQuestion(questions[currentQuestion].leftPhrase_ar, questions[currentQuestion].code, 1)} 
                                        className={choosedBg === 1 ? "Quscard option-card BackgrounQustionsCard text-white card-Click" : "Quscard option-card card-Click"} 
                                        style={{ marginRight: "12.5px" }}>
                                        {language === 'ar' ? questions[currentQuestion].leftPhrase_ar : questions[currentQuestion].leftPhrase_en}
                                    </div>
                                    <div 
                                        onClick={() => chooseQuestion(questions[currentQuestion].rightPhrase_ar, questions[currentQuestion].code, 2)} 
                                        className={choosedBg === 2 ? "Quscard option-card BackgrounQustionsCard text-white card-Click" : "Quscard option-card card-Click"} 
                                        style={{ marginLeft: "12.5px" }}>
                                        {language === 'ar' ? questions[currentQuestion].rightPhrase_ar : questions[currentQuestion].rightPhrase_en}
                                    </div>
                                </div>
                            </div>
                        )
                    )}

                    {!finalQuestion ? (
                        <button disabled={!choosedAnswer} onClick={incrementQuestion} className="Back-Btn btn btn-primary button">{language === 'ar' ? 'التالي' : 'Next'}</button>
                    ) : (
                        <button className="btn btn-primary button" type="submit">{language === 'ar' ? 'ارسال' : 'Submit'}</button>
                    )}
                </form>
            </div>
        </>
    );
}

export default BenchMarkQustions;
