import {Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2, IconButton, Menu, MenuItem, Stack, Typography, useMediaQuery } from "@mui/material"
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import { useContext, useEffect, useState } from "react";
import { appContext } from "../../../../../App";
import { useNavigate } from "react-router";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import ApiContext from "../../Context/context";
    function NavBar ({toggleDrawer, toggleCart}) {
    const isMobile = useMediaQuery("(max-width:600px)");

        const { 
          sentEnrollments,
          orders,
          packages,
          employees,
          setShouldFetchEmployees,
          setShouldFetchSentEnrollments,
          setShouldFetchOrders,
          setShouldFetchPackages,
        } = useContext(ApiContext);

        useEffect(() => {
          if(setShouldFetchSentEnrollments) {
            setShouldFetchSentEnrollments(true);
          }
          if(setShouldFetchOrders) {
            setShouldFetchOrders(true);
          }
          if(setShouldFetchPackages) {
            setShouldFetchPackages(true);
          }
          if(setShouldFetchEmployees) {
            setShouldFetchEmployees(true);
          }
        }, [setShouldFetchEmployees, 
          setShouldFetchSentEnrollments,
          setShouldFetchOrders,
          setShouldFetchPackages,]);
    
      
        const [anchorEl, setAnchorEl] = useState(null);
        const [open, setOpen] = useState(false);
        const isLoggedIn = useContext(appContext);      
          const [anchorNotfication, setanchorNotfication] = useState(null);
          const [last5Orders, setLast5Orders] = useState([]);
          const [orderCounts, setOrderCounts] = useState({});
          const [menuItems, setMenuItems] = useState([]);
          const [editMe, setEditMe] = useState({userName :'', phone : '', email :'', companyName : '',
            jopTilte : '', password : '', passwordConfirmation : ''})

          let navigate = useNavigate();
      
        const handleClickOpen = () => {
          handleClose()
            setOpen(true)
          };

        const handleClose = () => setAnchorEl(null);

        const handleOpen = (event) => {
            setAnchorEl(event.currentTarget); 
          };
        
        const updateUserData = async () => {
          try {
            const response = await axios.post(
              "https://api.twindix.com/auth/me",
              {
                phone : editMe.phone,
                email : editMe.email,
                userName : editMe.userName,
                companyName : editMe.companyName,
                jobTitle : editMe.jopTilte,
                password : editMe.password,
                password_confirmation: editMe.passwordConfirmation,
              },
              {headers: {
                Authorization: 'Bearer ' + localStorage.token
            }
        }
            );
            setOpen(false);
          } catch (error) {
            console.error("Error updating data:", error);
          }
        };
      
        function logout(e) {
            e.preventDefault();
            
            axios.delete("https://api.twindix.com/auth/logout", {headers:{
                Authorization:"Bearer " + localStorage.token
            }}).then(res => {
                console.log("logged out successfully");
                localStorage.removeItem("token");
                localStorage.removeItem("isAdmin");
                localStorage.removeItem("isLogged");
                localStorage.removeItem("userType");
                isLoggedIn.setLoggedin(null);
                isLoggedIn.setUserType(null);
                isLoggedIn.setIsAdmin(null);
                navigate('/')
                window.location.reload()
            }).catch(err => console.log(err))
        }

        const handleClickNotfication = (event) => {
          setanchorNotfication(event.currentTarget);
        };
        const handleCloseNotfication = () => {
          setanchorNotfication(null);
        };

      
        useEffect(() => {
          const fetchData = async () => {
            try {
              // Count order occurrences
              const counts = sentEnrollments.reduce((acc, ele) => {
                acc[ele.order_id] = (acc[ele.order_id] || 0) + 1;
                return acc;
              }, {});
              setOrderCounts(counts);
            } catch (err) {
            }
          };
      
          fetchData();
        }, []);
      
        useEffect(() => {
          if (orders.length && last5Orders.length && sentEnrollments.length) {
            const menuItemsList = [];
      
            // 1. Add filteredOrders
            const elevenMonthsAgo = new Date();
            elevenMonthsAgo.setMonth(elevenMonthsAgo.getMonth() - 11);
      
            const filteredOrders = last5Orders.filter((order) => {
              const orderDate = new Date(order.created_at);
              return (
                orderDate.getFullYear() === elevenMonthsAgo.getFullYear() &&
                orderDate.getMonth() === elevenMonthsAgo.getMonth()
              );
            });
      
            filteredOrders.forEach((order) => {
              const createdDate = new Date(order.created_at);
              createdDate.setMonth(createdDate.getMonth() + 11);
              const expiryDate = createdDate.toISOString().slice(0, 10);
      
              menuItemsList.push({
                key: `order-${order.id}`,
                content: `Your Order ${order.id}, Created At: ${order.created_at.slice(
                  0,
                  10
                )} and will expire after one month`,
                date: `${expiryDate}`,
              });
            });
      
            // 2. Add sentEnrollments
            sentEnrollments.forEach((ele) => {
              if (ele.finished === 1) {
                const employeeName = employees.find(
                  (emp) => emp.id === ele.user_id
                )?.name;
                if (employeeName) {
                  const changeDate = localStorage.getItem(
                    `enrollment-${ele.code}-date`
                  ) || new Date().toISOString().slice(0, 10);
      
                  if (!localStorage.getItem(`enrollment-${ele.code}-date`)) {
                    localStorage.setItem(`enrollment-${ele.code}-date`, changeDate);
                  }
      
                  menuItemsList.push({
                    key: `enrollment-${ele.code}`,
                    content: `${employeeName} has finished their exam with code ${ele.code}`,
                    date: changeDate,
                  });
                }
              }
            });
      
            // 3. Add orders with percentages
            Object.entries(orderCounts).forEach(([orderId, count]) => {
              const matchingPack = packages.find((pack) =>
                orders.some((ele) => ele.orderable_id === pack.id)
              );
      
              if (matchingPack) {
                const percentage = (count / matchingPack.exams_count) * 100;
                if (percentage >= 80 || percentage === 100) {
                  menuItemsList.push({
                    key: `percentage-order-${orderId}-${matchingPack.id}`,
                    content: `You have order with id ${orderId}, ${percentage}% finished`,
                    date: new Date().toISOString().slice(0, 10),
                  });
                }
              }
            });

      
            setMenuItems(menuItemsList);
          }
        }, [orders, last5Orders, sentEnrollments, orderCounts, packages, employees]);
      
        return (
            <Stack          
                sx={{
                    width: "100%",
                    background: "linear-gradient(45deg, rgb(0, 102, 102), rgb(0, 200, 202))",
                    padding: '12px',
                    position: 'fixed',
                    top: '0',
                    zIndex :'100',
                }}
                direction='row' spacing={4} alignItems='center' justifyContent='space-between'>
                <Stack direction='row' spacing={4}>
                    <IconButton sx={{color : 'white'}} onClick={toggleDrawer}>
                        <DensityMediumIcon />
                    </IconButton>
                        <Box>
                            <img src={isMobile ? 'https://assessment.twindix.com/Logo-sm-menu-mob.png' : 'https://assessment.twindix.com/Logo-sm-menu.png'} alt="dd" />
                        </Box>
                </Stack>
                {/* <Stack bgcolor='red'><Typography fontSize={25} fontWeight='bold' sx={{color: 'white'}}>نسخه تجريبيه</Typography></Stack> */}
                <Stack   direction='row' alignItems='center' justifyContent='space-around'>
                    <Stack direction='row' justifyContent='center' alignItems='center' variant="span">    
                        <Typography>
                        English (US) 
                        </Typography>
                        <IconButton >
                            <ArrowDropDownIcon/>
                        </IconButton>
                    </Stack>
                    <IconButton onClick={handleClickNotfication}>
                    <Badge badgeContent={menuItems.length} color="error">
                        <NotificationsIcon/>
                    </Badge>
                    </IconButton>
                    <Menu
                          anchorEl={anchorNotfication}
                          open={Boolean(anchorNotfication)}
                          onClose={handleCloseNotfication}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          >
                          {menuItems.length > 0 ? menuItems.map((item) => (
                                  <MenuItem key={item.key} m={1}>
                                    <Typography fontWeight='bold'>{item.content}</Typography><Typography sx={{color : 'gray'}}>{item.date}</Typography>
                                  </MenuItem>
                                )) : <Typography m={1}>There are no notifications at the moment.</Typography>}
                          </Menu>
                        <Box>
                        <IconButton onClick={handleOpen}>
                                <PersonIcon />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                              >
                                <MenuItem onClick={handleClose} sx={{textDecoration : 'underline', marginLeft: '-6px'}}>{isLoggedIn.userType == '1' ? "HR" :isLoggedIn.userType == '2' ?  'Indivdual' : 'Admin'} </MenuItem>
                                <MenuItem onClick={handleClose}>{isLoggedIn.userData?.name}</MenuItem>
                                <MenuItem onClick={handleClose}>{isLoggedIn.userData?.phone}</MenuItem>
                                <MenuItem onClick={handleClose}>{isLoggedIn.userData?.job_title}</MenuItem>
                                <MenuItem onClick={handleClickOpen}>My Account</MenuItem>
                                <MenuItem onClick={logout}>Logout</MenuItem>
                              </Menu>
                        </Box>

                </Stack>
                <Dialog maxWidth="lg" open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            bgcolor: "#929292",
            width: "500px",
          }}
        >
          <IconButton
            onClick={() => setOpen(false)}
            sx={{ position: "absolute", top: "8px", right: "8px", color: "white" }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle>
            <Typography
              sx={{
                width: "50%",
                margin: "auto",
                color: "white",
                textDecoration: "underline",
              }}
            >
              Confirm Your Contacting Data
            </Typography>
          </DialogTitle>
          <DialogContent>
          <Grid2 container direction="column" spacing={4}>
          <Grid2 item xs={12}>
                <Typography sx={{ textDecoration: "underline", color: "white", mb: "20px" }}>
                  ID:
                </Typography>
                <input
                disabled
                  value={isLoggedIn?.userData?.id}
                  style={{
                    padding: "10px",
                    fontSize: "10px",
                    border: "0",
                    borderRadius: "4px",
                    width: "100%",
                    height: "30px",
                  }}
                />
              </Grid2>
              <Grid2 item xs={12}>
              <Typography
                sx={{
                  textDecoration: "underline",
                  color: "white",
                  mb: "20px",
                }}
              >
                User Name:
              </Typography>
              <input
              name="userName"
                value={editMe.userName === "" ? isLoggedIn?.userName : editMe.userName}
                style={{
                  padding: "10px",
                  fontSize: "10px",
                  border: "0",
                  borderRadius: "4px",
                  width: "100%",
                  height: "30px",
                }}
                onChange={(e) =>
                    setEditMe((prev) => ({
                      ...prev,
                      userName: e.target.value
                    }))
                  }
              />
            </Grid2>
            <Grid2 item xs={12}>
              <Typography
                sx={{
                  textDecoration: "underline",
                  color: "white",
                  mb: "20px",
                }}
              >
                Phone:
              </Typography>
              <input
              name="phone"
                value={editMe.phone === '' ?  isLoggedIn?.userData?.phone : editMe.phone}
                style={{
                  padding: "10px",
                  fontSize: "10px",
                  border: "0",
                  borderRadius: "4px",
                  width: "100%",
                  height: "30px",
                }}
                onChange={(e) =>
                    setEditMe((prev) => ({
                      ...prev,
                      phone: e.target.value
                    }))
                  }
              />
            </Grid2>
            <Grid2 item xs={12}>
              <Typography
                sx={{
                  textDecoration: "underline",
                  color: "white",
                  mb: "20px",
                }}
              >
                Email:
              </Typography>
              <input
              name="email"
                value={editMe.email === "" ?  isLoggedIn?.userEmail : editMe.email}
                style={{
                  padding: "10px",
                  fontSize: "10px",
                  border: "0",
                  borderRadius: "4px",
                  width: "100%",
                  height: "30px",
                }}
                onChange={(e) =>
                    setEditMe((prev) => ({
                      ...prev,
                      email: e.target.value
                    }))
                  }
              />
            </Grid2>
            <Grid2 item xs={12}>
              <Typography
                sx={{
                  textDecoration: "underline",
                  color: "white",
                  mb: "20px",
                }}
              >
                Company Name:
              </Typography>
              <input
              name="companyName"
                value={editMe.companyName === "" ?  isLoggedIn?.company_name : editMe.companyName}
                style={{
                  padding: "10px",
                  fontSize: "10px",
                  border: "0",
                  borderRadius: "4px",
                  width: "100%",
                  height: "30px",
                }}
                onChange={(e) =>
                    setEditMe((prev) => ({
                      ...prev,
                      companyName: e.target.value
                    }))
                  }
              />
            </Grid2>
            <Grid2 item xs={12}>
              <Typography
                sx={{
                  textDecoration: "underline",
                  color: "white",
                  mb: "20px",
                }}
              >
                Job Title:
              </Typography>
              <input
              name="jopTilte"
                value={editMe.jopTilte === "" ?  isLoggedIn?.job_title : editMe.jopTilte}
                style={{
                  padding: "10px",
                  fontSize: "10px",
                  border: "0",
                  borderRadius: "4px",
                  width: "100%",
                  height: "30px",
                }}
                onChange={(e) =>
                    setEditMe((prev) => ({
                      ...prev,
                      jopTilte: e.target.value
                    }))
                  }
              />
            </Grid2>
            <Grid2 item xs={12}>
              <Typography
                sx={{
                  textDecoration: "underline",
                  color: "white",
                  mb: "20px",
                }}
              >
                Password:
              </Typography>
              <input
              name="password"
                value={editMe.password === "" ?  isLoggedIn?.job_title : editMe.password}
                style={{
                  padding: "10px",
                  fontSize: "10px",
                  border: "0",
                  borderRadius: "4px",
                  width: "100%",
                  height: "30px",
                }}
                onChange={(e) =>
                    setEditMe((prev) => ({
                      ...prev,
                      password: e.target.value
                    }))
                  }
              />
            </Grid2>
            <Grid2 item xs={12}>
              <Typography
                sx={{
                  textDecoration: "underline",
                  color: "white",
                  mb: "20px",
                }}
              >
                Password Confirmation:
              </Typography>
              <input
              name="passwordConfirmation"
                value={editMe.passwordConfirmation === "" ?  isLoggedIn?.job_title : editMe.passwordConfirmation}
                style={{
                  padding: "10px",
                  fontSize: "10px",
                  border: "0",
                  borderRadius: "4px",
                  width: "100%",
                  height: "30px",
                }}
                onChange={(e) =>
                    setEditMe((prev) => ({
                      ...prev,
                      passwordConfirmation: e.target.value
                    }))
                  }
              />
            </Grid2>
          </Grid2>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                borderRadius: "10px",
                margin: "auto",
              }}
              onClick={updateUserData}
              variant="contained"
            >
              Submit
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
            </Stack>
        )
    }

    export default NavBar