// RolesManagement.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ApiContext from '../Context/context';
import { useSearch } from '../Context/SearchContext';
import DeleteDialog from '../../../../FemilerCom/DeleteDialog/DeleteDiaolg';
import AddRole from './Component/RolesManagement/Diaolgs/AddRole';
import EditRole from './Component/RolesManagement/Diaolgs/EditRoles';
import LoadingSpinner from '../../../../FemilerCom/loading/spinner';
function RolesManagement() {
  const {
    roles,
    setShouldFetchPermissions,
    setShouldFetchRoles,
    setFetchRolesTrigger,
    loading,
    handelPermission2
  } = useContext(ApiContext);
        handelPermission2(["role-show", 'role-create', 'role-update', 'role-delete'])

    
  const { searchQuery } = useSearch();
  
  const [openEditDialog, setOpenEditDialog] = useState(false); // State to control the edit dialog
  const [selectedRole, setSelectedRole] = useState(null); // State to hold the selected role ID
  const [ open, setOpen] = useState(false)
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [RoleId, setRoleId] = useState()
  const [openDelete, setOpenDelete] = useState(false)

      useEffect(() => {
        if(setShouldFetchPermissions){
          setShouldFetchPermissions(true)
        }
        if(setShouldFetchRoles){
          setShouldFetchRoles(true)
        }
      }, [])

  const filteredcompanies = roles?.filter((product) =>
    product.name?.toLowerCase().includes(searchQuery?.toLowerCase())
  );
  // Handle deleting a role
  const handleDelete = async (roleId) => {
    try {
      const response = await axios.delete(`https://api.twindix.com/roles/${roleId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        handleClickSnackbar()
      }
    } catch (error) {
      console.error('Error deleting role:', error);
    }
  };

  const handleEdit = (roleId) => {
    setSelectedRole(roleId);
    setOpenEditDialog(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false)
  }
  const DeleteFun = () => {
      handleDelete(RoleId)
    handleCloseDelete()
  }

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
  };
  
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setOpenSnackbar(false);
  };
  return (
    <div>
      {loading && <LoadingSpinner />}

            <Button id='role-create' onClick={() => {
            setOpen(true)
          }} variant="contained" sx={{ background: "linear-gradient(45deg, #2c6264, #5ac4cb)" }}>
          <CheckCircleIcon />
          Add Role
        </Button>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="Roles Table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Guard Name</TableCell>
              <TableCell>HR ID</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.length > 0 ? (
              filteredcompanies.map((role) => (
                <TableRow key={role.id}>
                  <TableCell>{role.name}</TableCell>
                  <TableCell>{role.guard_name}</TableCell>
                  <TableCell>{role.hr_id}</TableCell>
                  <TableCell>
                    <Button
                    id='role-update'
                      variant="contained"
                      onClick={() => {
                        handleEdit(role.id)
                      }}
                        sx={{
                color: "white",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              }}
                    >
                      Edit
                    </Button>
                    <Button
                    id='role-delete'
                      variant="contained"
                      color="error"
                      sx={{ ml: 1,                
                        color: "white" }}
                      onClick={() => {
                        setRoleId(role.id)
                        setOpenDelete(true)
                        }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No roles available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AddRole
        setFetchRolesTrigger={setFetchRolesTrigger}
        open={open}
        setOpen={setOpen}
      />
      <EditRole
      setFetchRolesTrigger={setFetchRolesTrigger}
        open={openEditDialog}
        setOpen={setOpenEditDialog}
        roleId={selectedRole}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Role is deleted"
/>
<DeleteDialog open={openDelete} handleClose={handleCloseDelete} DialogTitle="Role" DialogDeleteContent='This Role' DeleteFun={DeleteFun}  />
    </div>
  );
}

export default RolesManagement;
