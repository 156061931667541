import * as React from 'react'; 
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { Typography } from '@mui/material';



export default function Cart({ open, toggleCart }) {
  const CartList = (
    <Box sx={{ width: '300px', height: '100%', bgcolor: 'rgb(27 70 65)', color : 'white' }} role="presentation">
      <Typography Component='h5' variant='h5' textAlign='center' margin={2} fontSize={22}>Cart</Typography>
    </Box>
  );

  return (
    <div>
      <Drawer anchor='right' open={open} onClose={toggleCart(false)}>
        {CartList}
      </Drawer>
    </div>
  );
}
