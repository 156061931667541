import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";


export default function CouponDialog ({
    openEditDialog,
    handleCloseEditDialog,
    isMobile,
    setReFetch,
    specifiedPackage
}) {
    const [coupon, setCoupon] = useState({});
    const [isCouponValid, setIsCouponValid] = useState(false);
    const [couponError, setCouponError] = useState("");
    const [FinalPrice, setFinalPrice] = useState();


const verifyPayment = async (transaction_id) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("No token found");

    const response = await axios.post(
      "https://api.twindix.com/payment/verify",
      { transaction_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setReFetch((prev) => !prev)
    return response.data;
  } catch (error) {
    console.error("Payment verification failed:", error);
    throw error;
  }
};

function buyExam(examId) {
    axios.post("https://api.twindix.com/payment/exam", {exam_id : examId, qty: 1, code: coupon[examId]  ?? '', is_debriefing:  0} ,{headers: {
        Authorization: "Bearer " + localStorage.token
    }})
    .then((res) => {
        const transactionId = res?.data?.payload?.transaction_id;
        localStorage.setItem('transaction_id', transactionId ?? 'no exam transaction Id');
        localStorage.setItem('exam_Id', examId);
        if(res?.data?.payload?.redirect === null) {
          verifyPayment(transactionId)
          handleCloseEditDialog()
        }else{
          setTimeout(() => {
            window.location.replace(res.data.payload.redirect);
          }, 1000);
        }
      })
}

const checkCoupon = (examId) => {
    const couponCode = coupon[examId] ?? '';
  axios
    .post(
      "https://api.twindix.com/coupon/exam",
      { exam_id: examId, code: couponCode, qty: 1 },
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    )
    .then((res) => {
      setFinalPrice(res.data.payload)
      if (res.data.success) {
        setIsCouponValid(true);
        setCouponError("");
      } else {
        setCouponError("Invalid coupon.");
        setIsCouponValid(false);
      }
    })
    .catch(() => {
      setIsCouponValid(false);
    });
};

const handleChange = (e, item) => {
  const newValue = e.target.value;
  setCoupon((prevValues) => ({
    ...prevValues,
    [item]: newValue,
  }));
};
    return (
        <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <Box sx={{ bgcolor: '#929292', width: isMobile ? '100%' : '450px' }}>
          <DialogContent sx={{ maxHeight: '500px', overflowY: 'auto' }}>
          <Stack>
            <Typography sx={{fontSize : '16px', margin: '6px', color : 'white'}}><Typography component='span' variant="span" fontWeight='bold'>Assessment Name:</Typography> {specifiedPackage?.title}</Typography>
            <Typography sx={{ fontSize: '16px', margin: '6px', color: 'white' }}>
            <Typography component='span' variant="span" fontWeight='bold'>
            Total Price: </Typography> <Typography component='span' variant="span" fontSize='20px' fontWeight='bolder'>  {FinalPrice ? FinalPrice.price  : specifiedPackage.price }  $ </Typography>
            </Typography>
          </Stack>
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
              <TextField
                label="Enter Coupon"
                variant="outlined"
                size="small"
                value={coupon[specifiedPackage.id] || ''}
                onChange={(e) => handleChange(e, specifiedPackage.id)}
                sx={{ flex: 1 }}
                name={`input-${specifiedPackage.id}`} 
                id={`input-${specifiedPackage.id}`} 
              />
              <Button
                variant="contained"
                
                onClick={() => checkCoupon(specifiedPackage.id)}
                sx={{fontSize: '12px', backgroundColor: "#4ca88f", color: "white", ml: isMobile ? 6 : 2, mt : isMobile ? '10px' : '0' }}
              >
                Check Coupon
              </Button>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
              <Stack justifyContent='center' alignItems='center' sx={{width: '100%'}}>
                <Button
                  variant="contained"
                  disabled={coupon[specifiedPackage.id] ? !isCouponValid : false}
                  onClick={() =>  buyExam(specifiedPackage.id)}
                  sx={{width : '200px', fontSize: '12px',background: "linear-gradient(45deg, #2c6264, #5ac4cb)", color: "white"}}
                >
                  Subscribe Now
                </Button>
              </Stack>
          </DialogActions>
        </Box>
      </Dialog>
    )
}