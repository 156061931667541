import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect } from "react";
import ApiContext from "../../../Context/context";
import AddIcon from '@mui/icons-material/Add';

export default function TwindixCompany({
  setShowBackBtn,
  showUsers,
  setOpen,
  setCompany,
  setShowUser,
}) {
  
  const { companies, setShouldFetchCompanies, handelPermission2 } = useContext(ApiContext);
  handelPermission2(["company-show", 'company-create'])
  
  const isMobile = useMediaQuery("(max-width:600px)");
  useEffect(() => {
    if (setShouldFetchCompanies) {
      setShouldFetchCompanies(true);
    }
    setShowBackBtn(true);
  }, [setShouldFetchCompanies, showUsers, setShowBackBtn]);

  return (
    <Stack sx={isMobile ? { overflow: 'scroll' }: {}}>
      <Button
      id="company-create"
        onClick={() => {
            setOpen(true);
        }}
        variant="contained"
        sx={{
          width: '200px',
          color: "white",
          background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
          borderRadius: '14px',
          fontWeight: 'bold',
          padding: '10px 5px',
          marginBottom: 2,
        }}
      >
        <AddIcon />
        Add Company
      </Button>

      {companies?.length > 0 ? (
        companies
          .filter((ele) => ele.exam_id === 1)
          .map((company) => (
            <Stack
              key={company.id}
              width={isMobile ? '900px' : '100%'}
              p={2}
              borderBottom="1px solid rgb(192, 192, 192)"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack alignItems="center" direction="row">
                <Typography
                  onClick={() => {
                    setCompany(company);
                    setShowUser(true);
                  }}
                  sx={{
                    width: isMobile ? '139px' : '200px',
                    cursor: "pointer",
                    color: "#5e5e5e",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                >
                  {company.name}
                </Typography>
              </Stack>
              <Typography sx={{ width: '150px', color: "#5e5e5e", textDecoration: "underline" }}>
                {company.phone}
              </Typography>
              <Typography sx={{ width: isMobile ? '306px' : '240px', color: "#5e5e5e", textDecoration: "underline" }}>
                {company.mail}
              </Typography>
              <Typography sx={{ width: '100px', color: "#5e5e5e", textDecoration: "underline" }}>
                {company.updated_at.slice(0, 10)}
              </Typography>
              <Stack direction="row" spacing={2}>
                <Typography
                  sx={{
                    color: "#3f8e8f",
                    border: '2.5px solid #3f8e8f',
                    borderRadius: '12px',
                    padding: '6px',
                    fontWeight: 'bold',
                  }}
                >
                  {company.total_employees} Participant
                </Typography>
              </Stack>
            </Stack>
          ))
      ) : (
        <Typography sx={{ marginLeft: '350px' }}>There Is No Companies Yet</Typography>
      )}
    </Stack>
  );
}