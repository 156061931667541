import {useEffect, useState } from "react";
import Header from "./Component/Header/Index";
import NavBar from "./Component/NavBar";
import TableCom from "./Component/Table";
import { Container } from "@mui/material";
import Payment from "./Component/Payment";

function IndividualComponent({ BackToLastPage, toggleDrawer, isAssessment, isBuyAssessment, toggleCart }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [ReFetch, setReFetch] = useState();

  useEffect(() => {
    setSearchQuery(""); 
  }, [isAssessment, isBuyAssessment]);

  const examNameToIdMap = {
    "Twindix Executive": 1,
    "Jop Bar": 65,
  };
  // Filter enrollments based on search query
  const matchedExamId = Object.keys(examNameToIdMap).find((examName) =>
    examName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const examIdToFilter = matchedExamId ? examNameToIdMap[matchedExamId] : null;

  return (
    <>
      <NavBar toggleDrawer={toggleDrawer} toggleCart={toggleCart} />
      {!isAssessment   && BackToLastPage()}
      <Container maxWidth="lg">
        <Header searchQuery={searchQuery} setSearchQuery={setSearchQuery} headerTilte={isAssessment ? "Assessment" 
        : 
        isBuyAssessment ? "Buy Assessments" : "Assessments"
        } />
        {isBuyAssessment ? 
        <Payment setReFetch={setReFetch} /> 
        : 
        <TableCom 
        ReFetch={ReFetch}
        searchQuery={searchQuery}
        examIdToFilter={examIdToFilter} 
        />}
      </Container>
    </>
  );
}

export default IndividualComponent;
