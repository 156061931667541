import HrComponent from "../../../Component/Dashboard-V2/HR"
import { ApiProvider } from "../../../Component/Dashboard-V2/HR/Context/context";
import { SearchProvider } from "../../../Component/Dashboard-V2/HR/Context/SearchContext";


function HR ({toggleDrawer , 
    isDashboardHR,
    isTwindixIndivdual,
    isJopBar,
    isBenchmarksShelve,
    isTeamAccess,
    isMangeTwindixAssessments,
    isMangeJopBarAssessments,
    isSubscriptions,
    toggleCart,
    isHetInTouch,
    isMyPackages,
    Roles,
    Company,
    Employee,
    BackToLastPage,
    setshowBackBtn,
    MyEnrollments,
    setSubscriptions
}) {

    // fetching HR APIs


    return (
        <>
          <ApiProvider>
          <SearchProvider>
            <HrComponent
            //fetching
            setshowBackBtn={setshowBackBtn}
            toggleDrawer={toggleDrawer}   
            toggleCart={toggleCart}   
            isDashboardHR={isDashboardHR}
            isTwindixIndivdual={isTwindixIndivdual}
            isJopBar={isJopBar}
            isBenchmarksShelve={isBenchmarksShelve}
            isTeamAccess={isTeamAccess}
            isMangeTwindixAssessments={isMangeTwindixAssessments} 
            isMangeJopBarAssessments={isMangeJopBarAssessments} 
            isSubscriptions={isSubscriptions} 
            isHetInTouch={isHetInTouch} 
            isMyPackages={isMyPackages} 
            Roles={Roles}
            Company={Company}
            Employee={Employee}
            BackToLastPage={BackToLastPage}
            MyEnrollments={MyEnrollments}
            setSubscriptions={setSubscriptions}
            />
            </SearchProvider>
            </ApiProvider>
        </>
    )
}

export default HR