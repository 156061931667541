import { Box, Button, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useSearch } from "../../../Context/SearchContext";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingSpinner from "../../../../../../FemilerCom/loading/spinner";
import { useContext } from "react";
import ApiContext from "../../../Context/context";


function AccesPositionComponent ({
  setShowSection,
  handleBackButtonClick,
  setPositionId,
  getSpecCompany,
  loading
    })  {      
      
      const { searchQuery } = useSearch();
    const filteredPositions = getSpecCompany?.positions?.filter((product) =>
      product.title?.toLowerCase().includes(searchQuery?.toLowerCase())
    );
    return (
        <>
      {loading && <LoadingSpinner />}

          <Button onClick={handleBackButtonClick} sx={{ 
            position : 'fixed',
            top : '96px',
            left :'0',
            fontSize :'18px'
            }}>
                <ArrowBackIcon />Back
              </Button>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell sx={{float: 'right', marginRight :'60px'}}>Assessment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {filteredPositions
                ?.map((position) => {
                  return (
                      <TableRow key={position.id}>
                        <TableCell>
                          <Typography
                            onClick={() => {
                              setPositionId(position.id)
                              setShowSection('assessment')
                              }}
                            sx={{
                              width: '200px',
                              cursor: "pointer",
                              color: "#2b6566",
                              textDecoration: "underline",
                            }}
                          >
                            {position.title}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{float: 'right'}}>
                        <Stack direction='row' height='60px' alignItems='center'>
                        <Box >
                          <Typography
                            sx={{
                              margin: "4px",
                              color: "#3f8e8f",
                              border: "2.5px solid #3f8e8f",
                              borderRadius: "12px",
                              padding: "12px 6px",
                              fontWeight: "bold",
                              width : '150px',
                              textAlign : "center"
                            }}
                          >
                            {position.position_count} Assessment
                          </Typography>
                          </Box>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </>
    )
}

export default AccesPositionComponent