import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ApiContext from '../Context/context';
import { useSearch } from '../Context/SearchContext';
import DeleteDialog from '../../../../FemilerCom/DeleteDialog/DeleteDiaolg';
import EditCompany from './Component/CompanyManagement/Dialog/EditCompany';
import AddCompanyForm from '../ShareHr/AddCompanyForm';
import LoadingSpinner from '../../../../FemilerCom/loading/spinner';

function CompanyManagement() {
  const {
    companies,
    setShouldFetchCompanies,
    setFetchCompaniesTrigger,
    loading,
    handelPermission2
  } = useContext(ApiContext);

  handelPermission2(["company-show", 'company-create', 'company-update', 'company-delete'])
    
  const { searchQuery } = useSearch();

  const [selectedCompany, setSelectedCompany] = useState([]); // Currently selected company for editing
  const [openEditDialog, setOpenEditDialog] = useState(false); 
  const [open, setOpen] = useState(false);
  const [Company, setCompany] = useState()
  const [exam_id, setExam_id] = useState("")
  const [openDelete, setOpenDelete] = useState(false)


  const filteredcompanies = companies?.filter((product) =>
    product.name?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  useEffect(() => {
    if(setShouldFetchCompanies){
      setShouldFetchCompanies(true)
    }
  }, [])

  // Handle delete company
  const handleDelete = async (companyId) => {
    try {
      await axios.delete(`https://api.twindix.com/companies/${companyId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    } catch (error) {
      console.error('Error deleting company:', error);
    }
  };

  // Handle opening the edit dialog
  const handleEdit = (company) => {
    setSelectedCompany(company);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedCompany(null);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false)
  }
  const DeleteFun = () => {
    // if(isHasPermissionDelete){
      handleDelete(Company)
    // }
    handleCloseDelete()
  }
  
  return (
    <div>
      {loading && <LoadingSpinner />}
                <Button
                id='company-create'
              onClick={() => {
                // if(isHasPermissionAdd){
                  setOpen(true)
                // }
                }}
              variant="contained"
              sx={{
                marginBottom: '20px',
                color: "white",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              }}
            >
            <AddIcon />
              Add Company
            </Button>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="Company Table">
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Contact Person</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.length > 0 ? (
              filteredcompanies.map((company) => (
                <TableRow key={company.id}>
                  <TableCell>{company.id}</TableCell>
                  <TableCell>{company.name}</TableCell>
                  <TableCell>{company.mail}</TableCell>
                  <TableCell>{company.created_at.slice(0, 10)}</TableCell>
                  <TableCell>
                    <Button
                    id='company-update'
                      variant="contained"
                      onClick={() => {
                        handleEdit(company)
                        }}
                        sx={{
                color: "white",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              }}
                    >
                      Edit
                    </Button>
                    <Button
                    id='company-delete'
                      variant="contained"
                      color="error"
                      sx={{ ml: 1,                
                        color: "white" }}
                      onClick={() => {
                        setCompany(company.id)
                        setOpenDelete(true)
                        }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No companies available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

<EditCompany 
  openEditDialog={openEditDialog}
  handleCloseEditDialog={handleCloseEditDialog}
  selectedCompany={selectedCompany}
  setFetchCompaniesTrigger={setFetchCompaniesTrigger}
/>
<AddCompanyForm
    open={open} 
    setOpen={setOpen} 
    exam_id={exam_id}
    setExam_id={setExam_id}
/>
      <DeleteDialog open={openDelete} handleClose={handleCloseDelete} DialogTitle="company" DialogDeleteContent='This company' DeleteFun={DeleteFun}  />
    </div>
  );
}

export default CompanyManagement;
