import { useContext, useEffect } from "react";
import Badel from "../../../../Media/imges/WhatsApp Image 2024-01-09 at 12.28.12_84c2f948.jpg";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import ApiContext from "../Context/context";
import Statistics from "../ShareHr/statistics";

function Dashboard({setSubscriptions}) {
    const isMobile = useMediaQuery("(max-width:600px)");

    const { 
        exams, 
        setShouldFetchExams,
    } = useContext(ApiContext)

    useEffect(() => {
        setShouldFetchExams(true)
    }, [setShouldFetchExams])

    return (
        <>
        <Statistics btn={true} setSubscriptions={setSubscriptions} />
        <Box sx={{height: '850px'}}>
            <Box>
                <Stack spacing={6}>
                    {exams && exams?.map((exam, index) => (
                        <Stack
                            key={exam.id}
                            direction={isMobile ? 'column' : index % 2 === 0 ? "row" : "row-reverse"}
                            spacing={4}
                            alignItems="center"
                        >
                            <Box sx={{width: isMobile ? '100%' : '50%'}}>
                                <img
                                    src={exam.cover_image || Badel}
                                    alt="Exam Cover"
                                    style={{ borderRadius: 8, width :isMobile && '100%' }}
                                />
                            </Box>
                            <Box height='100%' margin='auto 32px'>
                                <Typography textAlign='justify'  fontSize={isMobile ? '16px' : '21px' }
                                fontWeight='400' sx={{color: '#929292', letterSpacing: '-1px', lineHeight: '34px',}}
                                variant="body2">
                                    {window.innerWidth < 750
                                        ? exam.description
                                        : exam.description}
                                </Typography>
                            </Box>
                        </Stack>
                    ))}
                </Stack>
            </Box>
        </Box>
        </>
    );
}

export default Dashboard;
