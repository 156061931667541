import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";

export default function Reminder({
  isMobile,
  reminderOpen,
  handleCloseReminder,
  dialogContentReminder,
  userId,
  handleClickSnackbarReminder,
  setSnackbarTextReminder,
  selectedEmployees
}) {

  const [loading, setLoading] = useState(false);
  const reminderUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
        const response = await axios.post("https://api.twindix.com/reminder", {user_id: selectedEmployees.length > 0 ? selectedEmployees : userId}, {
            headers: {
                Authorization: "Bearer " + localStorage.token,
            },
        });
        setLoading(false);
        handleCloseReminder();
        setSnackbarTextReminder('Reminder Is Sent');
        handleClickSnackbarReminder();
        
    } catch (response) {
        setLoading(false);        
        setSnackbarTextReminder(response.message);
        handleClickSnackbarReminder();
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          position: isMobile ? "fixed" : "relative",
          left: isMobile ? 10 : "auto",
          margin: 0,
          height: isMobile ? "auto" : "fit-content",
          borderRadius: isMobile ? 0 : "8px",
          width: isMobile ? "100%" : "450px",
          maxWidth: isMobile ? "sm" : "md",
        },
      }}
      open={reminderOpen}
      onClose={handleCloseReminder}
    >
      <Box
        sx={{
          bgcolor: "#929292",
          width: "100%",
          height: isMobile ? "100%" : "auto",
        }}
      >
        <DialogContent
          sx={{
            maxHeight: "500px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
            },
            "&::-webkit-scrollbar-track": {
              background: "#929292",
              borderRadius: "10px",
            },
          }}
        >
          <Typography
            variant="h5"
            component="h5"
            fontSize={17}
            sx={{ color: "white" }}
          >
          {dialogContentReminder}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
                reminderUser(e);
            }}
            sx={{
              background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              borderRadius: "10px",
              margin: "auto",
              color: "white",
              fontWeight: "bold",
              padding: "8px 16px",
            }}
            variant="contained"
          >
             {loading ? 'Sending' : 'Send Reminder' }
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}