import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  TextField,
  Typography,
  Checkbox,
  Button,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { appContext } from "../../App";
import emailIcon from "../../Media/Icons/Forms/icons8-email-26.png";
import passwordIcon from "../../Media/Icons/Forms/icons8-password-50.png";
import LoginVildation from "./LoginForAdminVildation";

function LogInBox() {
  let [error, seterror] = useState([])
  let [errorMsg, setErrorMsg] = useState("")
  let [vlidation, setvlidation] = useState ({
      email: ' ',
      password: ' ',

  });
  let adminData = useContext(appContext);
  let navigate = useNavigate()

  let vlidationinput = (e) => {
      setvlidation(prev => ({...prev, [e.target.name]: e.target.value}))
  } 

  let VildationSupmitFun = (e) => {
      e.preventDefault();
      seterror(LoginVildation(vlidation))
  }

  useEffect(() => {
      if (error.email === 'valid' && error.password === 'valid') {
      axios
          .post('https://api.twindix.com/dashboard/auth/login', vlidation)
          .then((res) => {

              if(res.data.code == 200) {
                  localStorage.token = res.data.payload.token;
                  adminData.setUserType(null);
                  adminData.setLoggedin(true);
                  adminData.setIsAdmin(true);
                  localStorage.isAdmin = true;
                  localStorage.isLogged = true;
                  localStorage.setUserType = 3;
                  adminData.setUserName("");
                  navigate('/Admin')
              }
          // navigate("/")
            // Additional logic after successful API request
          })
          .catch((err) => {console.log(err); setErrorMsg("check your inputs again")});
      }
    }, [error]); // Run this effect whenever the 'error' state changes
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
        padding: "20px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: "400px",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <img src="https://assessment.twindix.com/Login-logo.png" alt="Logo" />
        </Box>
        <Typography
          variant="h5"
          align="center"
          sx={{ mb: 2, color: "#2c6264", fontWeight: "bold" }}
        >
          ADMIN LOGIN
        </Typography>
        <form onSubmit={VildationSupmitFun}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <img
                  src={emailIcon}
                  alt="Email Icon"
                  style={{ marginRight: "8px", width: "24px" }}
                />
                <Typography sx={{ textDecoration: "underline" }}>
                  Email
                </Typography>
              </Box>
              <TextField
                fullWidth
                variant="outlined"
                name="email"
                onChange={vlidationinput}
                error={Boolean(error.email)}
                helperText={error.email === "valid" ? "" : error.email}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <img
                  src={passwordIcon}
                  alt="Password Icon"
                  style={{ marginRight: "8px", width: "24px" }}
                />
                <Typography sx={{ textDecoration: "underline" }}>
                  Password
                </Typography>
              </Box>
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                name="password"
                onChange={vlidationinput}
                error={Boolean(error.password)}
                helperText={error.password === "valid" ? "" : error.password}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Checkbox />
            <Typography Component="span">Remember me</Typography>
          </Box>
          <Button href="/LogInForAdmin/ResetPasswordForAdmin" sx={{ mt: 2, textAlign: "right" }}>
          Forget Password?
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
            }}
          >
            LOGIN
          </Button>
        </form>
      </Box>
    </Box>
  );
}

export default LogInBox;
