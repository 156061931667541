import AdminComponent from "../../../Component/Dashboard-V2/Admin"


function Admin ({BackToLastPage, AdminRoles, isCoupons, isAdmins, toggleDrawer,toggleCart,isHomeAdmin,isEnrollments,isUsers,isPackages,isExams,isTemplates,isBenchmark}) {
    return (
        <>

<AdminComponent toggleCart={toggleCart}
                BackToLastPage={BackToLastPage}
                toggleDrawer={toggleDrawer} 
                isHomeAdmin={isHomeAdmin}
                isEnrollments={isEnrollments}
                isUsers={isUsers}
                isPackages={isPackages}
                isExams={isExams}
                isTemplates={isTemplates}
                isBenchmark={isBenchmark}
                isAdmins={isAdmins}
                isCoupons={isCoupons}
                AdminRoles={AdminRoles}
             />
        </>
    )
}

export default Admin