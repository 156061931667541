import { Box, Button, Divider, Stack, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect } from "react";
import logo from '../../../../Media/Logo/Logo-big.png'
import logo2 from '../../../../Media/Logo/Job-Bar-Logo.jpg'
import ApiContext from "../Context/context";
function Statistics ({setSubscriptions, btn}) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const {
    statistics,
    setShouldFetchStatistics,
  } = useContext(ApiContext);
  
  const Twindix = statistics?.statistics?.[1] || [];
  const JopBar = statistics?.statistics?.[65] || [];

  useEffect(() => {
    if(setShouldFetchStatistics){
      setShouldFetchStatistics(true);
    }
  
  }, [setShouldFetchStatistics]);

    return (
            <>
              <Box sx={{ 
                    background: '#2d6969', // Gradient background
                    borderRadius: '30px', // Rounded corners
                    display: isMobile ? 'none' : 'flex',
                    alignItems: 'center',
                    color: 'white', 
                    fontFamily: 'Arial, sans-serif', 
                    p: '4px',
                    height: '80px',
                    border : '2px solid #82bebf',
                    }}>

                    <Box sx={{width:'20%', textAlign :'center',pl: '20px', mr: '32px' }}> {/* Spacing to the right of the Job Bar section */}
                      <Typography variant="h5" sx={{ fontWeight: 600, fontSize: '18px', mb: '4px' }}>{JopBar?.title ?? 'jopBar'}</Typography> {/* Margin bottom */}
                      <Typography variant="body2" sx={{width :'110%', fontSize: '18px' }}>Active subscription</Typography>
                    </Box>
                 
                    <Stack justifyContent='center' direction='row' sx={{border : '2px solid #82bebf',width: '80%', p: '12px',borderRadius :'30px', bgcolor :'#ffffff', display: 'flex',height: '98%', alignItems: 'center', gap: '24px' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h6" sx={{color: '#2d6969', fontWeight: 500, fontSize: '21px' }}>{JopBar?.total ?? 0} Assessments</Typography>
                        <Typography variant="body2" sx={{ fontSize: '16px', color: '#2d6969', textDecoration: 'underline' }}>Annual subscription</Typography> {/* Underline and light blue color */}
                      </Box>
                      <Divider 
                            orientation="vertical" 
                            flexItem 
                            sx={{bgcolor:'#439699', width: '4px', height: 'auto', margin: '0 10px' }}
                          />
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h6" sx={{color: '#2d6969', fontWeight: 500, fontSize: '21px' }}>{statistics?.total_add_ons === 0  ? 'no Add Ons' : statistics?.total_add_ons } </Typography>
                        <Typography variant="body2" sx={{ fontSize: '16px', color: '#2d6969', textDecoration: 'underline' }}>Add Ons</Typography> 
                      </Box>
                      <Divider 
                            orientation="vertical" 
                            flexItem 
                            sx={{bgcolor:'#439699', width: '4px', height: 'auto', margin: '0 10px' }}
                          />
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}> {/* Align to right */}
                        <Typography variant="body1" sx={{color: '#2d6969', fontSize: '15px' }}>Start Date -   {statistics?.subscribed_package?.created_at.slice(0, 10)}</Typography>
                        <Typography variant="body1" sx={{color: '#2d6969', fontSize: '15px' }}>End Date -   {statistics?.subscribed_package?.expire_at.slice(0, 10)}</Typography>
                      </Box>
                    </Stack>
                  </Box>
                  <Stack spacing={isMobile ? 1 : ''} sx={{flexDirection : isMobile ? 'column' :'row' ,width:'100%',justifyContent:'space-between', alignItems: 'center', fontFamily: 'Arial, sans-serif' }}>
                 
                  <Box sx={{width:'150px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', m: '24px 24px 0 0' }}> {/* Adjust spacing as needed */}
                  <Box>
                      <img style={{width: '100%'}} src={logo} alt="dd" />
                  </Box>
                  </Box>

                  <Box sx={{ 
                background: 'linear-gradient(to right, #2e6294, #6bb4ed)', // Gradient background
                borderRadius: '28px', 
                p: '12px 16px', // Adjust padding
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent:'space-around',
                color: 'white',
                mr: '16px' ,
                width:'300px'
               }}>
                <Typography variant="h5" sx={{ fontWeight: 600, fontSize: '20px' }}>{Twindix?.total ?? 0}</Typography>
                <Typography variant="body2" sx={{ fontSize: '14px' }}>Total Assessments</Typography>
                  </Box>
             
                  {/* Red Section */}
                  <Box sx={{
                background: 'linear-gradient(to right, #9c3526, #73adde)', // Gradient background
                borderRadius: '28px',
                p: '12px 16px', // Adjust padding
                display: 'flex',
                flexDirection: 'row',
                justifyContent:'space-around',
                alignItems: 'center',
                color: 'white',
                mr: '16px' ,
                width:'300px'
              }}>
                <Typography variant="h5" sx={{ fontWeight: 600, fontSize: '20px' }}>{Twindix?.consumed ?? 0}</Typography>
                <Typography variant="body2" sx={{ fontSize: '14px' }}>Used Assessments</Typography>
                  </Box>

                  {/* Green Section */}
                  <Box sx={{
                background: 'linear-gradient(to right, #387a32, #6ebae8)', // Gradient background
                borderRadius: '18px',
                p: '12px 16px', 
                display: 'flex',
                flexDirection: 'row',
                justifyContent:'space-around',
                alignItems: 'center',
                color: 'white',
                width:'300px'
              }}>
                <Typography variant="h5" sx={{ fontWeight: 600, fontSize: '20px' }}>{Twindix?.remaining ?? 0}</Typography>
                <Typography variant="body2" sx={{ fontSize: '14px' }}>Available Assessments</Typography>
                  </Box>

                  </Stack>
                  <Stack spacing={isMobile ? 1 : ''} sx={{flexDirection : isMobile ? 'column' :'row', marginBottom:'20px', width:'100%', alignItems: 'center',justifyContent:'space-between', fontFamily: 'Arial, sans-serif' }}>

              <Box sx={{width:'150px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mr: '24px' }}> {/* Adjust spacing as needed */}
                  <Box>
                      <img style={{width: '95%'}} src={logo2} alt="dd" />
                  </Box>
              </Box>

              <Box sx={{ 
                background: 'linear-gradient(to right, #2e6294, #6bb4ed)', // Gradient background
                borderRadius: '28px', 
                p: '12px 16px', // Adjust padding
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent:'space-around',
                color: 'white',
                mr: '16px' ,
                width:'300px'
              }}>
                <Typography variant="h5" sx={{ fontWeight: 600, fontSize: '20px' }}>{JopBar?.total ?? 0}</Typography>
                <Typography variant="body2" sx={{ fontSize: '14px' }}>Total Assessments</Typography>
              </Box>
              {/* Red Section */}
              <Box sx={{
                background: 'linear-gradient(to right, #9c3526, #73adde)', // Gradient background
                borderRadius: '28px',
                p: '12px 16px', // Adjust padding
                display: 'flex',
                flexDirection: 'row',
                justifyContent:'space-around',
                alignItems: 'center',
                color: 'white',
                mr: '16px' ,
                width:'300px'
              }}>
                <Typography variant="h5" sx={{ fontWeight: 600, fontSize: '20px' }}>{JopBar?.consumed ?? 0}</Typography>
                <Typography variant="body2" sx={{ fontSize: '14px' }}>Used Assessments</Typography>
              </Box>

              {/* Green Section */}
              <Box sx={{
                background: 'linear-gradient(to right, #387a32, #6ebae8)', // Gradient background
                borderRadius: '18px',
                p: '12px 16px', 
                display: 'flex',
                flexDirection: 'row',
                justifyContent:'space-around',
                alignItems: 'center',
                color: 'white',
                width:'300px'
              }}>
                <Typography variant="h5" sx={{ fontWeight: 600, fontSize: '20px' }}>{ JopBar?.remaining ?? 0}</Typography>
                <Typography variant="body2" sx={{ fontSize: '14px' }}>Available Assessments</Typography>
              </Box>

                  </Stack>
              {btn && 
                    <Box onClick={() => setSubscriptions()} sx={{marginBottom:'20px', display: 'flex', justifyContent: 'center' }}>
                      <Button sx={{borderRadius : '30px'}} variant="contained" color="warning">
                        + Add More Assessments
                      </Button>
                    </Box>
              }
            </>
    )
}

export default Statistics