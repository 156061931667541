import React, {useEffect, useState } from "react";
import { Box } from "@mui/material";
//Component
import CompanyComponent from "./Component/MangeJopBarAssessments/Company";
import PositionComponent from "./Component/MangeJopBarAssessments/Position";
import MangeJopBar from "./Component/MangeJopBarAssessments/MangeJopBar";
//HOOKS
import axios from "axios";

function  MangeJopBarAssessments({ setShowBackBtn }) {
  
  const [showSection, setShowSection] = useState('company');
  const [company, setCompany] = useState();
  const [PositionId, setPositionId] = useState()
  const [getSpecCompany, setGetSpecCompany] = useState();
  const [reFetchSpecificCompany, setReFetchSpecificCompany] = useState(0);
  const [loading, setLoading] = useState();

  useEffect(() => {
    if(company?.id !== undefined){      
      setLoading(true)
    const fetchSpecificCompany = async () => {
      try {
        const res = await axios.get(`https://api.twindix.com/companies/${company?.id}`, {
          headers: { Authorization: `Bearer ${localStorage.token}` },
        });
      setLoading(false)
        setGetSpecCompany(res.data.payload);
      } catch (err) {
      setLoading(false)
        console.log(err)
      }
    };

    fetchSpecificCompany();
  }
  }, [company?.id, reFetchSpecificCompany]);

  useEffect(() => {
    setShowBackBtn(true);
  }, [showSection, setShowBackBtn]);

  const handleBackButtonClick = () => {
    if (showSection === 'assessment') {
      setShowSection('position'); // Go back to the position section
    } else if (showSection === 'position') {
      setShowSection('company'); // Go back to the company section
    }
  };

  return (
    <Box>
      {showSection === 'company' ?
        <CompanyComponent
          setSelectedCompany={setCompany}
          setShowSection={setShowSection}
        />
        : showSection === 'position' ?
          <PositionComponent
            setShowSection={setShowSection}
            company={company}
            handleBackButtonClick={handleBackButtonClick}
            setPositionId={setPositionId}
            PositionId={PositionId}
            getSpecCompany={getSpecCompany}
            loading={loading}
            setReFetchSpecificCompany={setReFetchSpecificCompany}
          />
          : showSection === 'assessment' &&
          <MangeJopBar
            PositionId={PositionId}
            company={company}
            handleBackButtonClick={handleBackButtonClick}
            getSpecCompany={getSpecCompany}
            setReFetchSpecificCompany={setReFetchSpecificCompany}
          />
      }
    </Box>
  );
}

export default MangeJopBarAssessments;