import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  TextField,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import ApiContext from "../../../../Context/context";

export default function AddBench({ isMobile, open, handleClose, companies, positions }) {
  const { setFetchBenchmarkTrigger } = useContext(ApiContext);
  const [formData, setFormData] = useState({ title: null, company: "", position: "", code: "" });
  const [error, setError] = useState("");
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Generate a random 8-digit code
  const generateCode = () => {
    return Math.floor(10000000 + Math.random() * 90000000).toString();
  };

  // Generate and set the code when the dialog opens
  useEffect(() => {
    if (open) {
      const newCode = generateCode();
      setFormData((prev) => ({ ...prev, code: newCode }));
    }
  }, [open]);

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle company selection
  const handleCompanyChange = (event) => {
    const companyId = event.target.value;
    setSelectedCompanyId(companyId);
    handleInputChange(event); // Update formData
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!formData.title || !formData.company || !formData.position) {
      setError("Please fill all required fields.");
      return;
    }

    const payload = {
      benchmark: ['0'],
      title: formData.title,
      code: formData.code,
      company_id: formData.company,
      position_id: formData.position,
    };

    setIsLoading(true);
    setError("");

    try {
      await axios.post(
        "https://api.twindix.com/benchmark-questions/1/benchmarks",
        payload,
        { headers: { Authorization: "Bearer " + localStorage.token } }
      );
      setFetchBenchmarkTrigger((prev) => prev + 1);
      handleClose();
    } catch (error) {
      console.error("Error adding benchmark:", error);
      setError("Failed to add benchmark. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          position: isMobile ? "fixed" : "relative",
          left: isMobile ? 40 : "auto",
          margin: 0,
          height: isMobile ? "100%" : "auto",
          borderRadius: isMobile ? 0 : "8px",
          width: isMobile ? "auto" : "450px",
        },
      }}
      maxWidth={isMobile ? "sm" : "md"}
      open={open}
      onClose={handleClose}
    >
      <Box sx={{ bgcolor: "#929292", height: isMobile ? "100%" : "auto" }}>
        <DialogTitle sx={{ color: "white" }}>Add Benchmark</DialogTitle>
        <DialogContent
          sx={{
            maxHeight: "500px",
            overflowY: "auto",
            "&::-webkit-scrollbar": { width: "6px" },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
            },
            "&::-webkit-scrollbar-track": { background: "#929292", borderRadius: "10px" },
          }}
        >
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>
                Create Benchmark Name:
              </Typography>
              <TextField
                name="title"
                value={formData.title ?? ""}
                onChange={handleInputChange}
                fullWidth
                placeholder="Enter Benchmark Name"
                sx={{ bgcolor: "white", borderRadius: "4px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Company:</Typography>
              <Select
                name="company"
                value={formData.company ?? ""}
                onChange={handleCompanyChange}
                fullWidth
                displayEmpty
                sx={{ bgcolor: "white", borderRadius: "4px" }}
              >
                <MenuItem value="" disabled>
                  Select Company
                </MenuItem>
                {companies
                  .filter((ele) => ele.exam_id === 65)
                  .map((com) => (
                    <MenuItem key={com.id} value={com.id}>
                      {com.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Position:</Typography>
              <Select
                name="position"
                value={formData.position ?? ""}
                onChange={handleInputChange}
                fullWidth
                displayEmpty
                sx={{ bgcolor: "white", borderRadius: "4px" }}
              >
                <MenuItem value="" disabled>
                  Select Position
                </MenuItem>
                {positions
                  ?.filter((pos) => pos?.company_id === selectedCompanyId)
                  .map((pos) => (
                    <MenuItem key={pos.id} value={pos.id}>
                      {pos.title}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              borderRadius: "10px",
              margin: "auto",
            }}
            variant="contained"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Add"}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}