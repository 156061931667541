export const useCircle = ({  answers = [], benchmark = [],}) => {
  const boxesData = [
    { answersKeys: [25211, 25212, 25213], benchmarkKey: 20, rotate: '-5deg' },
    { answersKeys: [25221, 25222, 25223], benchmarkKey: 21, rotate: '-1deg' },
    { answersKeys: [25232, 25233, 25234], benchmarkKey: 22, rotate: '5deg' },
    { answersKeys: [25241, 25242, 25243], benchmarkKey: 23, rotate: '5deg' },
    { answersKeys: [25251, 25252, 25253], benchmarkKey: 24, rotate: '9deg' },
  ];


  
  const answersValues = boxesData.map((answer) =>
    answer.answersKeys.map((value) => answers?.[value] === undefined ? 0  : answers?.[value])
  );
  const values = answersValues.map((ele) => ele[0] + ele[1] + ele[2] )

  function compareNumbers(a, b) {
    return a - b;
  }
  const finalValue = values.sort(compareNumbers)

  const answersValuesBench = boxesData.map((answer) =>
    answer.benchmarkKey
  );
  const valuesBench = answersValuesBench.map((ele) => benchmark?.[ele])

  const finalValueBench = valuesBench.sort(compareNumbers)

  const boxesData2 = [
    { answersKeys: [26261, 26262, 26263], benchmarkKey: 26, rotate: '-5deg' },
    { answersKeys: [26271, 26272, 26273], benchmarkKey: 26, rotate: '-3deg' },
    { answersKeys: [26281, 26282, 26283], benchmarkKey: 27, rotate: '3deg' },
    { answersKeys: [26291, 26292, 26293], benchmarkKey: 28, rotate: '4deg' },
    { answersKeys: [26301, 26302, 26303], benchmarkKey: 29, rotate: '7deg' },
  ];

  const answersValues2 = boxesData2.map((answer) =>
    answer.answersKeys.map((value) => answers?.[value] === undefined ? 0  : answers?.[value])
  );
  const values2 = answersValues2.map((ele) => ele[0] + ele[1] + ele[2] )

  
  const finalValue2 = values2.sort(compareNumbers)

  const answersValuesBench2 = boxesData2.map((answer) =>
    answer.benchmarkKey
  );
  const valuesBench2 = answersValuesBench2.map((ele) => benchmark?.[ele])

  const finalValueBench2 = valuesBench2.sort(compareNumbers)


  const boxesData3 = [
    { answersKeys: [27311, 27312, 27313], benchmarkKey: 30, rotate: '-5deg',},
    { answersKeys: [27321, 27322, 27323], benchmarkKey: 31, rotate: '-3deg', },
    { answersKeys: [27331, 27332, 27333], benchmarkKey: 32, rotate: '3deg', },
    { answersKeys: [27341, 27342, 27343], benchmarkKey: 33, rotate: '6deg', },
    { answersKeys: [27351, 27352, 27353], benchmarkKey: 34, rotate: '9deg', },
  ];

  const answersValues3 = boxesData3.map((answer) =>
    answer.answersKeys.map((value) => answers?.[value] === undefined ? 0  : answers?.[value])
  );
  const values3 = answersValues3.map((ele) => ele[0] + ele[1] + ele[2] )


  const finalValue3 = values3.sort(compareNumbers)

  const answersValuesBench3 = boxesData3.map((answer) =>
    answer.benchmarkKey
  );
  const valuesBench3 = answersValuesBench3.map((ele) => benchmark?.[ele])

  const finalValueBench3 = valuesBench3.sort(compareNumbers)

  const boxesData4 = [
    { answersKeys: [28361, 28362, 28363], benchmarkKey: 35, rotate: '-7deg', },
    { answersKeys: [28371, 28372, 28373], benchmarkKey: 36, rotate: '-4deg', },
    { answersKeys: [28381, 28382, 28383],   benchmarkKey: 37,   rotate: '1deg', },
    { answersKeys: [28391, 28392, 28393],  benchmarkKey: 38,  rotate: '6deg',},
    { answersKeys: [28401, 28402, 28403],  benchmarkKey: 39,  rotate: '9deg',},
  ];

  const answersValues4 = boxesData4.map((answer) =>
    answer.answersKeys.map((value) => answers?.[value] === undefined ? 0  : answers?.[value])
  );
  const values4 = answersValues4.map((ele) => ele[0] + ele[1] + ele[2] )


  const finalValue4 = values4.sort(compareNumbers)

  const answersValuesBench4 = boxesData4.map((answer) =>
    answer.benchmarkKey
  );
  const valuesBench4 = answersValuesBench4.map((ele) => benchmark?.[ele])

  const finalValueBench4 = valuesBench4.sort(compareNumbers)


  const boxesData5 = [
    {  answersKeys: [39411, 39412, 39413],  benchmarkKey: 40,  rotate: '-5deg',},
    {  answersKeys: [39421, 39422, 39423],  benchmarkKey: 41,  rotate: '-2deg',},
    {  answersKeys: [39431, 39432, 39433],  benchmarkKey: 42,  rotate: '3deg',},
    {  answersKeys: [39441, 39442, 39443],  benchmarkKey: 43,  rotate: '8deg',},
    {  answersKeys: [39451, 39452, 39453],  benchmarkKey: 44,  rotate: '13deg',},
  ];
  

  const answersValues5 = boxesData5.map((answer) =>
    answer.answersKeys.map((value) => answers?.[value] === undefined ? 0  : answers?.[value])
  );
  const values5 = answersValues5.map((ele) => ele[0] + ele[1] + ele[2] )


  const finalValue5 = values5.sort(compareNumbers).reverse()

  const answersValuesBench5 = boxesData5.map((answer) =>
    answer.benchmarkKey
  );
  const valuesBench5 = answersValuesBench5.map((ele) => benchmark?.[ele])

  const finalValueBench5 = valuesBench5.sort(compareNumbers).reverse()


  const boxesData6 = [
    {  answersKeys: [310461, 310462, 310463],  benchmarkKey: 45,  rotate: '-8deg',},
    {  answersKeys: [310471, 310472, 310473],  benchmarkKey: 46,  rotate: '-3deg',},
    {  answersKeys: [310481, 310482, 310483],  benchmarkKey: 47,  rotate: '3deg',},
    {  answersKeys: [310491, 310492, 310493],  benchmarkKey: 48,  rotate: '6deg',},
    {  answersKeys: [310501, 310502, 310503],  benchmarkKey: 49,  rotate: '10deg',},
  ];
  
  

  const answersValues6 = boxesData6.map((answer) =>
    answer.answersKeys.map((value) => answers?.[value] === undefined ? 0  : answers?.[value])
  );
  const values6 = answersValues6.map((ele) => ele[0] + ele[1] + ele[2] )


  const finalValue6 = values6.sort(compareNumbers).reverse()

  const answersValuesBench6 = boxesData6.map((answer) =>
    answer.benchmarkKey
  );
  const valuesBench6 = answersValuesBench6.map((ele) => benchmark?.[ele])

  const finalValueBench6 = valuesBench6.sort(compareNumbers).reverse()


  const boxesData7 = [
    {  answersKeys: [311511, 311512, 311513],  benchmarkKey: 50,   rotateSecond: "-8deg",},
    {  answersKeys: [311521, 311522, 311523],  benchmarkKey: 51,   rotateSecond: "-1.5deg",},
    {  answersKeys: [311531, 311532, 311533],  benchmarkKey: 52,  rotateSecond: "1deg",},
    {  answersKeys: [311541, 311542, 311543],  benchmarkKey: 53,  rotateSecond: "7deg",},
    {  answersKeys: [311551, 311552, 311553],  benchmarkKey: 54,   rotateSecond: "11deg",},
  ];
  
  

  const answersValues7 = boxesData7.map((answer) =>
    answer.answersKeys.map((value) => answers?.[value] === undefined ? 0  : answers?.[value])
  );
  const values7 = answersValues7.map((ele) => ele[0] + ele[1] + ele[2] )


  const finalValue7 = values7.sort(compareNumbers).reverse()

  const answersValuesBench7 = boxesData7.map((answer) =>
    answer.benchmarkKey
  );
  const valuesBench7 = answersValuesBench7.map((ele) => benchmark?.[ele])

  const finalValueBench7 = valuesBench7.sort(compareNumbers).reverse()


  const boxesData8 = [
    { answersKeys: [312561, 312562, 312563], benchmarkKey: 55, rotate: "-7deg" },
    { answersKeys: [312571, 312572, 312573], benchmarkKey: 56, rotate: "-3deg" },
    { answersKeys: [312581, 312582, 312583], benchmarkKey: 57, rotate: "2deg" },
    { answersKeys: [312591, 312592, 312593], benchmarkKey: 58, rotate: "7deg" },
    { answersKeys: [312601, 312602, 312603], benchmarkKey: 59, rotate: "11deg" },
  ];
  
  

  const answersValues8 = boxesData8.map((answer) =>
    answer.answersKeys.map((value) => answers?.[value] === undefined ? 0  : answers?.[value])
  );
  const values8 = answersValues8.map((ele) => ele[0] + ele[1] + ele[2] )


  const finalValue8 = values8.sort(compareNumbers).reverse()

  const answersValuesBench8 = boxesData8.map((answer) =>
    answer.benchmarkKey
  );
  const valuesBench8 = answersValuesBench8.map((ele) => benchmark?.[ele])

  const finalValueBench8 = valuesBench8.sort(compareNumbers).reverse()


  const boxesData9 = [
    { answersKeys: [413611, 413612, 413613], benchmarkKey: 60, rotate: "-5deg" },
    { answersKeys: [413621, 413622, 413623], benchmarkKey: 61, rotate: "-3deg" },
    { answersKeys: [413631, 413632, 413633], benchmarkKey: 62, rotate: "3deg" },
    { answersKeys: [413641, 413642, 413643], benchmarkKey: 63, rotate: "6deg" },
    { answersKeys: [413651, 413652, 413653], benchmarkKey: 64, rotate: "9deg" },
  ];
  

  const answersValues9 = boxesData9.map((answer) =>
    answer.answersKeys.map((value) => answers?.[value] === undefined ? 0  : answers?.[value])
  );
  const values9 = answersValues9.map((ele) => ele[0] + ele[1] + ele[2] )


  const finalValue9 = values9.sort(compareNumbers).reverse()

  const answersValuesBench9 = boxesData9.map((answer) =>
    answer.benchmarkKey
  );
  const valuesBench9 = answersValuesBench9.map((ele) => benchmark?.[ele])

  const finalValueBench9 = valuesBench9.sort(compareNumbers).reverse()

  const boxesData10 = [
    { answersKeys: [414661, 414662, 414663], benchmarkKey: 65, rotate: "-5deg", top: "-8px", right: "10px" },
    { answersKeys: [414671, 414672, 414673], benchmarkKey: 66, rotate: "-2deg", top: "-6px", right: "11px" },
    { answersKeys: [414681, 414682, 414683], benchmarkKey: 67, rotate: "3deg", top: "-3px", right: "12px" },
    { answersKeys: [414691, 414692, 414693], benchmarkKey: 68, rotate: "7deg", top: "-4px", right: "12px" },
    { answersKeys: [414701, 414702, 414703], benchmarkKey: 69, rotate: "11deg", top: "-5.5px", right: "13px" },
  ];
  

  const answersValues10 = boxesData10.map((answer) =>
    answer.answersKeys.map((value) => answers?.[value] === undefined ? 0  : answers?.[value])
  );
  const values10 = answersValues10.map((ele) => ele[0] + ele[1] + ele[2] )


  const finalValue10 = values10.sort(compareNumbers).reverse()

  const answersValuesBench10 = boxesData10.map((answer) =>
    answer.benchmarkKey
  );
  const valuesBench10 = answersValuesBench10.map((ele) => benchmark?.[ele])

  const finalValueBench10 = valuesBench10.sort(compareNumbers).reverse()

  const boxesData11 = [
    { answersKeys: [415711, 415712, 415713], benchmarkKey: 70, rotateTop: "-9deg", rotateBottom: "-8deg" },
    { answersKeys: [415721, 415722, 415723], benchmarkKey: 71, rotateTop: "-3deg", rotateBottom: "-2deg" },
    { answersKeys: [415731, 415732, 415733], benchmarkKey: 72, rotateTop: "1deg", rotateBottom: "0deg" },
    { answersKeys: [415741, 415742, 415743], benchmarkKey: 73, rotateTop: "6deg", rotateBottom: "7deg" },
    { answersKeys: [415751, 415752, 415753], benchmarkKey: 74, rotateTop: "9deg", rotateBottom: "11deg" }
  ];
  

  const answersValues11 = boxesData11.map((answer) =>
    answer.answersKeys.map((value) => answers?.[value] === undefined ? 0  : answers?.[value])
  );
  const values11 = answersValues11.map((ele) => ele[0] + ele[1] + ele[2] )


  const finalValue11 = values11.sort(compareNumbers).reverse()

  const answersValuesBench11 = boxesData11.map((answer) =>
    answer.benchmarkKey
  );
  const valuesBench11 = answersValuesBench11.map((ele) => benchmark?.[ele])

  const finalValueBench11 = valuesBench11.sort(compareNumbers).reverse()


  const boxesData12 = [
    { answersKeys: [416761, 416762, 416763], benchmarkKey: 75, rotate: "-5deg", top: "-8px", right: "10px" },
    { answersKeys: [416771, 416772, 416773], benchmarkKey: 76, rotate: "-1deg", top: "-6px", right: "10.5px" },
    { answersKeys: [416781, 416782, 416783], benchmarkKey: 77, rotate: "3deg", top: "-3px", right: "12px" },
    { answersKeys: [416791, 416792, 416793], benchmarkKey: 78, rotate: "9deg", top: "-4px", right: "12px" },
    { answersKeys: [416801, 416802, 416803], benchmarkKey: 79, rotate: "12deg", top: "-7px", right: "12.5px" },
  ];

  const answersValues12 = boxesData12.map((answer) =>
    answer.answersKeys.map((value) => answers?.[value] === undefined ? 0  : answers?.[value])
  );
  const values12 = answersValues12.map((ele) => ele[0] + ele[1] + ele[2] )


  const finalValue12 = values12.sort(compareNumbers).reverse()

  const answersValuesBench12 = boxesData12.map((answer) =>
    answer.benchmarkKey
  );
  const valuesBench12 = answersValuesBench12.map((ele) => benchmark?.[ele])

  const finalValueBench12 = valuesBench12.sort(compareNumbers).reverse()


  const boxesData13 = [
    { answersKeys: [1111, 1112, 1113], benchmarkIndex: 0, rotate: "-5deg", position: { right: "10px", top: "-8px" } },
    { answersKeys: [1121, 1122, 1123], benchmarkIndex: 1, rotate: "-1deg", position: { right: "10.5px", top: "-6px" } },
    { answersKeys: [1131, 1132, 1133], benchmarkIndex: 2, rotate: "3deg", position: { right: "12px", top: "-2px" } },
    { answersKeys: [1141, 1142, 1143], benchmarkIndex: 3, rotate: "8deg", position: { right: "12px", top: "-4px" } },
    { answersKeys: [1151, 1152, 1153], benchmarkIndex: 4, rotate: "12deg", position: { right: "12.5px", top: "-7px" } }
  ];


  const answersValues13 = boxesData13.map((answer) =>
    answer.answersKeys.map((value) => answers?.[value] === undefined ? 0  : answers?.[value])
  );
  const values13 = answersValues13.map((ele) => ele[0] + ele[1] + ele[2] )


  const finalValue13 = values13.sort(compareNumbers)

  const answersValuesBench13 = boxesData13.map((answer) =>
    answer.benchmarkIndex
  );
  const valuesBench13 = answersValuesBench13.map((ele) => benchmark?.[ele])

  const finalValueBench13 = valuesBench13.sort(compareNumbers)


  const boxesData14 = [
    { answersKeys: [1261, 1262, 1263], benchmarkIndex: 5, rotate: "-5deg"},
    { answersKeys: [1271, 1272, 1273], benchmarkIndex: 6, rotate: "-1deg", },
    { answersKeys: [1281, 1282, 1283], benchmarkIndex: 7, rotate: "3deg", },
    { answersKeys: [1291, 1292, 1293], benchmarkIndex: 8, rotate: "8deg"},
    { answersKeys: [12101, 12102, 12103], benchmarkIndex: 9, rotate: "11deg", },
  ];

  const answersValues14 = boxesData14.map((answer) =>
    answer.answersKeys.map((value) => answers?.[value] === undefined ? 0  : answers?.[value])
  );
  const values14 = answersValues14.map((ele) => ele[0] + ele[1] + ele[2] )


  const finalValue14 = values14.sort(compareNumbers)

  const answersValuesBench14 = boxesData14.map((answer) =>
    answer.benchmarkIndex
  );
  const valuesBench14 = answersValuesBench14.map((ele) => benchmark?.[ele])

  const finalValueBench14 = valuesBench14.sort(compareNumbers)
 
 
  const boxesData15 = [
    {answersKeys: [13111, 13112, 13113], benchmarkKey: 10, rotate: "-5deg", },
    {answersKeys: [13121, 13122, 13123], benchmarkKey: 11, rotate: "-3deg", },
    {answersKeys: [13131, 13132, 13133], benchmarkKey: 12, rotate: "3deg",  },
    {answersKeys: [13141, 13142, 13143], benchmarkKey: 13, rotate: "6deg",  },
    {answersKeys: [13151, 13152, 13153], benchmarkKey: 14, rotate: "12deg", }
  ];
  

  const answersValues15 = boxesData15.map((answer) =>
    answer.answersKeys.map((value) => answers?.[value] === undefined ? 0  : answers?.[value])
  );
  const values15 = answersValues15.map((ele) => ele[0] + ele[1] + ele[2] )


  const finalValue15 = values15.sort(compareNumbers)

  const answersValuesBench15 = boxesData15.map((answer) =>
    answer.benchmarkKey
  );
  const valuesBench15 = answersValuesBench15.map((ele) => benchmark?.[ele])

  const finalValueBench15 = valuesBench15.sort(compareNumbers)
  
  
  const boxesData16 = [
    { answersKeys: [14161, 14162, 14163], benchmarkKey: 15, rotate: "-5deg" },
    { answersKeys: [14171, 14172, 14173], benchmarkKey: 16, rotate: "0deg" },
    { answersKeys: [14181, 14182, 14183], benchmarkKey: 17, rotate: "5deg" },
    { answersKeys: [14191, 14192, 14193], benchmarkKey: 18, rotate: "9deg" },
    { answersKeys: [14201, 14202, 14203], benchmarkKey: 19, rotate: "12deg" },
  ];

  const answersValues16 = boxesData16.map((answer) =>
    answer.answersKeys.map((value) => answers?.[value] === undefined ? 0  : answers?.[value])
  );
  const values16 = answersValues16.map((ele) => ele[0] + ele[1] + ele[2] )


  const finalValue16 = values16.sort(compareNumbers)

  const answersValuesBench16 = boxesData16.map((answer) =>
    answer.benchmarkKey
  );
  const valuesBench16 = answersValuesBench16.map((ele) => benchmark?.[ele])

  const finalValueBench16 = valuesBench16.sort(compareNumbers)

  return { 
    finalValue,
    finalValueBench,
    finalValue2,
    finalValueBench2,
    finalValue3,
    finalValueBench3,
    finalValue4,
    finalValueBench4,
    finalValue5,
    finalValueBench5,
    finalValue6,
    finalValueBench6,
    finalValue7,
    finalValueBench7,
    finalValue8,
    finalValueBench8,
    finalValue9,
    finalValueBench9,
    finalValue10,
    finalValueBench10,
    finalValue11,
    finalValueBench11,
    finalValue12,
    finalValueBench12,
    finalValue13,
    finalValueBench13,
    finalValue14,
    finalValueBench14,
    finalValue15,
    finalValueBench15,
    finalValue16,
    finalValueBench16,
   };
};
