import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, Grid, TextField, Typography, Autocomplete, useMediaQuery, CircularProgress } from '@mui/material';
import ApiContext from '../../../../Context/context';

export default function AddTeamForm({ setFetchEditorsTrigger, open, setOpen }) {
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    role_id: null,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const { roles } = React.useContext(ApiContext);
  const isMobile = useMediaQuery('(max-width:600px)');

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle role selection
  const handleRoleChange = (event, newValue) => {
    setFormData((prev) => ({ ...prev, role_id: newValue ? newValue.id : null }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!formData.name || !formData.email || !formData.role_id) {
      setError('Please fill all required fields.');
      return;
    }

    const payload = {
      name: formData.name,
      email: formData.email,
      role_id: formData.role_id,
    };

    setIsLoading(true);
    setError('');

    try {
      const response = await fetch('https://api.twindix.com/editors', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.token,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Error: ' + response.statusText);
      }

      const data = await response.json();
      setFetchEditorsTrigger((prev) => prev + 1);
      console.log('Response:', data);
      setOpen(false); // Close the dialog on successful submission
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('Failed to submit form. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          position: isMobile ? 'fixed' : 'relative',
          left: isMobile ? 60 : 'auto',
          margin: 0,
          height: isMobile ? '100%' : 'auto',
          borderRadius: isMobile ? 0 : '8px',
          width: isMobile ? 'auto' : '450px',
        },
      }}
      maxWidth={isMobile ? 'sm' : 'md'}
      open={open}
      onClose={handleClose}
    >
      <Box sx={{ bgcolor: '#929292', height: isMobile ? '100%' : 'auto' }}>
        <DialogContent
          sx={{
            maxHeight: '500px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': { width: '6px' },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '10px',
              background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
            },
            '&::-webkit-scrollbar-track': { background: '#929292', borderRadius: '10px' },
          }}
        >
          <Grid container direction="column" spacing={4}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>
                Name:
              </Typography>
              <TextField
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Insert name"
                fullWidth
                sx={{ backgroundColor: 'white', borderRadius: 1 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>
                Email:
              </Typography>
              <TextField
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Insert email"
                fullWidth
                sx={{ backgroundColor: 'white', borderRadius: 1 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>
                Role:
              </Typography>
              <Autocomplete
                options={roles}
                getOptionLabel={(option) => option.name || ''}
                value={roles.find((role) => role.id === formData.role_id) || null}
                onChange={handleRoleChange}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select role" sx={{ backgroundColor: 'white', borderRadius: 1 }} />
                )}
              />
            </Grid>
          </Grid>
          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ background: 'linear-gradient(45deg, #2c6264, #5ac4cb)', borderRadius: '10px', margin: 'auto' }}
            onClick={handleSubmit}
            variant="contained"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Send Member'}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}