import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

const DeleteDialog = ({ open, handleClose, DialogTitle, DialogDeleteContent, DeleteFun, error }) => {
  return (
    <Dialog
      open={open}
      maxWidth={'lg'}
      onClose={handleClose} // Use handleClose instead of setOpenDelete
    >
      <Box sx={{ backgroundColor: '#929292', width: '450px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Typography component="h5" variant="h5" sx={{ color: 'white', width: 'auto', margin: '12px', textDecoration: "underline" }}>
          Delete {DialogTitle}
        </Typography>
        <DialogContent>
          <Typography component="span" variant="span" sx={{ color: 'white' }}>
            Are You Sure You Want To Delete {DialogDeleteContent}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
              borderRadius: '10px',
              margin: 'auto',
            }}
            onClick={handleClose} // Use handleClose instead of setOpenDelete
          >
            No
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: 'white',
              background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
              borderRadius: '10px',
              margin: 'auto',
            }}
            onClick={DeleteFun}
          >
            Yes
          </Button>
        </DialogActions>
        {error && (
          <Typography color="error">
            {error}
          </Typography>
        )}
      </Box>
    </Dialog>
  );
};

export default DeleteDialog;