import { Chip, Stack, useMediaQuery } from "@mui/material";

function BigChips({ userName, finishedAssessments, pendingAssessments }) {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Stack mt={4} mb={isMobile ? 2 : 4} direction={isMobile ? 'column' : 'row'} justifyContent={isMobile ? 'center' : 'left'} alignItems='center'>
      <Stack>
        <Chip
          sx={{
            width: '100%',
            mr: 2,
            padding: '30px 70px',
            borderRadius: "140px",
            border: '3px solid #3e7e72',
            color: '#3e7e72',
            fontSize: "18px",
            fontWeight: '700'
          }}
          label={userName}
          variant="outlined"
        />
      </Stack>
      <Stack justifyContent='center' alignItems='center' width={'100%'} direction={isMobile ? "column" : 'row'}>
        {finishedAssessments && (
          <Chip
            sx={{
              mt: isMobile ? '10px' : 0, // Fixed: Use ternary operator
              width: isMobile ? '70%' : 'calc(100% / 2)',
              ml: isMobile ? 0 : 2,
              background: "linear-gradient(45deg, #77c853, #347461)",
              color: 'white',
              padding: isMobile ? '0' : '30px 70px',
              fontSize: isMobile ? '12px' : "18px",
              fontWeight: '700',
              borderRadius: "140px"
            }}
            label={`${finishedAssessments} Available Assessments`}
          />
        )}
        {pendingAssessments && (
          <Chip
            sx={{
              mt: isMobile ? '10px' : 0, // Fixed: Use ternary operator
              width: isMobile ? '70%' : 'calc(100% / 2)',
              ml: isMobile ? 0 : 2,
              background: "linear-gradient(45deg, #ad301a, #d87631)",
              color: 'white',
              padding: isMobile ? '0' : '30px 70px',
              fontSize: isMobile ? '12px' : "18px",
              fontWeight: '700',
              borderRadius: "140px"
            }}
            label={`${pendingAssessments} Pending Assessments`}
          />
        )}
      </Stack>
    </Stack>
  );
}

export default BigChips;