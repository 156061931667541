import { useEffect, useState } from "react"
import axios from "axios"
import Badel from "../../../../Media/imges/WhatsApp Image 2024-01-09 at 12.28.12_84c2f948.jpg"
import { Box, useMediaQuery } from "@mui/material";
import CouponDialog from "./Form/couponDiaolg";
import "./Payment.css"

function PaymentCom ({setReFetch}) {

  const isMobile = useMediaQuery("(max-width:600px)");
  
  const [exams, setExams] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [specifiedPackage, setSpecifiedPackage] = useState([])

    useEffect(() => {
        if(exams == null) {
            axios.get("https://api.twindix.com/exams",{headers:{
                Authorization: "Bearer " + localStorage.token
            }, params:{
            }}).then(res => {
                console.log(res.data.payload.data);
                setExams(res.data.payload.data);
            })

        }
    }, [exams])
      
    const specifiedPackageFun = (packId) => {
        axios
          .get(
            `https://api.twindix.com/exams/${packId}`)
          .then((res) => {
            setSpecifiedPackage(res?.data?.payload)
          })
      };    

    return (
        <Box sx={{ padding: isMobile ? '10px' : '20px' }}>
            <div className="PayRightSection" >
                <div className="PaymentContaner">
                        <div className="exams">
                        {exams && exams.map((exam, index) => {
                          return (
                                  <div
                                      key={exam.id} 
                                      className="ExamContaner exam"
                                      style={index % 2 === 0 ? { flexDirection: isMobile ? 'column' :  "row", marginBottom:'10px' } : { flexDirection: isMobile ?"column" : "row-reverse", marginBottom:'10px' }}
                                  >
                                      <div>
                                          <img
                                              className="ExamImage"
                                              src={exam.cover_image ? exam.cover_image : Badel}
                                              alt="exam cover"
                                          />
                                      </div>
                                      <div>
                                          <h2 className="ExamTitle" style={{height : isMobile && '6%' }}>{exam.title}</h2>
                                          <Box component='span'  height='75%' textAlign='justify'  fontSize={isMobile ? '12px' : '18px'} fontWeight={isMobile ? '600px' : '400'} sx={{color: '#929292', letterSpacing: '-1px', lineHeight: '25px'}} variant="body2">
                                              {window.innerWidth < 750 ? exam.description.slice(0, 270) : exam.description.slice(0, 370)}
                                              {window.innerWidth < 750 ? "..." : ""}
                                          </Box>
                                          <div className="PriceAndBtncon" style={{width : isMobile && '110%'}}>
                                              <div className="PriceAndBtn">
                                                  <span>{exam.price} $</span>
                                                  <div style={{margin :'auto'}}>
                                                      <button className="ExamBtn" onClick={() => {
                                                              specifiedPackageFun(exam.id);
                                                              setOpenEditDialog(true);
                                                          }}>
                                                          Buy The Assessments
                                                      </button>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                          );
                      }) }
                        </div>
                </div>
        </div>
    <CouponDialog 
      openEditDialog={openEditDialog}
      handleCloseEditDialog={() => setOpenEditDialog(false)}
      isMobile={isMobile}
      setReFetch={setReFetch}
      specifiedPackage={specifiedPackage}
    />
        </Box>
    )
}

export default PaymentCom