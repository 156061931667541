import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import ManCode from '.';
import { Box } from '@mui/material';
import { appContext } from '../../../App';

export const repoCon = createContext(null);

 const ManCodePDFs = ({name, date, lang}) => {
  const [referencess, setReferences] = useState();
  const [answers, setAnswers] = useState();
    let [enrolmentId, setenrolmentId] = useState("");
    const [benchmark, setBenchmark] = useState([])
    const [isBenchMark, setIsBenchMark] = useState()
    const [enroll, setEnroll] = useState();

    const isLoggedIn = useContext(appContext);
    
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const enroId = searchParams.get('enro_id');
    const examId = searchParams.get('exam_id');
    const isAdminPara = searchParams.get('isAdmin');
    setenrolmentId(enroId);
    if (isAdminPara == 1) {
      axios
        .get('https://api.twindix.com/dashboard/exams/' + examId, {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        })
        .then((res) => {
          // console.log(res.data);
          setReferences(res.data.payload.template.references);
        });
      axios
        .get('https://api.twindix.com/dashboard/enrollments/' + enroId, {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        })
        .then((res) => setAnswers(res.data.payload.answers));
    } else {
      axios
        .get('https://api.twindix.com/exams/' + examId, {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        })
        .then((res) => {
          console.log(res)
          setReferences(res.data.payload.template.references);
        });
      axios
        .get('https://api.twindix.com/enrollments/' + enroId, {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        })
        .then((res) =>{
          setAnswers(res.data.payload.answers)
          if(isLoggedIn?.userData?.type === 2){
            setBenchmark([])
          }else{
            setBenchmark(res?.data?.payload?.benchmarks[0].benchmark)
          }
          if(benchmark.length > 1) setIsBenchMark(true)
            else setIsBenchMark(false)
        });
    }
  }, []);

 
  useEffect(() => {
    if(!enroll) {
      axios.get("https://api.twindix.com/enrollments", {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
      setEnroll(res.data.payload.data);
      
    })
  }

}, [enroll, ])


  return (
    <Box sx={{width :'100%'}}>
        <ManCode lang={lang} isBenchMark={isBenchMark} name={name} date={date} benchmark={benchmark} answers={answers} referencess={referencess} />
    </Box>
  );
};


export default ManCodePDFs;


