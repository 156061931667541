import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography, CircularProgress } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import ApiContext from "../../../../Context/context";
import axios from "axios";

export default function EditTeamMember({
  isMobile,
  openEditDialog,
  handleCloseEditDialog,
  editorId, // Pass the editor ID instead of the editor object
  setFetchEditorsTrigger,
}) {
  const [currentEditor, setCurrentEditor] = useState({ 
    name: '',
    email: '',
    role_id: null 
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false); // Loading state for fetching editor data
  const [error, setError] = useState('');
  const { roles } = useContext(ApiContext);
  // Fetch editor data when the dialog is opened
  useEffect(() => {
    if (openEditDialog && editorId) {
      const fetchEditorData = async () => {
        setIsFetching(true);
        setError('');

        try {
          const response = await axios.get(
            `https://api.twindix.com/editors/${editorId}`,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.token,
              },
            }
          );

          const editorData = response.data.payload;
          setCurrentEditor({
            name: editorData.name ?? '',
            email: editorData.email ?? '',
            role_id: editorData.role?.id ?? null,
          });
        } catch (error) {
          console.error('Error fetching editor data:', error);
          setError('Failed to fetch editor data. Please try again.');
        } finally {
          setIsFetching(false);
        }
      };

      fetchEditorData();
    }
  }, [openEditDialog, editorId]);

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentEditor((prev) => ({ ...prev, [name]: value }));
  };

  // Handle role selection
  const handleRoleChange = (event, newValue) => {
    setCurrentEditor((prev) => ({ ...prev, role_id: newValue ? newValue.id : null }));
  };

  // Handle form submission
  const handleSaveEdit = async () => {
    if (!currentEditor.name || !currentEditor.email || !currentEditor.role_id) {
      setError('Please fill all required fields.');
      return;
    }

    const updatedEditor = {
      ...currentEditor,
      role_id: currentEditor.role_id,
    };

    setIsLoading(true);
    setError('');

    try {
      const response = await axios.put(
        `https://api.twindix.com/editors/${editorId}`,
        updatedEditor,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        }
      );

      if (response.status === 200) {
        handleCloseEditDialog();
        setFetchEditorsTrigger((prev) => prev + 1); // Trigger refetch
      }
    } catch (error) {
      console.error('Error saving editor details:', error);
      setError('Failed to save changes. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          position: isMobile ? 'fixed' : 'relative',
          left: isMobile ? 50 : 'auto',
          margin: 0,
          height: isMobile ? '100%' : 'auto',
          borderRadius: isMobile ? 0 : '8px',
          width: isMobile ? 'auto' : '450px',
        },
      }}
      maxWidth={isMobile ? 'sm' : 'md'}
      open={openEditDialog}
      onClose={handleCloseEditDialog}
    >
      <Box sx={{ bgcolor: '#929292', height: isMobile ? '100%' : 'auto' }}>
        <DialogContent sx={{ maxHeight: '500px', overflowY: 'auto' }}>
          {isFetching ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
              <CircularProgress />
            </Box>
          ) : (
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>
                  Name:
                </Typography>
                <TextField
                  name="name"
                  value={currentEditor.name}
                  onChange={handleInputChange}
                  placeholder="Editor Name"
                  fullWidth
                  sx={{ backgroundColor: 'white', borderRadius: 1 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>
                  Email:
                </Typography>
                <TextField
                  name="email"
                  value={currentEditor.email}
                  onChange={handleInputChange}
                  placeholder="Editor Email"
                  fullWidth
                  sx={{ backgroundColor: 'white', borderRadius: 1 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white' }}>
                  Role:
                </Typography>
                <Autocomplete
                  options={roles}
                  getOptionLabel={(option) => option.name || ''}
                  value={roles.find((role) => role.id === currentEditor.role_id) || null}
                  onChange={handleRoleChange}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select role" sx={{ backgroundColor: 'white', borderRadius: 1 }} />
                  )}
                />
              </Grid>
            </Grid>
          )}
          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSaveEdit}
            variant="contained"
            sx={{ background: 'linear-gradient(45deg, #2c6264, #5ac4cb)' }}
            disabled={isLoading || isFetching}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Save Changes'}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}