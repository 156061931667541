import { Button, Checkbox, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import axios from "axios";
import SendIcon from '@mui/icons-material/Send';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useContext } from "react";
import ApiContext from "../../../Context/context";

export default function EmployeeTable ({
            setGetSpecEmp,
            setUserId,
            setOpenEditForm,
            setEmployeeId,
            setOpenBench,
            selectedEmployees,
            setSelectedEmployees,
            allSelected,
            handleSelectAll,
            employees,
            handleOpenReminder,
            setDialogContentReminder
    }) {
      const {
        handelPermission2
      } = useContext(ApiContext);

        handelPermission2(["employee-show", 'employee-create', 'exam-invite'])

        const handleSelectRow = (id) => {
        
          if (selectedEmployees.includes(id)) {
            setSelectedEmployees(selectedEmployees.filter((empId) => empId !== id));
          } else {
            setSelectedEmployees([...selectedEmployees, id]);
          }
        };
        const handleGetSpecEmp = async (id) => {
            try {
                // Fetch specific employee details
                const res = await axios.get(`https://api.twindix.com/employees/${id}`, {
                    headers: { Authorization: `Bearer ${localStorage.token}` },
                });
                    const employee = res.data.payload;
                    setGetSpecEmp(employee);
                } catch (error) {
                    console.error("Error fetching employee:", error);
                }
        };
      
    return (
        <TableContainer>
        <Table aria-label="Employees Table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={allSelected}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
        <TableBody>
          {employees?.map((user) => {
              return (
                <TableRow key={user.user_id}>
                  <TableCell>
                    <Checkbox
                      checked={selectedEmployees?.includes(user.user_id)}
                      onChange={() => handleSelectRow(user.user_id)}
                    />
                    </TableCell>
                    <TableCell>{user.user_name}</TableCell>
                    <TableCell>{user.user_email}</TableCell>
                    <TableCell>{user.user_phone ?? "There is no phone"}</TableCell>
                    <TableCell>
                      <Button
                      id="employee-update"
                        variant="contained"
                        sx={{ width: "100px", padding: "10px 0", borderRadius: "14px", background: "#2b6566" }}
                        onClick={() => {
                            setOpenEditForm(true);
                            setEmployeeId(user.user_id);
                            handleGetSpecEmp(user.user_id);
                        }}
                      >
                        Edit
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={2}>
                      {user?.has_enrollment === false ?
                        <Button
                        id="exam-invite"
                          variant="contained"
                          sx={{ width: "200px", padding: "10px 0", borderRadius: "14px", background: "#2b6566" }}
                          onClick={() => {
                              setUserId(user.user_id);
                              setOpenBench();
                          }}
                        >
                          <SendIcon sx={{ marginRight: 1 }} />
                          Send Assessment
                        </Button>                      
                      :
                      <Button
                          variant="contained"
                          sx={{ width: "200px", padding: "10px 0", borderRadius: "14px", background: "#2b6566" }}
                          onClick={() => {
                            setDialogContentReminder(`Are you sure you want to send an Reminder to ${user.user_name} ?`)
                            handleOpenReminder();
                          }}
                        >
                          <NotificationsIcon sx={{ marginRight: 1 }} />
                          Send Reminder
                        </Button>                      
                      }
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
    </TableContainer>
    )
}