import { Box } from "@mui/material"; 
import { useState } from "react";
import MangeInd from "./Component/MangeTwindixAssessments/MangeInd";
import TwindixCompany from "./Component/MangeTwindixAssessments/TwindixCompany";
import AddCompanyForm from "../ShareHr/AddCompanyForm";

function MangeTwindixAssessments({setshowBackBtn}) {

  const [open, setOpen] = useState(false);
  const [showUsers, setShowUser] = useState(false);
  const [company, setCompany] = useState([]);

  return (
    <Box>
      {showUsers ? (
        <>
          <MangeInd setShowUser={setShowUser} company={company} />
        </>
      ) : (
        <TwindixCompany 
          setShowBackBtn={setshowBackBtn}
          showUsers={showUsers}
          setOpen={setOpen}
          setCompany={setCompany}
          setShowUser={setShowUser}
        />
      )}
      <AddCompanyForm exam_id={1} open={open} setOpen={setOpen} />
    </Box>
  );
}

export default MangeTwindixAssessments;
