import {Stack, Typography,useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ApiContext from "../Context/context";
import { useSearch } from "../Context/SearchContext";
import TwindexEmployee from "./Component/AccessTwindixIndivdual/TwindexEmployee";
import LoadingSpinner from "../../../../FemilerCom/loading/spinner";

function AccessTwindixIndivdual({
  setshowBackBtn,
}) {
  const { companies, setShouldFetchCompanies, loading, handelPermission } = useContext(ApiContext);
  const isHasPermission = handelPermission('company-show')

  const isMobile = useMediaQuery("(max-width:600px)");

  const { searchQuery } = useSearch();
  const [showUser, setShowUser] = useState(false);
  const [company, setCompany] = useState(false);
  
    const filteredCompanies = companies?.filter((product) =>
    product.name?.toLowerCase().includes(searchQuery?.toLowerCase())
    );
    
    useEffect(() => {
      if(setShouldFetchCompanies && isHasPermission) {
        setShouldFetchCompanies(true)
      }
    }, [setShouldFetchCompanies])
  
    useEffect(() => {
      setshowBackBtn(true)
    }, [showUser, setshowBackBtn]); 

  return (
    <>
      {showUser ? (
          <TwindexEmployee 
            setShowUser={setShowUser}
            setShowBackBtn={setshowBackBtn}
            company={company}
          />
      ) :(
      <Stack sx={isMobile ? { overflow : 'scroll'} : {}}>
      {loading && <LoadingSpinner />}
        {companies.length > 0 ? (
          filteredCompanies
            ?.filter((ele) => ele.exam_id === 1)
            .map((company) => {
              return (
                <Stack
                  key={company.id}
                  sx={{width : isMobile ? '850px' : '100%', borderBottom: "2px solid #5d5d5d" }}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  p={1}
                  m={3}
                >
                  <Typography
                    sx={{
                      width: "160px",
                      cursor: "pointer",
                      color: "#5d5d5d",
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      setCompany(company)
                      setShowUser(true);
                    }}
                  >
                    {company.name}
                  </Typography>
                  <Typography
                    sx={{
                      width: "150px",
                      color: "#5e5e5e",
                      textDecoration: "underline",
                    }}
                  >
                    {company.phone}
                  </Typography>
                  <Typography
                    sx={{
                      width: "220px",
                      color: "#5e5e5e",
                      textDecoration: "underline",
                    }}
                  >
                    {company.mail}
                  </Typography>
                  <Typography
                    sx={{
                      width: "100px",
                      color: "#5e5e5e",
                      textDecoration: "underline",
                    }}
                  >
                    {company.updated_at.slice(0, 10)}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#3f8e8f",
                      border: "2.5px solid #3f8e8f",
                      borderRadius: "12px",
                      padding: "6px",
                      fontWeight: "bold",
                    }}
                  >
                    {company.total_exam_count}  Reports
                  </Typography>
                </Stack>
              );
            })
        ) : (
          "There Is No Enrollments Yet"
        )}
      </Stack>
    )
      }
    </>
  );
}

export default AccessTwindixIndivdual;
