import { Box, Stack, Typography } from '@mui/material';
import P1 from '../../../Media/ManCode/P1.jpg';
import P2 from '../../../Media/ManCode/P2.jpg';
import P2en from '../../../Media/ManCode/P2-en-no.jpg';
import P3 from '../../../Media/ManCode/P3.jpg';
import P4 from '../../../Media/ManCode/P4.jpg';
import P7 from '../../../Media/ManCode/P7.jpg';
import P8 from '../../../Media/ManCode/P8.jpg';
import P9 from '../../../Media/ManCode/P9.jpg';
import req from '../../../Media/ManCode/Req-ar.jpg';
import reqen from '../../../Media/ManCode/P3-Req-Btn-en.jpg';
import p4_Act from '../../../Media/ManCode/P4-Actual.jpg'
import p4_Req from '../../../Media/ManCode/P4-Req.jpg'


import { FirstSection, ForthSection, Header, Page, ScoSection, ThirdSection } from './Share';
import '../CSS/GlopalReport.css'
import { useManCode1 } from './Data1';
import { useCircle } from './circle';

function Firstpart({name, date, benchmark, referencess, answers, isBenchMark, lang}) {


  const radius = 460; // Radius of the circle
  const items = 16; // Total number of child stacks
  const angleStep = (2 * Math.PI) / items; // Angle increment for each child stack

  const {
    firstBox,
    firstBox2,
    firstBox3,
    firstBox4,
    firstBox5,
    firstBox6,
    firstBox7,
    firstBox8,
    firstBox9,
    firstBox10,
    firstBox11,
    firstBox12,
    firstBox13,
    firstBox14,
    firstBox15,
    firstBox16,
    sortedBoxes1,
    sortedBoxes2,
    sortedBoxes,
    sortedBoxesbench,
    sortedBoxes1bench,
    sortedBoxes2bench,
  } = useManCode1({benchmark, referencess, answers, isBenchMark, lang})

  const {
    finalValue,
    finalValueBench,
    finalValue2,
    finalValueBench2,
    finalValue3,
    finalValueBench3,
    finalValue4,
    finalValueBench4,
    finalValue5,
    finalValueBench5,
    finalValue6,
    finalValueBench6,
    finalValue7,
    finalValueBench7,
    finalValue8,
    finalValueBench8,
    finalValue9,
    finalValueBench9,
    finalValue10,
    finalValueBench10,
    finalValue11,
    finalValueBench11,
    finalValue12,
    finalValueBench12,
    finalValue13,
    finalValueBench13,
    finalValue14,
    finalValueBench14,
    finalValue15,
    finalValueBench15,
    finalValue16,
    finalValueBench16,
  } = useCircle({benchmark, answers})
  
  const isEng = lang ==='en'
  
  return (
    <Box sx={{direction: 'rtl'}}>
        <Page srcPage={P1}>
          <Header lang={lang} name={name} date={date} headerTitle={referencess?.[0]?.[lang === 'en' ? 'en content' : 'ar content']}          />
        <Stack width='95%' mr="35px" mt="33px" height='81.5%'>
          <Box sx={isEng &&{direction : 'ltr'}} fontSize={isEng ? '20px' :'23px'} width='98%'   mr="10px" height="230px">{referencess?.[1]?.[lang === 'en' ? 'en content' : 'ar content']} {referencess?.[2]?.[lang === 'en' ? 'en content' : 'ar content']}</Box>
          <Stack  width='100%' mt='39.6px' height='73.5%' spacing={2}>
              <Stack height='calc(100% / 4)' mb={0} direction='row' width='100%' >
                <Stack height='96%' fontWeight='bold' fontSize='40px'  color='white' justifyContent='center' alignItems='center' width='30.5%'>{referencess?.[3]?.[lang === 'en' ? 'en content' : 'ar content']}</Stack>
                <Stack sx={isEng &&{direction : 'ltr'}} fontSize={isEng ? '20px' :'23px'} justifyContent='start' alignItems='center' height='96%' width='67%' pr='10px'>{referencess?.[4]?.[lang === 'en' ? 'en content' : 'ar content']}</Stack>
              </Stack>
              <Stack height='calc(100% / 4)' mb={0} direction='row' width='100%' >
                <Stack height='96%' fontWeight='bold' fontSize='40px'  color='white' justifyContent='center' alignItems='center' sx={{color :'black'}} width='30.5%'>{referencess?.[5]?.[lang === 'en' ? 'en content' : 'ar content']}</Stack>
                <Stack   sx={isEng &&{direction : 'ltr'}} fontSize={isEng ? '20px' :'23px'} justifyContent='center' alignItems='center' height='96%' width='67%' pr='10px'>{referencess?.[6]?.[lang === 'en' ? 'en content' : 'ar content']}</Stack>
              </Stack>
              <Stack height='calc(100% / 4)'  mb={0} direction='row' width='100%' >
                <Stack height='96%' fontWeight='bold' fontSize='40px'  color='white' justifyContent='center' alignItems='center' width='30.5%'>{referencess?.[7]?.[lang === 'en' ? 'en content' : 'ar content']}</Stack>
                <Stack  sx={isEng &&{direction : 'ltr'}} fontSize={isEng ? '20px' :'23px'} justifyContent='center' alignItems='center' height='96%' width='67%' pr='10px'>{referencess?.[8]?.[lang === 'en' ? 'en content' : 'ar content']}</Stack>
              </Stack>
              <Stack height='calc(100% / 4)' mb={0} direction='row' width='100%' >
                <Stack height='96%' fontWeight='bold' fontSize='40px'  color='white' justifyContent='center' alignItems='center' width='30.5%'>{referencess?.[9]?.[lang === 'en' ? 'en content' : 'ar content']}</Stack>
                <Stack   sx={isEng &&{direction : 'ltr'}} fontSize={isEng ? '20px' :'23px'} justifyContent='center' alignItems='center' height='96%' width='67%' pr='10px'>{referencess?.[10]?.[lang === 'en' ? 'en content' : 'ar content']}</Stack>
              </Stack>
          </Stack>
        </Stack>
        </Page>
      <Page srcPage={lang === 'en' ?  P2en: P2 }>
      <Header lang={lang} no={true} name={name} date={date} headerTitle={referencess?.[11]?.[lang === 'en' ? 'en content' : 'ar content']}/>
      <Box
      sx={{
        position: "relative",
        bottom: '0px',
        width: radius * 2,
        height: radius * 2,
        borderRadius: "50%",
        margin: "auto",
      }}
    >
    <Stack
      sx={{
        position: "absolute",
        top: "496px",
        left: "803px",
        rotate: "-80deg",
        width: '139px',
        height: '79px',
        color: "white",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
      }}
    >
    {finalValue.map((value, index) => {
  const benchValue = finalValueBench[index] ?? 0; // Get corresponding benchmark value

  return (
    <Box key={`final-${index}`} width={10} mt={1}>
      <Box
      position='relative'  
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '-9px' : index === 1 ? '-7px' : index === 2 ? '-7px' : index === 3 ? '-10px' : index === 4 ? '-14px' : '' ,
          right: index === 0 ? '10px' : index === 1 ? '7px' : index === 2 ? '4px' : index === 3 ? '1px' : index === 4 ? '-2px' : '' ,
          rotate: index === 0 ? '-5deg' : index === 1 ? '-1deg' : index === 2 ? '5deg' : index === 3 ? '6deg' : index === 4 ? '12deg' : '' ,
          bgcolor: value >= 2 ? "#f7e560" : "transparent",
          border: value < 2 ? "2px solid #f7e560" : "transparent",
        }}
      >
      </Box>
      <Box
      position='relative'  right='6px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '3px' : index === 1 ? '5px' : index === 2 ? '5px' : index === 3 ? '2px' : index === 4 ? '-4px' : '' ,
          right: index === 0 ? '4px' : index === 1 ? '6px' : index === 2 ? '7px' : index === 3 ? '9px' : index === 4 ? '11px' : '' ,
          rotate: index === 0 ? '-5deg' : index === 1 ? '-1deg' : index === 2 ? '3deg' : index === 3 ? '6deg' : index === 4 ? '11deg' : '' ,
          bgcolor: benchValue >= 1 ? "#f7e560" : "transparent",
          border: benchValue === 0 ? "2px solid #f7e560" : "transparent",
        }}
      >
      </Box>
    </Box>
  );
})}

    </Stack>
    <Stack
        sx={{
          position: "absolute",
          top: '652.034379px',
          left: '737.984585px',
          rotate: '-55deg',
          width: '139px',
          height: '79px',
          color: "white",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: 'row'
        }}
    >
    {finalValue2.map((value, index) => {
  const benchValue = finalValueBench2[index] ?? 0; // Get corresponding benchmark value

  return (
    <Box key={`final-${index}`} width={10} ml='19px' mt={1}>
      <Box
      position='relative'  top='-13px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '-14px' : index === 1 ? '-10px' : index === 2 ? '-9px' : index === 3 ? '-12px' : index === 4 ? '-15px' : '' ,
          right: index === 0 ? '0px' : index === 1 ? '0px' : index === 2 ? '1px' : index === 3 ? '2px' : index === 4 ? '2px' : '' ,
          rotate: index === 0 ? '-9deg' : index === 1 ? '-3deg' : index === 2 ? '2deg' : index === 3 ? '4deg' : index === 4 ? '9deg' : '' ,
          bgcolor: value >= 2 ? "#f7e560" : "transparent",
          border: value < 2 ? "2px solid #f7e560" : "transparent",
        }}
      >
      </Box>
      <Box
      position='relative'  right='6px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '-2px' : index === 1 ? '1px' : index === 2 ? '0' : index === 3 ? '0px' : index === 4 ? '-5px' : '' ,
          right: index === 0 ? '-10px' : index === 1 ? '-5px' : index === 2 ? '1px' : index === 3 ? '6px' : index === 4 ? '12px' : '' ,
          rotate: index === 0 ? '-7deg' : index === 1 ? '-4deg' : index === 2 ? '0deg' : index === 3 ? '3deg' : index === 4 ? '9deg' : '' ,
          bgcolor: benchValue >= 1 ? "#f7e560" : "transparent",
          border: benchValue === 0 ? "2px solid #f7e560" : "transparent",
        }}
      >
      </Box>
    </Box>
  );
})}

    </Stack>
    <Stack
       sx={{
          position: "absolute",
          top: '743.269119px',
          left: '608.269119px',
          rotate: '-35deg',
          width: 100,
          height: 80,
          color: "white",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: 'row'
        }}
    >
    {finalValue3.map((value, index) => {
  const benchValue = finalValueBench3[index] ?? 0; // Get corresponding benchmark value

  return (
    <Box key={`final-${index}`} width={10} ml='19px' mt={1}>
      <Box
      position='relative'  top='-13px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '22px' : index === 1 ? '24px' : index === 2 ? '23px' : index === 3 ? '20px' : index === 4 ? '15px' : '' ,
          right: index === 0 ? '-15px' : index === 1 ? '-14px' : index === 2 ? '-12px' : index === 3 ? '-11px' : index === 4 ? '-10px' : '' ,
          rotate: index === 0 ? '-7deg' : index === 1 ? '-3deg' : index === 2 ? '0deg' : index === 3 ? '5deg' : index === 4 ? '10deg' : '' ,
          bgcolor: value >= 2 ? "#f7e560" : "transparent",
          border: value < 2 ? "2px solid #f7e560" : "transparent",
        }}
      >
      </Box>
      <Box
      position='relative'  right='6px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '31px' : index === 1 ? '33px' : index === 2 ? '32px' : index === 3 ? '29px' : index === 4 ? '22px' : '' ,
          right: index === 0 ? '-22px' : index === 1 ? '-16px' : index === 2 ? '-10px' : index === 3 ? '-3px' : index === 4 ? '2px' : '' ,
          rotate: index === 0 ? '-6deg' : index === 1 ? '0deg' : index === 2 ? '3deg' : index === 3 ? '8deg' : index === 4 ? '11deg' : '' ,
          bgcolor: benchValue >= 1 ? "#f7e560" : "transparent",
          border: benchValue === 0 ? "2px solid #f7e560" : "transparent",
        }}
      >
      </Box>
    </Box>
  );
})}

    </Stack>
    <Stack
       sx={{
        position: "absolute",
        top: '813.984585px',
        left:'488.034379px',
        rotate: '-10deg',
          width: 100,
          height: 80,
          color: "white",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: 'row'
        }}
    >
    {finalValue4?.map((value, index) => {
  const benchValue = finalValueBench4[index] ?? 0; // Get corresponding benchmark value

  return (
    <Box key={`final-${index}`} width={10} ml='19px' mt={1}>
      <Box
      position='relative'  top='-13px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '-9px' : index === 1 ? '-6px' : index === 2 ? '-5px' : index === 3 ? '-7px' : index === 4 ? '-11px' : '' ,
          right: index === 0 ? '4px' : index === 1 ? '6px' : index === 2 ? '8px' : index === 3 ? '9px' : index === 4 ? '11px' : '' ,
          rotate: index === 0 ? '-9deg' : index === 1 ? '-5deg' : index === 2 ? '0deg' : index === 3 ? '4deg' : index === 4 ? '10deg' : '' ,
          bgcolor: value >= 2 ? "#f7e560" : "transparent",
          border: value < 2 ? "2px solid #f7e560" : "transparent",
        }}
      >
      </Box>
      <Box
      position='relative'  right='6px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '-2px' : index === 1 ? '3px' : index === 2 ? '3px' : index === 3 ? '2px' : index === 4 ? '-0.5px ' : '' ,
          right: index === 0 ? '-5px' : index === 1 ? '1px' : index === 2 ? '9px' : index === 3 ? '15px' : index === 4 ? '22px' : '' ,
          rotate: index === 0 ? '-8deg' : index === 1 ? '-4deg' : index === 2 ? '0deg' : index === 3 ? '5deg' : index === 4 ? '11deg' : '' ,
          bgcolor: benchValue >= 1 ? "#f7e560" : "transparent",
          border: benchValue === 0 ? "2px solid #f7e560" : "transparent",
        }}
      >
      </Box>
    </Box>
  );
})}

    </Stack>
    <Stack
      sx={{
        position: "absolute",
        top: '810.5px',
        left: '332.5px',
        rotate: '10deg',
        width: 100,
        height: 80,
        color: "white",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'row'
        }}
    >
    {finalValue5?.map((value, index) => {
  const benchValue = finalValueBench5[index] ?? 0; // Get corresponding benchmark value

  return (
    <Box key={`final-${index}`} width={10} ml='19px' mt={1}>
      <Box
      position='relative'  top='-13px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '-7px' : index === 1 ? '-4px' : index === 2 ? '-4px' : index === 3 ? '-6px' : index === 4 ? '-11px' : '' ,
          right: index === 0 ? '10px' : index === 1 ? '13px' : index === 2 ? '15px' : index === 3 ? '16px' : index === 4 ? '17px' : '' ,
          rotate: index === 0 ? '-5deg' : index === 1 ? '0deg' : index === 2 ? '4deg' : index === 3 ? '10deg' : index === 4 ? '14deg' : '' ,
          bgcolor: value >= 2 ? "#de3939" : "transparent",
          border: value < 2 ? "2px solid #de3939" : "transparent",
        }}
      >
      </Box>
      <Box
      position='relative'  right='6px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '4px' : index === 1 ? '8px' : index === 2 ? '8px' : index === 3 ? '6px' : index === 4 ? '0.5px ' : '' ,
          right: index === 0 ? '4px' : index === 1 ? '13px' : index === 2 ? '19px' : index === 3 ? '26px' : index === 4 ? '32px' : '' ,
          rotate: index === 0 ? '-5deg' : index === 1 ? '1deg' : index === 2 ? '5deg' : index === 3 ? '9deg' : index === 4 ? '13deg' : '' ,
          bgcolor: benchValue >= 1 ? "#de3939" : "transparent",
          border: benchValue === 0 ? "2px solid #de3939" : "transparent",
        }}
      >
      </Box>
    </Box>
  );
})}

    </Stack>
    <Stack
      sx={{
        position: "absolute",
        top: '754.984585px',
        left: '180.965621px',
        rotate: '31deg',
        width: 100,
        height: 80,
        color: "white",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'row'
        }}
    >
    {finalValue6?.map((value, index) => {
  const benchValue = finalValueBench6[index] ?? 0; // Get corresponding benchmark value

  return (
    <Box key={`final-${index}`} width={10} ml='19px' mt={1}>
      <Box
      position='relative'  top='-13px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '-9px' : index === 1 ? '-7px' : index === 2 ? '-7px' : index === 3 ? '-9px' : index === 4 ? '-14px' : '' ,
          right: index === 0 ? '10px' : index === 1 ? '13px' : index === 2 ? '14px' : index === 3 ? '15px' : index === 4 ? '16px' : '' ,
          rotate: index === 0 ? '-3deg' : index === 1 ? '1deg' : index === 2 ? '6deg' : index === 3 ? '11deg' : index === 4 ? '16deg' : '' ,
          bgcolor: value >= 2 ? "#e33f4b" : "transparent",
          border: value < 2 ? "2px solid #e33f4b" : "transparent",
        }}
      >
      </Box>
      <Box
      position='relative'  right='6px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '4px' : index === 1 ? '6px' : index === 2 ? '6px' : index === 3 ? '3px' : index === 4 ? '-2.5px ' : '' ,
          right: index === 0 ? '8px' : index === 1 ? '14px' : index === 2 ? '21px' : index === 3 ? '27px' : index === 4 ? '33px' : '' ,
          rotate: index === 0 ? '-2deg' : index === 1 ? '1deg' : index === 2 ? '6deg' : index === 3 ? '11deg' : index === 4 ? '15deg' : '' ,
          bgcolor: benchValue >= 1 ? "#e33f4b" : "transparent",
          border: benchValue === 0 ? "2px solid #e33f4b" : "transparent",
        }}
      >
      </Box>
    </Box>
  );
})}

    </Stack>
    <Stack
      sx={{
        position: "absolute",
        top: '641.269119px',
        left: '68.730881px',
        rotate: '57deg',
        width: 100,
        height: 80,
        color: "white",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'row'
        }}
    >
    {finalValue7?.map((value, index) => {
  const benchValue = finalValueBench7[index] ?? 0; // Get corresponding benchmark value

  return (
    <Box key={`final-${index}`} width={10} ml='19px' mt={1}>
      <Box
      position='relative'  top='-13px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '-5px' : index === 1 ? '-1px' : index === 2 ? '1px' : index === 3 ? '0px' : index === 4 ? '-2px' : '' ,
          right: index === 0 ? '13px' : index === 1 ? '14px' : index === 2 ? '14px' : index === 3 ? '14px' : index === 4 ? '15px' : '' ,
          rotate: index === 0 ? '-7deg' : index === 1 ? '-3deg' : index === 2 ? '3deg' : index === 3 ? '6deg' : index === 4 ? '12deg' : '' ,
          bgcolor: value >= 2 ? "#e8545a" : "transparent",
          border: value < 2 ? "2px solid #e8545a" : "transparent",
        }}
      >
      </Box>
      <Box
      position='relative'  right='6px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '7px' : index === 1 ? '10px' : index === 2 ? '13px' : index === 3 ? '12px' : index === 4 ? '8.5px ' : '' ,
          right: index === 0 ? '6px' : index === 1 ? '12px' : index === 2 ? '17px' : index === 3 ? '23px' : index === 4 ? '28px' : '' ,
          rotate: index === 0 ? '-6deg' : index === 1 ? '-2deg' : index === 2 ? '4deg' : index === 3 ? '7deg' : index === 4 ? '11deg' : '' ,
          bgcolor: benchValue >= 1 ? "#e8545a" : "transparent",
          border: benchValue === 0 ? "2px solid #e8545a" : "transparent",
        }}
      >
      </Box>
    </Box>
  );
})}

    </Stack>
    <Stack
      sx={{
        position: "absolute",
        top: '500.034379px',
        left: '3.015415px',
        rotate: '79deg',
        width: 100,
        height: 80,
        color: "white",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'row'
        }}
    >
    {finalValue8?.map((value, index) => {
  const benchValue = finalValueBench8[index] ?? 0; // Get corresponding benchmark value

  return (
    <Box key={`final-${index}`} width={10} ml='19px' mt={1}>
      <Box
      position='relative'  top='-13px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '-7px' : index === 1 ? '-4px' : index === 2 ? '-2px' : index === 3 ? '-4px' : index === 4 ? '-7px' : '' ,
          right: index === 0 ? '12px' : index === 1 ? '12px' : index === 2 ? '13px' : index === 3 ? '13px' : index === 4 ? '13px' : '' ,
          rotate: index === 0 ? '-7deg' : index === 1 ? '-1deg' : index === 2 ? '4deg' : index === 3 ? '6deg' : index === 4 ? '11deg' : '' ,
          bgcolor: value >= 2 ? "#e8545a" : "transparent",
          border: value < 2 ? "2px solid #e8545a" : "transparent",
        }}
      >
      </Box>
      <Box
      position='relative'  right='6px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '6px' : index === 1 ? '9px' : index === 2 ? '11px' : index === 3 ? '9px' : index === 4 ? '5.5px' : '' ,
          right: index === 0 ? '6px' : index === 1 ? '11px' : index === 2 ? '15px' : index === 3 ? '21px' : index === 4 ? '25px' : '' ,
          rotate: index === 0 ? '-6deg' : index === 1 ? '-1deg' : index === 2 ? '2deg' : index === 3 ? '7deg' : index === 4 ? '11deg' : '' ,
          bgcolor: benchValue >= 1 ? "#e8545a" : "transparent",
          border: benchValue === 0 ? "2px solid #e8545a" : "transparent",
        }}
      >
      </Box>
    </Box>
  );
})}

    </Stack>
    <Stack
      sx={{
        position: "absolute",
        top: '345.5px',
        left: '1px',
        rotate: '101deg',
        width: 100,
        height: 80,
        color: "white",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'row'
        }}
    >
    {finalValue9?.map((value, index) => {
  const benchValue = finalValueBench9[index] ?? 0; // Get corresponding benchmark value

  return (
    <Box key={`final-${index}`} width={10} ml='19px' mt={1}>
      <Box
      position='relative'  top='-13px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '-6px' : index === 1 ? '-4px' : index === 2 ? '-4px' : index === 3 ? '-6px' : index === 4 ? '-11px' : '' ,
          right: index === 0 ? '10px' : index === 1 ? '12px' : index === 2 ? '13px' : index === 3 ? '13px' : index === 4 ? '13px' : '' ,
          rotate: index === 0 ? '-7deg' : index === 1 ? '-1deg' : index === 2 ? '4deg' : index === 3 ? '6deg' : index === 4 ? '10deg' : '' ,
          bgcolor: value >= 2 ? "#51b25f" : "transparent",
          border: value < 2 ? "2px solid #51b25f" : "transparent",
        }}
      >
      </Box>
      <Box
      position='relative'  right='6px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '6px' : index === 1 ? '10px' : index === 2 ? '9px' : index === 3 ? '7px' : index === 4 ? '1.5px' : '' ,
          right: index === 0 ? '5px' : index === 1 ? '10px' : index === 2 ? '15px' : index === 3 ? '20px' : index === 4 ? '25px' : '' ,
          rotate: index === 0 ? '-6deg' : index === 1 ? '-2deg' : index === 2 ? '0deg' : index === 3 ? '5deg' : index === 4 ? '10deg' : '' ,
          bgcolor: benchValue >= 1 ? "#51b25f" : "transparent",
          border: benchValue === 0 ? "2px solid #51b25f" : "transparent",
        }}
      >
      </Box>
    </Box>
  );
})}

    </Stack>
    <Stack
      sx={{
        position: "absolute",
        top: '202.965621px',
        left: '61.015415px',
        rotate: '123deg',
        width: 100,
        height: 80,
        color: "white",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'row'
        }}
    >
    {finalValue10?.map((value, index) => {
  const benchValue = finalValueBench10[index] ?? 0; // Get corresponding benchmark value

  return (
    <Box key={`final-${index}`} width={10} ml='19px' mt={1}>
      <Box
      position='relative'  top='-13px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '-5px' : index === 1 ? '-4px' : index === 2 ? '-4px' : index === 3 ? '-7px' : index === 4 ? '-12px' : '' ,
          right: index === 0 ? '13px' : index === 1 ? '13px' : index === 2 ? '14px' : index === 3 ? '14px' : index === 4 ? '14px' : '' ,
          rotate: index === 0 ? '-6deg' : index === 1 ? '-1deg' : index === 2 ? '1deg' : index === 3 ? '6deg' : index === 4 ? '9deg' : '' ,
          bgcolor: value >= 2 ? "#57be5c" : "transparent",
          border: value < 2 ? "2px solid #57be5c" : "transparent",
        }}
      >
      </Box>
      <Box
      position='relative'  right='6px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '6px' : index === 1 ? '8px' : index === 2 ? '8px' : index === 3 ? '5px' : index === 4 ? '-1.5px ' : '' ,
          right: index === 0 ? '5px' : index === 1 ? '10px' : index === 2 ? '16px' : index === 3 ? '21px' : index === 4 ? '27px' : '' ,
          rotate: index === 0 ? '-7deg' : index === 1 ? '-2deg' : index === 2 ? '2deg' : index === 3 ? '7deg' : index === 4 ? '9deg' : '' ,
          bgcolor: benchValue >= 1 ? "#57be5c" : "transparent",
          border: benchValue === 0 ? "2px solid #57be5c" : "transparent",
        }}
      >
      </Box>
    </Box>
  );
})}

    </Stack>
    <Stack
      sx={{
        position: "absolute",
        top: '95.730881px',
        left: '175.730881px',
        rotate: '148deg',
        width: 100,
        height: 80,
        color: "white",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'row'
        }}
    >
    {finalValue11?.map((value, index) => {
  const benchValue = finalValueBench11[index] ?? 0; // Get corresponding benchmark value

  return (
    <Box key={`final-${index}`} width={10} ml='19px' mt={1}>
      <Box
      position='relative'  top='-13px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '-6px' : index === 1 ? '-3px' : index === 2 ? '-2px' : index === 3 ? '-4px' : index === 4 ? '-7px' : '' ,
          right: index === 0 ? '14px' : index === 1 ? '16px' : index === 2 ? '17px' : index === 3 ? '18px' : index === 4 ? '19px' : '' ,
          rotate: index === 0 ? '-10deg' : index === 1 ? '-5deg' : index === 2 ? '-2deg' : index === 3 ? '4deg' : index === 4 ? '8deg' : '' ,
          bgcolor: value >= 2 ? "#5fce67" : "transparent",
          border: value < 2 ? "2px solid #5fce67" : "transparent",
        }}
      >
      </Box>
      <Box
      position='relative'  right='6px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '6px' : index === 1 ? '9px' : index === 2 ? '10px' : index === 3 ? '8px' : index === 4 ? '4.5px ' : '' ,
          right: index === 0 ? '4px' : index === 1 ? '9px' : index === 2 ? '16px' : index === 3 ? '22px' : index === 4 ? '29px' : '' ,
          rotate: index === 0 ? '-9deg' : index === 1 ? '-6deg' : index === 2 ? '-1deg' : index === 3 ? '4deg' : index === 4 ? '7deg' : '' ,
          bgcolor: benchValue >= 1 ? "#5fce67" : "transparent",
          border: benchValue === 0 ? "2px solid #5fce67" : "transparent",
        }}
      >
      </Box>
    </Box>
  );
})}

    </Stack>
    <Stack
      sx={{
        position: "absolute",
        top:'34.015415px',
        left: '323.965621px',
        rotate: '167deg',
        width: 100,
        height: 80,
        color: "white",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'row'
        }}
    >
    {finalValue12?.map((value, index) => {
  const benchValue = finalValueBench12[index] ?? 0; // Get corresponding benchmark value

  return (
    <Box key={`final-${index}`} width={10} ml='19px' mt={1}>
      <Box
      position='relative'  top='-13px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '-5px' : index === 1 ? '-3px' : index === 2 ? '-4px' : index === 3 ? '-7px' : index === 4 ? '-12px' : '' ,
          right: index === 0 ? '12px' : index === 1 ? '14px' : index === 2 ? '15px' : index === 3 ? '16px' : index === 4 ? '17px' : '' ,
          rotate: index === 0 ? '-7deg' : index === 1 ? '-2deg' : index === 2 ? '3deg ' : index === 3 ? '8deg' : index === 4 ? '13deg' : '' ,
          bgcolor: value >= 2 ? "#65db72" : "transparent",
          border: value < 2 ? "2px solid #65db72" : "transparent",
        }}
      >
      </Box>
      <Box
      position='relative'  right='6px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '8px' : index === 1 ? '9px' : index === 2 ? '8px' : index === 3 ? '5px' : index === 4 ? '-0.5px ' : '' ,
          right: index === 0 ? '5px' : index === 1 ? '12px' : index === 2 ? '19px' : index === 3 ? '26px' : index === 4 ? '32px' : '' ,
          rotate: index === 0 ? '-7deg' : index === 1 ? '-1deg' : index === 2 ? '3deg' : index === 3 ? '9deg' : index === 4 ? '13deg' : '' ,
          bgcolor: benchValue >= 1 ? "#65db72" : "transparent",
          border: benchValue === 0 ? "2px solid #65db72" : "transparent",
        }}
      >
      </Box>
    </Box>
  );
})}

    </Stack>
    <Stack
      sx={{
        position: "absolute",
        top: '33px',
        left: '495px',
        rotate: '190deg',
        width: 100,
        height: 80,
        color: "white",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'row'
        }}
    >
    {finalValue13?.map((value, index) => {
  const benchValue = finalValueBench13[index] ?? 0; 

  return (
    <Box key={`final-${index}`} width={10} ml='19px' mt={1}>
      <Box
      position='relative'  top='-13px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '-7px' : index === 1 ? '-4px' : index === 2 ? '-4px' : index === 3 ? '-6px' : index === 4 ? '-11px' : '' ,
          right: index === 0 ? '3px' : index === 1 ? '5px' : index === 2 ? '7px' : index === 3 ? '8px' : index === 4 ? '9px' : '' ,
          rotate: index === 0 ? '-5deg' : index === 1 ? '0deg' : index === 2 ? '5deg' : index === 3 ? '9deg' : index === 4 ? '14deg' : '' ,
          bgcolor: value >= 2 ? "#2a2a6c" : "transparent",
          border: value < 2 ? "2px solid #2a2a6c" : "transparent",
        }}
      >
      </Box>
      <Box
      position='relative'  right='6px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '5px' : index === 1 ? '8px' : index === 2 ? '8px' : index === 3 ? '5px' : index === 4 ? '-0.5px ' : '' ,
          right: index === 0 ? '-2.5px' : index === 1 ? '5px' : index === 2 ? '11px' : index === 3 ? '18px' : index === 4 ? '24px' : '' ,
          rotate: index === 0 ? '-5deg' : index === 1 ? '0deg' : index === 2 ? '5deg' : index === 3 ? '10deg' : index === 4 ? '14deg' : '' ,
          bgcolor: benchValue >= 1 ? "#2a2a6c" : "transparent",
          border: benchValue === 0 ? "2px solid #2a2a6c" : "transparent",
        }}
      >
      </Box>
    </Box>
  );
})}

    </Stack>
    <Stack
      sx={{
        position: "absolute",
        top: '91.015415px',
        left: '645.534379px',
        rotate: '212deg',
        width: 100,
        height: 80,
        color: "white",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'row'
        }}
    >
    {finalValue14?.map((value, index) => {
  const benchValue = finalValueBench14[index] ?? 0; // Get corresponding benchmark value

  return (
    <Box key={`final-${index}`} width={10} ml='19px' mt={1}>
      <Box
      position='relative'  top='-13px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '-10px' : index === 1 ? '-8px' : index === 2 ? '-7px' : index === 3 ? '-8px' : index === 4 ? '-13px' : '' ,
          right: index === 0 ? '3px' : index === 1 ? '5px' : index === 2 ? '5px' : index === 3 ? '7px' : index === 4 ? '8px' : '' ,
          rotate: index === 0 ? '-2deg' : index === 1 ? '0deg' : index === 2 ? '6deg' : index === 3 ? '10deg' : index === 4 ? '13deg' : '' ,
          bgcolor: value >= 2 ? "#2a2b6c" : "transparent",
          border: value < 2 ? "2px solid #2a2b6c" : "transparent",
        }}
      >
      </Box>
      <Box
      position='relative'  right='6px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '3px' : index === 1 ? '5px' : index === 2 ? '5px' : index === 3 ? '3px' : index === 4 ? '-0.5px ' : '' ,
          right: index === 0 ? '-2px' : index === 1 ? '5px' : index === 2 ? '12px' : index === 3 ? '18px' : index === 4 ? '24px' : '' ,
          rotate: index === 0 ? '-4deg' : index === 1 ? '1deg' : index === 2 ? '7deg' : index === 3 ? '11deg' : index === 4 ? '15deg' : '' ,
          bgcolor: benchValue >= 1 ? "#2a2b6c" : "transparent",
          border: benchValue === 0 ? "2px solid #2a2b6c" : "transparent",
        }}
      >
      </Box>
    </Box>
  );
})}

    </Stack>
    <Stack
      sx={{
        position: "absolute",
        top: '200.730881px',
        left: '759.269119px',
        rotate: '235deg',
        width: 100,
        height: 80,
        color: "white",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'row'
        }}
    >
    {finalValue15?.map((value, index) => {
  const benchValue = finalValueBench15[index] ?? 0; // Get corresponding benchmark value

  return (
    <Box key={`final-${index}`} width={10} ml='19px' mt={1}>
      <Box
      position='relative'  top='-13px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '-11px ' : index === 1 ? '-8px' : index === 2 ? '-7px' : index === 3 ? '-9px' : index === 4 ? '-13px' : '' ,
          right: index === 0 ? '7px' : index === 1 ? '7px' : index === 2 ? '8px' : index === 3 ? '8px' : index === 4 ? '8px' : '' ,
          rotate: index === 0 ? '-3deg' : index === 1 ? '2deg' : index === 2 ? '5deg' : index === 3 ? ' 10deg' : index === 4 ? ' 13deg' : '' ,
          bgcolor: value >= 2 ? "#313182" : "transparent",
          border: value < 2 ? "2px solid #313182" : "transparent",
        }}
      >
      </Box>
      <Box
      position='relative'  right='6px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '2px' : index === 1 ? '6px' : index === 2 ? '6px' : index === 3 ? '5px' : index === 4 ? '-0.5px ' : '' ,
          right: index === 0 ? '2px' : index === 1 ? '8px' : index === 2 ? '13px' : index === 3 ? '19px' : index === 4 ? '23px' : '' ,
          rotate: index === 0 ? '-4deg' : index === 1 ? '2deg' : index === 2 ? '5deg' : index === 3 ? '7deg' : index === 4 ? '13deg' : '' ,
          bgcolor: benchValue >= 1 ? "#313182" : "transparent",
          border: benchValue === 0 ? "2px solid #313182" : "transparent",
        }}
      >
      </Box>
    </Box>
  );
})}

    </Stack>
    <Stack
      sx={{
        position: "absolute",
        top:'344px',
        left: '818px',
        rotate: '256deg',
        width: 100,
        height: 80,
        color: "white",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'row'
        }}
    >
    {finalValue16?.map((value, index) => {
  const benchValue = finalValueBench16[index] ?? 0; // Get corresponding benchmark value

  return (
    <Box key={`final-${index}`} width={10} ml='19px' mt={1}>
      <Box
      position='relative'  top='-13px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '-5px' : index === 1 ? '-3px' : index === 2 ? '-4px' : index === 3 ? '-6px' : index === 4 ? '-12px' : '' ,
          right: index === 0 ? '9px' : index === 1 ? '9px' : index === 2 ? '9px' : index === 3 ? '8px' : index === 4 ? '9px' : '' ,
          rotate: index === 0 ? '-2deg' : index === 1 ? '1deg' : index === 2 ? '5deg' : index === 3 ? '10deg' : index === 4 ? '14deg' : '' ,
          bgcolor: value >= 2 ? "#3d3d99" : "transparent",
          border: value < 2 ? "2px solid #3d3d99" : "transparent",
        }}
      >
      </Box>
      <Box
      position='relative'  right='6px'
      width='19.5px' height="53px" mt={.1}
      borderRadius={3}
        sx={{
          top: index === 0 ? '6px' : index === 1 ? '10px' : index === 2 ? '9px' : index === 3 ? '5px' : index === 4 ? '-0.5px ' : '' ,
          right: index === 0 ? '5px' : index === 1 ? '10px' : index === 2 ? '15px' : index === 3 ? '19px' : index === 4 ? '24px' : '' ,
          rotate: index === 0 ? '-3deg' : index === 1 ? '1deg' : index === 2 ? '5deg' : index === 3 ? '9deg' : index === 4 ? '13deg' : '' ,
          bgcolor: benchValue >= 1 ? "#3d3d99" : "transparent",
          border: benchValue === 0 ? "2px solid #3d3d99" : "transparent",
        }}
      >
      </Box>
    </Box>
  );
})}

    </Stack>
    </Box>
      </Page>
      <Page srcPage={P3}>
        <Header lang={lang} name={name} date={date} headerTitle={referencess?.[32]?.[lang === 'en' ? 'en content' : 'ar content']} />
        <Stack height='62%' sx={{position: 'relative',top: '-15px', right: '36px', width :'93%'}}>
          <Stack height='calc(100% / 4)' mt='27px' direction='row-reverse' width='100%' justifyContent='space-between'>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
            <Stack spacing={1.6} pt="4.5px" width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '32px',
                          fontSize: '12px',
                          display: 'flex',
                          alignItems :'center',
                       }}
                    >
                      <img style={{ width: '100%', height: '100%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
            
          <Stack spacing={2} width='60%'>
            {/* Sort the answers array and render the answers with their corresponding benchmarks */}
            {firstBox[0].answers
              .sort((a, b) => {
                // Calculate the sum of values for both `a` and `b`
                const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
              
                // Sort by the sum of the values first
                return sumA - sumB;
              })
              .map((item, index) => {
                // Calculate the sum of the values for the background color
                const sum = Object.values(item.values)
                  .map(value => Math.trunc(Number(value)))
                  .reduce((acc, val) => acc + val, 0);
              
                const background = sum >= 2 ? "rgb(38,34,95)" : 'white';
                const color = sum >= 2 ? 'white' : '#323283';
              
                return (
                  <Box
                    key={`answer-${index}`}
                    sx={{
                      background,
                      color,
                      border: '2px solid #323283',
                      mt : "4px !important",
                      alignContent: 'center',
                      position: 'relative',
                      top: '4px',
                      right: '4.5px',
                      width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                    }}
                  >
                    {item.ref}
                  </Box>
                );
              })}
          </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
            <Stack spacing={1.6} pt="4.5px" width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox2[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '32px',
                          fontSize: '12px',
                          display: 'flex',
                          alignItems :'center',
                       }}
                    >
                      <img style={{ width: '100%', height: '100%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={2} width='60%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox2[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value) || 0)) // Handle undefined values safely
                      .reduce((acc, val) => acc + val, 0);

                    const background = sum >= 2 ? "rgb(42,42,114)" : 'white';
                    const color = sum >= 2 ? 'white' : '#323283';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                          border: '2px solid #323283',
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                      mt : "4px !important"
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} pt="4.5px" width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox3[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '32px',
                          fontSize: '12px',
                          display: 'flex',
                          alignItems :'center',
                       }}
                    >
                      <img style={{ width: '100%', height: '100%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox3[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(50,50,136)" : 'white';
                    const color = sum >= 2 ? 'white' : '#323283';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                          border: '2px solid #323283',
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                      mt : "4px !important"
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} pt="4.5px" width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox4[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '32px',
                          fontSize: '12px',
                          display: 'flex',
                          alignItems :'center'
                       }}
                    >
                      <img style={{ width: '100%', height: '100%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={2} width='60%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox4[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(61,61,159)" : 'white';
                    const color = sum >= 2 ? 'white' : '#323283';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                          border: '2px solid #323283',
                          mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
          </Stack>
        <Stack height='calc(100% / 4)' mt='69px' direction='row-reverse' width='100%' justifyContent='space-between'>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
            <Stack spacing={1.6} pt="4.5px" width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox5[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden', 
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '32px',
                          fontSize: '12px',
                          display: 'flex',
                          alignItems :'center'
                       }}
                    >
                      <img style={{ width: '100%', height: '100%'}} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox5[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value) || 0)) // Handle undefined values safely
                      .reduce((acc, val) => acc + val, 0);

                    const background = sum >= 2 ? "rgb(251,232,34)" : 'white';
                    const color = sum >= 2 ? 'black' : 'black';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #f7e560',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} pt="4.5px" width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox6[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden', 
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '32px',
                          fontSize: '12px',
                          display: 'flex',
                          alignItems :'center'
                       }}
                    >
                      <img style={{ width: '100%', height: '100%'}} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox6[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value) || 0)) // Handle undefined values safely
                      .reduce((acc, val) => acc + val, 0);

                    const background = sum >= 2 ? "rgb(251,232,34)" : 'white';
                    const color = sum >= 2 ? 'black' : 'black';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #f7e560',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} pt="4.5px" width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox7[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '32px',
                          fontSize: '12px',
                          display: 'flex',
                          alignItems :'center'
                       }}
                    >
                      <img style={{ width: '100%', height: '100%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox7[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(251,228,62)" : 'white';
                    const color = sum >= 2 ? 'black' : 'black';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #f7e560',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} pt="4.5px" width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox8[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                                                position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '32px',
                          fontSize: '12px',
                          display: 'flex',
                          alignItems :'center'
                       }}
                    >
                      <img style={{ width: '100%', height: '100%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox8[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(251,224,95)" : 'white';
                    const color = sum >= 2 ? 'black' : 'black';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #f7e560',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
          </Stack>
          <Stack height='calc(100% / 4)' mt='70px' direction='row-reverse' width='100%' justifyContent='space-between'>
            <Stack direction="row" justifyContent="space-between" width='calc(91% / 4)' height='100%'>
          <Stack spacing={1.6} pt="4.5px" width='35%'>
            {/* Sort the answers array and render the benchmark */}
            {firstBox9[0].answers
              .sort((a, b) => {
                // Calculate the sum of values for both `a` and `b`
                const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
              
                // Sort by the sum of the values first
                return sumA - sumB;
              })
              .map((item, index) => (
                <Box
                  key={`benchmark-${index}`}
                  sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '32px',
                          fontSize: '12px',
                          display: 'flex',
                          alignItems :'center'
                   }}
                >
                  <img style={{ width: '100%', height: '100%'}} src={lang === 'en' ? reqen : req} alt="req" />
                </Box>
              ))}
          </Stack>
            
          <Stack spacing={2} width='57%'>
            {/* Sort the answers array and render the answers with their corresponding benchmarks */}
            {firstBox9[0].answers
              .sort((a, b) => {
                // Calculate the sum of values for both `a` and `b`
                const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
              
                // Sort by the sum of the values first
                return sumA - sumB;
              })
              .map((item, index) => {
                // Calculate the sum of the values for the background color
                const sum = Object.values(item.values)
                  .map(value => Math.trunc(Number(value)))
                  .reduce((acc, val) => acc + val, 0);
              
                const background = sum >= 2 ? "rgb(241,28,46)" : 'white';
                const color = sum >= 2 ? 'white' : '#e33f4c';
              
                return (
                  <Box
                    key={`answer-${index}`}
                    sx={{
                      background,
                      color,
                                            border: '2px solid #e33f4c',
                      mt : "4px !important",
                      alignContent: 'center',
                      position: 'relative',
                      top: '4px',
                      right: '4.5px',
                      width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                    }}
                  >
                    {item.ref}
                  </Box>
                );
              })}
          </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} pt="4.5px" width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox10[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                                                position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '32px',
                          fontSize: '12px',
                          display: 'flex',
                          alignItems :'center'
                       }}
                    >
                      <img style={{ width: '100%', height: '100%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox10[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value) || 0)) // Handle undefined values safely
                      .reduce((acc, val) => acc + val, 0);

                    const background = sum >= 2 ? "rgb(238,45,69)" : 'white';
                    const color = sum >= 2 ? 'white' : '#e33f4c';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #e33f4c',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} pt="4.5px" width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox11[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                                                                      position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '32px',
                          fontSize: '12px',
                          display: 'flex',
                          alignItems :'center'
                       }}
                    >
                      <img style={{ width: '100%', height: '100%'}} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox11[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(247,38,69)" : 'white';
                    const color = sum >= 2 ? 'white' : '#e33f4c';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #e33f4c',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} pt="4.5px" width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox12[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '32px',
                          fontSize: '12px',
                          display: 'flex',
                          alignItems :'center'
                       }}
                    >
                      <img style={{ width: '100%', height: '100%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox12[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(251,69,84)" : 'white';
                    const color = sum >= 2 ? 'white' : '#e33f4c';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #e33f4c',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
          </Stack>
          <Stack height='calc(100% / 4)' mt='70px' direction='row-reverse'  width='100%' justifyContent='space-between'>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
            <Stack spacing={1.6} pt="4.5px" width='35%'>
            {/* Sort the answers array and render the benchmark */}
            {firstBox13[0].answers
              .sort((a, b) => {
                // Calculate the sum of values for both `a` and `b`
                const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
              
                // Sort by the sum of the values first
                return sumA - sumB;
              })
              .map((item, index) => (
                <Box
                  key={`benchmark-${index}`}
                  sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                                                                                                              position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '32px',
                          fontSize: '12px',
                          display: 'flex',
                          alignItems :'center'
                   }}
                >
                  <img style={{ width: '100%', height: '100%' }} src={lang === 'en' ? reqen : req} alt="req" />
                </Box>
              ))}
            </Stack>
            <Stack spacing={2} width='57%'>
            {/* Sort the answers array and render the answers with their corresponding benchmarks */}
            {firstBox13[0].answers
              .sort((a, b) => {
                // Calculate the sum of values for both `a` and `b`
                const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
              
                // Sort by the sum of the values first
                return sumA - sumB;
              })
              .map((item, index) => {
                // Calculate the sum of the values for the background color
                const sum = Object.values(item.values)
                  .map(value => Math.trunc(Number(value)))
                  .reduce((acc, val) => acc + val, 0);
              
                const background = sum >= 2 ? "rgb(0,181,84)" : 'white';
                const color = sum >= 2 ? 'white' : '#5fce68';
              
                return (
                  <Box
                    key={`answer-${index}`}
                    sx={{
                      background,
                      color,
                      border: '2px solid #5fce68',
                      mt : "4px !important",
                      alignContent: 'center',
                      position: 'relative',
                      top: '4px',
                      right: '4.5px',
                      width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                    }}
                  >
                    {item.ref}
                  </Box>
                );
              })}
            </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} pt="4.5px" width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox14[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                                                                                                                  position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '32px',
                          fontSize: '12px',
                          display: 'flex',
                          alignItems :'center'
                       }}
                    >
                      <img style={{ width: '100%', height: '100%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox14[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value) || 0)) // Handle undefined values safely
                      .reduce((acc, val) => acc + val, 0);

                    const background = sum >= 2 ? "rgb(0,193,78)" : 'white';
                    const color = sum >= 2 ? 'white' : '#5fce68';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #5fce68',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} pt="4.5px" width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox15[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                                                                                                                  position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '32px',
                          fontSize: '12px',
                          display: 'flex',
                          alignItems :'center'
                       }}
                    >
                      <img style={{ width: '100%', height: '100%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox15[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(0,209,89)" : 'white';
                    const color = sum >= 2 ? 'white' : '#5fce68';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #5fce68',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} pt="4.5px" width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox16[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                                                                                                                  position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '32px',
                          fontSize: '12px',
                          display: 'flex',
                          alignItems :'center'
                       }}
                    >
                      <img style={{ width: '100%', height: '100%',}} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox16[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(0,222,99)" : 'white';
                    const color = sum >= 2 ? 'white' : '#5fce68';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #5fce68',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Page>
      <Page srcPage={P4}>
      <Header lang={lang} name={name} date={date}  headerTitle={referencess?.[129]?.[lang === 'en' ? 'en content' : 'ar content']}/>
        <Stack width='93.4%' height='77%' marginTop='70px' marginRight='38px'>
          <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%' height='124px'>
          <Stack width='50%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Stack  sx={{ marginRight: '26px', marginLeft: '36px'}} width='70%' direction='row' justifyContent='space-around' alignItems='center'>
              <Typography  width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr', fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[131]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
              <Typography  width='17%' textAlign='center' ml="-11px" display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[130]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>  
            </Stack>
            <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={
                Math.trunc(Number(answers?.[1911])) +
                Math.trunc(Number(answers?.[1912])) +
                Math.trunc(Number(answers?.[1913])) >= 2 
              ? 'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[80] === 0 ? 'block' : 'none' : 'none'} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='49.7%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1911])) +
                Math.trunc(Number(answers?.[1912])) +
                Math.trunc(Number(answers?.[1913])) < 2
              ? 'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[80] === 1 ? 'block' : 'none' : "none"} width={80} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack sx={{marginRight: '26px', marginLeft: '36px'}} width='70%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography width='17%' textAlign='center'  display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[132]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>  
            <Typography width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[133]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
          </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%' height='110px'>
          <Stack width='50%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '26px', marginLeft: '36px'}} width='70%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[135]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
            <Typography width='17%' textAlign='center' ml="-11px" display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[134]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>  
          </Stack>
              <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1921])) +
                Math.trunc(Number(answers?.[1922])) +
                Math.trunc(Number(answers?.[1923])) >= 2
              ? 'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[81] === 0 ? 'block' : 'none': "none"} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='49.7%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1921])) +
                Math.trunc(Number(answers?.[1922])) +
                Math.trunc(Number(answers?.[1923])) < 2
              ?  'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[81] === 1 ? 'block' : 'none': "none"} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='78%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='17%' textAlign='center'display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[136]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
                <Typography width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[137]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%' height='100px'>
          <Stack width='50%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '26px', marginLeft: '36px'}} width='70%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[139]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
            <Typography width='17%' textAlign='center' ml="-11px" display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[138]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>  
          </Stack>
              <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1931]))  +
                Math.trunc(Number(answers?.[1932]))  +
                Math.trunc(Number(answers?.[1933])) >= 2 
              ?  'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[82] === 0 ? 'block' : 'none': "none"} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='49.7%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1931])) +
                Math.trunc(Number(answers?.[1932]))  +
                Math.trunc(Number(answers?.[1933]))  < 2
              ?  'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[82] === 1 ? 'block' : 'none': "none"} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='78%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='17%' textAlign='center'  display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[140]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
                <Typography width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[141]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%' height='105px'>
          <Stack width='50%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '26px', marginLeft: '36px'}} width='70%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[143]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
            <Typography width='17%' textAlign='center' display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[142]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>  
          </Stack>
              <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1941]))  +
                Math.trunc(Number(answers?.[1942])) +
                Math.trunc(Number(answers?.[1943])) >= 2 
              ?  'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[83] === 0 ? 'block' : 'none': "none"} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='49.7%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1941]))  +
                Math.trunc(Number(answers?.[1942]))  +
                Math.trunc(Number(answers?.[1943])) < 2
              ?  'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[83] === 1 ? 'block' : 'none': "none"} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='78%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='17%' textAlign='center' display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[144]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
                <Typography width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[145]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%' height='103px'>
          <Stack width='50%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '26px', marginLeft: '36px'}} width='70%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[147]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
            <Typography width='17%' textAlign='center' ml="-11px" display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[146]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>  
          </Stack>
              <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1951]))  +
                Math.trunc(Number(answers?.[1952]))  +
                Math.trunc(Number(answers?.[1953]))  >= 2
              ?  'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[84] === 0 ? 'block' : 'none': "none"} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='49.7%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1951])) +
                Math.trunc(Number(answers?.[1952])) +
                Math.trunc(Number(answers?.[1953])) < 2
              ?  'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[84] === 1 ? 'block' : 'none': "none"} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='78%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='17%' textAlign='center' display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[148]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
                <Typography width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[149]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%' height='101px'>
          <Stack width='50%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '26px', marginLeft: '36px'}} width='70%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[151]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
            <Typography width='17%' textAlign='center' ml="-11px" display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[150]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>  
          </Stack>
              <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1961])) +
                Math.trunc(Number(answers?.[1962])) +
                Math.trunc(Number(answers?.[1963])) >= 2
              ?  'none' : 'block'}  width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[85] === 0 ? 'block' : 'none': "none"} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='49.7%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1961])) +
                Math.trunc(Number(answers?.[1962])) +
                Math.trunc(Number(answers?.[1963])) < 2
              ?  'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[85] === 1 ? 'block' : 'none': "none"} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='78%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='17%' textAlign='center' display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[152]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
                <Typography  width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[153]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%' height='102px'>
          <Stack width='50%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '26px', marginLeft: '36px'}} width='70%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[155]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
            <Typography width='17%' textAlign='center' ml="-11px" display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[154]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>  
          </Stack>
              <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1971]))  +
                Math.trunc(Number(answers?.[1972])) +
                Math.trunc(Number(answers?.[1973])) >= 2
              ?  'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[86] === 0 ? 'block' : 'none': "none"} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='49.7%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1971]))  +
                Math.trunc(Number(answers?.[1972])) +
                Math.trunc(Number(answers?.[1973])) < 2
              ?  'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[86] === 1 ? 'block' : 'none': "none"} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='78%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='17%' textAlign='center' display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[156]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
                <Typography width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[157]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%' height='109px'>
          <Stack width='50%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '26px', marginLeft: '36px'}} width='70%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[159]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
            <Typography width='17%' textAlign='center' ml="-11px" display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[158]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>  
          </Stack>
              <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1981])) +
                Math.trunc(Number(answers?.[1982])) +
                Math.trunc(Number(answers?.[1983])) >= 2
              ?  'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[87] === 0 ? 'block' : 'none': "none"} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='49.7%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1981])) +
                Math.trunc(Number(answers?.[1982])) +
                Math.trunc(Number(answers?.[1983])) < 2
              ?  'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[87] === 1 ? 'block' : 'none': "none"} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='78%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='17%' textAlign='center' display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[160]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
                <Typography width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[161]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%' height='104px'>
          <Stack width='50%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '26px', marginLeft: '36px'}} width='70%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[163]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
            <Typography width='17%' textAlign='center' ml="-11px" display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[162]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>  
          </Stack>
              <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1991]))  +
                Math.trunc(Number(answers?.[1992])) +
                Math.trunc(Number(answers?.[1993])) >= 2
              ?  'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[88] === 0 ? 'block' : 'none': "none"} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='49.7%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1991]))  +
                Math.trunc(Number(answers?.[1992])) +
                Math.trunc(Number(answers?.[1993])) < 2
              ?  'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[88] === 1 ? 'block' : 'none': "none"} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='78%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='17%' textAlign='center' display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[164]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
                <Typography width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[165]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%' height='118px'>
          <Stack width='50%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '26px', marginLeft: '36px'}} width='70%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{direction : isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[167]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
            <Typography width='17%' textAlign='center' ml="-11px" display='flex' justifyContent="center"  fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}} >{referencess?.[166]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>  
          </Stack>
              <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[19101]))  +
                Math.trunc(Number(answers?.[19102]))  +
                Math.trunc(Number(answers?.[19103]))  >= 2
              ?  'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[89] === 0 ? 'block' : 'none': "none"} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='49.7%' justifyContent='space-between' sx={{marginTop: '0'}} direction='row' >
            <Box sx={{height :'63px', display: 'flex', flexDirection :'column', justifyContent :'space-around'}} alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[19101]))  +
                Math.trunc(Number(answers?.[19102])) +
                Math.trunc(Number(answers?.[19103])) < 2
              ?  'none' : 'block'} width={80}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[89] === 1 ? 'block' : 'none': "none"} width={80} sx={{marginTop: '-10px', marginLeft: '1px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='78%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='17%' textAlign='center' display='flex' justifyContent="center" fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}} >{referencess?.[168]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
                <Typography width='63%' textAlign="justify" ml="34px" height='85%' fontSize={isEng ? '11px' :'14px' } sx={{fontFamily: 'var(--Regular-Font-Family)', color : '#4ba6aa', fontWeight :'700'}}>{referencess?.[169]?.[lang === 'en' ? 'en content' : 'ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Page>
      <Page srcPage={P7}>
        <Header lang={lang} name={name} date={date} headerTitle={referencess?.[170]?.[lang === 'en' ? 'en content' : 'ar content']} />
        <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
    <FirstSection lang={lang}
      firstBoxType={referencess?.[171]?.[lang === 'en' ? 'en content' : 'ar content']} 
      firstBoxDis1={referencess?.[172]?.[lang === 'en' ? 'en content' : 'ar content']} 
      scoBoxType={referencess?.[173]?.[lang === 'en' ? 'en content' : 'ar content']}
      scoBoxDis={referencess?.[174]?.[lang === 'en' ? 'en content' : 'ar content']}
      thirdBoxDis1={referencess?.[175]?.[lang === 'en' ? 'en content' : 'ar content']} 
      thirdBoxDis2={referencess?.[176]?.[lang === 'en' ? 'en content' : 'ar content']} 
      colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes1?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxes1bench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>
      )}
    />
    <ScoSection 
    lang={lang}
      firstBoxTitle={referencess?.[177]?.[lang === 'en' ? 'en content' : 'ar content']}
      Box1listItem1={referencess?.[178]?.[lang === 'en' ? 'en content' : 'ar content']}
      Box1listItem2={referencess?.[179]?.[lang === 'en' ? 'en content' : 'ar content']}
      Box1listItem3={referencess?.[180]?.[lang === 'en' ? 'en content' : 'ar content']}
      Box1listItem4={referencess?.[181]?.[lang === 'en' ? 'en content' : 'ar content']}
      Box1listItem5={referencess?.[182]?.[lang === 'en' ? 'en content' : 'ar content']}
      scoBoxTitle={referencess?.[183]?.[lang === 'en' ? 'en content' : 'ar content']}
      Box2listItem1={referencess?.[184]?.[lang === 'en' ? 'en content' : 'ar content']}
      Box2listItem2={referencess?.[185]?.[lang === 'en' ? 'en content' : 'ar content']}
      Box2listItem3={referencess?.[186]?.[lang === 'en' ? 'en content' : 'ar content']}
      Box2listItem4={referencess?.[187]?.[lang === 'en' ? 'en content' : 'ar content']}
      Box2listItem5={referencess?.[188]?.[lang === 'en' ? 'en content' : 'ar content']}
    />
    <ThirdSection lang={lang}
      Box3Title={referencess?.[189]?.[lang === 'en' ? 'en content' : 'ar content']}
      line1item1={referencess?.[190]?.[lang === 'en' ? 'en content' : 'ar content']}
      line1item2={referencess?.[191]?.[lang === 'en' ? 'en content' : 'ar content']}
      line1item3={referencess?.[192]?.[lang === 'en' ? 'en content' : 'ar content']}
      line2item1={referencess?.[193]?.[lang === 'en' ? 'en content' : 'ar content']}
      line2item2={referencess?.[194]?.[lang === 'en' ? 'en content' : 'ar content']}
      line2item3={referencess?.[195]?.[lang === 'en' ? 'en content' : 'ar content']}
      line3item1={referencess?.[196]?.[lang === 'en' ? 'en content' : 'ar content']}
      line3item2={referencess?.[197]?.[lang === 'en' ? 'en content' : 'ar content']}
      line3item3={referencess?.[198]?.[lang === 'en' ? 'en content' : 'ar content']}
      line4item1={referencess?.[199]?.[lang === 'en' ? 'en content' : 'ar content']}
      line4item2={referencess?.[200]?.[lang === 'en' ? 'en content' : 'ar content']}
      line4item3={referencess?.[201]?.[lang === 'en' ? 'en content' : 'ar content']}
    />
    <ForthSection 
      Box3Title={referencess?.[202]?.[lang === 'en' ? 'en content' : 'ar content']}
      line1item1={referencess?.[203]?.[lang === 'en' ? 'en content' : 'ar content']}
      line1item2={referencess?.[204]?.[lang === 'en' ? 'en content' : 'ar content']}
      line1item3={referencess?.[205]?.[lang === 'en' ? 'en content' : 'ar content']}
      line2item1={referencess?.[206]?.[lang === 'en' ? 'en content' : 'ar content']}
      line2item2={referencess?.[207]?.[lang === 'en' ? 'en content' : 'ar content']}
      line2item3={referencess?.[208]?.[lang === 'en' ? 'en content' : 'ar content']}
      line3item1={referencess?.[209]?.[lang === 'en' ? 'en content' : 'ar content']}
      line3item2={referencess?.[210]?.[lang === 'en' ? 'en content' : 'ar content']}
      line3item3={referencess?.[211]?.[lang === 'en' ? 'en content' : 'ar content']}
      line4item1={referencess?.[212]?.[lang === 'en' ? 'en content' : 'ar content']}
      line4item2={referencess?.[213]?.[lang === 'en' ? 'en content' : 'ar content']}
      line4item3={referencess?.[214]?.[lang === 'en' ? 'en content' : 'ar content']}
    />
        </Stack>
      </Page>
      <Page srcPage={P8}>
          <Header lang={lang} name={name} date={date}  headerTitle={referencess?.[170]?.[lang === 'en' ? 'en content' : 'ar content']}/>
          <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
            <FirstSection lang={lang} 
            firstBoxType={referencess?.[216]?.[lang === 'en' ? 'en content' : 'ar content']} 
        firstBoxDis1={referencess?.[217]?.[lang === 'en' ? 'en content' : 'ar content']} 
        scoBoxType={referencess?.[218]?.[lang === 'en' ? 'en content' : 'ar content']}
        scoBoxDis={referencess?.[219]?.[lang === 'en' ? 'en content' : 'ar content']}
        thirdBoxDis1={referencess?.[220]?.[lang === 'en' ? 'en content' : 'ar content']} 
        thirdBoxDis2={referencess?.[221]?.[lang === 'en' ? 'en content' : 'ar content']} 
        colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxesbench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>
                )}
                />
                    <ScoSection 
    lang={lang}
                firstBoxTitle={referencess?.[222]?.[lang === 'en' ? 'en content' : 'ar content']}
Box1listItem1={referencess?.[223]?.[lang === 'en' ? 'en content' : 'ar content']}
Box1listItem2={referencess?.[224]?.[lang === 'en' ? 'en content' : 'ar content']}
Box1listItem3={referencess?.[225]?.[lang === 'en' ? 'en content' : 'ar content']}
Box1listItem4={referencess?.[226]?.[lang === 'en' ? 'en content' : 'ar content']}
Box1listItem5={referencess?.[227]?.[lang === 'en' ? 'en content' : 'ar content']}
scoBoxTitle={referencess?.[228]?.[lang === 'en' ? 'en content' : 'ar content']}
Box2listItem1={referencess?.[229]?.[lang === 'en' ? 'en content' : 'ar content']}
Box2listItem2={referencess?.[230]?.[lang === 'en' ? 'en content' : 'ar content']}
Box2listItem3={referencess?.[231]?.[lang === 'en' ? 'en content' : 'ar content']}
Box2listItem4={referencess?.[232]?.[lang === 'en' ? 'en content' : 'ar content']}
                Box2listItem5={referencess?.[233]?.[lang === 'en' ? 'en content' : 'ar content']}
                />
                <ThirdSection lang={lang} 
                Box3Title={referencess?.[234]?.[lang === 'en' ? 'en content' : 'ar content']}
line1item1={referencess?.[235]?.[lang === 'en' ? 'en content' : 'ar content']}
line1item2={referencess?.[236]?.[lang === 'en' ? 'en content' : 'ar content']}
line1item3={referencess?.[237]?.[lang === 'en' ? 'en content' : 'ar content']}
line2item1={referencess?.[238]?.[lang === 'en' ? 'en content' : 'ar content']}
line2item2={referencess?.[239]?.[lang === 'en' ? 'en content' : 'ar content']}
line2item3={referencess?.[240]?.[lang === 'en' ? 'en content' : 'ar content']}
line3item1={referencess?.[241]?.[lang === 'en' ? 'en content' : 'ar content']}
line3item2={referencess?.[242]?.[lang === 'en' ? 'en content' : 'ar content']}
line3item3={referencess?.[243]?.[lang === 'en' ? 'en content' : 'ar content']}
line4item1={referencess?.[244]?.[lang === 'en' ? 'en content' : 'ar content']}
line4item2={referencess?.[245]?.[lang === 'en' ? 'en content' : 'ar content']}
                line4item3={referencess?.[246]?.[lang === 'en' ? 'en content' : 'ar content']}
                />
                <ForthSection 
                Box3Title={referencess?.[247]?.[lang === 'en' ? 'en content' : 'ar content']}
line1item1={referencess?.[248]?.[lang === 'en' ? 'en content' : 'ar content']}
line1item2={referencess?.[249]?.[lang === 'en' ? 'en content' : 'ar content']}
line1item3={referencess?.[250]?.[lang === 'en' ? 'en content' : 'ar content']}
line2item1={referencess?.[251]?.[lang === 'en' ? 'en content' : 'ar content']}
line2item2={referencess?.[252]?.[lang === 'en' ? 'en content' : 'ar content']}
line2item3={referencess?.[253]?.[lang === 'en' ? 'en content' : 'ar content']}
line3item1={referencess?.[254]?.[lang === 'en' ? 'en content' : 'ar content']}
line3item2={referencess?.[255]?.[lang === 'en' ? 'en content' : 'ar content']}
line3item3={referencess?.[256]?.[lang === 'en' ? 'en content' : 'ar content']}
line4item1={referencess?.[257]?.[lang === 'en' ? 'en content' : 'ar content']}
line4item2={referencess?.[258]?.[lang === 'en' ? 'en content' : 'ar content']}
                line4item3={referencess?.[259]?.[lang === 'en' ? 'en content' : 'ar content']}
                />
            </Stack>
      </Page>
      <Page srcPage={P9}>
          <Header lang={lang} name={name} date={date}  headerTitle={referencess?.[260]?.[lang === 'en' ? 'en content' : 'ar content']}/>
          <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
            <FirstSection lang={lang} 
            firstBoxType={referencess?.[261]?.[lang === 'en' ? 'en content' : 'ar content']} 
        firstBoxDis1={referencess?.[262]?.[lang === 'en' ? 'en content' : 'ar content']} 
        scoBoxType={referencess?.[263]?.[lang === 'en' ? 'en content' : 'ar content']}
        scoBoxDis={referencess?.[264]?.[lang === 'en' ? 'en content' : 'ar content']}
        thirdBoxDis1={referencess?.[265]?.[lang === 'en' ? 'en content' : 'ar content']} 
        thirdBoxDis2={referencess?.[266]?.[lang === 'en' ? 'en content' : 'ar content']} 
        colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes2?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxes2bench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>
                )}
                />
                    <ScoSection 
    lang={lang}
                firstBoxTitle={referencess?.[267]?.[lang === 'en' ? 'en content' : 'ar content']}
Box1listItem1={referencess?.[268]?.[lang === 'en' ? 'en content' : 'ar content']}
Box1listItem2={referencess?.[269]?.[lang === 'en' ? 'en content' : 'ar content']}
Box1listItem3={referencess?.[270]?.[lang === 'en' ? 'en content' : 'ar content']}
Box1listItem4={referencess?.[271]?.[lang === 'en' ? 'en content' : 'ar content']}
Box1listItem5={referencess?.[272]?.[lang === 'en' ? 'en content' : 'ar content']}
scoBoxTitle={referencess?.[273]?.[lang === 'en' ? 'en content' : 'ar content']}
Box2listItem1={referencess?.[274]?.[lang === 'en' ? 'en content' : 'ar content']}
Box2listItem2={referencess?.[275]?.[lang === 'en' ? 'en content' : 'ar content']}
Box2listItem3={referencess?.[276]?.[lang === 'en' ? 'en content' : 'ar content']}
Box2listItem4={referencess?.[277]?.[lang === 'en' ? 'en content' : 'ar content']}
                Box2listItem5={referencess?.[278]?.[lang === 'en' ? 'en content' : 'ar content']}
                />
                <ThirdSection lang={lang} 
                Box3Title={referencess?.[279]?.[lang === 'en' ? 'en content' : 'ar content']}
line1item1={referencess?.[280]?.[lang === 'en' ? 'en content' : 'ar content']}
line1item2={referencess?.[281]?.[lang === 'en' ? 'en content' : 'ar content']}
line1item3={referencess?.[282]?.[lang === 'en' ? 'en content' : 'ar content']}
line2item1={referencess?.[283]?.[lang === 'en' ? 'en content' : 'ar content']}
line2item2={referencess?.[284]?.[lang === 'en' ? 'en content' : 'ar content']}
line2item3={referencess?.[285]?.[lang === 'en' ? 'en content' : 'ar content']}
line3item1={referencess?.[286]?.[lang === 'en' ? 'en content' : 'ar content']}
line3item2={referencess?.[287]?.[lang === 'en' ? 'en content' : 'ar content']}
line3item3={referencess?.[288]?.[lang === 'en' ? 'en content' : 'ar content']}
line4item1={referencess?.[289]?.[lang === 'en' ? 'en content' : 'ar content']}
line4item2={referencess?.[290]?.[lang === 'en' ? 'en content' : 'ar content']}
                line4item3={referencess?.[291]?.[lang === 'en' ? 'en content' : 'ar content']}
                />
                <ForthSection 
                Box3Title={referencess?.[292]?.[lang === 'en' ? 'en content' : 'ar content']}
line1item1={referencess?.[293]?.[lang === 'en' ? 'en content' : 'ar content']}
line1item2={referencess?.[294]?.[lang === 'en' ? 'en content' : 'ar content']}
line1item3={referencess?.[295]?.[lang === 'en' ? 'en content' : 'ar content']}
line2item1={referencess?.[296]?.[lang === 'en' ? 'en content' : 'ar content']}
line2item2={referencess?.[297]?.[lang === 'en' ? 'en content' : 'ar content']}
line2item3={referencess?.[298]?.[lang === 'en' ? 'en content' : 'ar content']}
line3item1={referencess?.[299]?.[lang === 'en' ? 'en content' : 'ar content']}
line3item2={referencess?.[300]?.[lang === 'en' ? 'en content' : 'ar content']}
line3item3={referencess?.[301]?.[lang === 'en' ? 'en content' : 'ar content']}
line4item1={referencess?.[302]?.[lang === 'en' ? 'en content' : 'ar content']}
line4item2={referencess?.[303]?.[lang === 'en' ? 'en content' : 'ar content']}
                line4item3={referencess?.[304]?.[lang === 'en' ? 'en content' : 'ar content']}
                />
            </Stack>
      </Page>
    </Box>
  );
}

export default Firstpart;
