import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useContext, useEffect, useState } from "react";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ApiContext from "../../../Context/context";
import ConfirmPay from "./Dialog/ConfirmPay";
import LoadingSpinner from "../../../../../../FemilerCom/loading/spinner";

// Extracted QuantitySelector Component
const QuantitySelector = ({ count, setCount }) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <IconButton
      size="small"
      sx={{
        borderRadius: "10px",
        color: "white",
        background: "#2b5b5b",
        "&:hover": {
          background: "#2b5b5b",
        },
      }}
      onClick={() => setCount(count > 1 ? count - 1 : 1)}
    >
      <RemoveIcon fontSize="small" />
    </IconButton>
    <Box
      sx={{
        width: 30,
        height: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #ccc",
        borderRadius: "4px",
      }}
    >
      <Typography variant="body2">{count}</Typography>
    </Box>
    <IconButton
      size="small"
      sx={{
        borderRadius: "10px",
        color: "white",
        background: "#2b5b5b",
        "&:hover": {
          background: "#2b5b5b",
        },
      }}
      onClick={() => setCount(count + 1)}
    >
      <AddIcon fontSize="small" />
    </IconButton>
  </Stack>
);

// Extracted BuyButton Component
const BuyButton = ({ onClick, disabled = false }) => (
  <Button
    onClick={onClick}
    variant="contained"
    disabled={disabled}
    sx={{
      color: "white",
      width: "250px",
      height: '50%',
      margin: "20px auto",
      background: "linear-gradient(45deg, #77c853, #347461)",
      "&:disabled": {
        background: "#ccc",
      },
    }}
  >
    Buy Now
  </Button>
);

export default function Flex() {
  const { packages, setShouldFetchPackages, loading, setFetchStatisticsTrigger } = useContext(ApiContext);
  const isMobile = useMediaQuery("(max-width:600px)");

  // State to track counts for each package
  const [packageCounts, setPackageCounts] = useState({});
  const [showText, setShowText] = useState(false);
  const [opeConfirmPayDialog, setOpeConfirmPayDialog] = useState(false);
  const [currentPackage, setCurrentPackage] = useState(null);

  useEffect(() => {
    if (setShouldFetchPackages) {
      setShouldFetchPackages(true);
    }

    // Initialize counts when packages are loaded
    if (packages) {
      const initialCounts = {};
      packages
        .filter((ele) => ele.name === "Twindix Executives" || ele.name === "Job Bar")
        .forEach((exam) => {
          initialCounts[exam.id] = {
            examCount: 1,
            debriefingCount: 1
          };
        });
      setPackageCounts(initialCounts);
    }
  }, [setShouldFetchPackages, packages]);

  const toggleText = () => {
    setShowText((prev) => !prev);
  };

  const handleBuyNow = (isDebriefing, examId) => {
      setCurrentPackage({
        id: examId,
        isDebriefing,
        count: isDebriefing 
          ? packageCounts[examId]?.debriefingCount || 1
          : packageCounts[examId]?.examCount || 1
      });
      setOpeConfirmPayDialog(true);
  };

  const updatePackageCount = (examId, key, value) => {
    setPackageCounts(prev => ({
      ...prev,
      [examId]: {
        ...prev[examId],
        [key]: value
      }
    }));
  };

  return (
    <>
      {loading && <LoadingSpinner />}

      {packages
        ?.filter((ele) => ele.name === "Twindix Executives" || ele.name === "Job Bar")
        ?.map((exam) => (
          <Stack
            key={exam.id}
            width="100%"
            direction={isMobile ? 'column' : "row"}
            spacing={4}
            m={isMobile ? 0 : 4}
            sx={{ borderBottom: "3px solid #4ca88f", position: "relative" }}
          >
            <Box sx={isMobile ? { width: '100%' } : {}}>
              <img
                style={{ width: isMobile && '100%' }}
                src={exam.cover_image}
                alt="Exam Cover"
              />
            </Box>

            <Stack flex={1}>
              <Typography
                m="auto"
                mb={2}
                sx={{fontSize: '24px', color: "#2b6565", textDecoration: "underline" }}
              >
                {exam.name}
              </Typography>

              <Typography
                maxWidth={700}
                textAlign="justify"
                ml={!isMobile && 8}
                sx={{ color: "#787878" }}
              >
                {exam.description}
              </Typography>

              <Typography m={3} ml={8}>
                <b>Please Note:</b>This product is delivered digitally. Payment & Delivery
              </Typography>

              <Stack
                spacing={3}
                m={2}
                direction={isMobile ? 'column' : "row"}
                width={isMobile ? '88%' : 500}
                justifyContent="space-between"
                alignItems='center'
              >
                <Stack width='100%' ml={3} justifyContent="center" alignItems="center">
                  <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: "#2b5b5b" }}>
                    {exam.price}$
                  </Typography>
                  <Typography
                    ml={isMobile ? '0' : '84px'}
                    sx={{ width: '100%', fontSize: "16px", fontWeight: "bold", color: "#2b5b5b", textAlign: isMobile && 'center' }}
                  >
                    Assessment {exam.id === 1 ? 'Only' : ''}
                  </Typography>
                </Stack>
                <Stack alignItems="center" justifyContent="center" spacing={2} direction='row'>
                  <Typography>Qty:</Typography>
                  <QuantitySelector 
                    count={packageCounts[exam.id]?.examCount || 1} 
                    setCount={(count) => updatePackageCount(exam.id, 'examCount', count)} 
                  />
                </Stack>
                <BuyButton onClick={() => handleBuyNow(false, exam.id)} />
              </Stack>

              {exam.exam_id === 1 && (
                <Stack
                  spacing={3}
                  m={2}
                  direction={isMobile ? 'column' : "row"}
                  width={isMobile ? '88%' : 500}
                  alignItems='center'
                  justifyContent="space-between"
                >
                  <Stack width='100%' justifyContent="center" alignItems="center">
                    <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: "#2b5b5b" }}>
                      {exam.price * 3.8}$
                    </Typography>
                    <Typography
                      ml='12px'
                      textAlign='center'
                      sx={{ width: '128%', fontSize: "16px", fontWeight: "bold", color: "#2b5b5b" }}
                    >
                      Assessment<br /> + Debriefing
                      <span display='inline' sx={{ cursor: 'pointer', width: '10px', color: '#2b6565' }} onClick={toggleText} variant="text">
                        <HelpOutlineIcon />
                      </span>
                    </Typography>
                  </Stack>
                  <Stack alignItems="center" justifyContent="center" spacing={2} direction="row">
                    <Typography>Qty:</Typography>
                    <QuantitySelector 
                      count={packageCounts[exam.id]?.debriefingCount || 1} 
                      setCount={(count) => updatePackageCount(exam.id, 'debriefingCount', count)} 
                    />
                  </Stack>
                  <BuyButton onClick={() => handleBuyNow(true, exam.id)} />
                </Stack>
              )}

              {showText && (
                <Box mb={3}>
                  The "Twindix Executives" Report's Debriefing Session is a
                  structured consultation conducted by one of our certified
                  experts. This session provides an in-depth analysis and
                  comprehensive explanation of the report, ensuring a clear
                  understanding of its findings. The objective is to maximize the
                  value of the report by enabling informed decision-making and
                  actionable insights, empowering executives to excel and adopt
                  best practices in management and organizational excellence.
                </Box>
              )}
            </Stack>
          </Stack>
        ))}

      <ConfirmPay
        isMobile={isMobile}
        opeConfirmPayDialog={opeConfirmPayDialog}
        setOpeConfirmPayDialog={setOpeConfirmPayDialog}
        isDebriefing={currentPackage?.isDebriefing || false}
        countDebriefing={currentPackage?.isDebriefing ? currentPackage?.count : 1}
        countExam={!currentPackage?.isDebriefing ? currentPackage?.count : 1}
        packageId={currentPackage?.id}
        setFetchStatisticsTrigger={setFetchStatisticsTrigger}
      />
    </>
  );
}