import { Box, ListItem, Stack, Typography } from "@mui/material";
import logo from '../../../Media/Logo/Job-Bar-Logo.jpg'
import logoDwn from '../../../Media/Logo/Job-Bar-Logo-Dwn.jpg'
import req from '../../../Media/ManCode/Req-ar.jpg';
import { useSearchParams } from "react-router-dom";

export  const Page = ({ srcPage, children, no }) => {
    return (
        <Box  sx={{fontFamily: 'var(--Regular-Font-Family)'}} height={no ? '' : '1400px'} m={no ? 'auto' : ''} width={no ? '650px' : '100%'}  position="relative">
            <img src={srcPage} alt="1" style={{ width: '100%', height :no ? '': '100%' }} />
            <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                display="flex"
                flexDirection="column"
                pointerEvents="none"
            >
            {children}
            </Box>
            <Stack position='absolute' bottom='0px' left='0' alignItems='center' direction='row-reverse'>
              <Box><img style={{height: '50xp', width: '70px', margin : '10px'}}  src={logoDwn} alt='logo' /></Box>
              <Typography>JOP BAR by TWINDIX | Copyright 2016, 2024 TWINDIX, inc</Typography>
            </Stack>
        </Box>
    );
    };
export   const Header = ({lang, no, date,  headerTitle }) => {
    const isEng = lang ==='en'
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");
    return (
        <Stack mt={no ? '' : "28px"} direction='row' justifyContent='space-between' alignItems='center' width='100%' height='126px'>
        <Box mr="20px"><img style={{height: '100xp', width: '190px'}}  src={logo} alt='logo' /></Box>
        <Typography fontSize={isEng ? '2rem' : '3rem'} mt={no ? "150px" : "15px"} textAlign='center' width='550px' variant='h3' Component='h3' fontWeight='bold' sx={{fontFamily: 'var(--Bold-Font-Family)', color: '#5d5d5d !important'}}>{headerTitle}</Typography>
        <Stack alignItems='center' justifyContent='center' ml='20px'>
        <Box Component='h3' fontSize='30px' fontWeight='bold' sx={{fontFamily: 'var(--Bold-Font-Family)', color: '#5d5d5d !important'}} >{name}</Box>
        <Box Component='h3' fontSize='30px' fontWeight='bold' sx={{fontFamily: 'var(--Bold-Font-Family)', color: '#5d5d5d !important'}} >{date}</Box>
        </Stack>
    </Stack>
    );
    };

export const FirstSection =  ({
  yellow = false,
  firstBoxType,
    firstBoxDis1,
    scoBoxType,
    scoBoxDis,
    thirdBoxDis1,
    thirdBoxDis2,
    colors,
    lang
  }) =>  {
    const isEng = lang ==='en'

    return (
        <Stack  sx={{fontFamily: 'var(--Bold-Font-Family)'}} width='94%' mr='18px'  height='74px' direction='row'>
          <Stack direction='row' width='calc(100% / 3)'>
            <Box position='relative' left='-20px' textAlign='center' alignContent='center' top="-5px" fontSize='17px' fontWeight='bold' width='20%' color={yellow ? 'black':'white' }>{firstBoxType}</Box>
            <Box textAlign='center' alignContent='center' fontSize='18px' fontWeight='bold'  width='72%'>{firstBoxDis1}</Box>
          </Stack>
          <Stack direction='row' width='calc(100% / 3)'  mr='8px'>
            <Box position='relative' left='-25px' textAlign='center' alignContent='center' fontSize={isEng ? '11px' : '17px'} top="-5px" fontWeight='bold' width='20%' color={yellow ? 'black':'white' }>{scoBoxType}</Box>
            <Box position='relative' left='-10px' textAlign='center' alignContent='center' fontSize='18px' fontWeight='bold' width='72%'>{scoBoxDis}</Box>
          </Stack>
          <Stack  direction='row' width='calc(100% / 3)'>
            <Box width='38%' mr='14px' textAlign='center'>
              <Box fontSize='20px' fontWeight='bold' height='50%'>{thirdBoxDis1}</Box>
              <Box fontSize='20px' fontWeight='bold' height='50%'>{thirdBoxDis2}</Box>
            </Box>
            {colors()}
          </Stack>
        </Stack>
    )
}

export const ScoSection = ({
    firstBoxTitle,
Box1listItem1,
Box1listItem2,
Box1listItem3,
Box1listItem4,
Box1listItem5,
scoBoxTitle,
Box2listItem1,
Box2listItem2,
Box2listItem3,
Box2listItem4,
Box2listItem5,
lang
}) => {
  const isEng = lang ==='en'

    return (
        <Stack mt="38px !important" direction='row' width='97%' mr='12px !important' height='357px'>
        <Box  width='511px'  height='106%' sx={{position: 'relative', bottom: '27px'}}>
        <Typography variant="span" Component='span' fontSize={isEng ? '23px' : '28px'} sx={{direction: isEng && 'ltr',fontFamily: 'var(--Regular-Font-Family)'}}  width='100%' >{firstBoxTitle}</Typography>
          <ListItem sx={{ textAlign: isEng ? 'left' : 'right',direction : isEng && 'ltr', fontSize: isEng ? '15px' : '19px',height: '34px', margin: '17px 0'}}>
          • {Box1listItem1}</ListItem>
          <ListItem sx={{ textAlign: isEng ? 'left' : 'right',direction : isEng && 'ltr', fontSize: isEng ? '15px' : '19px',height: '34px', margin: '17px 0'}}>
          • {Box1listItem2}</ListItem>
          <ListItem sx={{ textAlign: isEng ? 'left' : 'right',direction : isEng && 'ltr', fontSize: isEng ? '15px' : '19px',height: '34px', margin: '17px 0'}}>
          • {Box1listItem3}</ListItem>
          <ListItem sx={{ textAlign: isEng ? 'left' : 'right',direction : isEng && 'ltr', fontSize: isEng ? '15px' : '19px',height: '34px', margin: '17px 0'}}>
          • {Box1listItem4}</ListItem>
          <ListItem sx={{ textAlign: isEng ? 'left' : 'right',direction : isEng && 'ltr', fontSize: isEng ? '15px' : '19px',height: '34px', margin: '17px 0'}}>
          • {Box1listItem5}</ListItem>
        </Box>
        <Box width='493px' height='106%' sx={{position: 'relative', bottom: '26px', right: '18px'}}>
        <Typography variant="span" Component='span' fontSize={isEng ? '23px' : '28px'} sx={{direction: isEng && 'ltr', fontFamily: 'var(--Regular-Font-Family)'}} mb='26px' width='100%' >{scoBoxTitle}</Typography>
          <ListItem sx={{ textAlign: isEng ? 'left' : 'right',direction : isEng && 'ltr', fontSize: isEng ? '15px' : '19px',height: '34px', margin: '17px 0'}}>
          • {Box2listItem1}</ListItem>
          <ListItem sx={{ textAlign: isEng ? 'left' : 'right',direction : isEng && 'ltr', fontSize: isEng ? '15px' : '19px',height: '34px', margin: '17px 0'}}>
          • {Box2listItem2}</ListItem>
          <ListItem sx={{ textAlign: isEng ? 'left' : 'right',direction : isEng && 'ltr', fontSize: isEng ? '15px' : '19px',height: '34px', margin: '17px 0'}}>
          • {Box2listItem3}</ListItem>
          <ListItem sx={{ textAlign: isEng ? 'left' : 'right',direction : isEng && 'ltr', fontSize: isEng ? '15px' : '19px',height: '34px', margin: '17px 0'}}>
          • {Box2listItem4}</ListItem>
          <ListItem sx={{ textAlign: isEng ? 'left' : 'right',direction : isEng && 'ltr', fontSize: isEng ? '15px' : '19px',height: '34px', margin: '17px 0'}}>
          • {Box2listItem5}</ListItem>
        </Box>
      </Stack>
    )
}

export const ThirdSection = ({
    Box3Title,
line1item1,
line1item2,
line1item3,
line2item1,
line2item2,
line2item3,
line3item1,
line3item2,
line3item3,
line4item1,
line4item2,
line4item3,
lang
}) => {
  const isEng = lang ==='en'

    return(
        <Stack sx={{fontFamily: 'var(--Regular-Font-Family)'}} width='95%' mr="13px !important" height='315px' mt='4px !important'>
        <Typography sx={{fontFamily: 'var(--Regular-Font-Family)'}} mr='12px !important'  fontSize='28px' >{Box3Title}</Typography>
        <Stack direction='row' mt='16px' height='260px'>
        <Stack width='222px' mr="19px" height='100%'>
            <Box fontSize={isEng ? '13px' :'15px'} fontWeight='700' sx={{direction :isEng && 'ltr', position: 'relative',top: '29px',height: '46px',width: '100%'}} textAlign='center' alignContent='center' >{line1item1}</Box>
            <Box fontSize={isEng ? '13px' :'15px'} fontWeight='700' sx={{direction :isEng && 'ltr',mt :'32px', position: 'relative',top: '29px',height: '46px',width: '100%'}} textAlign='center' alignContent='center' >{line1item2}</Box>
            <Box fontSize={isEng ? '13px' :'15px'} fontWeight='700' sx={{direction :isEng && 'ltr',mt:'30px', position: 'relative',top: '29px',height: '46px',width: '100%'}} textAlign='center' alignContent='center' >{line1item3}</Box>
          </Stack>
          <Stack width='222px' mr="35px" height='100%'>
            <Box fontSize={isEng ? '13px' :'15px'} fontWeight='700' sx={{direction :isEng && 'ltr', position: 'relative',top: '29px',height: '46px',width: '100%'}} textAlign='center' alignContent='center' >{line2item1}</Box>
            <Box fontSize={isEng ? '13px' :'15px'} fontWeight='700' sx={{direction :isEng && 'ltr',mt :'32px', position: 'relative',top: '29px',height: '46px',width: '100%'}} textAlign='center' alignContent='center' >{line2item2}</Box>
            <Box fontSize={isEng ? '13px' :'15px'} fontWeight='700' sx={{direction :isEng && 'ltr',mt:'30px', position: 'relative',top: '29px',height: '46px',width: '100%'}} textAlign='center' alignContent='center' >{line2item3}</Box>
          </Stack>
          <Stack width='222px' mr="35px" height='100%'>
            <Box fontSize={isEng ? '13px' :'15px'} fontWeight='700' sx={{ direction :isEng && 'ltr',position: 'relative',top: '29px',height: '46px',width: '100%'}}  textAlign='center' alignContent='center' >{line3item1}</Box>
            <Box fontSize={isEng ? '13px' :'15px'} fontWeight='700' marginLeft='6px' sx={{direction :isEng && 'ltr',mt :'32px', position: 'relative',top: '29px',height: '46px',width: '100%'}}  textAlign='center' alignContent='center' >{line3item2}</Box>
            <Box fontSize={isEng ? '13px' :'15px'} fontWeight='700' sx={{direction :isEng && 'ltr',mt:'30px', position: 'relative',top: '29px',height: '46px',width: '100%'}} textAlign='center' alignContent='center' >{line3item3}</Box>
          </Stack>
          <Stack width='222px' mr="35px" height='100%'>
            <Box fontSize={isEng ? '13px' :'15px'} fontWeight='700'  sx={{direction :isEng && 'ltr', position: 'relative',top: '29px',height: '46px',width: '100%'}} textAlign='center' alignContent='center' >{line4item1}</Box>
            <Box fontSize={isEng ? '13px' :'15px'} fontWeight='700'  sx={{direction :isEng && 'ltr',mt :'32px', position: 'relative',top: '29px',height: '46px',width: '100%'}} marginLeft='6px'  textAlign='center' alignContent='center' >{line4item2}</Box>
            <Box fontSize={isEng ? '13px' :'15px'} fontWeight='700'  sx={{direction :isEng && 'ltr',mt:'30px', position: 'relative',top: '29px',height: '46px',width: '100%'}} marginLeft='6px' textAlign='center' alignContent='center' >{line4item3}</Box>
          </Stack>
        </Stack>
        </Stack>
    )
}

export const ForthSection = ({
  yellow = false,
    Box3Title,
line1item1,
line1item2,
line1item3,
line2item1,
line2item2,
line2item3,
line3item1,
line3item2,
line3item3,
line4item1,
line4item2,
line4item3,
}) => {
    return(
        <Stack sx={{fontFamily: 'var(--Regular-Font-Family)', position: 'relative', left : '-11px'}}  mt='0 !important' width='97%' height='253px'>
        <Typography sx={{fontFamily: 'var(--Regular-Font-Family)'}} mr='34px !important' variant='h6' fontSize='28px' Component='h6'>{Box3Title}</Typography>
        <Stack direction='row' justifyContent='space-between' mr="20px" width='95%' height='97%' mt="25px">
        <Stack mt='-1.4px' justifyContent='space-between' width ='calc((100% / 4) - 25px)' height='100%' >
            <Box height='calc((100% / 3) - 16px)' fontSize='16px'  color={yellow  ? 'black' : 'white'}  textAlign='center' alignContent='center' >{line1item1}</Box>
            <Box height='calc((100% / 3) - 16px)' fontSize='16px'  color={yellow  ? 'black' : 'white'}  textAlign='center' alignContent='center' >{line1item2}</Box>
            <Box height='calc((100% / 3) - 16px)' fontSize='16px'  color={yellow  ? 'black' : 'white'}  textAlign='center' alignContent='center' >{line1item3}</Box>
          </Stack>
          <Stack mt='-1.4px' justifyContent='space-between' width ='calc((100% / 4) - 25px)' height='100%'>
            <Box fontSize='16px'  color={yellow  ? 'black' : 'white'}  height='calc((100% / 3) - 16px)' textAlign='center' alignContent='center' >{line2item1}</Box>
            <Box fontSize='16px'  color={yellow  ? 'black' : 'white'}  height='calc((100% / 3) - 16px)' textAlign='center' alignContent='center' >{line2item2}</Box>
            <Box fontSize='16px'  color={yellow  ? 'black' : 'white'}  height='calc((100% / 3) - 16px)' textAlign='center' alignContent='center' >{line2item3}</Box>
          </Stack>
          <Stack mt='-1.4px' justifyContent='space-between' width ='calc((100% / 4) - 25px)' height='100%'>
            <Box fontSize='16px'  color={yellow  ? 'black' : 'white'}  height='calc((100% / 3) - 16px)' textAlign='center' alignContent='center' >{line3item1}</Box>
            <Box fontSize='16px'  color={yellow  ? 'black' : 'white'}  height='calc((100% / 3) - 16px)' textAlign='center' alignContent='center' >{line3item2}</Box>
            <Box fontSize='16px'  color={yellow  ? 'black' : 'white'}  height='calc((100% / 3) - 16px)' textAlign='center' alignContent='center' >{line3item3}</Box>
          </Stack>
          <Stack mt='-1.4px' justifyContent='space-between' width ='calc((100% / 4) - 25px)' height='100%'>
            <Box fontSize='16px'  color={yellow  ? 'black' : 'white'}  height='calc((100% / 3) - 16px)' textAlign='center' alignContent='center' >{line4item1}</Box>
            <Box fontSize='16px'  color={yellow  ? 'black' : 'white'}  height='calc((100% / 3) - 16px)' textAlign='center' alignContent='center' >{line4item2}</Box>
            <Box fontSize='16px'  color={yellow  ? 'black' : 'white'}  height='calc((100% / 3) - 16px)' textAlign='center' alignContent='center' >{line4item3}</Box>
          </Stack>
        </Stack>
        </Stack>
    )
}


export const ManyBoxsSection = ({
  ManyBox1,
  ManyBox2,
  ManyBox3,
  ManyBox4,
  ManyBox5,
}) => {
    return(
      <Stack sx={{position: 'relative',top: '-10px', right: '20px'}}>
            <Stack  direction='row' width={145} height={160} >
              <Stack spacing={.6}>
                  <Box width='50px' height='20px'><img style={{width: '100%', height: '100%'}} src={req} alt='req' /></Box>
                  <Box width='50px' height='20px'><img style={{width: '100%', height: '100%'}} src={req} alt='req' /></Box>
                  <Box width='50px' height='20px'><img style={{width: '100%', height: '100%'}} src={req} alt='req' /></Box>
                  <Box width='50px' height='20px'><img style={{width: '100%', height: '100%'}} src={req} alt='req' /></Box>
                  <Box width='50px' height='20px'><img style={{width: '100%', height: '100%'}} src={req} alt='req' /></Box>
              </Stack>
              <Stack  spacing={.5}>
                  <Box sx={{background: '#323283', color : 'white'}} position='relative' top='4px' right='4.5px' width='90px'  borderRadius={5} textAlign='center'  height='21px' fontSize={13}>{ManyBox1}</Box>
                  <Box sx={{background: '#323283', color : 'white'}} position='relative' top='4px' right='4.5px' width='90px'  borderRadius={5} textAlign='center'  height='21px' fontSize={13}>{ManyBox2}</Box>
                  <Box sx={{background: '#323283', color : 'white'}} position='relative' top='4px' right='4.5px' width='90px'  borderRadius={5} textAlign='center'  height='21px' fontSize={13}>{ManyBox3}</Box>
                  <Box sx={{background: '#323283', color : 'white'}} position='relative' top='4px' right='4.5px' width='90px'  borderRadius={5} textAlign='center'  height='21px' fontSize={13}>{ManyBox4}</Box>
                  <Box sx={{background: '#323283', color : 'white'}} position='relative' top='4px' right='4.5px' width='90px'  borderRadius={5} textAlign='center'  height='21px' fontSize={13}>{ManyBox5}</Box>
              </Stack>
            </Stack>
      </Stack>
    )
}

