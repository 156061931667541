import {useNavigate } from "react-router"
import LogInBoxForAdmin from "../../Component/LoginForAdmin/LogInForAdminCom"
function LogInForAdmin () {
    let navigate = useNavigate()
    window.onload = () => {
        if(localStorage.isAdmin && localStorage.token) {
            navigate("/admin")
        }
    }
    return (
            <LogInBoxForAdmin />
    )
}

export default LogInForAdmin