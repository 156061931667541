import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, MenuItem, TextField, Typography, useMediaQuery } from "@mui/material";
import axios from "axios";
import ApiContext from "../Context/context";

export default function AddCompanyForm({ open, setOpen , exam_id, setExam_id}) {
  const isMobile = useMediaQuery("(max-width:600px)");

  const {setFetchCompaniesTrigger} = useContext(ApiContext)
  const [CompanyForm, setCompanyForm] = useState({
    name : '', 
    contactPerson : '', 
    phoneNumber : '', 
    contact_person_job :'',
    mail :'',
  })
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyForm(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Close dialog
  const handleClose = () => {
    setOpen(false);
    setCompanyForm({
      name: '',
      contactPerson: '',
      phoneNumber: '',
      contact_person_job: '',
      mail: '',
    });
  };

  // Handle "Add Company" submission
  const handleAddCompany = () => {
    setLoading(true)
    const payload = {
      name: CompanyForm.name,
      contact_person: CompanyForm.contactPerson,
      contact_person_job : CompanyForm.contact_person_job,
      phone: CompanyForm.phoneNumber,
      exam_id:exam_id,
      mail : CompanyForm.mail,
    };

    axios
      .post("https://api.twindix.com/companies", payload, {
        headers: { Authorization: "Bearer " + localStorage.token },
      })
      .then((response) => {
        setFetchCompaniesTrigger((prev) => prev + 1)
        setLoading(false)
        setOpen(false); 
      })
      .catch((error) => {
        setLoading(false)
        console.error("Error adding company:", error);
        setError("Failed to add company. Please try again.");
      });
  };

  return (
    <React.Fragment>
      <Dialog 
      PaperProps={isMobile ? {
        sx: {
          position: "fixed",
          left: 60, // Align to the left border
          margin: 0,
          height: "100%",
          maxHeight: "100vh",
          borderRadius: isMobile ? 0 : "8px", // No border-radius in mobile
        },
      } : {}}
      maxWidth={isMobile ? "sm" : 'md'} open={open} onClose={handleClose}>
        <Box
          sx={{
            bgcolor: "#929292",
            width: isMobile ? 'auto' : "450px",
            height: isMobile ? '100%' : ''
          }}
        >
          <DialogTitle>
            <Typography sx={{color: 'white'}}>Add New Company</Typography>
          </DialogTitle>
          <DialogContent
            sx={{
              maxHeight: "500px",
              overflowY: "auto",
              "&::-webkit-scrollbar": { width: "6px" },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              },
              "&::-webkit-scrollbar-track": { background: "#929292", borderRadius: "10px" },
            }}
          >
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Company Name:</Typography>
                <input
                  name="name"
                  value={CompanyForm.name}
                  onChange={handleChange}
                  placeholder="Enter company name"
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Contact person mail:</Typography>
                <input
                name="mail"
                  value={CompanyForm.mail}
                  onChange={handleChange}
                  placeholder="Enter mail"
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Phone Number:</Typography>
                <input
                  type="number"
                  name="phoneNumber"
                  value={CompanyForm.phoneNumber}
                  onChange={handleChange}
                  placeholder="Enter phone number"
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>contact person name:</Typography>
                <input
                  name="contactPerson"
                  value={CompanyForm.contactPerson}
                  onChange={handleChange}
                  placeholder="Enter contact person name"
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>contact person job:</Typography>
                <input
                name="contact_person_job"
                  value={CompanyForm.contact_person_job}
                  onChange={handleChange}
                  placeholder="Enter contact person job"
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                />
              </Grid>
              {!exam_id &&
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Exam:</Typography>
                  <TextField
                    fullWidth
                    select
                    name="examId"
                    value={exam_id}
                    onChange={(e) => setExam_id(e.target.value)}
                    variant="outlined"
                    sx={{ backgroundColor: "white", borderRadius: "4px" }}
                  >
                    <MenuItem value="">Select an option</MenuItem>
                    <MenuItem value="1">Twindix</MenuItem>
                    <MenuItem value="65">Man Code</MenuItem>
                  </TextField>
                </Grid>
                }
            </Grid>
            {error && (
              <Typography color="red" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                borderRadius: "10px",
                margin: "auto",
              }}
              variant="contained"
              onClick={handleAddCompany}
              disabled={loading}
            >
              {loading ?  "Adding" : 'Add Company' } 
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
