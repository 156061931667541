import React, { useEffect } from 'react';
import { CircularProgress, Box } from '@mui/material';

const LoadingSpinner = () => {
  // Lock scrolling when the spinner is mounted
  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Disable scrolling
    return () => {
      document.body.style.overflow = 'auto'; // Re-enable scrolling when the component is unmounted
    };
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="background.default"
      position="fixed" // Fix the spinner to the viewport
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={9999} 
      sx={{opacity :'0.3'}}
      >
      <CircularProgress />
    </Box>
  );
};

export default LoadingSpinner;