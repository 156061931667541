import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, MenuItem, Select, Typography } from "@mui/material";


export default function EditEmployee ({
    isMobile,
    open,
    setOpen,
    currentEmployee,
    formData,
    setFormData,
    companies,
    setSelectedCompany,
    positions,
    selectedCompany,
    handleSubmit,
    
}) {

    return (
        <Dialog
        PaperProps={isMobile ? {
    sx: {
      position: "fixed",
      left: 70, // Align to the left border
      margin: 0,
      height: "auto",
      borderRadius: isMobile ? 0 : "8px", // No border-radius in mobile
    },
  }: {}}
  maxWidth={isMobile ? "sm" : 'md'} 
  open={open} onClose={() => setOpen(false)}>
    <Box
      sx={{
        bgcolor: "#929292",
        width: isMobile ? 'auto' : "450px",
        height: isMobile && '100%'
      }}
    >      
  <DialogTitle>
  <Typography sx={{fontSize: "24px",  color : 'white'}}>
    {currentEmployee ? "Edit Employee" : "Add Employee"}
  </Typography>
  </DialogTitle>
  <DialogContent>
    {/* Name Field */}
    <InputLabel sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px', mt: '20px' }}>Name</InputLabel>
    <input
          style={{
            padding: '10px',
            fontSize: '10px',
            border: '0',
            borderRadius: '4px',
            width: '100%',
            height: '40px',
            bgcolor: 'white'
          }}
      label="Name"
      value={formData.name}
      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
    />
    {/* Email Field */}
    <InputLabel sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px', mt: '20px' }}>Email</InputLabel>
    <input
          style={{
            padding: '10px',
            fontSize: '10px',
            border: '0',
            borderRadius: '4px',
            width: '100%',
            height: '40px',
            bgcolor: 'white'
          }}
      label="Email"
      value={formData.email}
      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
    />
    {/* Company Dropdown */}
    <InputLabel sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px', mt: '20px' }}>Company</InputLabel>
    <Select
                  sx={{
            padding: '10px',
            fontSize: '10px',
            border: '0',
            borderRadius: '4px',
            width: '100%',
            height: '40px',
            bgcolor: 'white'
          }}
      fullWidth
      value={formData?.company_id}
      onChange={(e) =>
        setFormData({ ...formData, company_id: e.target.value, position_id: "" })
      } // Reset position when company changes
    >
      <MenuItem value="">Select Company</MenuItem>
      {companies?.map((comp) => (
        <MenuItem key={comp?.id} value={comp?.id} onClick={() => setSelectedCompany(comp)}>
          {comp?.name}
        </MenuItem>
      ))}
    </Select>
    <InputLabel sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px', mt: '20px' }}>Position</InputLabel>
    <Select
                  sx={{
            padding: '10px',
            fontSize: '10px',
            border: '0',
            borderRadius: '4px',
            width: '100%',
            height: '40px',
            bgcolor: 'white'
          }}
      fullWidth
      value={formData.position_id}
      onChange={(e) =>
        setFormData({ ...formData, position_id: e.target.value })
      }
      disabled={!formData.company_id} // Disable if no company is selected
    >
      <MenuItem value="">Select Position</MenuItem>
      {positions?.filter((ele) => ele.company_id === selectedCompany.id)
      ?.map((pos) => (
        <MenuItem key={pos.id} value={pos.id}>
          {pos.title}
        </MenuItem>
      ))}
    </Select>
  </DialogContent>
  <DialogActions>
        <Button
          sx={{
            background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
            borderRadius: '10px',
            margin: 'auto',
          }}
          onClick={handleSubmit}
          variant="contained"
        >
          Edit Participant
        </Button>
      </DialogActions>
  </Box>
</Dialog>
    )
}