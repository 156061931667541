import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";

export default function Invite({
  isMobile,
  formOpen,
  handleClose,
  dialogContent,
  userId,
  selectedEmployees,
  handleClickSnackbar,
  setFetchStatisticsTrigger,
  company_id,
  setRefreshSpecCompany,
  setSnackbarText
}) {

  const [loading, setLoading] = useState(false);
  const [inviteResponses, setInviteResponses] = useState([])

const inviteUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    setInviteResponses([]); // Clear previous responses

    try {
        if (selectedEmployees.length > 1) {
            // Handle multiple invites
            const requests = selectedEmployees.map((employee) => {
                const inviteData = {
                    user_id: employee,
                    company_id: company_id,
                };

                return axios.post("https://api.twindix.com/invite", inviteData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.token,
                    },
                });
            });

            // Wait for all requests and capture responses
            const responses = await Promise.all(requests);
            setInviteResponses(responses.map(res => res.data)); // Store all responses
        } else {
            // Handle single invite
            const inviteData = {
                user_id: userId,
                company_id: company_id,
            };

            const response = await axios.post("https://api.twindix.com/invite", inviteData, {
                headers: {
                    Authorization: "Bearer " + localStorage.token,
                },
            });
            setInviteResponses([response.data]); // Store single response as array
        }

        setFetchStatisticsTrigger((prev) => prev + 1);
        setRefreshSpecCompany((prev) => prev + 1);
        setLoading(false);
        handleClose();
        setSnackbarText('Invites sent successfully!'); // Set success message
        handleClickSnackbar();
    } catch (error) {
        setLoading(false);
        console.error("Error sending invites:", error);
        
        // Set error message based on response
        const errorMessage = error.response?.data?.message || 'Failed to send invites';
        setSnackbarText(errorMessage);
        handleClickSnackbar();
    }
};

  return (
    <Dialog
      PaperProps={{
        sx: {
          position: isMobile ? "fixed" : "relative",
          left: isMobile ? 10 : "auto",
          margin: 0,
          height: isMobile ? "auto" : "fit-content",
          borderRadius: isMobile ? 0 : "8px",
          width: isMobile ? "100%" : "450px",
          maxWidth: isMobile ? "sm" : "md",
        },
      }}
      open={formOpen}
      onClose={handleClose}
    >
      <Box
        sx={{
          bgcolor: "#929292",
          width: "100%",
          height: isMobile ? "100%" : "auto",
        }}
      >
        <DialogContent
          sx={{
            maxHeight: "500px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
            },
            "&::-webkit-scrollbar-track": {
              background: "#929292",
              borderRadius: "10px",
            },
          }}
        >
          <Typography
            variant="h5"
            component="h5"
            fontSize={17}
            sx={{ color: "white" }}
          >
            {/* Are you sure you want to send an invite to {selectedUser?.name}? */}
          {dialogContent}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={inviteUser}
            sx={{
              background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              borderRadius: "10px",
              margin: "auto",
              color: "white",
              fontWeight: "bold",
              padding: "8px 16px",
            }}
            variant="contained"
          >
             {loading ? 'Sending' : 'Send Assessment' }
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}