import React, { useState } from "react";
import { Box, TextField, Typography, Button, Grid, Alert } from "@mui/material";
import {useSearchParams } from "react-router-dom";
import axios from "axios";

function ResetPassword() {

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [resetSent, setResetSent] = useState(false);
  const [error, setError] = useState("");

  // Extract token from the path

  // Extract email from the query string
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    if (error) setError("");
  };

  const handlePasswordConfirmationChange = (event) => {
    setPasswordConfirmation(event.target.value);
    if (error) setError("");
  };

  const handleResetSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    // Validate token and email
    if (!token || !email) {
      setError("Invalid reset link. Please request a new one.");
      return;
    }

    // Validate password match
    if (password !== passwordConfirmation) {
      setError("Passwords do not match.");
      return;
    }

    // Validate password strength (optional)
    if (password.length < 8) {
      setError("Password must be at least 8 characters long.");
      return;
    }

    try {
      const response = await axios.post(
        `https://api.twindix.com/auth/reset-password`,
        {
          password,
          password_confirmation: passwordConfirmation,
          token,
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle success
      if (response.status === 200) {
        setResetSent(true);
        setError("");
      }
    } catch (err) {
      console.error(err);
      setError(
        err.response?.data?.message ||
          "Failed to reset password. Please try again."
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
        padding: "20px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: "400px",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <img src="https://assessment.twindix.com/Login-logo.png" alt="Logo" />
        </Box>
        <Typography
          variant="h5"
          align="center"
          sx={{ mb: 2, color: "#2c6264", fontWeight: "bold" }}
        >
          RESET PASSWORD
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        {resetSent ? (
          <Alert severity="success" sx={{ mb: 2 }}>
            Password reset successful. You can now log in with your new password.
          </Alert>
        ) : (
          <form onSubmit={handleResetSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Password"
                  value={password}
                  onChange={handlePasswordChange}
                  type="password"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Confirm Password"
                  value={passwordConfirmation}
                  onChange={handlePasswordConfirmationChange}
                  type="password"
                  required
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, background: "linear-gradient(45deg, #2c6264, #5ac4cb)" }}
            >
              RESET PASSWORD
            </Button>
          </form>
        )}
      </Box>
    </Box>
  );
}

export default ResetPassword;