import { Box, Button, Dialog, DialogContent, DialogTitle, Stack, TextField, Typography, Snackbar } from "@mui/material";
import axios from "axios";
import { useState } from "react";

export default function EditPosition({
  isMobile,
  openEditPosition,
  setOpenEditPosition,
  setReFetchSpecificCompany,
  companyId,
  PositionId,
}) {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [currentPosition, setCurrentPosition] = useState("");

  const handleEdit = async () => {
    try {
      await axios.put(
        `https://api.twindix.com/companies/${companyId}/positions/${PositionId}`,
        { title: currentPosition.name },
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      );
      setOpenEditPosition(false);
      setCurrentPosition({ id: "", name: "" });
      setReFetchSpecificCompany((prev) => prev + 1)
      setSnackbarMessage("position Edit successful")
    } catch (error) {
      setSnackbarMessage("position not Edit")
      console.error("Error saving position:", error);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <Dialog
        PaperProps={
          isMobile ? {
            sx: {
              position: "fixed",
              left: 60,
              margin: 0,
              height: "100%",
              maxHeight: "100vh",
              borderRadius: isMobile ? 0 : "8px",
            },
          } : {}
        }
        maxWidth={isMobile ? "sm" : "md"}
        open={openEditPosition}
        onClose={() => setOpenEditPosition(false)}
      >
        <Box
          sx={{
            bgcolor: "#929292",
            width: isMobile ? "auto" : "450px",
            height: isMobile && "100%",
          }}
        >
          <DialogTitle id="edit-position-dialog-title">
            <Typography sx={{ color: "white" }}>Edit Position</Typography>
          </DialogTitle>
          <DialogContent>
            <TextField
              aria-label="Position Name"
              label="Position Name"
              value={currentPosition.name}
              onChange={(e) =>
                setCurrentPosition({ ...currentPosition, name: e.target.value })
              }
              fullWidth
              margin="normal"
              sx={{
                backgroundColor: "white",
                borderRadius: "4px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "#5ac4cb",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#5ac4cb",
                  },
                },
              }}
            />
          </DialogContent>
          <Stack justifyContent="center" alignItems="center" direction="row" spacing={2} sx={{ p: 2 }}>
            <Button onClick={() => setOpenEditPosition(false)}>Cancel</Button>
            <Button
            sx={{
              background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
            }}
              onClick={handleEdit}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Stack>
        </Box>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        action={
          <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
            Close
          </Button>
        }
        sx={{
          "& .MuiSnackbarContent-root": {
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
          },
        }}
      />
    </>
  );
}