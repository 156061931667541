import { Button, Divider, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Snackbar, useMediaQuery } from "@mui/material";
import AddTeamForm from "./Component/TeamAccess/Diaolgs/AddTeamForm";
import React, { useState, useEffect, useContext } from "react";
import AddRole from "./Component/RolesManagement/Diaolgs/AddRole";
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ApiContext from "../Context/context";
import { useSearch } from "../Context/SearchContext";
import DeleteDialog from "../../../../FemilerCom/DeleteDialog/DeleteDiaolg";
import EditTeamMember from "./Component/TeamAccess/Diaolgs/EditTeamMember";
import LoadingSpinner from "../../../../FemilerCom/loading/spinner";
function TeamAccess() {
  const {
    editors,
    setShouldFetchRoles,
    setShouldFetchEditors,
    setFetchEditorsTrigger,
    setFetchRolesTrigger,
    loading,
    handelPermission2
  } = useContext(ApiContext);
        handelPermission2(["employee-show", 'employee-create', 'employee-update', 'employee-delete', 'role-create'])


  const { searchQuery } = useSearch();
  const isMobile = useMediaQuery("(max-width:600px)");

  const [openAddTeamForm, setOpenAddTeamForm] = useState(false);
  const [openAddRole, setOpenAddRole] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false); // State to open the edit dialog
  const [editorId, setEditorId] = React.useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [TeamAccessId, setTeamAccessId] = useState()
  const [openDelete, setOpenDelete] = useState(false)

  useEffect(() => {
    if(setShouldFetchRoles){
      setShouldFetchRoles(true)
    }
    if(setShouldFetchEditors){
      setShouldFetchEditors(true)
    }
  }, [])

  const handleEditClick = () => setOpenEditDialog(true);
  const handleCloseEditDialog = () => setOpenEditDialog(false)
  const handleCloseDelete = () => setOpenDelete(false)

  const handleDelete = async (editorId) => {
    try {
      const response = await axios.delete(`https://api.twindix.com/editors/${editorId}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      if (response.status === 200 || response.status === 201) {
        handleClickSnackbar()
        setFetchEditorsTrigger((prev) => prev + 1)
      }
    } catch (error) {
      console.error('Error deleting editor:', error);
    }
  };

  const DeleteFun = () => {
      handleDelete(TeamAccessId)
      handleCloseDelete()
    }

  const filteredcompanies = editors?.filter((product) =>
      product.name?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  const handleClickSnackbar = () => {
      setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenSnackbar(false);
  };

  return (
    <>
      {loading && <LoadingSpinner />}

    <Stack direction="row" spacing={2}>
        <Button id="role-create" onClick={() => {
            setOpenAddRole(true)
          }} variant="contained" sx={{ background: "linear-gradient(45deg, #2c6264, #5ac4cb)" }}>
          <CheckCircleIcon />
          Add Role
        </Button>
        <Button id="employee-create" onClick={() => {
            setOpenAddTeamForm(true)  
          }} variant="contained" sx={{ background: "linear-gradient(45deg, #2c6264, #5ac4cb)" }}>
          <PersonAddIcon />
          Add Team Member
        </Button>
      </Stack>

      <Divider sx={{ marginY: 2 }} />

      <TableContainer>
        <Table  aria-label="Editors Table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role Name</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {editors?.length > 0 ? (
              filteredcompanies?.map((editor) => (
                <TableRow key={editor.id}>
                  <TableCell sx={{ color: '#5e5e5e', textDecoration: 'underline' }}>{editor.name}</TableCell>
                  <TableCell sx={{ color: '#5e5e5e', textDecoration: 'underline' }}>{editor.email}</TableCell>
                  <TableCell sx={{ color: '#5e5e5e', textDecoration: 'underline' }}>{editor?.roles.length > 0 ? editor?.roles.map((ele) => ele.name) : 'No Role'}</TableCell>
                  <TableCell sx={{ color: '#5e5e5e', textDecoration: 'underline' }}>{editor.created_at.slice(0, 10)}</TableCell>
                  <TableCell>
                    <Button
                    id="employee-update"
                      variant="contained"
                      sx={{ background: "#2b6566" }}
                      onClick={() => {
                        setEditorId(editor.id)
                        handleEditClick()
                        }}
                    >
                      Edit Access
                    </Button>
                    <Button
                    id="employee-delete"
                      variant="contained"
                      sx={{ marginLeft: 1,                        
                       color: "white",
                        background: "red",  }}
                      onClick={() =>{
                        setTeamAccessId(editor.id)
                        setOpenDelete(true)
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: 'center', color: '#5e5e5e' }}>
                  No editors available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <AddTeamForm setFetchEditorsTrigger={setFetchEditorsTrigger} open={openAddTeamForm} setOpen={setOpenAddTeamForm} />
      <AddRole setFetchRolesTrigger={setFetchRolesTrigger} open={openAddRole} setOpen={setOpenAddRole} />
      <EditTeamMember
      isMobile={isMobile}
      openEditDialog={openEditDialog}
      handleCloseEditDialog={handleCloseEditDialog}
      editorId={editorId}
      setFetchEditorsTrigger={setFetchEditorsTrigger}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Employee is deleted"
      />
      <DeleteDialog open={openDelete} handleClose={handleCloseDelete} DialogTitle="Team Member" DialogDeleteContent='This Team Member' DeleteFun={DeleteFun}  />
    </>
  );
}

export default TeamAccess;
