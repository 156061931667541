import React, { useContext, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import ApiContext from "../Context/context";
import { useSearch } from "../Context/SearchContext";
import LoadingSpinner from "../../../../FemilerCom/loading/spinner";

function MyOrder() {
  const {
    orders,
    packages,
    addOns,
    setShouldFetchPackages,
    setShouldFetchAddOns,
    setShouldFetchOrders,
    loading,
    handelPermission2
  } = useContext(ApiContext);
        handelPermission2(["order-show", 'role-create', 'role-update', 'role-delete'])

  
  const { searchQuery } = useSearch();

    useEffect(() => {
      if(setShouldFetchPackages){
        setShouldFetchPackages(true)
      }
      if(setShouldFetchAddOns){
        setShouldFetchAddOns(true)
      }
      if(setShouldFetchOrders){
        setShouldFetchOrders(true)
      }
    }, [])
  
    const filteredcompanies = orders?.filter((order) => {
          // Find the matching package for the order
          const matchingPackageName = packages?.find(
            (ele) => ele.id === order.orderable_id && ele.exam_id === 1
          );
        
          // Determine the package label
          const packageLabel = matchingPackageName ? "TWINDIx Individual" : "Man Code";
        
          // Filter based on the package label
          return packageLabel.toLowerCase().includes(searchQuery?.toLowerCase());
    });
        
  return (
    <Box>
      {loading && <LoadingSpinner />}

      <TableContainer>
        <Table aria-label="Enrollments Table">
          <TableHead>
            <TableRow>
              <TableCell>Exam Name</TableCell>
              <TableCell align="left">Package Name</TableCell>
              <TableCell>Order id</TableCell>
              <TableCell align="left">Package Id</TableCell>
              <TableCell align="left">•	Date </TableCell>
              <TableCell align="left">amount</TableCell>
              <TableCell align="left">status</TableCell>
              <TableCell align="left">type</TableCell>
              <TableCell align="left">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {orders ? filteredcompanies?.map((order, index) => {
            const matchingPackageName = packages?.find(
    (ele) => ele.id === order.orderable_id && ele.exam_id === 1
  );
  
  // Determine the label based on the matching package
  const packageLabel = matchingPackageName ? 'TWINDIx Individual' : 'Man Code';

            const matchingPackage = packages?.find((pack) => pack.id === order.orderable_id);
            const addonLabel = addOns?.some((addon) => {
              return packages?.some((pack) => pack.id === order.orderable_id && addon.name === pack.name);
              }) ? 'Add Ons' : 'Package';
              const currentDate = new Date();
  const updatedAtDate = new Date(order.updated_at);
  const twelveMonthsAgo = new Date();
  twelveMonthsAgo.setMonth(currentDate.getMonth() - 12);

  const status = updatedAtDate >= twelveMonthsAgo ? 'Active' : 'Expired';

    return( <TableRow key={order.id}>
      <TableCell>{packageLabel}</TableCell>
      <TableCell>{packages?.map((ele) => ele.id === order.orderable_id && ele.name)}</TableCell>
      <TableCell scope="row">{order.id}</TableCell>
      <TableCell>{order.orderable_id}</TableCell>
      <TableCell>{order.updated_at.slice(0, 10)}</TableCell>
      <TableCell>{order.amount}</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>{addonLabel}</TableCell>
      <TableCell>{packages?.map((ele) => ele.id === order.orderable_id && ele.price)}$</TableCell>
    </TableRow>)
  })  : "There is no packages"}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 2,
        }}
      >
      </Box>
    </Box>
  );
}

export default MyOrder;
