import { Stack, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect } from "react";
import { appContext } from "../../../../../../App";
import Swal from "sweetalert2";
import { useSearch } from "../../../Context/SearchContext";
import ApiContext from "../../../Context/context";
import LoadingSpinner from "../../../../../../FemilerCom/loading/spinner";

function AccesCompanyComponent({ setShowSection, setCompany }) {
  const { companies, setShouldFetchCompanies, loading } = useContext(ApiContext);
  const isMobile = useMediaQuery("(max-width:600px)");
  const isLoggedIn = useContext(appContext);
  const { searchQuery } = useSearch();

  // Fetch companies on component mount
  useEffect(() => {
    if (setShouldFetchCompanies) {
      setShouldFetchCompanies(true);
    }
  }, [setShouldFetchCompanies]);

  // Check if the user has permission to create a company
  const hasActionPermission = isLoggedIn?.userData?.roles?.[0]?.permissions?.reduce(
    (acc, role) => {
      if (role.name === "company-create") acc.companyCreate = true;
      return acc;
    },
    { companyCreate: false }
  );

  // Show error if the user doesn't have permission
  const hasPermission = () => {
    if (isLoggedIn?.userData?.managed_by !== null && !hasActionPermission.companyCreate) {
      Swal.fire({
        icon: "error",
        title: "Access Denied",
        text: "You do not have permission to access this.",
        timer: 3000,
        showConfirmButton: true,
      });
    }
  };

  // Filter companies based on search query and exam_id
  const filteredCompanies = companies
    ?.filter((company) => company.name?.toLowerCase().includes(searchQuery?.toLowerCase()))
    ?.filter((company) => company.exam_id === 65);

  return (
    <Stack sx={isMobile ? { overflow: "scroll" } : {}}>
      {loading && <LoadingSpinner />}

      {filteredCompanies?.map((company) => (
        <Stack
          key={company.id}
          sx={isMobile ? { width: "850px" } : {}}
          p={2}
          borderBottom="1px solid rgb(192, 192, 192)"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            onClick={() => {
              setShowSection('position');
              setCompany(company)
            }}
            sx={{
              width: "200px",
              cursor: "pointer",
              color: "#5e5e5e",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            {company.name}
          </Typography>
          <Typography sx={{ width: "150px", color: "#5e5e5e", textDecoration: "underline" }}>
            {company.phone}
          </Typography>
          <Typography sx={{ width: "220px", color: "#5e5e5e", textDecoration: "underline" }}>
            {company.mail}
          </Typography>
          <Typography sx={{ width: "100px", color: "#5e5e5e", textDecoration: "underline" }}>
            {company.updated_at.slice(0, 10)}
          </Typography>
          <Typography
            sx={{
              color: "#3f8e8f",
              border: "2.5px solid #3f8e8f",
              borderRadius: "12px",
              padding: "6px",
              fontWeight: "bold",
            }}
          >
            {company.total_exam_count} Assessment
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}

export default AccesCompanyComponent;