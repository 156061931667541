import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import ApiContext from "../../../Context/context";
import AddCompanyForm from "../../../ShareHr/AddCompanyForm";


function CompanyComponent ({ setSelectedCompany, setShowSection}) {

  const {
    companies,
    setShouldFetchCompanies,
    handelPermission2
  } = useContext(ApiContext);
  handelPermission2(["company-show", 'company-create'])

    const isMobile = useMediaQuery("(max-width:600px)");

    const [open, setOpen] = useState(false);

    useEffect(() => {
      if (setShouldFetchCompanies) {
        setShouldFetchCompanies(true);
      }
    }, [setShouldFetchCompanies]);

    return (
        <Stack sx={isMobile ? { overflow : 'scroll'}: {}}>
            <Button
            id="company-create"
              onClick={() => {
                  setOpen(true)
                
                }}
                variant="contained"
                sx={{
                  width :  '200px',
                  color: "white",
                  background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                }}
            >
              <AddIcon />
              Add Company
            </Button>
            {companies.filter((ele) => ele.exam_id === 65)
              ?.map((company) => {
                return (
                  <Stack
                    width={isMobile ? '850px' : '100%'}
                    key={company.id}
                    p={2}
                    borderBottom="1px solid rgb(192, 192, 192)"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      onClick={() => {
                        setShowSection('position')
                        setSelectedCompany(company)
                        }}
                      sx={{
                        width :'200px',
                        cursor: "pointer",
                        color: "#5e5e5e",
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {company.name}
                    </Typography>
                    <Typography sx={{width:'150px', color: "#5e5e5e", textDecoration: "underline" }}>{company.phone}</Typography>
                    <Typography sx={{width:'120px', color: "#5e5e5e", textDecoration: "underline" }}>{company.mail}</Typography>
                    <Typography sx={{width:'100px', color: "#5e5e5e", textDecoration: "underline" }}>
                      {company.updated_at.slice(0, 10)}
                    </Typography>
                    <Typography sx={{ color: "#3f8e8f", border: '2.5px solid #3f8e8f', borderRadius: '12px', padding: '6px', fontWeight: 'bold' }}>
                      {company.total_employees} Participant
                    </Typography>
                  </Stack>
                );
              })
          }
          <AddCompanyForm exam_id={65} open={open} setOpen={setOpen} />
        </Stack>
    )
}

export default CompanyComponent