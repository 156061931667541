import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  Checkbox,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import ActionsChips from "../Component/ActionsChips";
import Regenerate from "../../Individual/Component/Form/Regenerate";
import { appContext } from "../../../../App";

function Enrollments({ enrollmentsMap }) {
    const isMobile = useMediaQuery("(max-width:600px)");
  
  let [enrollments, setEnrollments] = useState();
    const [selectLanguage, setSelectLanguage ] = useState(false);
    const [selectedValue, setSelectedValue] = useState('ar');
    const isLoggedIn = useContext(appContext);
  const [openDownload2, setopenDownload2] = React.useState(false);
    const [openDownload, setopenDownload] = React.useState(false);
  const [open, setOpen] = useState();
  const name = isLoggedIn?.userName?.replaceAll(" ", "-")
    const [msg, setmsg] = useState('Done'); 
    const handleClickOpen2 = () => {
      setopenDownload2(true);
    };
  
    const [ids, setIds] = useState([]);
    const handleClick = () => {
      setOpen(true);
    };
    const handleRowSelect = (rowId) => {
      setSelectedRows((prev) =>
        prev.includes(rowId) ? prev.filter((id) => id !== rowId) : [...prev, rowId]
      );
    };
  
    const handleSelectAll = () => {
      if (selectAll) {
        setSelectedRows([]);
      } else {
        setSelectedRows(enrollments.map((row) => row.finished === 1 && row.id));
      }
      setSelectAll(!selectAll);
    };
  
    const handleSelectId = (rowId) => {
      console.log(rowId)
      setIds((prevIds) =>
        prevIds.includes(rowId) ? prevIds.filter((id) => id !== rowId) : [...prevIds, rowId]
      );
    };

    const handleDownloadAll = () => {
      const ids = enrollments
        .filter((row) => row.finished === 1)
        .map((row) => row.id);
    
        setIds(ids);
    };
  
      const handleClickOpen = () => {
        setopenDownload(true);
      };
    
      const handleCloseDownload = () => {
        setopenDownload(false);
        setSelectLanguage(false)
      };

        // fetch enrollments
    const fetchAllEnrollments = async () => {

      try {
          
              const response = await axios.get(`https://api.twindix.com/dashboard/enrollments`, {
                  headers: { Authorization: "Bearer " + localStorage.token }
              });
              console.log(response.data.payload.data)
              setEnrollments(response.data.payload.data);
      } catch (error) {
          console.error("Error fetching enrollments:", error);
      }
  };

  useEffect(() => {
    fetchAllEnrollments();
}, []);
  // fetch users 
const [users, setUsers] = useState();
useEffect(() => {
  if(!users) {
    axios.get("https://api.twindix.com/dashboard/users", {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
    setUsers(res.data.payload.data);
  })
  }

}, [users, ])

/// fetch Exams
let [examList, setexamList ]= useState()
const fetchExams = (url) => {
  axios
    .get(url, {
      headers: { Authorization: "Bearer " + localStorage.token },
    })
    .then((res) => {
      setexamList(res.data.payload.data);
      console.log(res.data.payload)
    })
    .catch((error) => {
      console.error("Error fetching exams:", error);
    });
};
useEffect(() => {
  if (!examList) {
    fetchExams("https://api.twindix.com/dashboard/exams");
  }
  console.log(examList);
}, [examList]);
  const rowsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  
  const [loaderr, setloaderr] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const currentPageRows = useMemo(
    () =>
      enrollments?.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
      ),
    [currentPage, enrollments]
  );

  const downloadReports = async (ids) => {
    setloaderr(true)
    try {
      const response = await axios.post(
        "https://vmi2353814.contaboserver.net/api/reports-download",
        {  enrollment_ids: ids } 
      );
      if (response) {
        setloaderr(false)
        console.log(response.data.payload.reports_zip_file)
        window.open(response.data.payload.reports_zip_file, "_blank");
      }
    } catch (error) {
      setloaderr(false)
      console.error("Error downloading reports:", error);
    }
  };


  return (
    <Box>
          {loaderr && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adds a translucent background
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              width: '50px',
              height: '50px',
              border: '6px solid #f3f3f3',
              borderTop: '6px solid #3498db',
              borderRadius: '50%',
              animation: 'spin 1s linear infinite',
            }}
          />
          {/* Keyframes inline animation */}
          <style>
            {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
          </style>
        </div>
      )}
    <ActionsChips 
        downloadAllReport={() => {
          handleDownloadAll()
          handleClickOpen()
           }}
        downloadSelectedReport={() => {
          setIds(selectedRows)
          handleClickOpen()
          }}
    />
      <TableContainer>
        <Table aria-label="Enrollments Table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                  aria-label="Select all rows"
                />
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell align="left">Code</TableCell>
              <TableCell align="left">User Name</TableCell>
              <TableCell align="left">Finished</TableCell>
              <TableCell align="left">Order ID</TableCell>
              <TableCell align="left">Exam Title</TableCell>
              <TableCell align="left">Action Report</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {enrollments ? enrollments?.map((exam,index) => {
            let  userName, examtitle ;
            users?.map((user) => user.id == exam.user_id ? userName = user.name : "")
            examList?.map((spasficexam) => spasficexam.id == exam.exam_id ? examtitle = spasficexam.title : "")
              return( <TableRow key={exam.id}>
                <TableCell><Checkbox
                checked={selectedRows.includes(exam.id)}
                onChange={() => handleRowSelect(exam.id)}
                aria-label={`Select row ${exam.id}`}
                sx={{ color: "#2c6264" }} /></TableCell>
              <TableCell scope="row">{exam.user_id}</TableCell>
              <TableCell>{exam.code}</TableCell>
              <TableCell>{userName}</TableCell>
              <TableCell>{exam.finished == 1 ? <span className="text-success">Finished</span> : <span className="text-danger">Not Finished Yet</span>}</TableCell>
              <TableCell>{exam.order_id}</TableCell>
              <TableCell>{examtitle}</TableCell>
              <TableCell className={index % 2 == 0 ? "tablerow tablerowColor Flex" : "tablerow Flex"}>
              {exam.finished == 1 ? 
        <Button onClick={() => {
                        handleSelectId(exam.id)
                        handleClickOpen();
        }} sx={{color : "white",background: "linear-gradient(45deg, #2c6264, #5ac4cb)"}}>
            Show Report
          </Button>
        
          : 
        <div> 
        <Button sx={{color : "white",background: "linear-gradient(45deg, #2c6264, #5ac4cb)"}}>
          No Report Yet
        </Button>
        </div>}
    </TableCell>
    </TableRow>)
  })  : "There is no enrollments"}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" mt={2}>
      </Box>
            <Regenerate
              openDownload={openDownload}
              isMobile={isMobile}
              selectLanguage={selectLanguage}
              selectedValue={selectedValue}
              handleCloseDownload={handleCloseDownload}
              setSelectLanguage={setSelectLanguage}
              setSelectedValue={setSelectedValue}
              handleClickOpen2={handleClickOpen2}
              handleClick={handleClick}
              name={name}
              ids={ids}
              setmsg={setmsg}
            />
    </Box>
  );
}

export default Enrollments;
