import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,   
} from "@mui/material";
import axios from "axios";
import DeleteDialog from "../../../../FemilerCom/DeleteDialog/DeleteDiaolg";

function Users() {
    const [users, setUsers] = useState();
    // const [link, setLink] = useState("https://api.twindix.com/dashboard/users");
    useEffect(() => {
      if(!users) {
        axios.get("https://api.twindix.com/dashboard/users", {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
        setUsers(res.data.payload.data);
        console.log(res.data.payload.data)
      })
      }
  
  }, [users])
  function dropUser(id) {
    axios.delete("https://api.twindix.com/dashboard/users/" + id, {headers: {Authorization: "Bearer " + localStorage.token}}).then((res) => {
      console.log(res)
      if(res.status) {
      } else {
      }
      axios.get("https://api.twindix.com/dashboard/users", {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
        setUsers(res.data.payload.data);
      })
    })
    
  }
    const [UsersId, setUsersId] = useState()
    const [openDelete, setOpenDelete] = useState(false)
    const handleCloseDelete = () => {
      setOpenDelete(false)
    }
    const DeleteFun = () => {
      dropUser(UsersId)
      handleCloseDelete()
    }
  return (
    <Box>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Phone</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Action Report</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {users ? users.map((user,index) => {
     return( <TableRow key={user.id}>
      <TableCell className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"} scope="row">{user.id}</TableCell>
      <TableCell className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"}>{user.name}</TableCell>
      <TableCell className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"}>{user.email}</TableCell>
      <TableCell className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"}>{user.phone ? user.phone : "no phone number"}</TableCell>
      <TableCell className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"}>{user.type == 1 ? "Hr" : "Individual"}</TableCell>
      <TableCell className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"}><Button variant="contained" sx={{color: 'white' , background: "linear-gradient(45deg, #2c6264, #5ac4cb)"}} onClick={() => {
        setUsersId(user.id)
        setOpenDelete(true)
      }}>Delete User</Button></TableCell>
    </TableRow>)
  })  : "There is no Users"}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination Component */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 2,
        }}
      >
      </Box>
      <DeleteDialog open={openDelete} handleClose={handleCloseDelete} DialogTitle="User" DialogDeleteContent='This User' DeleteFun={DeleteFun}  />
    </Box>
  );
}

export default Users;
