import LogoBlu from "../../../FemilerCom/Logo/SLogoBlu"
import Logowhit from "../../../FemilerCom/Logo/BLogowhit"
import thirtytwo from "../../../Media/Twindexreport/thirty-two.webp"
import thirtythree from "../../../Media/Twindexreport/thirty-three.webp"
import thirtyfour from "../../../Media/Twindexreport/thirty-four.webp"
import thirtyfive from "../../../Media/Twindexreport/thirty-five.webp"
import thirtysix from "../../../Media/Twindexreport/thirty-six.webp"
import thirtyseven from "../../../Media/Twindexreport/thirty-seven.webp"
import thirtyeight from "../../../Media/Twindexreport/thirty-eight.webp"
import thirtynine from "../../../Media/Twindexreport/thirty-nine.webp"
import forty from "../../../Media/Twindexreport/forty.webp"
import fortyone  from "../../../Media/Twindexreport/forty-one.webp"
import { useContext } from "react"
import { repoCon } from "../ReportComponent"
function Report4 ({lang})  {
    const repoConn = useContext(repoCon)

    function Charts (upchart, downchart)  {
        let s = 200 - (upchart + downchart);
        upchart = Math.ceil((upchart + Math.ceil(s*upchart / 100)) / 2);
        downchart = Math.ceil((downchart + Math.ceil(s*downchart / 100)) / 2);
        return { upchart, downchart };
    }

    let R250 = repoConn.references["R250"];
    let R249 = repoConn.references["R249"];
    let R248 = repoConn.references["R248"];
    let R256 = repoConn.references["R256"];
    let R255 = repoConn.references["R255"];
    let R254 = repoConn.references["R254"];
    const result21 = Charts(R250, R256);
    const result22 = Charts(R249, R255);
    const result23 = Charts(R248, R254);
    
    const SR250 = {
        height: `${result21.upchart}%`,
    };
    const SR249 = {
        height: `${result22.upchart}%`,
    };
    const SR248 = {
        height: `${result23.upchart}%`,
    };
    const SR256 = {
        height: `${result21.downchart}%`,
    };
    const SR255 = {
        height: `${result22.downchart}%`,
    };
    const SR254 = {
        height: `${result23.downchart}%`,
    };
    

    let R289 = repoConn.references["R289"];
    let R288 = repoConn.references["R288"];
    let R287 = repoConn.references["R287"];

    let R295 = repoConn.references["R295"];
    let R294 = repoConn.references["R294"];
    let R293 = repoConn.references["R293"];
    const result30 = Charts(R289, R295);
    const result31 = Charts(R288, R294);
    const result32 = Charts(R287, R293);
    
    const SR289 = {
        height: `${result30.upchart}%`,
    };
    const SR288 = {
        height: `${result31.upchart}%`,
    };
    const SR287 = {
        height: `${result32.upchart}%`,
    };
    const SR295 = {
        height: `${result30.downchart}%`,
    };
    const SR294 = {
        height: `${result31.downchart}%`,
    };
    const SR293 = {
        height: `${result32.downchart}%`,
    };
    
    

    let R328 = repoConn.references["R328"];
    let R327 = repoConn.references["R327"];
    let R326 = repoConn.references["R326"];
    let R334 = repoConn.references["R334"];
    let R333 = repoConn.references["R333"];
    let R332 = repoConn.references["R332"];
    const result27 = Charts(R328, R334);
    const result28 = Charts(R327, R333);
    const result29 = Charts(R326, R332);
    
    const SR328 = {
        height: `${result27.upchart}%`,
    };
    const SR327 = {
        height: `${result28.upchart}%`,
    };
    const SR326 = {
        height: `${result29.upchart}%`,
    };
    const SR334 = {
        height: `${result27.downchart}%`,
    };
    const SR333 = {
        height: `${result28.downchart}%`,
    };
    const SR332 = {
        height: `${result29.downchart}%`,
    };
    const IsEnglish  = lang === 'en'
    
    return (
        <>
            <div id="Forth">
            <div className='ScoCover-4 pdf-container-8'>
                <img className='ImgReport' src={thirtytwo} alt="ReportImge" />
                <div className='AsidLogo'>
                    <Logowhit />
                </div>
                <div className="Text" style={{fontSize: IsEnglish ? '31px' : '42px'}}>{repoConn.referencess && repoConn.referencess[468][lang === 'en' ? 'en content' : 'ar content']}</div>
            </div>
            <div className='BigOneGraph-4 pdf-container-8'>
                <img className='ImgReport' src={thirtythree} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                <div className='IntroBatrn'>
                    <div className="StrepCountaner">
                    <div>{repoConn.referencess && repoConn.referencess[469][lang === 'en' ? 'en content' : 'ar content']}</div>
                    </div>
                </div>
                </div>
                <div className="TopTitle">
                <span>{repoConn.referencess && repoConn.referencess[470][lang === 'en' ? 'en content' : 'ar content']}</span>
                </div>
                <div className="Graph-1 ">
                    <div className="TopBox">
                        <div></div>
                        <div>
                            <span>{repoConn.referencess && repoConn.referencess[472][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span>{repoConn.referencess && repoConn.referencess[473][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span>{repoConn.referencess && repoConn.referencess[474][lang === 'en' ? 'en content' : 'ar content']}</span>
                        </div>
                        <div>
                            <span>{result21.upchart}%</span>
                            <span>{result22.upchart}%</span>
                            <span>{result23.upchart}%</span>
                        </div>
                    </div>
                    <div className="Graph-Top-1">
                        <div className="BigCanvas" style={SR250}></div>
                        <div className="BigCanvas" style={SR249}></div>
                        <div className="BigCanvas" style={SR248}></div>
                    </div>
                    <div className="Graph-Bottom-1">
                        <div className="BigCanvas" style={SR256}></div>
                        <div className="BigCanvas" style={SR255}></div>
                        <div className="BigCanvas" style={SR254}></div>
                    </div>
                    <div className="BottomBox">
                        <div>
                            <span>{result21.downchart}%</span>
                            <span>{result22.downchart}%</span>
                            <span>{result23.downchart}%</span>
                        </div>
                        <div>
                            <span>{repoConn.referencess && repoConn.referencess[475][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span>{repoConn.referencess && repoConn.referencess[476][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span>{repoConn.referencess && repoConn.referencess[477][lang === 'en' ? 'en content' : 'ar content']}</span>
                        </div>
                    </div>
                </div>
                <div className="BottomTitle">
                    <div>{repoConn.referencess && repoConn.referencess[471][lang === 'en' ? 'en content' : 'ar content']}</div>
                </div>
            </div>
            <div className='ManyBox-1 pdf-container-8'>
                <img className='ImgReport' src={thirtyfour} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                <div className='IntroBatrn'>
                    <div className="StrepCountaner">
                    <div>{repoConn.referencess && repoConn.referencess[478][lang === 'en' ? 'en content' : 'ar content']}</div>
                    </div>
                </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Blue-1" style={{top : "333.8px"}}>
                    <div className="LiftBox">
                        <span className="TopTitle">{repoConn.referencess && repoConn.referencess[479][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[483][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[482][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[481][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <div className="CenterBox">
                            {repoConn.answers["311"] == 1? <div className="ManyBoxsTop ManyBoxTopCol-Blue">{repoConn.referencess && repoConn.referencess[487][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[490][lang === 'en' ? 'en content' : 'ar content']}</div>}
                            {repoConn.answers["211"] == 1? <div className="ManyBoxsTop ManyBoxTopCol-Blue">{repoConn.referencess && repoConn.referencess[488][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[491][lang === 'en' ? 'en content' : 'ar content']}</div>}
                            {repoConn.answers["111"] == 1? <div className="ManyBoxsTop ManyBoxTopCol-Blue">{repoConn.referencess && repoConn.referencess[489][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[492][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[486][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[485][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[484][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "116px"}}>{repoConn.referencess && repoConn.referencess[480][lang === 'en' ? 'en content' : 'ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                                {repoConn.answers["111"] == 1 && repoConn.answers["211"] == 1 && repoConn.answers["311"] == 1 ?
                                repoConn.referencess && repoConn.referencess[493][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["111"] == 1 && repoConn.answers["211"] == 1 && repoConn.answers["311"] == 0 ?
                                repoConn.referencess && repoConn.referencess[494][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["111"] == 1 && repoConn.answers["211"] == 0 && repoConn.answers["311"] == 1 ?
                                repoConn.referencess && repoConn.referencess[495][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["111"] == 1 && repoConn.answers["211"] == 0 && repoConn.answers["311"] == 0 ?
                                repoConn.referencess && repoConn.referencess[496][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["111"] == 0 && repoConn.answers["211"] == 1 && repoConn.answers["311"] == 1 ?
                                repoConn.referencess && repoConn.referencess[497][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["111"] == 0 && repoConn.answers["211"] == 1 && repoConn.answers["311"] == 0 ?
                                repoConn.referencess && repoConn.referencess[498][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["111"] == 0 && repoConn.answers["211"] == 0 && repoConn.answers["311"] == 1 ?
                                repoConn.referencess && repoConn.referencess[499][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["111"] == 0 && repoConn.answers["211"] == 0 && repoConn.answers["311"] == 0 ?
                                repoConn.referencess && repoConn.referencess[500][lang === 'en' ? 'en content' : 'ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Blue-2" style={{top :"684px"}}>
                    <div className="LiftBox">
                        <span className="TopTitle">{repoConn.referencess && repoConn.referencess[501][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[505][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[504][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[503][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["312"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[509][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[512][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["212"] == 1? <div className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[510][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[513][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["112"] == 1? <div className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[511][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[514][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[508][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[507][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[506][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "70px"}}>{repoConn.referencess && repoConn.referencess[502][lang === 'en' ? 'en content' : 'ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["112"] == 1 && repoConn.answers["212"] == 1 && repoConn.answers["312"] == 1 ?
                                repoConn.referencess && repoConn.referencess[515][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["112"] == 1 && repoConn.answers["212"] == 1 && repoConn.answers["312"] == 0 ?
                                repoConn.referencess && repoConn.referencess[516][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["112"] == 1 && repoConn.answers["212"] == 0 && repoConn.answers["312"] == 1 ?
                                repoConn.referencess && repoConn.referencess[517][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["112"] == 1 && repoConn.answers["212"] == 0 && repoConn.answers["312"] == 0 ?
                                repoConn.referencess && repoConn.referencess[518][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["112"] == 0 && repoConn.answers["212"] == 1 && repoConn.answers["312"] == 1 ?
                                repoConn.referencess && repoConn.referencess[519][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["112"] == 0 && repoConn.answers["212"] == 1 && repoConn.answers["312"] == 0 ?
                                repoConn.referencess && repoConn.referencess[520][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["112"] == 0 && repoConn.answers["212"] == 0 && repoConn.answers["312"] == 1 ?
                                repoConn.referencess && repoConn.referencess[521][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["112"] == 0 && repoConn.answers["212"] == 0 && repoConn.answers["312"] == 0 ?
                                repoConn.referencess && repoConn.referencess[522][lang === 'en' ? 'en content' : 'ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Blue-3" style={{top :"1034px"}}>
                    <div className="LiftBox">
                        <span className="TopTitle">{repoConn.referencess && repoConn.referencess[523][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[527][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[526][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[525][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["313"] == 1 ? <div style={{position : "relative"  , top : '1px'}} className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[531][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[534][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["213"] == 1 ? <div style={{position : "relative"  , top : '1px'}} className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[532][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[535][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["113"] == 1 ? <div style={{position : "relative"  , top : '1px'}} className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[533][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[536][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[530][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[529][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[528][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "200px"}}>{repoConn.referencess && repoConn.referencess[524][lang === 'en' ? 'en content' : 'ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["113"] == 1 && repoConn.answers["213"] == 1 && repoConn.answers["313"] == 1 ?
                                repoConn.referencess && repoConn.referencess[537][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["113"] == 1 && repoConn.answers["213"] == 1 && repoConn.answers["313"] == 0 ?
                                repoConn.referencess && repoConn.referencess[538][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["113"] == 1 && repoConn.answers["213"] == 0 && repoConn.answers["313"] == 1 ?
                                repoConn.referencess && repoConn.referencess[539][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["113"] == 1 && repoConn.answers["213"] == 0 && repoConn.answers["313"] == 0 ?
                                repoConn.referencess && repoConn.referencess[540][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["113"] == 0 && repoConn.answers["213"] == 1 && repoConn.answers["313"] == 1 ?
                                repoConn.referencess && repoConn.referencess[541][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["113"] == 0 && repoConn.answers["213"] == 1 && repoConn.answers["313"] == 0 ?
                                repoConn.referencess && repoConn.referencess[542][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["113"] == 0 && repoConn.answers["213"] == 0 && repoConn.answers["313"] == 1 ?
                                repoConn.referencess && repoConn.referencess[543][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["113"] == 0 && repoConn.answers["213"] == 0 && repoConn.answers["313"] == 0 ?
                                repoConn.referencess && repoConn.referencess[544][lang === 'en' ? 'en content' : 'ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ManyBox-2 pdf-container-8'>
                <img className='ImgReport' src={thirtyfive} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Blue-1">
                    <div className="LiftBox">
                        <span className="TopTitle" style={{position: "reletive",left: "80px"}}>{repoConn.referencess && repoConn.referencess[545][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[549][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[548][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[547][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["314"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[553][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[556][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["214"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[554][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[557][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["114"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[555][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[558][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[552][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[551][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[550][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <span className="BottumTitle">{repoConn.referencess && repoConn.referencess[546][lang === 'en' ? 'en content' : 'ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["114"] == 1 && repoConn.answers["214"] == 1 && repoConn.answers["314"] == 1 ?
                                repoConn.referencess && repoConn.referencess[559][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["114"] == 1 && repoConn.answers["214"] == 1 && repoConn.answers["314"] == 0 ?
                                repoConn.referencess && repoConn.referencess[560][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["114"] == 1 && repoConn.answers["214"] == 0 && repoConn.answers["314"] == 1 ?
                                repoConn.referencess && repoConn.referencess[561][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["114"] == 1 && repoConn.answers["214"] == 0 && repoConn.answers["314"] == 0 ?
                                repoConn.referencess && repoConn.referencess[562][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["114"] == 0 && repoConn.answers["214"] == 1 && repoConn.answers["314"] == 1 ?
                                repoConn.referencess && repoConn.referencess[563][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["114"] == 0 && repoConn.answers["214"] == 1 && repoConn.answers["314"] == 0 ?
                                repoConn.referencess && repoConn.referencess[564][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["114"] == 0 && repoConn.answers["214"] == 0 && repoConn.answers["314"] == 1 ?
                                repoConn.referencess && repoConn.referencess[565][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["114"] == 0 && repoConn.answers["214"] == 0 && repoConn.answers["314"] == 0 ?
                                repoConn.referencess && repoConn.referencess[566][lang === 'en' ? 'en content' : 'ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Blue-2 ">
                    <div className="LiftBox">
                        <span className="TopTitle" style={{position: "reletive",left: "78px", top: "10px"}}>{repoConn.referencess && repoConn.referencess[567][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[571][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[570][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[569][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["315"] == 1 ? <div style={{position : 'relative', top : '-1px'}} className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[575][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[578][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["215"] == 1 ? <div style={{position : 'relative', top : '-1px'}} className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[576][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[579][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["115"] == 1 ? <div style={{position : 'relative', top : '-1px'}} className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[577][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[580][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[574][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[573][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[572][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <span className="BottumTitle" >{repoConn.referencess && repoConn.referencess[568][lang === 'en' ? 'en content' : 'ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["115"] == 1 && repoConn.answers["215"] == 1 && repoConn.answers["315"] == 1 ?
                                repoConn.referencess && repoConn.referencess[581][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["115"] == 1 && repoConn.answers["215"] == 1 && repoConn.answers["315"] == 0 ?
                                repoConn.referencess && repoConn.referencess[582][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["115"] == 1 && repoConn.answers["215"] == 0 && repoConn.answers["315"] == 1 ?
                                repoConn.referencess && repoConn.referencess[583][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["115"] == 1 && repoConn.answers["215"] == 0 && repoConn.answers["315"] == 0 ?
                                repoConn.referencess && repoConn.referencess[584][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["115"] == 0 && repoConn.answers["215"] == 1 && repoConn.answers["315"] == 1 ?
                                repoConn.referencess && repoConn.referencess[585][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["115"] == 0 && repoConn.answers["215"] == 1 && repoConn.answers["315"] == 0 ?
                                repoConn.referencess && repoConn.referencess[586][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["115"] == 0 && repoConn.answers["215"] == 0 && repoConn.answers["315"] == 1 ?
                                repoConn.referencess && repoConn.referencess[587][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["115"] == 0 && repoConn.answers["215"] == 0 && repoConn.answers["315"] == 0 ?
                                repoConn.referencess && repoConn.referencess[588][lang === 'en' ? 'en content' : 'ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Blue-3">
                    <div className="LiftBox" style={{position: "relative", top: "-80px"}}>
                        <span className="TopTitle" style={{position: "reletive",left: "5%"}}>{repoConn.referencess && repoConn.referencess[589][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[593][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[592][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[591][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <div className="CenterBox" style={{top : "40px"}}>
                        {repoConn.answers["316"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[597][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[600][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["216"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[598][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[601][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["116"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[599][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[602][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[596][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[595][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[594][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "40px"}}>{repoConn.referencess && repoConn.referencess[590][lang === 'en' ? 'en content' : 'ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["116"] == 1 && repoConn.answers["216"] == 1 && repoConn.answers["316"] == 1 ?
                                repoConn.referencess && repoConn.referencess[603][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["116"] == 1 && repoConn.answers["216"] == 1 && repoConn.answers["316"] == 0 ?
                                repoConn.referencess && repoConn.referencess[604][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["116"] == 1 && repoConn.answers["216"] == 0 && repoConn.answers["316"] == 1 ?
                                repoConn.referencess && repoConn.referencess[605][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["116"] == 1 && repoConn.answers["216"] == 0 && repoConn.answers["316"] == 0 ?
                                repoConn.referencess && repoConn.referencess[606][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["116"] == 0 && repoConn.answers["216"] == 1 && repoConn.answers["316"] == 1 ?
                                repoConn.referencess && repoConn.referencess[607][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["116"] == 0 && repoConn.answers["216"] == 1 && repoConn.answers["316"] == 0 ?
                                repoConn.referencess && repoConn.referencess[608][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["116"] == 0 && repoConn.answers["216"] == 0 && repoConn.answers["316"] == 1 ?
                                repoConn.referencess && repoConn.referencess[609][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["116"] == 0 && repoConn.answers["216"] == 0 && repoConn.answers["316"] == 0 ?
                                repoConn.referencess && repoConn.referencess[610][lang === 'en' ? 'en content' : 'ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ManyBox-3 pdf-container-9'>
                <img className='ImgReport' src={thirtysix} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Blue-1">
                    <div className="LiftBox">
                        <span className="TopTitle" style={{position: "reletive",left: "90px"}}>{repoConn.referencess && repoConn.referencess[611][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[615][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[614][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[613][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <div style={{top : '40px'}} className="CenterBox">
                        {repoConn.answers["317"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[619][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[622][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["217"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[620][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[623][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["117"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[621][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[624][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[618][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[617][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[616][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "30px"}}>{repoConn.referencess && repoConn.referencess[612][lang === 'en' ? 'en content' : 'ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["117"] == 1 && repoConn.answers["217"] == 1 && repoConn.answers["317"] == 1 ?
                                repoConn.referencess && repoConn.referencess[625][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["117"] == 1 && repoConn.answers["217"] == 1 && repoConn.answers["317"] == 0 ?
                                repoConn.referencess && repoConn.referencess[626][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["117"] == 1 && repoConn.answers["217"] == 0 && repoConn.answers["317"] == 1 ?
                                repoConn.referencess && repoConn.referencess[627][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["117"] == 1 && repoConn.answers["217"] == 0 && repoConn.answers["317"] == 0 ?
                                repoConn.referencess && repoConn.referencess[628][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["117"] == 0 && repoConn.answers["217"] == 1 && repoConn.answers["317"] == 1 ?
                                repoConn.referencess && repoConn.referencess[629][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["117"] == 0 && repoConn.answers["217"] == 1 && repoConn.answers["317"] == 0 ?
                                repoConn.referencess && repoConn.referencess[630][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["117"] == 0 && repoConn.answers["217"] == 0 && repoConn.answers["317"] == 1 ?
                                repoConn.referencess && repoConn.referencess[631][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["117"] == 0 && repoConn.answers["217"] == 0 && repoConn.answers["317"] == 0 ?
                                repoConn.referencess && repoConn.referencess[632][lang === 'en' ? 'en content' : 'ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Blue-2 ">
                    <div className="LiftBox">
                        <span className="TopTitle" style={{position: "reletive",left: "30px"}}>{repoConn.referencess && repoConn.referencess[633][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[637][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[636][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[635][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["318"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[641][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[644][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["218"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[642][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[645][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["118"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[643][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[646][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[640][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[639][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[638][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "40px"}}>{repoConn.referencess && repoConn.referencess[634][lang === 'en' ? 'en content' : 'ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["118"] == 1 && repoConn.answers["218"] == 1 && repoConn.answers["318"] == 1 ?
                                repoConn.referencess && repoConn.referencess[647][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["118"] == 1 && repoConn.answers["218"] == 1 && repoConn.answers["318"] == 0 ?
                                repoConn.referencess && repoConn.referencess[648][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["118"] == 1 && repoConn.answers["218"] == 0 && repoConn.answers["318"] == 1 ?
                                repoConn.referencess && repoConn.referencess[649][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["118"] == 1 && repoConn.answers["218"] == 0 && repoConn.answers["318"] == 0 ?
                                repoConn.referencess && repoConn.referencess[650][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["118"] == 0 && repoConn.answers["218"] == 1 && repoConn.answers["318"] == 1 ?
                                repoConn.referencess && repoConn.referencess[651][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["118"] == 0 && repoConn.answers["218"] == 1 && repoConn.answers["318"] == 0 ?
                                repoConn.referencess && repoConn.referencess[652][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["118"] == 0 && repoConn.answers["218"] == 0 && repoConn.answers["318"] == 1 ?
                                repoConn.referencess && repoConn.referencess[653][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["118"] == 0 && repoConn.answers["218"] == 0 && repoConn.answers["318"] == 0 ?
                                repoConn.referencess && repoConn.referencess[654][lang === 'en' ? 'en content' : 'ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Blue-3">
                    <div className="LiftBox">
                        <span className="TopTitle"style={{position: "reletive",left: "76px"}}>{repoConn.referencess && repoConn.referencess[655][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[659][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[658][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[657][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["319"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[663][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[666][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["219"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[664][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[667][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["119"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Blue ">{repoConn.referencess && repoConn.referencess[665][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Blue ">{repoConn.referencess && repoConn.referencess[668][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[662][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[661][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[660][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "86px"}}>{repoConn.referencess && repoConn.referencess[656][lang === 'en' ? 'en content' : 'ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["119"] == 1 && repoConn.answers["219"] == 1 && repoConn.answers["319"] == 1 ?
                                repoConn.referencess && repoConn.referencess[669][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["119"] == 1 && repoConn.answers["219"] == 1 && repoConn.answers["319"] == 0 ?
                                repoConn.referencess && repoConn.referencess[670][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["119"] == 1 && repoConn.answers["219"] == 0 && repoConn.answers["319"] == 1 ?
                                repoConn.referencess && repoConn.referencess[671][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["119"] == 1 && repoConn.answers["219"] == 0 && repoConn.answers["319"] == 0 ?
                                repoConn.referencess && repoConn.referencess[672][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["119"] == 0 && repoConn.answers["219"] == 1 && repoConn.answers["319"] == 1 ?
                                repoConn.referencess && repoConn.referencess[673][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["119"] == 0 && repoConn.answers["219"] == 1 && repoConn.answers["319"] == 0 ?
                                repoConn.referencess && repoConn.referencess[674][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["119"] == 0 && repoConn.answers["219"] == 0 && repoConn.answers["319"] == 1 ?
                                repoConn.referencess && repoConn.referencess[675][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["119"] == 0 && repoConn.answers["219"] == 0 && repoConn.answers["319"] == 0 ?
                                repoConn.referencess && repoConn.referencess[676][lang === 'en' ? 'en content' : 'ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='BigOneGraph-5 pdf-container-9'>
                <img className='ImgReport' src={thirtyseven} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                <div className='IntroBatrn'>
                    <div className="StrepCountaner">
                    <div>{repoConn.referencess && repoConn.referencess[677][lang === 'en' ? 'en content' : 'ar content']}</div>
                    </div>
                </div>
                </div>
                <div className="TopTitle">
                <span>{repoConn.referencess && repoConn.referencess[678][lang === 'en' ? 'en content' : 'ar content']}</span>
                </div>
                <div className="Graph-1 ">
                    <div className="TopBox">
                        <div></div>
                        <div>
                            <span>{repoConn.referencess && repoConn.referencess[680][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span>{repoConn.referencess && repoConn.referencess[681][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span>{repoConn.referencess && repoConn.referencess[682][lang === 'en' ? 'en content' : 'ar content']}</span>
                        </div>
                        <div>
                            <span>{result30.upchart}%</span>
                            <span>{result31.upchart}%</span>
                            <span>{result32.upchart}%</span>
                        </div>
                    </div>
                    <div className="Graph-Top-1">
                        <div className="BigCanvas" style={SR289}></div>
                        <div className="BigCanvas" style={SR288}></div>
                        <div className="BigCanvas" style={SR287}></div>
                    </div>
                    <div className="Graph-Bottom-1">
                        <div className="BigCanvas" style={SR295}></div>
                        <div className="BigCanvas" style={SR294}></div>
                        <div className="BigCanvas" style={SR293}></div>
                    </div>
                    <div className="BottomBox">
                        <div>
                            <span>{result30.downchart}%</span>
                            <span>{result31.downchart}%</span>
                            <span>{result32.downchart}%</span>
                        </div>
                        <div>
                            <span>{repoConn.referencess && repoConn.referencess[683][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span>{repoConn.referencess && repoConn.referencess[684][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span>{repoConn.referencess && repoConn.referencess[685][lang === 'en' ? 'en content' : 'ar content']}</span>
                        </div>
                    </div>
                </div>
                <div className="BottomTitle">
                    <span>{repoConn.referencess && repoConn.referencess[679][lang === 'en' ? 'en content' : 'ar content']}</span>
                </div>
            </div>
            <div className='ManyBox-4 pdf-container-9'>
                <img className='ImgReport' src={thirtyeight} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                <div className='IntroBatrn'>
                    <div className="StrepCountaner">
                        <div>{repoConn.referencess && repoConn.referencess[686][lang === 'en' ? 'en content' : 'ar content']}</div>
                    </div>
                </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Black-1" style={{top : "304px"}}>
                    <div className="LiftBox">
                        <span className="TopTitle" style={{position: "reletive",left: "139px"}}>{repoConn.referencess && repoConn.referencess[687][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[691][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[690][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[689][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["321"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray">{repoConn.referencess && repoConn.referencess[695][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray">{repoConn.referencess && repoConn.referencess[698][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["221"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray">{repoConn.referencess && repoConn.referencess[696][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray">{repoConn.referencess && repoConn.referencess[699][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["121"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray">{repoConn.referencess && repoConn.referencess[697][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray">{repoConn.referencess && repoConn.referencess[700][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[694][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[693][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[692][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "170px"}}>{repoConn.referencess && repoConn.referencess[688][lang === 'en' ? 'en content' : 'ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["121"] == 1 && repoConn.answers["221"] == 1 && repoConn.answers["321"] == 1 ?
                                repoConn.referencess && repoConn.referencess[701][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["121"] == 1 && repoConn.answers["221"] == 1 && repoConn.answers["321"] == 0 ?
                                repoConn.referencess && repoConn.referencess[702][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["121"] == 1 && repoConn.answers["221"] == 0 && repoConn.answers["321"] == 1 ?
                                repoConn.referencess && repoConn.referencess[703][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["121"] == 1 && repoConn.answers["221"] == 0 && repoConn.answers["321"] == 0 ?
                                repoConn.referencess && repoConn.referencess[704][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["121"] == 0 && repoConn.answers["221"] == 1 && repoConn.answers["321"] == 1 ?
                                repoConn.referencess && repoConn.referencess[705][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["121"] == 0 && repoConn.answers["221"] == 1 && repoConn.answers["321"] == 0 ?
                                repoConn.referencess && repoConn.referencess[706][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["121"] == 0 && repoConn.answers["221"] == 0 && repoConn.answers["321"] == 1 ?
                                repoConn.referencess && repoConn.referencess[707][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["121"] == 0 && repoConn.answers["221"] == 0 && repoConn.answers["321"] == 0 ?
                                repoConn.referencess && repoConn.referencess[708][lang === 'en' ? 'en content' : 'ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Black-2" style={{top : "651.67px"}}>
                    <div className="LiftBox">
                        <span className="TopTitle" style={{position: "reletive",left: "28px"}}>{repoConn.referencess && repoConn.referencess[709][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[713][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[712][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[711][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["322"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top: "1px"}}>{repoConn.referencess && repoConn.referencess[717][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "1px"}}>{repoConn.referencess && repoConn.referencess[720][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["222"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top: "1px"}}>{repoConn.referencess && repoConn.referencess[718][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "1px"}}>{repoConn.referencess && repoConn.referencess[721][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["122"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top: "1px"}}>{repoConn.referencess && repoConn.referencess[719][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "1px"}}>{repoConn.referencess && repoConn.referencess[722][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[716][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[715][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[714][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "110px"}}>{repoConn.referencess && repoConn.referencess[710][lang === 'en' ? 'en content' : 'ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["122"] == 1 && repoConn.answers["222"] == 1 && repoConn.answers["322"] == 1 ?
                                repoConn.referencess && repoConn.referencess[723][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["122"] == 1 && repoConn.answers["222"] == 1 && repoConn.answers["322"] == 0 ?
                                repoConn.referencess && repoConn.referencess[724][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["122"] == 1 && repoConn.answers["222"] == 0 && repoConn.answers["322"] == 1 ?
                                repoConn.referencess && repoConn.referencess[725][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["122"] == 1 && repoConn.answers["222"] == 0 && repoConn.answers["322"] == 0 ?
                                repoConn.referencess && repoConn.referencess[726][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["122"] == 0 && repoConn.answers["222"] == 1 && repoConn.answers["322"] == 1 ?
                                repoConn.referencess && repoConn.referencess[727][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["122"] == 0 && repoConn.answers["222"] == 1 && repoConn.answers["322"] == 0 ?
                                repoConn.referencess && repoConn.referencess[728][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["122"] == 0 && repoConn.answers["222"] == 0 && repoConn.answers["322"] == 1 ?
                                repoConn.referencess && repoConn.referencess[729][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["122"] == 0 && repoConn.answers["222"] == 0 && repoConn.answers["322"] == 0 ?
                                repoConn.referencess && repoConn.referencess[730][lang === 'en' ? 'en content' : 'ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Black-3" style={{top : "1013.7px"}}>
                    <div className="LiftBox" style={{top: "-96px"}}>
                        <span className="TopTitle" style={{position: "reletive",left: "88px"}}>{repoConn.referencess && repoConn.referencess[732][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[735][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[734][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[733][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["323"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top : "-3px"}}>{repoConn.referencess && repoConn.referencess[739][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "-2px"}}>{repoConn.referencess && repoConn.referencess[742][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["223"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top : "-3px"}}>{repoConn.referencess && repoConn.referencess[740][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "-2px"}}>{repoConn.referencess && repoConn.referencess[743][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["123"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top : "-3px"}}>{repoConn.referencess && repoConn.referencess[741][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "-2px"}}>{repoConn.referencess && repoConn.referencess[744][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[738][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[737][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[736][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "70px"}}>{repoConn.referencess && repoConn.referencess[731][lang === 'en' ? 'en content' : 'ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["123"] == 1 && repoConn.answers["223"] == 1 && repoConn.answers["323"] == 1 ?
                                repoConn.referencess && repoConn.referencess[745][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["123"] == 1 && repoConn.answers["223"] == 1 && repoConn.answers["323"] == 0 ?
                                repoConn.referencess && repoConn.referencess[746][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["123"] == 1 && repoConn.answers["223"] == 0 && repoConn.answers["323"] == 1 ?
                                repoConn.referencess && repoConn.referencess[747][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["123"] == 1 && repoConn.answers["223"] == 0 && repoConn.answers["323"] == 0 ?
                                repoConn.referencess && repoConn.referencess[748][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["123"] == 0 && repoConn.answers["223"] == 1 && repoConn.answers["323"] == 1 ?
                                repoConn.referencess && repoConn.referencess[749][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["123"] == 0 && repoConn.answers["223"] == 1 && repoConn.answers["323"] == 0 ?
                                repoConn.referencess && repoConn.referencess[750][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["123"] == 0 && repoConn.answers["223"] == 0 && repoConn.answers["323"] == 1 ?
                                repoConn.referencess && repoConn.referencess[751][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["123"] == 0 && repoConn.answers["223"] == 0 && repoConn.answers["323"] == 0 ?
                                repoConn.referencess && repoConn.referencess[752][lang === 'en' ? 'en content' : 'ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ManyBox-5 pdf-container-9'>
                <img className='ImgReport' src={thirtynine} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Black-1">
                    <div className="LiftBox">
                        <span className="TopTitle" style={{position: "reletive",left: "60px"}}>{repoConn.referencess && repoConn.referencess[753][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[757][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[756][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[755][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["324"] ==1 ? <div  className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top: "2px"}}>{repoConn.referencess && repoConn.referencess[761][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[764][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["224"] ==1 ? <div  className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top: "2px"}}>{repoConn.referencess && repoConn.referencess[762][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[765][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["124"] ==1 ? <div  className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top: "2px"}}>{repoConn.referencess && repoConn.referencess[763][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[766][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[760][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[759][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[758][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <span className="BottumTitle"style={{position: "reletive",left: "50px"}} >{repoConn.referencess && repoConn.referencess[754][lang === 'en' ? 'en content' : 'ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["124"] == 1 && repoConn.answers["224"] == 1 && repoConn.answers["324"] == 1 ?
                                repoConn.referencess && repoConn.referencess[767][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["124"] == 1 && repoConn.answers["224"] == 1 && repoConn.answers["324"] == 0 ?
                                repoConn.referencess && repoConn.referencess[768][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["124"] == 1 && repoConn.answers["224"] == 0 && repoConn.answers["324"] == 1 ?
                                repoConn.referencess && repoConn.referencess[769][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["124"] == 1 && repoConn.answers["224"] == 0 && repoConn.answers["324"] == 0 ?
                                repoConn.referencess && repoConn.referencess[770][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["124"] == 0 && repoConn.answers["224"] == 1 && repoConn.answers["324"] == 1 ?
                                repoConn.referencess && repoConn.referencess[771][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["124"] == 0 && repoConn.answers["224"] == 1 && repoConn.answers["324"] == 0 ?
                                repoConn.referencess && repoConn.referencess[772][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["124"] == 0 && repoConn.answers["224"] == 0 && repoConn.answers["324"] == 1 ?
                                repoConn.referencess && repoConn.referencess[773][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["124"] == 0 && repoConn.answers["224"] == 0 && repoConn.answers["324"] == 0 ?
                                repoConn.referencess && repoConn.referencess[774][lang === 'en' ? 'en content' : 'ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Black-2 ">
                    <div className="LiftBox">
                        <span className="TopTitle" style={{position: "reletive",left: "110px", top: "15px"}}>{repoConn.referencess && repoConn.referencess[775][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[779][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[778][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[777][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["325"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top : "3px"}}>{repoConn.referencess && repoConn.referencess[783][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[786][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["225"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top : "3px"}}>{repoConn.referencess && repoConn.referencess[784][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[787][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["125"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top : "3px"}}>{repoConn.referencess && repoConn.referencess[785][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[788][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[782][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[781][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[780][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "110px"}}>{repoConn.referencess && repoConn.referencess[776][lang === 'en' ? 'en content' : 'ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["125"] == 1 && repoConn.answers["225"] == 1 && repoConn.answers["325"] == 1 ?
                                repoConn.referencess && repoConn.referencess[789][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["125"] == 1 && repoConn.answers["225"] == 1 && repoConn.answers["325"] == 0 ?
                                repoConn.referencess && repoConn.referencess[790][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["125"] == 1 && repoConn.answers["225"] == 0 && repoConn.answers["325"] == 1 ?
                                repoConn.referencess && repoConn.referencess[791][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["125"] == 1 && repoConn.answers["225"] == 0 && repoConn.answers["325"] == 0 ?
                                repoConn.referencess && repoConn.referencess[792][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["125"] == 0 && repoConn.answers["225"] == 1 && repoConn.answers["325"] == 1 ?
                                repoConn.referencess && repoConn.referencess[793][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["125"] == 0 && repoConn.answers["225"] == 1 && repoConn.answers["325"] == 0 ?
                                repoConn.referencess && repoConn.referencess[794][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["125"] == 0 && repoConn.answers["225"] == 0 && repoConn.answers["325"] == 1 ?
                                repoConn.referencess && repoConn.referencess[795][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["125"] == 0 && repoConn.answers["225"] == 0 && repoConn.answers["325"] == 0 ?
                                repoConn.referencess && repoConn.referencess[796][lang === 'en' ? 'en content' : 'ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Black-3" style={{top : '919px'}}>
                    <div className="LiftBox">
                        <span className="TopTitle" style={{position: "reletive",left: "40px"}}>{repoConn.referencess && repoConn.referencess[797][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[801][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[800][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[799][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["326"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray"style={{position: "relative", top : "2px"}}>{repoConn.referencess && repoConn.referencess[805][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "2.5px"}}>{repoConn.referencess && repoConn.referencess[808][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["226"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray"style={{position: "relative", top : "2px"}}>{repoConn.referencess && repoConn.referencess[806][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "2.5px"}}>{repoConn.referencess && repoConn.referencess[809][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["126"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray"style={{position: "relative", top : "2px"}}>{repoConn.referencess && repoConn.referencess[807][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "2.5px"}}>{repoConn.referencess && repoConn.referencess[810][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[804][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[803][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[802][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "55px"}}>{repoConn.referencess && repoConn.referencess[798][lang === 'en' ? 'en content' : 'ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["126"] == 1 && repoConn.answers["226"] == 1 && repoConn.answers["326"] == 1 ?
                                repoConn.referencess && repoConn.referencess[811][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["126"] == 1 && repoConn.answers["226"] == 1 && repoConn.answers["326"] == 0 ?
                                repoConn.referencess && repoConn.referencess[812][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["126"] == 1 && repoConn.answers["226"] == 0 && repoConn.answers["326"] == 1 ?
                                repoConn.referencess && repoConn.referencess[813][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["126"] == 1 && repoConn.answers["226"] == 0 && repoConn.answers["326"] == 0 ?
                                repoConn.referencess && repoConn.referencess[814][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["126"] == 0 && repoConn.answers["226"] == 1 && repoConn.answers["326"] == 1 ?
                                repoConn.referencess && repoConn.referencess[815][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["126"] == 0 && repoConn.answers["226"] == 1 && repoConn.answers["326"] == 0 ?
                                repoConn.referencess && repoConn.referencess[816][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["126"] == 0 && repoConn.answers["226"] == 0 && repoConn.answers["326"] == 1 ?
                                repoConn.referencess && repoConn.referencess[817][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["126"] == 0 && repoConn.answers["226"] == 0 && repoConn.answers["326"] == 0 ?
                                repoConn.referencess && repoConn.referencess[818][lang === 'en' ? 'en content' : 'ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ManyBox-6 pdf-container-10'>
                <img className='ImgReport' src={forty} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Black-1">
                    <div className="LiftBox">
                        <span className="TopTitle" style={{position: "reletive",left: "40px"}}>{repoConn.referencess && repoConn.referencess[819][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[823][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[822][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[821][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["327"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top : "3px"}}>{repoConn.referencess && repoConn.referencess[827][lang === 'en' ? 'en content' : 'ar content']}</div> : 
                        <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[830][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["227"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top : "3px"}}>{repoConn.referencess && repoConn.referencess[828][lang === 'en' ? 'en content' : 'ar content']}</div> :
                        <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[831][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["127"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top : "3px"}}>{repoConn.referencess && repoConn.referencess[829][lang === 'en' ? 'en content' : 'ar content']}</div> :
                        <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[832][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[826][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[825][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[824][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "110px"}}>{repoConn.referencess && repoConn.referencess[820][lang === 'en' ? 'en content' : 'ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["127"] == 1 && repoConn.answers["227"] == 1 && repoConn.answers["327"] == 1 ? 
                                repoConn.referencess && repoConn.referencess[833][lang === 'en' ? 'en content' : 'ar content']: ""}
                                {repoConn.answers["127"] == 1 && repoConn.answers["227"] == 1 && repoConn.answers["327"] == 0 ?
                                repoConn.referencess && repoConn.referencess[834][lang === 'en' ? 'en content' : 'ar content']: ""}
                                {repoConn.answers["127"] == 1 && repoConn.answers["227"] == 0 && repoConn.answers["327"] == 1 ?
                                repoConn.referencess && repoConn.referencess[835][lang === 'en' ? 'en content' : 'ar content']: ""}
                                {repoConn.answers["127"] == 1 && repoConn.answers["227"] == 0 && repoConn.answers["327"] == 0 ?
                                repoConn.referencess && repoConn.referencess[836][lang === 'en' ? 'en content' : 'ar content']: ""}
                                {repoConn.answers["127"] == 0 && repoConn.answers["227"] == 1 && repoConn.answers["327"] == 1 ?
                                repoConn.referencess && repoConn.referencess[837][lang === 'en' ? 'en content' : 'ar content']: ""}
                                {repoConn.answers["127"] == 0 && repoConn.answers["227"] == 1 && repoConn.answers["327"] == 0 ?
                                repoConn.referencess && repoConn.referencess[838][lang === 'en' ? 'en content' : 'ar content']: ""}
                                {repoConn.answers["127"] == 0 && repoConn.answers["227"] == 0 && repoConn.answers["327"] == 1 ?
                                repoConn.referencess && repoConn.referencess[839][lang === 'en' ? 'en content' : 'ar content']: ""}
                                {repoConn.answers["127"] == 0 && repoConn.answers["227"] == 0 && repoConn.answers["327"] == 0 ?
                                repoConn.referencess && repoConn.referencess[840][lang === 'en' ? 'en content' : 'ar content']: ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Black-2 ">
                    <div className="LiftBox">
                        <span className="TopTitle">{repoConn.referencess && repoConn.referencess[841][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[845][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[844][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[843][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["328"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top : "3px"}}>{repoConn.referencess && repoConn.referencess[849][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[852][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["228"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top : "3px"}}>{repoConn.referencess && repoConn.referencess[850][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[853][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["128"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top : "3px"}}>{repoConn.referencess && repoConn.referencess[851][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[854][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[848][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[847][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[846][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "58px"}}>{repoConn.referencess && repoConn.referencess[842][lang === 'en' ? 'en content' : 'ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["128"] == 1 && repoConn.answers["228"] == 1 && repoConn.answers["328"] == 1 ?
                                repoConn.referencess && repoConn.referencess[855][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["128"] == 1 && repoConn.answers["228"] == 1 && repoConn.answers["328"] == 0 ?
                                repoConn.referencess && repoConn.referencess[856][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["128"] == 1 && repoConn.answers["228"] == 0 && repoConn.answers["328"] == 1 ?
                                repoConn.referencess && repoConn.referencess[857][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["128"] == 1 && repoConn.answers["228"] == 0 && repoConn.answers["328"] == 0 ?
                                repoConn.referencess && repoConn.referencess[858][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["128"] == 0 && repoConn.answers["228"] == 1 && repoConn.answers["328"] == 1 ?
                                repoConn.referencess && repoConn.referencess[859][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["128"] == 0 && repoConn.answers["228"] == 1 && repoConn.answers["328"] == 0 ?
                                repoConn.referencess && repoConn.referencess[860][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["128"] == 0 && repoConn.answers["228"] == 0 && repoConn.answers["328"] == 1 ?
                                repoConn.referencess && repoConn.referencess[861][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["128"] == 0 && repoConn.answers["228"] == 0 && repoConn.answers["328"] == 0 ?
                                repoConn.referencess && repoConn.referencess[862][lang === 'en' ? 'en content' : 'ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Black-3">
                    <div className="LiftBox">
                        <span className="TopTitle" style={{position: "reletive",left: "40px"}}>{repoConn.referencess && repoConn.referencess[863][lang === 'en' ? 'en content' : 'ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[867][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[866][lang === 'en' ? 'en content' : 'ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[865][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <div className="CenterBox" style={{top :'31px'}}>
                        {repoConn.answers["329"] ==1 ? <div className="ManyBoxsTop ManyBoxTopCol-Gray" style={{position: "relative", top : "1px"}}>{repoConn.referencess && repoConn.referencess[871][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "1px"}}>{repoConn.referencess && repoConn.referencess[874][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["229"] ==1? <div className="ManyBoxsTop ManyBoxTopCol-Gray"  style={{position: "relative", top : "1px"}}>{repoConn.referencess && repoConn.referencess[872][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "1px"}}>{repoConn.referencess && repoConn.referencess[875][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        {repoConn.answers["129"] ==1? <div className="ManyBoxsTop ManyBoxTopCol-Gray"  style={{position: "relative", top : "1px"}}>{repoConn.referencess && repoConn.referencess[873][lang === 'en' ? 'en content' : 'ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Gray" style={{position: "relative", top: "1px"}}>{repoConn.referencess && repoConn.referencess[876][lang === 'en' ? 'en content' : 'ar content']}</div>}
                        </div> 
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[870][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[869][lang === 'en' ? 'en content' : 'ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[868][lang === 'en' ? 'en content' : 'ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "40px"}}>{repoConn.referencess && repoConn.referencess[864][lang === 'en' ? 'en content' : 'ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["129"] == 1 && repoConn.answers["229"] == 1 && repoConn.answers["329"] == 1 ?
                                repoConn.referencess && repoConn.referencess[877][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["129"] == 1 && repoConn.answers["229"] == 1 && repoConn.answers["329"] == 0 ?
                                repoConn.referencess && repoConn.referencess[878][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["129"] == 1 && repoConn.answers["229"] == 0 && repoConn.answers["329"] == 1 ?
                                repoConn.referencess && repoConn.referencess[879][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["129"] == 1 && repoConn.answers["229"] == 0 && repoConn.answers["329"] == 0 ?
                                repoConn.referencess && repoConn.referencess[880][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["129"] == 0 && repoConn.answers["229"] == 1 && repoConn.answers["329"] == 1 ?
                                repoConn.referencess && repoConn.referencess[881][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["129"] == 0 && repoConn.answers["229"] == 1 && repoConn.answers["329"] == 0 ?
                                repoConn.referencess && repoConn.referencess[882][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["129"] == 0 && repoConn.answers["229"] == 0 && repoConn.answers["329"] == 1 ?
                                repoConn.referencess && repoConn.referencess[883][lang === 'en' ? 'en content' : 'ar content'] : ""}
                                {repoConn.answers["129"] == 0 && repoConn.answers["229"] == 0 && repoConn.answers["329"] == 0 ?
                                repoConn.referencess && repoConn.referencess[884][lang === 'en' ? 'en content' : 'ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='BigOneGraph-6 pdf-container-10'>
                <img className='ImgReport' src={fortyone} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                <div className='IntroBatrn'>
                    <div className="StrepCountaner">
                        <div>{repoConn.referencess && repoConn.referencess[885][lang === 'en' ? 'en content' : 'ar content']}</div>
                    </div>
                </div>
                </div>
                <div className="TopTitle">
                <span>{repoConn.referencess && repoConn.referencess[886][lang === 'en' ? 'en content' : 'ar content']}</span>
                </div>
                <div className="Graph-1 ">
                    <div className="TopBox">
                        <div></div>
                        <div>
                            <span>{repoConn.referencess && repoConn.referencess[888][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span>{repoConn.referencess && repoConn.referencess[889][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span>{repoConn.referencess && repoConn.referencess[890][lang === 'en' ? 'en content' : 'ar content']}</span>
                        </div>
                        <div>
                            <span>{result27.upchart}%</span>
                            <span>{result28.upchart}%</span>
                            <span>{result29.upchart}%</span>
                        </div>
                    </div>
                    <div className="Graph-Top-1">
                        <div className="BigCanvas" style={SR328}></div>
                        <div className="BigCanvas" style={SR327}></div>
                        <div className="BigCanvas" style={SR326}></div>
                    </div>
                    <div className="Graph-Bottom-1">
                        <div className="BigCanvas" style={SR334}></div>
                        <div className="BigCanvas" style={SR333}></div>
                        <div className="BigCanvas" style={SR332}></div>
                    </div>
                    <div className="BottomBox">
                        <div>
                            <span>{result27.downchart}%</span>
                            <span>{result28.downchart}%</span>
                            <span>{result29.downchart}%</span>
                        </div>
                        <div>
                            <span>{repoConn.referencess && repoConn.referencess[891][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span>{repoConn.referencess && repoConn.referencess[892][lang === 'en' ? 'en content' : 'ar content']}</span>
                            <span>{repoConn.referencess && repoConn.referencess[893][lang === 'en' ? 'en content' : 'ar content']}</span>
                        </div>
                    </div>
                </div>
                <div className="BottomTitle">
                    <span>{repoConn.referencess && repoConn.referencess[887][lang === 'en' ? 'en content' : 'ar content']}</span>
                </div>
            </div>
            </div>
        </>
    )
}

export default Report4