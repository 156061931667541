import React, { useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, Typography, useMediaQuery } from '@mui/material';

export default function AddParticipant({setFetchCompaniesTrigger, open, setopen, company_id}) {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  


  const handleClose = () => {
    setopen(false);
    setName('');
    setEmail('');
    setPhone('');
    setError(null);
  };

  const handleSubmit = async () => {
    if (!name || !email) {
      setError('Please fill out both name and email fields.');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await axios.post('https://api.twindix.com/employees', {
        name,
        email,
        company_id,
        phone
      },{headers: {
        Authorization: "Bearer " + localStorage.token
    }});
    setFetchCompaniesTrigger((prev) => prev + 1)
      console.log('Response:', response.data);
      handleClose(); 
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to send participant. Please try again.');
    } finally {
      setLoading(false);
    }
  };
          const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <React.Fragment>
      <Dialog 
            PaperProps={isMobile ? {
        sx: {
          position: "fixed",
          left: 60, // Align to the left border
          margin: 0,
          height: "auto",
          borderRadius: isMobile ? 0 : "8px", // No border-radius in mobile
        },
      }: {}}
      maxWidth={isMobile ? "sm" : 'md'} open={open} onClose={handleClose}>
        <Box
          sx={{
            bgcolor: "#929292",
            width: isMobile ? 'auto' : "450px",
            height: isMobile && '100%'
          }}
        >
          <DialogContent
            sx={{
              maxHeight: '500px',
              overflowY: 'auto',
              "&::-webkit-scrollbar": {
                width: '6px',
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: '10px',
                background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
              },
              "&::-webkit-scrollbar-track": {
                background: '#929292',
                borderRadius: '10px',
              },
            }}
          >
            <Typography
              sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}
            >
              Participant Name:
            </Typography>
            <input
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{
                padding: '10px',
                fontSize: '10px',
                border: '0',
                borderRadius: '4px',
                width: '100%',
                height: '40px',
              }}
            />

            <Typography
              sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px', mt: '20px' }}
            >
              Email:
            </Typography>
            <input
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                padding: '10px',
                fontSize: '10px',
                border: '0',
                borderRadius: '4px',
                width: '100%',
                height: '40px',
              }}
            />
            <Typography
              sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px', mt: '20px' }}
            >
              Phone:
            </Typography>
            <input
              placeholder="Enter Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              style={{
                padding: '10px',
                fontSize: '10px',
                border: '0',
                borderRadius: '4px',
                width: '100%',
                height: '40px',
              }}
            />

            {error && (
              <Typography sx={{ color: 'red', mt: '10px' }}>{error}</Typography>
            )}
          </DialogContent>

          <DialogActions>
            <Button
              sx={{
                background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
                borderRadius: '10px',
                margin: 'auto',
              }}
              onClick={handleSubmit}
              variant="contained"
              disabled={loading}
            >
              {loading ? 'Adding...' : 'Add Participant'}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
