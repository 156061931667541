import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import PaymentSuccessDialog from "../../../../../Individual/Component/Form/PaymentSuccessDialog";

export default function ConfirmPay({
  isMobile,
  opeConfirmPayDialog,
  setOpeConfirmPayDialog,
  isDebriefing,
  countDebriefing,
  countExam,
  packageId,
  isPackage,
  setFetchStatisticsTrigger
}) {
  const [coupon, setCoupon] = useState({});
  const [finalPrice, setFinalPrice] = useState(null);
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [openFailedCoupon, setOpenFailedCoupon] = useState(false);
  const [specifiedPackage, setSpecifiedPackage] = useState(null);
  const [openFailedPayment, setOpenFailedPayment] = useState(false);
  const [openPaymentSuccess, setOpenPaymentSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [VerfyMesaag, setVerfyMesaag] = useState(false);

  // Reset state when dialog is closed
  const resetState = () => {
    setCoupon({});
    setFinalPrice(null);
    setIsCouponValid(false);
    setSpecifiedPackage(null);
  };

  // Handle dialog close
  const handleClose = () => {
    setOpeConfirmPayDialog(false);
    resetState();
  };

  // Handle coupon input change
  const handleChange = (e, item) => {
    const newValue = e.target.value;
    setCoupon((prevValues) => ({
      ...prevValues,
      [item]: newValue,
    }));
  };

  // Validate coupon
  const checkCoupon = useCallback(async (examId) => {
    const couponCode = coupon[examId] ?? "";
    if (!couponCode) {
      setIsCouponValid(false);
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://api.twindix.com/coupon/package",
        { package_id: examId, code: couponCode, qty: countExam },
        { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
      );

      if (response.data.success) {
        setFinalPrice(response.data.payload);
        setIsCouponValid(true);
      } else {
        setIsCouponValid(false);
        setOpenFailedCoupon(true);
      }
    } catch (error) {
      setIsCouponValid(false);
      setOpenFailedCoupon(true);
    } finally {
      setIsLoading(false);
    }
  }, [coupon, countExam]);

  // Handle exam purchase
  const buyExam = useCallback(async (examId) => {
    setIsLoading(true);
    const flexPayLoad = {
      package_id: examId,
      qty: isDebriefing ? countDebriefing : countExam,
      code: coupon[examId] ?? "",
      is_debriefing: isDebriefing ? 1 : 0,
    }
    const packagePayLoad = {
      package_id: examId,
      code: coupon[examId] ?? "",
    }
    try {
      const response = await axios.post(
        "https://api.twindix.com/payment/package",
        isPackage ? packagePayLoad : flexPayLoad,
        { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
      );

      const transactionId = response?.data?.payload?.transaction_id;
      localStorage.setItem("transaction_id", transactionId ?? "no exam transaction Id");
      localStorage.setItem("is_debriefing", isDebriefing ? 1 : 0);
      localStorage.setItem("exam_Id", examId);

      if (response?.data?.payload?.redirect === null) {
        await verifyPayment(transactionId);
        setOpeConfirmPayDialog(false);
        setOpenPaymentSuccess(true);
      } else {
        window.location.replace(response.data.payload.redirect);
      }
    } catch (error) {
      setOpenFailedPayment(true);
      console.error("Error during purchase:", error);
    } finally {
      setIsLoading(false);
    }
  }, [coupon, countExam, isDebriefing, setOpeConfirmPayDialog]);

  // Fetch package details when dialog is opened
  useEffect(() => {
    if (opeConfirmPayDialog && packageId) {
      setIsLoading(true);
      axios
        .get(`https://api.twindix.com/packages/${packageId}`)
        .then((res) => {
          setSpecifiedPackage(res?.data?.payload);
        })
        .catch((error) => {
          console.error("Error fetching package details:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [opeConfirmPayDialog, packageId]);

  // Verify payment
  const verifyPayment = async (transaction_id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      const response = await axios.post(
        "https://api.twindix.com/payment/verify",
        { transaction_id },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setVerfyMesaag(response?.data?.message)
      setFetchStatisticsTrigger((prev) => prev + 1)
      
    } catch (error) {
      console.error("Payment verification failed:", error);
      throw error;
    }
  };
  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            position: isMobile ? "fixed" : "relative",
            left: isMobile ? 0 : "auto",
            margin: 0,
            height: isMobile ? "100%" : "auto",
            borderRadius: isMobile ? 0 : "8px",
            width: isMobile ? "100%" : "450px",
          },
        }}
        maxWidth={isMobile ? "sm" : "md"}
        open={opeConfirmPayDialog}
        onClose={handleClose}
      >
        <Box sx={{ bgcolor: "#929292", height: "100%" }}>
          <DialogContent sx={{ maxHeight: "500px", overflowY: "auto" }}>
            <Stack spacing={2}>
              <Typography sx={{ fontSize: "16px", color: "white" }}>
                <Typography component="span" fontWeight="bold">
                  Assessment Name:
                </Typography>{" "}
                {specifiedPackage?.name}
              </Typography>
              <Typography sx={{ fontSize: "16px", color: "white" }}>
                <Typography component="span" fontWeight="bold">
                  Type:
                </Typography>{" "}
                {isDebriefing ? "With Debriefing" : "With No Debriefing"}
              </Typography>
              <Typography sx={{ fontSize: "16px", color: "white" }}>
                <Typography component="span" fontWeight="bold">
                  Total Price:
                </Typography>
                <Typography component="span" fontSize="20px" fontWeight="bolder">
                {isPackage 
                  ? (finalPrice?.price !== undefined ? finalPrice.price : specifiedPackage?.price) 
                  : (isDebriefing 
                      ? (finalPrice?.price !== undefined 
                          ? finalPrice.price * countDebriefing * 3.8 
                          : specifiedPackage?.price * countDebriefing * 3.8)
                      : (finalPrice?.price !== undefined 
                          ? finalPrice.price * countExam 
                          : specifiedPackage?.price * countExam)
                    )
                }
                  $
                </Typography>
              </Typography>
            </Stack>

            <Grid container direction="column" spacing={4} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <TextField
                  label="Enter Coupon"
                  variant="outlined"
                  size="small"
                  value={coupon[specifiedPackage?.id] || ""}
                  onChange={(e) => handleChange(e, specifiedPackage?.id)}
                  sx={{ flex: 1 }}
                  fullWidth
                />
                <Button
                  variant="contained"
                  onClick={() => checkCoupon(specifiedPackage?.id)}
                  sx={{
                    fontSize: "12px",
                    backgroundColor: "#4ca88f",
                    color: "white",
                    mt: 2,
                    width: "100%",
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={20} /> : "Check Coupon"}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Stack justifyContent="center" alignItems="center" sx={{ width: "100%" }}>
              <Button
                variant="contained"
                disabled={isLoading || (coupon[specifiedPackage?.id] && !isCouponValid)}
                onClick={() => buyExam(specifiedPackage?.id)}
                sx={{
                  width: "200px",
                  fontSize: "12px",
                  background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                  color: "white",
                }}
              >
                {isLoading ? <CircularProgress size={20} /> : "Subscribe Now"}
              </Button>
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>

      <Snackbar
        open={openFailedCoupon}
        autoHideDuration={6000}
        onClose={() => setOpenFailedCoupon(false)}
        message="Failed to check Coupon"
      />
      <Snackbar
        open={openFailedPayment}
        autoHideDuration={6000}
        onClose={() => setOpenFailedPayment(false)}
        message={VerfyMesaag}
      />
      <PaymentSuccessDialog open={openPaymentSuccess} onClose={() => setOpenPaymentSuccess(false)} />
    </>
  );
}