import React, { useContext, useEffect, useState, useMemo } from "react";
import {
  Checkbox,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Snackbar,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { appContext } from "../../../../App";
import axios from "axios";
import BigChips from "./BigChips";
import ActionsChips from "./ActionsChips";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Regenerate from "./Form/Regenerate";
import ResendAnswers from "./Form/ResendAnswers";

function MyTableWithPagination({
  ReFetch, 
  searchQuery, 
  examIdToFilter
}) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isLoggedIn = useContext(appContext);
  const [downloadDetails, setDownloadDetails ] = useState({});
  const [SnackbarOpen,  setSnackbarOpen ] = useState(false);
  const [SnackbarText,  setSnackbarText] = useState('ar');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [openSendExist, setOpenSendExist] = useState(false);
  const [ids, setIds] = useState([]);
  const [enroll, setEnroll] = useState([]);

  // Fetch enrollment data
  useEffect(() => {
    const fetchEnrollments = async () => {
      try {
        const token = localStorage.getItem("token");
        let allEnrollments = [];
        let currentPage = 1;
        let hasMoreData = true;
        while (hasMoreData) {
          const enrollmentsRes = await axios.get(`https://api.twindix.com/enrollments?page=${currentPage}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const newEnrollments = enrollmentsRes.data.payload.data;
          allEnrollments = [...allEnrollments, ...newEnrollments];
          hasMoreData = newEnrollments.length === 15;
          currentPage++;
        }
        setEnroll(allEnrollments.reverse());
      } catch (err) {
        console.log('ERROR', err)
      }
    };
    fetchEnrollments();
}, [ReFetch]);

  // Filter enrollments based on `code` or `exam_id`
  const filteredEnroll = searchQuery !== ""
    ? enroll?.filter((item) =>
        item?.code?.toLowerCase().includes(searchQuery.toLowerCase()) || 
        (examIdToFilter && item?.exam_id === examIdToFilter)
      )
    : enroll;

    const finishedAssessment = useMemo(
      () => filteredEnroll.filter((row) => row.finished === 1).length,
      [filteredEnroll]
    );
  
    const pendingAssessment = useMemo(
      () => filteredEnroll.filter((row) => row.finished === 0).length,
      [filteredEnroll]
    );

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleRowSelect = (rowId) => {
    setSelectedRows((prev) =>
      prev.includes(rowId) ? prev.filter((id) => id !== rowId) : [...prev, rowId]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(filteredEnroll.map((row) => row.finished === 1 && row.id));
    }
    setSelectAll(!selectAll);
  };

  const handleSelectId = (id) => {
    setIds((prevIds) =>
      prevIds.includes(id) ? prevIds.filter((prevId) => prevId !== id) : [...prevIds, id]
    );
  };

  const handleDownloadAll = () => {
    const ids = filteredEnroll
      .filter((row) => row.finished === 1)
      .map((row) => row.id);
  
      setIds(ids);
  };
  
  const handleCloseDownload = () => {
    setOpenDownload(false);
  };
  console.log(downloadDetails)
  return (
    <Box>
      <BigChips
        UserName={isLoggedIn.userName}
        FinishedAssessment={finishedAssessment}
        PendingAssessment={pendingAssessment}
      />
      
      <ActionsChips
        downloadAllReport={() => {
          handleDownloadAll()
          setOpenDownload(true)
          }}
        downloadSelectedReport={() => {
          setIds(selectedRows)
          setOpenDownload(true)
          }}
      />
      <IconButton sx={{
            display : isMobile ? 'flex' : 'none',
            position: 'absolute',
            right: '14px',
            top: '401px',
            background: 'black',
            color: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '3px',
            paddingRight: '0'
      }}>
        <ArrowForwardIosIcon />
      </IconButton>
      <TableContainer id="table-container" style={{ maxHeight: 500, overflowY: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                  aria-label="Select all rows"
                />
              </TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Assessment Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEnroll.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Checkbox
                    checked={selectedRows.includes(row.id)}
                    onChange={() => handleRowSelect(row.id)}
                    aria-label={`Select row ${row.id}`}
                  />
                </TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell>
                  {row.finished === 1 ? <Typography color="success">Finished</Typography> : <Typography color="error">Not Finished</Typography>}
                </TableCell>
                <TableCell>{row.exam_id === 1 ? "Twindix Executive" : "Job Bar"}</TableCell>
                <TableCell>
                <Button
                    variant="contained"
                    style={{
                      background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                      color: "white",
                    }}
                    onClick={() => {
                      if (row.finished === 1) {
                        setDownloadDetails(row);
                        setOpenDownload(true);
                        handleSelectId(row.id)
                      } else {
                        setDownloadDetails(row);
                        setOpenSendExist(true)
                      }
                    }}
                  >
                    {row.finished === 1 ? "Download Report" : "Take Assessment"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Regenerate
        openDownload={openDownload}
        handleCloseDownload={handleCloseDownload}
        isMobil={isMobile}
        ids={ids}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarText={setSnackbarText}
        enroid={downloadDetails.id}
        examid={downloadDetails.exam_id}
      />
      <ResendAnswers 
        openSendExist={openSendExist}
        handleSendExistClose={() => setOpenSendExist(false)}
        downloadDetails={downloadDetails}
        filteredEnroll={filteredEnroll}
      />
      <Snackbar
        open={SnackbarOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        message={SnackbarText}
      />
    </Box>
  );
}

export default MyTableWithPagination;
