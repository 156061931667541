import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box } from '@mui/material';
import axios from 'axios';


const CustemTypography = ({ label }) => {
  return (
    <Typography
      sx={{
        fontSize: '22px',
        textDecoration: 'underline',
        color: 'white',
        mb: '20px',
      }}
    >
      {label}:
    </Typography>
  );
};

export default function AddExamForm({ open, setOpen }) {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
    template_id: "",
    questions: null,
    question_algorithms: null,
    combined_algorithms: null,
    cover_image: null,
  });

  const [fileNames, setFileNames] = useState({
    questions: "",
    question_algorithms: "",
    combined_algorithms: "",
    cover_image: "",
  });

  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFileNames((prevFileNames) => ({
        ...prevFileNames,
        [name]: files[0]?.name || "",
      }));
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    console.log(formData.cover_image)
    e.preventDefault();
    setLoading(true);
    const formDataToSend = {
      title: formData.title,
      description: formData.description,
      price: formData.price,
      template_id: formData.template_id,
      questions: formData.questions, // File object is not included directly in formDataToSend
      question_algorithms: formData.question_algorithms,
      // combined_algorithms: formData.combined_algorithms,
      cover_image: formData.cover_image
    };
    console.log(formDataToSend)

    axios.post('https://api.twindix.com/dashboard/exams', formDataToSend, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "multipart/form-data",
                maxBodyLength: Infinity,
            }
    }).then((res) => {
      setOpen(false)
        console.log(res)
        setLoading(false);
}).catch(error => {
  setLoading(false);
  console.error("Error:", error)
              setErrMsg(error.response ? error.response.data.message : "An error occurred");
})
};

const [templates, settemplates] = useState([]);

useEffect(() => {
  if (!templates.length) {
    axios.get("https://api.twindix.com/dashboard/templates", {
      headers: { Authorization: "Bearer " + localStorage.token },
    }).then(res => {
      settemplates(res.data.payload.data);
    });
  }
}, [templates]);
  return (
    <Dialog maxWidth="md" open={open} onClose={() => setOpen(false)}>
      <Box sx={{ bgcolor: '#929292', width: '450px' }}>
        <DialogTitle>
          <Typography sx={{ textAlign: 'center', color: 'white', textDecoration: 'underline' }}>
            Add Exam
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSubmit} style={{border: '0', padding: '0'}} >
          <DialogContent
            sx={{
                maxHeight: "500px", 
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                  background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#929292",
                  borderRadius: "10px",
                },
              }}
          >
                  <CustemTypography label="Exam Title" />
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    placeholder="Insert exam Title"
                    style={{ padding: '10px', fontSize: '10px', border: '0', borderRadius: '4px', width: '100%', height: '40px' }}
                  />
                  <CustemTypography label="Price" />
                  <input
                    type="text"
                    name="title"
                    value={formData.price}
                    onChange={handleChange}
                    placeholder="Insert Price"
                    style={{ padding: '10px', fontSize: '10px', border: '0', borderRadius: '4px', width: '100%', height: '40px' }}
                  />
                  <CustemTypography label='description' />
                  <input
                    type="text"
                    name="title"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Insert description"
                    style={{ padding: '10px', fontSize: '10px', border: '0', borderRadius: '4px', width: '100%', height: '40px' }}
                  />
                  <CustemTypography label='template' />
                  <select
                    type="text"
                    name="template_id"
                    value={formData.template_id}
                    onChange={handleChange}
                    placeholder="Insert template_id"
                    style={{ padding: '10px', fontSize: '10px', border: '0', borderRadius: '4px', width: '100%', height: '40px' }}
                  >
                    <option value="" disabled>
                      Select template
                    </option>
                    {templates?.map((ele) => {
                      return (
                        <option value={ele.id}>{ele.name}</option>
                      )
                    })}
                  </select>
              <React.Fragment> 
              <CustemTypography label='Questions' />
                <Box
                  sx={{
                    border: '2px dashed white',
                    borderRadius: '4px',
                    padding: '20px',
                    textAlign: 'center',
                    color: 'white',
                    cursor: 'pointer',
                    backgroundColor : 'transparent',
                    margin : '12px'
                  }}
                >
                  <input                   
                  onChange={handleChange}
                  type="file"
                  name="questions" />
                  {fileNames.questions? (
                    <Typography>{fileNames.questions}</Typography>
                  ) : (
                    <Typography>Drag and drop a file, or click to select one</Typography>
                  )}
                </Box>
                <CustemTypography label='Questions Algorithms' />
                <Box
                  sx={{
                    border: '2px dashed white',
                    borderRadius: '4px',
                    padding: '20px',
                    textAlign: 'center',
                    color: 'white',
                    cursor: 'pointer',
                    backgroundColor : 'transparent',
                    margin : '12px'
                  }}
                >
                  <input                   
                  onChange={handleChange}
                  type="file"
                  name="question_algorithms" />
                  {fileNames.question_algorithms? (
                    <Typography>{fileNames.question_algorithms}</Typography>
                  ) : (
                    <Typography>Drag and drop a file, or click to select one</Typography>
                  )}
                </Box>
                {/* <CustemTypography label='Combined Algorithms' />
                <Box
                  sx={{
                    border: '2px dashed white',
                    borderRadius: '4px',
                    padding: '20px',
                    textAlign: 'center',
                    color: 'white',
                    cursor: 'pointer',
                    backgroundColor : 'transparent',
                    margin : '12px'
                  }}
                >
                  <input                   
                  onChange={handleChange}
                  type="file"
                  name="combined_algorithms" />
                  {fileNames.combined_algorithms? (
                    <Typography>{fileNames.combined_algorithms}</Typography>
                  ) : (
                    <Typography>Drag and drop a file, or click to select one</Typography>
                  )}
                </Box> */}
                <CustemTypography label='Cove Image' />
                <Box
                  sx={{
                    border: '2px dashed white',
                    borderRadius: '4px',
                    padding: '20px',
                    textAlign: 'center',
                    color: 'white',
                    cursor: 'pointer',
                    backgroundColor : 'transparent',
                    margin : '12px'
                  }}
                >
                  <input                   
                  onChange={handleChange}
                  type="file"
                  name="cover_image" />
                  {fileNames.cover_image? (
                    <Typography>{fileNames.cover_image}</Typography>
                  ) : (
                    <Typography>Drag and drop a file, or click to select one</Typography>
                  )}
                </Box>
              </React.Fragment>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" sx={{ background: '#5ac4cb', margin: 'auto' }}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
}
