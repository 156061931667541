import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, Snackbar, Typography, useMediaQuery, TextField } from "@mui/material";
import axios from "axios";

export default function AddPositionForm({ open, setOpen, companyId, setReFetchSpecificCompany }) {
  const [positionName, setPositionName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const isMobile = useMediaQuery("(max-width:600px)");

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddPosition = () => {
    if (!positionName.trim()) {
      setSnackbarMessage("Position name cannot be empty.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    setIsLoading(true);

    const payload = {
      title: positionName,
    };

    axios
      .post(`https://api.twindix.com/companies/${companyId}/positions`, payload, {
        headers: { Authorization: "Bearer " + localStorage.token },
      })
      .then((response) => {
        setReFetchSpecificCompany((prev) => prev + 1);
        setOpen(false);
        setPositionName("");
        handleClose();
        setSnackbarMessage("Position added successfully!");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        setIsLoading(false)
      })
      .catch((error) => {
        console.error("Error adding position:", error);
        setSnackbarMessage("Failed to add position. Please try again.");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        setIsLoading(false)

      })
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <React.Fragment>
      <Dialog
        PaperProps={
          isMobile ? {
            sx: {
              position: "fixed",
              left: 60,
              margin: 0,
              height: "100%",
              maxHeight: "100vh",
              borderRadius: isMobile ? 0 : "8px",
            },
          }: {}
        }
        maxWidth={isMobile ? "sm" : "md"}
        open={open}
        onClose={handleClose}
      >
        <Box
          sx={{
            bgcolor: "#929292",
            width: isMobile ? "auto" : "450px",
            height: isMobile && "100%",
          }}
        >
          <DialogTitle sx={{ color: "white" }} id="add-position-dialog-title">
            Add New Position
          </DialogTitle>
          <DialogContent
            sx={{
              maxHeight: "500px",
              overflowY: "auto",
              "&::-webkit-scrollbar": { width: "6px" },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              },
              "&::-webkit-scrollbar-track": { background: "#929292", borderRadius: "10px" },
            }}
          >
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Position Name:</Typography>
                <TextField
                  aria-label="Position Name"
                  fullWidth
                  value={positionName}
                  onChange={(e) => setPositionName(e.target.value)}
                  placeholder="Enter position name"
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "4px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "#5ac4cb",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#5ac4cb",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                borderRadius: "10px",
                margin: "auto",
              }}
              variant="contained"
              onClick={handleAddPosition}
              disabled={isLoading}
            >
              {isLoading ? "Adding..." : "Add Position"}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        action={
          <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
            Close
          </Button>
        }
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: snackbarSeverity === "error" ? "#d32f2f" : "#43a047",
          },
        }}
      />
    </React.Fragment>
  );
}