import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Box,
  Button,   
} from "@mui/material";
import axios from "axios";
import EditTemplates from "../Forms/EditTemplates";
import AddTemplates from "../Forms/AddTemplates";
import DeleteDialog from "../../../../FemilerCom/DeleteDialog/DeleteDiaolg";

function Templates() {
  const [formType, setFormType] = useState(null);
  const [templates, settemplates] = useState([]);
  const [tempId, settempId] = useState([]);
  const [tempName, settempName] = useState([]);

  let [SpescficTem, setSpescficTem] = useState([])

    const getSpescficTem = (id) => {
        axios.get(`https://api.twindix.com/dashboard/templates/${id}`, {
          headers: { Authorization: "Bearer " + localStorage.token },
        }).then(res => {
          setSpescficTem(res.data.payload);
        });
    }
  
  useEffect(() => {
    if (!templates.length) {
      axios.get("https://api.twindix.com/dashboard/templates", {
        headers: { Authorization: "Bearer " + localStorage.token },
      }).then(res => {
        settemplates(res.data.payload.data);
      });
    }
  }, [templates]);

  function dropTemplate(id) {
    axios.delete("https://api.twindix.com/dashboard/templates/" + id, {
      headers: { Authorization: "Bearer " + localStorage.token },
    }).then((res) => {
      if (res.status) {
        axios.get("https://api.twindix.com/dashboard/templates", {
          headers: { Authorization: "Bearer " + localStorage.token },
        }).then(res => {
          settemplates(res.data.payload.data);
        });
      }
    });
  }
    const [TemplateId, setTemplateId] = useState()
    const [openDelete, setOpenDelete] = useState(false)
    const handleCloseDelete = () => {
      setOpenDelete(false)
    }
    const DeleteFun = () => {
      dropTemplate(TemplateId)
      handleCloseDelete()
    }
    console.log(SpescficTem)
  return (
    <Box>
      <Button 
        variant="contained" 
        sx={{ width: "150px", margin: 'auto', background: "linear-gradient(45deg, #2c6264, #5ac4cb)" }} 
        onClick={() => setFormType('add')} // Set to 'add' to open AddTemplates form
      >
        Add Template
      </Button>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Action Report</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates.length ? templates.map((template) => {
              return (
                <TableRow key={template.id}>
                  <TableCell>{template.id}</TableCell>
                  <TableCell>{template.name}</TableCell>
                  <TableCell style={{ width: "20%", textAlign: "center" }}>
                    <Button 
                    sx={{ width :'160px',background: "linear-gradient(45deg, #2c6264, #5ac4cb)", color : 'white'}}
                      onClick={() => {
                        settempId(template.id)
                        settempName(template.name)
                        setFormType('edit');
                        getSpescficTem(template.id)
                      }} 
                    >
                      Edit Template
                    </Button>
                    <Button 
                      sx={{width :'160px', background: "linear-gradient(45deg, #2c6264, #5ac4cb)", margin: '12px', color : 'white' }} 
                      className="btn btn-danger" 
                      onClick={() => {
                        setTemplateId(template.id)
                        setOpenDelete(true)
                      }}
                    >
                      Delete Template
                    </Button>
                  </TableCell>
                </TableRow>
              );
            }) : "There is no templates"}
          </TableBody>
        </Table>
      </TableContainer>
      
      {formType === 'edit' && <EditTemplates SpescficTem={SpescficTem} tempId={tempId} tempName={tempName} open={formType === 'edit'} setOpen={setFormType} />}
      {formType === 'add' && <AddTemplates open={formType === 'add'} setOpen={setFormType} />}
      <DeleteDialog open={openDelete} handleClose={handleCloseDelete} DialogTitle="Template" DialogDeleteContent='This Template' DeleteFun={DeleteFun}  />
    </Box>
  );
}

export default Templates;
