import React, { useState, useEffect, useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Typography, Box, InputLabel, Select, MenuItem, useMediaQuery } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import { appContext } from '../../../../App';
import Swal from 'sweetalert2';
import ApiContext from '../Context/context';
import { useSearch } from '../Context/SearchContext';
import DeleteDialog from '../../../../FemilerCom/DeleteDialog/DeleteDiaolg';
import AddEmployee from './Component/EmployeeManagement/Diaolgs/AddEmployee';
import EditEmployee from './Component/EmployeeManagement/Diaolgs/EditEmployee';
import LoadingSpinner from '../../../../FemilerCom/loading/spinner';
const EmployeeManagement = () => {
  const {
    employees,
    companies,
    setShouldFetchCompanies,
    setShouldFetchEmployees,
    setFetchEmployeesTrigger,
    loading,
    handelPermission2
    } = useContext(ApiContext);
    
        handelPermission2(["employee-show", 'employee-create', 'employee-update', 'employee-delete'])
    
    const isMobile = useMediaQuery("(max-width:600px)");

  const { searchQuery } = useSearch();

  const [open1, setOpen1] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [formData, setFormData] = useState({ name: '', email: '', position_id: '', company_id: '' });
  const [positions, setPositions] = useState([])
  const [selectedCompany, setSelectedCompany] = useState([])
  const [EmployeeId, setEmployeeId] = useState()
  const [openDelete, setOpenDelete] = useState(false)

          useEffect(() => {
            if(setShouldFetchCompanies){
              setShouldFetchCompanies(true)
            }
            if(setShouldFetchEmployees){
              setShouldFetchEmployees(true)
            }
          }, [])

  useEffect(() => {
    axios
      .get(`https://api.twindix.com/companies/${selectedCompany.id}/positions`, {
        headers: { Authorization: `Bearer ${localStorage.token}` },
      })
      .then((res) => setPositions(res.data.payload))
      .catch((error) => console.error("Error fetching positions:", error));
}, [selectedCompany]);

const filteredcompanies = employees?.filter((product) =>
  product.name?.toLowerCase().includes(searchQuery?.toLowerCase())
);

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`https://api.twindix.com/employees/${id}`, {
        headers: { Authorization: `Bearer ${token}`},
      });
    } catch (error) {
      console.error('Error deleting employee:', error);
    }
  };

  const handleEdit = (employee) => {
    setCurrentEmployee(employee);
    setFormData({
      name: employee.name,
      email: employee.email,
      position_id: employee.position_id || '',
      company_id: employee.company_id || '',
    });
    setOpen(true);
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      if (currentEmployee) {
        // Update existing employee
        await axios.put(`https://api.twindix.com/employees/${currentEmployee.id}`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
      } else {
        // Create new employee
        await axios.post('https://api.twindix.com/employees', formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
      setFetchEmployeesTrigger((prev) => prev + 1)
      setOpen1(false);
      setOpen(false);
      setCurrentEmployee(null);
      setFormData({ name: '', email: '', position_id: '', company_id: '' });
    } catch (error) {
      console.error('Error saving employee:', error);
    }
  };

  const handleCloseDelete = () => {
    setOpenDelete(false)
  }
  const DeleteFun = () => {
      handleDelete(EmployeeId)
    handleCloseDelete()
  }              
  return (
    <div>
      {loading && <LoadingSpinner />}

      <Button id='employee-create' onClick={() => {
          setOpen1(true)
        }} size="large" variant="contained" sx={{fontSize: '17px', borderRadius : "15px",background: "#3f8e8f" }}>
      <AddIcon />
      Add Participant
      </Button>
      <TableContainer style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredcompanies?.map((employee) => {
              const company = companies.find((comp) => comp.id === employee.company_id);
              const position = positions.find((pos) => pos.id === employee.position_id);
              return (
                <TableRow key={employee.id}>
                  <TableCell>{employee.name}</TableCell>
                  <TableCell>{employee.email}</TableCell>
                  <TableCell>{position ? position.title : 'Unknown Position'}</TableCell>
                  <TableCell>{company ? company.name : 'Unknown Company'}</TableCell>
                  <TableCell>
                    <IconButton id='employee-update' color="primary" onClick={() => {
                        handleEdit(employee)
                    }}>
                      <Edit />
                    </IconButton>
                    <IconButton id='employee-delete' color="secondary" onClick={() => {
                          setEmployeeId(employee.id)
                          setOpenDelete(true)
                    }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

<EditEmployee 
  isMobile={isMobile}
  open={open}
  setOpen={setOpen}
  currentEmployee={currentEmployee}
  formData={formData}
  setFormData={setFormData}
  companies={companies}
  setSelectedCompany={setSelectedCompany}
  positions={positions}
  selectedCompany={selectedCompany}
  handleSubmit={handleSubmit}
/>
<AddEmployee 
  isMobile={isMobile}
  open1={open1}
  setOpen1={setOpen1}
  formData={formData}
  setFormData={setFormData}
  companies={companies}
  setSelectedCompany={setSelectedCompany}
  positions={positions}
  selectedCompany={selectedCompany}
  handleSubmit={handleSubmit}
/>
      <DeleteDialog open={openDelete} handleClose={handleCloseDelete} DialogTitle="Employee" DialogDeleteContent='This Employee' DeleteFun={DeleteFun}  />
    </div>
  );
};

export default EmployeeManagement;
