import { useNavigate } from "react-router-dom"
import LogInBox from "../../Component/LogIn/LogInCom"
import { useEffect } from "react"

function LogIn () {
    let navigate = useNavigate()
    useEffect(() => {
        if(localStorage.token) {
            if(localStorage.userType == 2) {
                navigate("/individual", {replace: true})
            } else if(localStorage.userType == 1) {
                navigate("/Business", {replace: true})
            }
        }
    },[])
    return (
        <>
            <LogInBox />
        </>
    )
}

export default LogIn