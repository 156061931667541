import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  TextField,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";

export default function EditBench({
  companies,
  positions,
  error,
  isMobile,
  open,
  handleClose,
  benchmarkId,
  setFetchBenchmarkTrigger
}) {
  const [formData, setFormData] = useState({
    title: "",
    company: "",
    position: "",
  });
  const [errorMessage, setErrorMessage] = useState(error || "");

  // Fetch specific benchmark data when the dialog opens
  useEffect(() => {
    if (open && benchmarkId) {
      fetchBenchmarkData(benchmarkId);
    }
  }, [open, benchmarkId]);

  // Fetch benchmark data
  const fetchBenchmarkData = async (id) => {
    try {
      const res = await axios.get(
        `https://api.twindix.com/benchmark-questions/1/benchmarks/${id}`,
        { headers: { Authorization: `Bearer ${localStorage.token}` } }
      );
      const benchmark = res.data.payload;
      setFormData({
        title: benchmark.title,
        company: benchmark.company_id,
        position: benchmark.position_id,
      });
    } catch (error) {
      console.error("Error fetching benchmark:", error);
      setErrorMessage("Failed to fetch benchmark data.");
    }
  };

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmitEdit = async () => {
    if (!formData.title || !formData.company || !formData.position) {
      setErrorMessage("Please fill all required fields.");
      return;
    }

    const payload = {
      title: formData.title,
      company_id: formData.company,
      position_id: formData.position,
    };

    try {
      await axios.put(
        `https://api.twindix.com/benchmark-questions/1/benchmarks/${benchmarkId}`,
        payload,
        { headers: { Authorization: "Bearer " + localStorage.token } }
      );
      handleClose()
      setFetchBenchmarkTrigger((prev) => prev + 1)
    } catch (error) {
      console.error("Error updating benchmark:", error);
      setErrorMessage("Failed to update benchmark. Please try again.");
    } finally {
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          position: isMobile ? "fixed" : "relative",
          left: isMobile ? 50 : "auto",
          margin: 0,
          height: isMobile ? "100%" : "auto",
          borderRadius: isMobile ? 0 : "8px",
          width: isMobile ? "auto" : "450px",
        },
      }}
      maxWidth={isMobile ? "sm" : "md"}
      open={open}
      onClose={handleClose}
    >
      <Box sx={{ bgcolor: "#929292", height: isMobile ? "100%" : "auto" }}>
        <DialogTitle sx={{ color: "white" }}>Edit Benchmark</DialogTitle>
        <DialogContent
          sx={{
            maxHeight: "500px",
            overflowY: "auto",
            "&::-webkit-scrollbar": { width: "6px" },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
            },
            "&::-webkit-scrollbar-track": { background: "#929292", borderRadius: "10px" },
          }}
        >
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>
                Benchmark Name:
              </Typography>
              <TextField
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                fullWidth
                placeholder="Enter Benchmark Name"
                sx={{ bgcolor: "white", borderRadius: "4px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Company:</Typography>
              <Select
                name="company"
                value={formData.company}
                onChange={handleInputChange}
                fullWidth
                displayEmpty
                sx={{ bgcolor: "white", borderRadius: "4px" }}
              >
                <MenuItem value="" disabled>
                  Select Company
                </MenuItem>
                {companies
                  .filter((ele) => ele.exam_id === 65)
                  .map((com) => (
                    <MenuItem key={com.id} value={com.id}>
                      {com.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Position:</Typography>
              <Select
                name="position"
                value={formData.position}
                onChange={handleInputChange}
                fullWidth
                displayEmpty
                sx={{ bgcolor: "white", borderRadius: "4px" }}
              >
                <MenuItem value="" disabled>
                  Select Position
                </MenuItem>
                {positions
                  ?.filter((pos) => pos.company_id === formData.company)
                  .map((pos) => (
                    <MenuItem key={pos.id} value={pos.id}>
                      {pos.title}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
          {errorMessage && (
            <Typography color="error" sx={{ mt: 2 }}>
              {errorMessage}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              borderRadius: "10px",
              margin: "auto",
            }}
            variant="contained"
            onClick={handleSubmitEdit}
          >
            Edit
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}