import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,   
} from "@mui/material";
import axios from "axios";
import EditExam from "../Forms/EditExam";
import AddExamForm from "../Forms/AddExamForm";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from "../../../../FemilerCom/DeleteDialog/DeleteDiaolg";
function Exams() {
    const [exams, setExams] = useState();
    const [open, setopen] = useState()
  
    useEffect(() => {
      if (!exams) {
        fetchExams("https://api.twindix.com/dashboard/exams");
      }
      console.log(exams);
    }, [exams]);
  
    const fetchExams = (url) => {
      axios
        .get(url, {
          headers: { Authorization: "Bearer " + localStorage.token },
        })
        .then((res) => {
          setExams(res.data.payload);
        })
        .catch((error) => {
          console.error("Error fetching exams:", error);
        });
    };

  
    function dropExam(id) {
      axios.delete("https://api.twindix.com/dashboard/exams/" + id, {headers: {Authorization: "Bearer " + localStorage.token}}).then((res) => {
        console.log(res)
        if(res.status) {
        } else {
        }
        axios.get("https://api.twindix.com/dashboard/exams", {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
          setExams(res.data.payload);
        })
      })
      
    }

    const [openDia, setopenDia] = useState(false)
  
  const [editId, setEditId] = useState(null);

  const handleEditClick = (id) => {
    setopen(true)
    setEditId(id); 
  };

  const [templates, settemplates] = useState([]);

useEffect(() => {
  if (!templates.length) {
    axios.get("https://api.twindix.com/dashboard/templates", {
      headers: { Authorization: "Bearer " + localStorage.token },
    }).then(res => {
      settemplates(res.data.payload.data);
    });
  }
}, [templates]);
    const [ExamsId, setExamsId] = useState()
    const [openDelete, setOpenDelete] = useState(false)
    const handleCloseDelete = () => {
      setOpenDelete(false)
    }
    const DeleteFun = () => {
      dropExam(ExamsId)
      handleCloseDelete()
    }
  return (
    <Box>
            <Box width={200} margin='auto'>
            <Button variant="contained" sx={{ background: "linear-gradient(45deg, #2c6264, #5ac4cb)",}} onClick={() => setopenDia(true)}>Add Exam</Button>
            <AddExamForm open={openDia} setOpen={setopenDia} />
        </Box>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">id</TableCell>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="left">Template</TableCell>
              <TableCell align="left">Price</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {exams
            ? exams.map((exam, index) => (
                <TableRow key={exam.id}>
                  <TableCell className={index % 2 === 0 ? "tablerow" : ""} scope="row">
                    {exam.id}
                  </TableCell>
                  <TableCell className={index % 2 === 0 ? "tablerow tablerowColor" : "tablerow"}>{exam.title}</TableCell>
                  <TableCell className={index % 2 === 0 ? "tablerow tablerowColor" : "tablerow"}>{exam.description}</TableCell>
                  <TableCell className={index % 2 === 0 ? "tablerow tablerowColor" : "tablerow"}>{templates.map((ele) => exam.template_id === ele.id && ele.name) }</TableCell>
                  <TableCell className={index % 2 === 0 ? "tablerow tablerowColor text-success" : "tablerow text-success"}>{exam.price}</TableCell>
                  <TableCell className={index % 2 === 0 ? "tablerow tablerowColor Flex" : "tablerow Flex"}>
                  <Button
                      sx={{
                        marginLeft:'25px',
                        width: "auto",
                        background: "red",
                        color: "white",
                      }}
                      onClick={() => {
                        setExamsId(exam.id)
                        setOpenDelete(true)
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                    <Button sx={{margin: "10px", width: "100px", background: "linear-gradient(45deg, #2c6264, #5ac4cb)", border: "0",color : 'white' }} onClick={() =>  handleEditClick(exam.id)} className="btn btn-primary">
                      Edit Exam
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            : "There is no Exams"}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination Component */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 2,
        }}
      >
      </Box>
      <EditExam editId={editId} open={open} setOpen={setopen} />
      <DeleteDialog open={openDelete} handleClose={handleCloseDelete} DialogTitle="Exam" DialogDeleteContent='This Exam' DeleteFun={DeleteFun}  />
    </Box>
  );
}

export default Exams;
