import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";

export default function EditEmployee({
  isMobile,
  openEditForm,
  setOpenEditForm,
  currentEmployee,
  companyId,
  setRefreshSpecCompany
}) {
  const [formData, setFormData] = useState({ name: "", email: "", phone: '' });
  const [getSpecEmp, setGetSpecEmp] = useState([]);
  useEffect(() => {
    if(currentEmployee !== undefined){      
    const fetchEmployeeDetails = async () => {
      try {
        const res = await axios.get(`https://api.twindix.com/employees/${currentEmployee}`, {
          headers: { Authorization: `Bearer ${localStorage.token}` },
        });
        const employee = res.data.payload;
        setGetSpecEmp(employee);
        setFormData({ name: employee.name, email: employee.email, phone: employee.phone }); // Populate form data
      } catch (err) {
        console.log(err)
      } 
    };
    
    fetchEmployeeDetails();
  }
  }, [currentEmployee]);


  // Initialize form data when `getSpecEmp` changes
  useEffect(() => {
    if (getSpecEmp) {
      setFormData({
        name: getSpecEmp.name || "",
        email: getSpecEmp.email || "",
      });
    }
  }, [getSpecEmp]);

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      // Update existing employee
      await axios.put(
        `https://api.twindix.com/employees/${currentEmployee}`,
        { ...formData, company_id: companyId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setRefreshSpecCompany((prev) => prev + 1);
      setOpenEditForm(false);
    } catch (error) {
      console.error("Error saving employee:", error);
    }
  };

  const handleClose = () => {
    setFormData({ name: "", email: "" });
    setOpenEditForm(false);
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          position: isMobile ? "fixed" : "relative",
          left: isMobile ? 55 : "auto",
          margin: 0,
          height: "auto",
          borderRadius: isMobile ? 0 : "8px",
          width: isMobile ? "100%" : "450px",
          maxWidth: isMobile ? "sm" : "md",
        },
      }}
      open={openEditForm}
      onClose={handleClose}
    >
      <Box
        sx={{
          bgcolor: "#929292",
          width: "100%",
          height: isMobile ? "100%" : "auto",
        }}
      >
        <DialogTitle>
          <Typography sx={{ color: "white" }}>Edit Employee</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ color: "white", textDecoration: "underline" }}>
            Name
          </Typography>
          <TextField
            fullWidth
            name="name"
            InputProps={{
              sx: { 
                backgroundColor: 'white',
                borderRadius: '12px',
                height:'50px'
               },
            }}
            value={formData.name}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
            margin="normal"
          />
          <Typography sx={{ color: "white", textDecoration: "underline" }}>
            Email
          </Typography>
          <TextField
            fullWidth
            name="email"
            InputProps={{
              sx: { 
                backgroundColor: 'white',
                borderRadius: '12px',
                height:'50px'
               },
            }}
            value={formData.email}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                email: e.target.value,
              }))
            }
            margin="normal"
          />
          <Typography sx={{ color: "white", textDecoration: "underline" }}>
            Phone
          </Typography>
          <TextField
            fullWidth
            type="number"
            name="phone"
            InputProps={{
              sx: { 
                backgroundColor: 'white',
                borderRadius: '12px',
                height:'50px'
               },
            }}
            value={formData.phone}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                phone: e.target.value,
              }))
            }
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              borderRadius: "10px",
              margin: "auto",
              color: "white",
              fontWeight: "bold",
              padding: "8px 16px",
            }}
            onClick={handleSubmit}
            variant="contained"
          >
            Update Participant
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}