import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";

export default function EditEmployee({
  openEditForm,
  handleCloseEditForm,
  getSpecEmp,
  EmployeeId,
  setReFetchSpecificCompany,
  PositionId,
  companyId
}) {
  const [formData, setFormData] = useState({ name: "", email: "", phone: "" });

  // Initialize formData with getSpecEmp when it changes
  useEffect(() => {
    if (getSpecEmp) {
      setFormData({
        name: getSpecEmp.name || "",
        email: getSpecEmp.email || "",
        phone: getSpecEmp.phone || "",
      });
    }
  }, [getSpecEmp]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    const payload = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
    };
    try {
      const token = localStorage.getItem('token');
      // Update existing employee
      await axios.put(`https://api.twindix.com/employees/${EmployeeId}`, { ...payload, position_id: PositionId, company_id: companyId }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFormData({ name: "", email: "", phone: "" });
      setReFetchSpecificCompany((prev) => prev + 1);
      handleCloseEditForm();
    } catch (error) {
      console.error('Error saving employee:', error);
    }
  };

  return (
    <Dialog open={openEditForm} onClose={handleCloseEditForm}>
      <Box sx={{ width: '500px', background: '#929292' }}>
        <DialogTitle>
          <Typography sx={{fontSize: '24px',  color: 'white' }}>Edit Employee</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ color: 'white', textDecoration: 'underline' }}>Name</Typography>
          <TextField
            fullWidth
            name="name"
            InputProps={{
              sx: { 
                backgroundColor: 'white',
                borderRadius: '12px',
                height:'50px'
                
                 }, // Ensure white text
            }}
            value={formData.name} // Always a defined value
            onChange={handleChange}
            margin="normal"
          />
          <Typography sx={{ color: 'white', textDecoration: 'underline' }}>Email</Typography>
          <TextField
            fullWidth
            name="email"
            InputProps={{
              sx: { 
                backgroundColor: 'white',
                borderRadius: '12px',
                height:'50px'
               }, // Ensure white text
            }}
            value={formData.email} // Always a defined value
            onChange={handleChange}
            margin="normal"
          />
          <Typography sx={{ color: 'white', textDecoration: 'underline' }}>Phone</Typography>
          <TextField
            fullWidth
            type="number"
            name="phone"
            InputProps={{
              sx: { 
                backgroundColor: 'white',
                borderRadius: '12px',
                height:'50px'
               }, // Ensure white text
            }}
            value={formData.phone} // Always a defined value
            onChange={handleChange}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
              borderRadius: '10px',
              margin: 'auto',
            }}
            onClick={handleSubmit}
            variant="contained"
          >
            Edit Participant
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}