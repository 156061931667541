import { Box, Button,Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from "@mui/material";
import AddPositionForm from "./Dialog/AddPositionForm";
import { useContext, useState } from "react";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DeleteDialog from "../../../../../../FemilerCom/DeleteDialog/DeleteDiaolg";
import EditPosition from "./Dialog/EditPosition";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingSpinner from "../../../../../../FemilerCom/loading/spinner";
import ApiContext from "../../../Context/context";

function PositionComponent ({
  loading, 
  setReFetchSpecificCompany, 
  getSpecCompany, 
  setPositionId, 
  PositionId,  
  handleBackButtonClick, 
  setShowSection, 
  company
})  {
  const {
    handelPermission2
  } = useContext(ApiContext);
handelPermission2(['position-show', 'position-create', 'position-update', 'position-delete'])


  const isMobile = useMediaQuery("(max-width:600px)");

  const [openAddPosition, setOpenAddPosition] = useState(false); 
  const [error, setError] = useState(false); 
  const [openEditPosition, setOpenEditPosition] = useState(false);
  const [openDelete, setOpenDelete] = useState(false)

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.twindix.com/companies/${company.id}/positions/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      });
      setReFetchSpecificCompany((prev) => prev  + 1)
    } catch (error) {
      setError('Cannot Remove Position Have Participant')
      console.error("Error deleting position:", error);
    }
  };

  const DeleteFun = () => {
      handleDelete(PositionId);
      setOpenDelete(false)
  }   
      return (
        <>
      {loading && <LoadingSpinner />}
          <Button onClick={handleBackButtonClick} 
                sx={{ 
                  position : 'fixed',
                  top : '96px',
                  left :'0',
                  fontSize :'18px'
                }}>
            <ArrowBackIcon />Back
          </Button>
          <Stack sx={isMobile ? {overflow :'scroll'}: {}}>
            <Button
            id="position-create"
                onClick={() => {
                    setOpenAddPosition(true)
                  }}
                variant="contained"
                sx={{
                  color: "white",
                  background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                  marginTop: "16px",
                  width :'200px'
                }}
              >
              <AddIcon />
                Add Position
              </Button>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell sx={{float: 'right', marginRight :'60px'}}>Participants</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getSpecCompany?.positions
                    ?.map((position) => {
                      return (
                        <TableRow key={position.id}>
                          <TableCell>
                            <Typography
                              onClick={() => {
                                setPositionId(position.id)
                                setShowSection('assessment')
                                }}
                              sx={{
                                width :'200px',
                                cursor: "pointer",
                                color: "#2b6566",
                                textDecoration: "underline",
                              }}
                            >
                              {position.title}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{float :'right'}}>
                          <Stack direction='row' height='60px' alignItems='center'>
                          <Box>
                            <Typography
                              sx={{
                                margin: "4px",
                                color: "#3f8e8f",
                                border: "2.5px solid #3f8e8f",
                                borderRadius: "12px",
                                padding: "12px 6px",
                                fontWeight: "bold",
                                width : '130px',
                                textAlign : "center"
                              }}
                            >
                              {position.position_count} Participant
                            </Typography>
                            </Box>
                            <Stack height='35px' direction="row" spacing={1}>
                              <Button
                              id="position-update"
                                variant="contained"
                                onClick={() => {
                                    setPositionId(position.id);
                                    setOpenEditPosition(true);
                                }}
                                sx={{
                                  borderRadius: "10px",
                                  color: "white",
                                  background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                              id="position-delete"
                                variant="contained"
                                color="error"
                                onClick={() => {
                                  setPositionId(position.id)
                                  setOpenDelete(true)
                                }}
                                sx={{
                                  borderRadius: "10px",
                                  color: "white",
                                }}
                              >
                                <DeleteIcon />
                              </Button>
                            </Stack>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <AddPositionForm
              open={openAddPosition}
              setOpen={setOpenAddPosition}
              companyId={company.id}
              setReFetchSpecificCompany={setReFetchSpecificCompany}
            />
            <EditPosition 
            isMobile={isMobile}
            openEditPosition={openEditPosition}
            setOpenEditPosition={setOpenEditPosition}
            setReFetchSpecificCompany={setReFetchSpecificCompany}
            companyId={company.id}
            PositionId={PositionId}
            />
            <DeleteDialog error={error} open={openDelete} setOpenDelete={setOpenDelete} DialogTitle="Position" DialogDeleteContent='This Position' DeleteFun={DeleteFun}  />
          </Stack>
        </>

    )
}

export default PositionComponent