import RegisterBox from "../../Component/Register/RegisterCom"
import { useEffect } from "react"
import { useNavigate } from "react-router"
function Register () {
    let navigate = useNavigate()
    useEffect(() => {
        if(localStorage.token) {
            if(localStorage.userType == 2) {
                navigate("/individual", {replace: true})
            } else if(localStorage.userType == 1) {
                navigate("/Business", {replace: true})

            }
        }
    }, [])
    
    return (
        <>
            <div > 
                <RegisterBox />
            </div>
        </>
    )
} 

export default Register