import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import { Box } from '@mui/material';

const PaymentSuccessDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
    <Box sx={{backgroundColor : '#929292'}}>
      <DialogTitle>
        <Typography sx={{color : 'white'}} variant="h5" align="center" fontWeight="bold">
          Payment Successful
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div style={{ textAlign: 'center' }}>
          <CheckCircleIcon style={{ fontSize: 60, color: green[500] }} />
          <Typography variant="body1" style={{color : 'white', marginTop: 16 }}>
            Your payment has been successfully processed.
          </Typography>
          <Typography variant="body2" sx={{color : 'white'}} style={{ marginTop: 8 }}>
            Thank you for your purchase! A confirmation email has been sent to your registered email address.
          </Typography>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', paddingBottom: 24 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          style={{ borderRadius: 20, padding: '10px 24px', background: "linear-gradient(45deg, #2c6264, #5ac4cb)", }}
        >
          Close
        </Button>
      </DialogActions>
    </Box>
    </Dialog>
  );
};

export default PaymentSuccessDialog;