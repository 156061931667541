import * as React from 'react'; 
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import { useContext } from "react";
import { appContext } from '../../App';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
// Define ListItemCom as a reusable Component
const ListItemCom = ({ label }) => (
  <ListItem
    sx={{
      color: 'white',
      fontWeight: '600',
      textAlign: 'center',
      width: '100%',
      margin: '0px auto',
      cursor : 'pointer',
    }}
  >
    {label}
  </ListItem>
);


export default function TemporaryDrawerHr({ open, toggleDrawer,  setisDashboard,
  setisTwindixIndivdual,
  setisJopBar,
  setisBenchmarksShelve,
  setisTeamAccess,
  setisMangeTwindixAssessments,
  setisMangeJopBarAssessments,
  setisSubscriptions,
  setisHetInTouch,
  setisMyPackages,
  setRoles,
  setCompany,
  setEmployee,
  setMyEnrollments
}) {
  let isLoggedIn = useContext(appContext);
  let navigate = useNavigate();
  function logout(e) {
      e.preventDefault();
      
      axios.delete("https://api.twindix.com/auth/logout", {headers:{
          Authorization:"Bearer " + localStorage.token
      }}).then(res => {
          console.log("logged out successfully");
          localStorage.removeItem("token");
          localStorage.removeItem("isAdmin");
          localStorage.removeItem("isLogged");
          localStorage.removeItem("userType");
          isLoggedIn.setLoggedin(null);
          isLoggedIn.setUserType(null);
          isLoggedIn.setIsAdmin(null);
          navigate('/')
          window.location.reload()
      }).catch(err => console.log(err))
  
  }
 
  const DrawerList = (
    <Stack direction='row' 
    sx={{ 
      width: '100%', backgroundColor: 'rgb(0, 70, 66)', 
      overflowY: "auto",
      "&::-webkit-scrollbar": { width: "11px" },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
      },
      "&::-webkit-scrollbar-track": { background: "#929292", borderRadius: "10px" },
      }} role="presentation">
      <Divider />
      <Stack sx={{width: '100%', height: '100%'}} direction='column' width={300} >
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisDashboard(true)}>
          <ListItemCom label="Home" />
        </Button>
      </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisTwindixIndivdual(true)}>
          <ListItemCom label="Twindix Executives Reports " />
          </Button>
      </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisJopBar(true)}>
        <ListItemCom label="Job bar Reports " />
        </Button>
        </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisMangeTwindixAssessments(true)}>
        <ListItemCom label="Mange Twindix Assessments" />
        </Button>
        </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisMangeJopBarAssessments(true)}>
        <ListItemCom label="Manage Job bar Assessments" />
        </Button>
        </span>
        <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisBenchmarksShelve(true)}>
        <ListItemCom label="Shelve Benchmarks" />
        </Button>
        </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisSubscriptions(true)}>
        <ListItemCom label="Subscriptions" />
        </Button>
        </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisMyPackages(true)}>
        <ListItemCom label="My Orders" />
        </Button>
        </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',background :  'rgba(55, 55, 55)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%',}} onClick={() => setRoles(true)}>
        <ListItemCom label="Roles" />
        </Button>
        </span>
        <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',background :  'rgba(55, 55, 55)', width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisTeamAccess(true)}>
        <ListItemCom label="Team Access" />
        </Button>
        </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',background :  'rgba(55, 55, 55)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setCompany(true)}>
        <ListItemCom label="company" />
        </Button>
        </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',background :  'rgba(55, 55, 55)', width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setEmployee(true)}>
        <ListItemCom label="Participant" />
        </Button>
        </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',background :  'rgba(55, 55, 55)', width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setMyEnrollments(true)}>
        <ListItemCom label="My Assessments" />
        </Button>
        </span>
        <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',background : 'rgba(55, 55, 55)', width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisHetInTouch(true)}>
        <ListItemCom label="Get In Touch"/>
        </Button>
        </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',background : 'rgba(55, 55, 55)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={logout}>
        <ListItemCom label="Log Out" />
        </Button>
        </span>
      </Stack>
    </Stack>
  );

  return (
    <div>
      <Drawer open={open} onClose={toggleDrawer(false)}>
      <Box  onClick={toggleDrawer(false)} textAlign="right" sx={{ color :"white" ,cursor: 'pointer', backgroundColor: 'rgb(0, 70, 66)'}}>
      <CloseIcon />
      </Box>
        {DrawerList}
      </Drawer>
    </div>
  );
}
